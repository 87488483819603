import { WellCertStatus } from './WellCertStatus';

export const tableClassNames = {
    wellGroupCell: 'wellCertTable-wellGroupCell', //
    wellCertStatus: 'wellCertStatus', //
    emptyCell: 'dataGrid-emptyCell', //
    certStatus(status: WellCertStatus) {
        return 'wellCertStatus-' + WellCertStatus[status];
    },
};

export const tableStyles = {
    ['& .' + tableClassNames.wellCertStatus]: {
        fontWeight: 500,
    },
    ['& .' + tableClassNames.certStatus(WellCertStatus.Expired)]: {
        color: '#FF0000',
    },
    ['& .' + tableClassNames.certStatus(WellCertStatus.ExpiresSoon)]: {
        color: '#9D1ACB',
    },
    ['& .' + tableClassNames.certStatus(WellCertStatus.Active)]: {
        color: '#3D6F1E',
    },
    ['& .' + tableClassNames.certStatus(WellCertStatus.NeverCertified)]: {
        color: '#999',
    },
    ['& .' + tableClassNames.certStatus(WellCertStatus.Upcoming)]: {
        color: '#078AD2',
    },
    ['& .' + tableClassNames.wellGroupCell]: {
        fontWeight: 500,
    },
    ['& .' + tableClassNames.emptyCell + '::before']: {
        color: 'primary.hintText',
        content: "'―'",
    },
};
