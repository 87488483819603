import { useMemo, useState } from 'react';
import _ from 'lodash';
import { AnnotationsGetAnnotationsResponseEvaluationScopeAssetAnnotationTopicDto } from '@projectcanary/trustwell-server-client-ts';
import { Annotation } from './QContext';

export type AssetAnnotations = { [assetId: number]: Annotation };

export function useAssetAnnotations() {
    const [assetAnnotations, setAssetAnnotations] = useState<AssetAnnotations>();

    return {
        assetAnnotations: assetAnnotations,
        annotatedAssets: useMemo(() => _.uniq(_.map(assetAnnotations, (annotation, assetId) => Number(assetId))), [assetAnnotations]),
        loadAssetAnnotations(response: AnnotationsGetAnnotationsResponseEvaluationScopeAssetAnnotationTopicDto) {
            const newAnnotations: AssetAnnotations = {};
            for (const note of response.notes) {
                newAnnotations[note.topic.assetId] ??= {};
                newAnnotations[note.topic.assetId].note = note.text;
            }
            for (const documentReference of response.documentReferences) {
                newAnnotations[documentReference.topic.assetId] ??= {};
                newAnnotations[documentReference.topic.assetId].documentReference = documentReference;
            }
            setAssetAnnotations(newAnnotations);
        },
        setAssetAnnotation(assetId: number, annotation: Annotation) {
            setAssetAnnotations((current) => {
                if (annotation.note == undefined && annotation.documentReference == undefined) {
                    delete current[assetId];
                } else {
                    current[assetId] = annotation;
                }
                return { ...current };
            });
        },
    };
}
