import _ from 'lodash';

// 1. replace a sequence of non-word characters with a single underscore
// Example: 'a!!!! ####????b' -> 'a_b'
function normalizeId(value: any) {
    return String(value).trim().replaceAll(/\W+/g, '_');
}

type Part = Record<string, string | [string]> | string;
type BuildTestIdFunction = (parts: Part[]) => string;

function buildSegmentFromPart(part: Part): string {
    if (_.isString(part)) {
        return part;
    } else if (_.isObject(part)) {
        const keyValuePairs = _.map(part, (value, key) => ({ value: value, key: key }));
        if (keyValuePairs.length !== 1) {
            throw new Error('When using { component: id } format in test id, the part must contain exactly one key-value pair.');
        }
        const keyValuePair = keyValuePairs[0];
        const normalizedId = normalizeId(keyValuePair.value);
        if (_.isArray(keyValuePair.value)) {
            return `${keyValuePair.key}[${normalizedId}]`;
        } else {
            return `${normalizedId}-${keyValuePair.key}`;
        }
    } else {
        throw new Error('Unsupported test-id part format.');
    }
}

export const buildTestIdInternal: BuildTestIdFunction = (parts: Part[]) => {
    // go through all the { button: "ok" } parts and turn them into path-like segments of the testId:
    // [{modal:"print"}, { button: "ok" }] -> print-modal.ok-button
    return _.flatMap(parts, (i) => buildSegmentFromPart(i)).join('.');
};

/**
 * Builds a test id given an array of id "parts"
 * @example:
 * const testId = testIdBuilder("messageBox", { messages: ['success'] }, { link: "close"})
 * ...data-testid={ testId }... --> messageBox.messages[success].close-link
 * you can add more parts to the testid (for nested objects, for example)
 * ...data-testId={ buildTestId(testId, { component: "id"})} --> messageBox.messages[success].close-link.id-component
 * @param parts - an array in one of the following formats:
 * @param parts - an array of parts in one of the following formats:
 *  { component-type : component-id } -> "component-type-component-id"
 *  { component-type : [component-id] } -> "component-type[component-id]
 *  "fixed-id" -> "fixed-id"
 *
 * @example:
 * const testId = buildTestId({modal: "Print Dialog}, {button: "ok"});
 * ...
 * <SomeComponent data-testid={testId}...
 */
export function buildTestId(...parts: Part[]) {
    return buildTestIdInternal(parts);
}
