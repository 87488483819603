import {
    AssessmentsAssessment,
    CertificationsInProgressCertification as Issuance,
    ReportGenerationReportFormat as ReportFormat,
} from '@projectcanary/trustwell-server-client-ts';
import DownloadIcon from '@mui/icons-material/Download';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { ReportType } from './PreliminaryReportDownloadsStepViewReportTypes';
import React from 'react';
import { IconButton } from 'Controls/IconButton';

export const buildColumns = (
    assessment: AssessmentsAssessment,
    issuance: Issuance,
    downloadReport: (
        issuance: Issuance,
        wellGroupId: number | undefined,
        downloader: any,
        assessment: AssessmentsAssessment,
        reportName: string,
        reportFormat: ReportFormat
    ) => Promise<void>,
    reportTypes: ReportType[]
) => {
    const columns: GridColDef[] = [
        {
            headerName: 'Pad',
            field: 'padName',
            flex: 1,
        },
    ];

    for (const reportType of reportTypes) {
        if (reportType.isPadLevelReport && assessment.activeModules.includes(reportType.module)) {
            columns.push({
                headerName: reportType.reportName,
                type: 'string',
                field: 'downloadReport' + reportType.reportName,
                align: 'center',
                renderCell: ({ row }) => (
                    <IconButton
                        size={'small'}
                        onClick={() =>
                            downloadReport(issuance, row.wellGroupId, reportType.downloader, assessment, reportType.reportName, reportType.reportFormat)
                        }
                    >
                        <DownloadIcon />
                    </IconButton>
                ),
                width: 200,
            } as GridColDef);
        }
    }
    return columns;
};
