import { DataGridPro, DataGridProProps, GRID_TREE_DATA_GROUPING_FIELD } from '@mui/x-data-grid-pro';
import _ from 'lodash';
import { useMemo } from 'react';
import { CertificationWellCertification as WellCertification } from '@projectcanary/trustwell-server-client-ts';
import DataGridCustomToolbar from 'Controls/DataGridCustomToolbar';
import { columns, groupingColumns, Row, WellGroupRow, WellRow } from './IssuedOprsTableColumns';
import { tableStyles } from './IssuedRatingsTableStyles';
import { useGridDefaultSort } from 'Utils/useGridDefaultSort';
import { withInitializers } from 'Utils/withInitializers';
import { certificationApi } from 'Services/TrustwellApiService';

type CertificationsTableProps = { assessmentId: number; forceRefreshCounter: number; wells: WellCertification[] };

const getTreeDataPath: DataGridProProps<Row>['getTreeDataPath'] = (row) => row.hierarchy;

const InnerIssuedOprsTable = ({ wells }: CertificationsTableProps) => {
    const rows: Row[] = useMemo(() => {
        // create well group rows
        const wellGroups = _.groupBy(wells, (wellCert) => wellCert.wellGroupId);
        const wellGroupRows = _.map(wellGroups, (wells, wgId) => {
            const firstWellCert = wells[0]!;
            const earliestExpiringCert = _.chain(wells)
                .orderBy((w) => w.timing?.timeTillExpiration, 'asc')
                .take(1)
                .value()[0];
            return {
                wellGroupId: Number(wgId), //
                hierarchy: [firstWellCert.wellGroupName], //
                status: earliestExpiringCert.status,
                timing: earliestExpiringCert.timing,
            } as WellGroupRow;
        });

        // create well rows
        const wellRows = _.map(wells, (well) => {
            return {
                hierarchy: [well.wellGroupName, well.wellRegistrationId],
                ...well,
            } as WellRow;
        });

        return [...wellRows, ...wellGroupRows];
    }, [wells]);

    const sortModel = useGridDefaultSort([{ field: GRID_TREE_DATA_GROUPING_FIELD, sort: 'asc' }]);

    return (
        <DataGridPro<Row>
            sx={tableStyles}
            treeData={true}
            getTreeDataPath={getTreeDataPath}
            columns={columns}
            rows={rows}
            groupingColDef={groupingColumns}
            defaultGroupingExpansionDepth={0}
            getRowId={(row) => row.hierarchy.join('/')}
            {...sortModel}
            components={{ Toolbar: DataGridCustomToolbar }}
        />
    );
};

export const IssuedOprsTable = withInitializers(
    {
        wells: async ({ assessmentId }) => {
            const api = await certificationApi();
            const ratings = (await api.getWellGroupCertifications(assessmentId)).wellCertifications.map(
                (wc) => _.mapValues(wc, (v) => (v === null ? undefined : v)) as WellCertification
            );
            return ratings;
        },
    },
    InnerIssuedOprsTable,
    'component'
);
