import { useField, useFormikContext } from 'formik';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { randomUniqueId } from 'Utils/uniqueIdGenerator';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { StringDisabledProps, withDisabledMessage } from 'Utils/withDisabledMessage';

export type RadioButtonGroupOption<T> = { label: string; value: T; disabled?: boolean };

export type InnerRadioButtonGroupFieldProps<T> = {
    name: string;
    label?: string;
    onChange?: (value: T) => void;
    options: Record<string, T> | RadioButtonGroupOption<T>[]; // TODO: replace with direction: 'row'|'column'
    row?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    sx?: SxProps<Theme>;
};

const InnerRadioButtonGroupField = <T extends any>(props: InnerRadioButtonGroupFieldProps<T>) => {
    const [field, meta, helpers] = useField(props.name);
    const { isValidating } = useFormikContext();
    const id = useMemo(randomUniqueId, []);
    const options: RadioButtonGroupOption<T>[] = useMemo(() => {
        if (_.isArray(props.options)) {
            return props.options;
        } else {
            return _.map(props.options, (value, key) => ({
                label: key,
                value: value,
                disabled: false,
            }));
        }
    }, [props.options]);

    const handleChange = useCallback(
        (e) => {
            const fieldValue = _.find(options, { label: e.target.value }).value;
            if (props.onChange) {
                props.onChange(fieldValue);
            }

            // HACK:
            // Formik has a bug: https://github.com/jaredpalmer/formik/issues/2457
            // If you call setTouched right after setValue - validation is executed twice,
            // first with correct value, and second validation is executed with incorrect value.
            // Something about validation being executed asynchronously.
            // The fix is to delay setTouched execution (that's what's done here).
            // Another approach is to use an undocumented feature of setValue. setValue is declared as
            // a void function, but it actually returns a promise, which we could use to call setTouched.
            helpers.setValue(fieldValue);
            _.defer(() => helpers.setTouched(true));
        },
        [helpers.setTouched, helpers.setValue, options, props.onChange]
    );

    return (
        <FormControl error={!isValidating && meta.touched && !!meta.error} fullWidth={true} sx={props.sx} disabled={props.disabled}>
            {props.label && <FormLabel id={id}>{props.label}</FormLabel>}
            <RadioGroup
                id={id}
                row={props.row}
                onChange={handleChange}
                onBlur={() => {
                    helpers.setTouched(true);
                }}
                value={_.find(options, (option) => _.isEqual(option.value, field.value))?.label ?? '__not_selected__'}
                sx={props.fullWidth ? { width: '100%', justifyContent: 'space-between' } : {}}
            >
                {_.map(options, (option) => (
                    <FormControlLabel
                        control={<Radio color="primary" />}
                        label={option.label}
                        // Note: although property 'value' has 'unknown' type, it converts everything into a string;
                        // and to support complex types (like arrays) we use label as a "id" for each option and
                        // then map it back to the value when need to
                        value={option.label}
                        key={option.label}
                        disabled={props.disabled || option.disabled}
                    />
                ))}
            </RadioGroup>
            <FormHelperText>{!isValidating && meta.touched && meta.error}</FormHelperText>
        </FormControl>
    );
};

export type RadioButtonGroupFieldProps<T> = StringDisabledProps<InnerRadioButtonGroupFieldProps<T>>;
export const RadioButtonGroupField: <T extends any>(props: RadioButtonGroupFieldProps<T>) => JSX.Element = withDisabledMessage(InnerRadioButtonGroupField);
