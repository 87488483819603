import { DatePicker } from '@mui/x-date-pickers-pro';
import { useField } from 'formik';
import _ from 'lodash';
import { startOfDay } from 'date-fns';
import { DateView } from '@mui/x-date-pickers/models/views';
import { withDisabledMessage } from 'Utils/withDisabledMessage';

export type DateSpecificity = 'day' | 'month';

const specificityConfigurations: Record<DateSpecificity, { views: DateView[]; initialView: DateView }> = {
    day: { views: ['year', 'month', 'day'], initialView: 'day' },
    month: { views: ['year', 'month'], initialView: 'month' },
};

export type DatePickerFieldProps = {
    name: string;
    label: string;
    disabled?: boolean;
    specificity?: DateSpecificity;
    initialView?: DateView;
};

const today = new Date();
const noValueDate = {} as Date;

export const DatePickerField = withDisabledMessage(
    ({ disabled, label, name, specificity, initialView, ...rest }: DatePickerFieldProps & { [key: string]: any }) => {
        const [field, meta, helper] = useField<Date>(name);

        const config = specificityConfigurations[specificity ?? 'day'];

        return (
            <DatePicker
                disabled={disabled}
                value={field.value ?? noValueDate}
                openTo={initialView ?? config.initialView}
                onChange={(value) => {
                    // Note: for some reason date picker includes current time when value is entered using a keyboard.
                    // So we have to reset it to the UTC midnight.
                    // Note: DatePicker's no-value is `null` and we use `undefined`.
                    helper.setValue(value ? startOfDay(value) : undefined);
                    // HACK:
                    // Formik has a bug: https://github.com/jaredpalmer/formik/issues/2457
                    // If you call setTouched right after setValue - validation is executed twice,
                    // first with correct value, and second validation is executed with incorrect value.
                    // Something about validation being executed asynchronously.
                    // The fix is to delay setTouched execution (that's what's done here).
                    // Another approach is to use an undocumented feature of setValue. setValue is declared as
                    // a void function, but it actually returns a promise, which we could use to call setTouched.
                    _.defer(() => helper.setTouched(true));
                }}
                {...rest}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        helperText: meta.touched && meta.error,
                        error: meta.touched && !!meta.error,
                        variant: 'standard',
                        onBlur: () => {
                            helper.setTouched(true);
                        },
                    },
                }}
                defaultCalendarMonth={today}
                defaultValue={today}
                views={config.views}
                label={label}
                format={'MM/dd/yyyy'}
            />
        );
    }
);
