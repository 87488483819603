import _ from 'lodash';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const siteMap = {
    assessment: (assessmentId: number) => {
        const assessmentBasePath = '/assessments/' + assessmentId;
        const certificationBasePath = assessmentBasePath + '/ratings';
        const deductionsBasePath = assessmentBasePath + '/deductions';
        const lmvaBasePath = assessmentBasePath + '/lmva';
        const lmrBasePath = assessmentBasePath + '/lmr';

        return _.assign(assessmentBasePath, {
            assets: '/assessments/' + assessmentId + '/assets', //
            deductions: _.assign(deductionsBasePath, {
                edit(deductionId: number) {
                    return deductionsBasePath + '/edit/' + deductionId;
                },
                add: deductionsBasePath + '/add',
            }), //
            certification: _.assign(certificationBasePath, {
                new: certificationBasePath + '/new',
                review: certificationBasePath + '/review',
            }),
            lmva: _.assign((id: number) => ({ edit: lmvaBasePath + '/' + id + '/edit' }), {
                toString: () => lmvaBasePath,
                add: lmvaBasePath + '/add',
            }),
            lmr: _.assign((section: string) => lmrBasePath + '/' + section, {
                toString: () => lmrBasePath,
            }),
        });
    },
    external: {
        sharePoint: {
            documentsRoot:
                'https://projectcanary1.sharepoint.com/sites/TrustWell/Shared%20Documents/Forms/AllItems.aspx?e=5%3Af1eaa91b9f874235ba8d9cab217a50a7&at=9&CT=1675874867125&OR=OWA%2DNT&CID=947d7c50%2D9166%2D5217%2Dd40b%2D10043d3356d7&FolderCTID=0x012000FCBC3464707CDD48B727A765D320CAF0&id=%2Fsites%2FTrustWell%2FShared%20Documents%2FGeneral%2F00%5FDeliverable%20Upload&viewid=322b338e%2D416a%2D4d9e%2D8afc%2Dfee42901faf3',
        },
    },
} as const;

export type SiteMap = typeof siteMap;

function openInNewWindow(url: string): void {
    window.open(url, '_blank');
}

export type UseNavigationApi = {
    site: SiteMap;
    navigateTo: (page: any) => void;
    replacePath: (url: string) => void;
    openInNewWindow: (url: string) => void;
};

export const useNavigation = (): UseNavigationApi => {
    const reactNavigate = useNavigate();

    const context = useMemo(
        () => ({
            site: siteMap,
            navigateTo: function (page: string | any) {
                reactNavigate(String(page));
            },
            openInNewWindow,
            replacePath: function (url: string) {
                reactNavigate(url, { replace: true, preventScrollReset: true });
            },
        }),
        [reactNavigate]
    );

    return context;
};
