import React, { useEffect, useState } from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { Button } from 'Controls/Button';
import ScoringRow from './ScoringRow';
import { LocalFactorsLocalFactor } from '@projectcanary/trustwell-server-client-ts';
import { useModal } from 'Controls/Modal';
import { FormattedScoresObject, LocalFactorScope, LocalFactorScore } from './LocalFactorConfig';

type AssessmentLevelScoringContainerProps = {
    scopedLocalFactors: LocalFactorsLocalFactor[];
    handleCancelAssessmentScores: () => void;
    handleSaveAssessmentLevelScores: (assessmentScores: FormattedScoresObject) => void;
};

const AssessmentLevelScoringContainer = ({
    scopedLocalFactors,
    handleCancelAssessmentScores,
    handleSaveAssessmentLevelScores,
}: AssessmentLevelScoringContainerProps) => {
    const modal = useModal();
    const [currentScores, setCurrentScores] = useState<FormattedScoresObject>({});

    useEffect(() => {
        const scoreObj = {};
        scopedLocalFactors.forEach((factor: LocalFactorsLocalFactor) => {
            const newScoreKey = { [factor.localFactorId]: null };
            Object.assign(scoreObj, newScoreKey);
        });
        setCurrentScores(scoreObj);
    }, [scopedLocalFactors]);

    const handleScoring = (_, newScore: LocalFactorScore, localFactor: LocalFactorsLocalFactor) => {
        setCurrentScores({
            ...currentScores,
            [localFactor.localFactorId]: newScore,
        });
    };

    const handleShowModal = () => {
        modal
            .show(
                { title: 'Warning', buttons: ['Accept', 'Cancel'] },
                <Typography variant="subtitle1">
                    This change is permanent, please make sure your Assessment Level Local Factors are configured correctly before continuing.
                </Typography>
            )
            .onAccept(handleModalConfirmation);
    };

    const handleModalConfirmation = () => {
        handleSaveAssessmentLevelScores(currentScores);
    };

    const allFactorsScored = () => {
        return Object.values(currentScores).every((score) => score !== null);
    };

    return (
        <Grid item xs={12}>
            <Accordion expanded={true}>
                <AccordionSummary>Assessment Level Local Factors</AccordionSummary>
                <AccordionDetails>
                    <ScoringRow
                        scope={LocalFactorScope.assessment}
                        scopedLocalFactors={scopedLocalFactors}
                        currentScopedScores={currentScores}
                        handleCommitScore={handleScoring}
                    />
                </AccordionDetails>
            </Accordion>
            <div>
                <Button variant="secondary" onClick={handleCancelAssessmentScores}>
                    Cancel
                </Button>
                <Button variant="primary" disabled={!allFactorsScored()} onClick={handleShowModal} style={{ marginLeft: '20px' }}>
                    Save Assessment Level Scores
                </Button>
            </div>
        </Grid>
    );
};

export default AssessmentLevelScoringContainer;
