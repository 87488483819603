import { AnyObject, Maybe } from 'yup/lib/types';

import * as Yup from 'yup';

export * from 'yup';

declare module 'yup' {
    interface StringSchema<TType extends Maybe<string> = string | undefined, TContext extends AnyObject = AnyObject, TOut extends TType = TType>
        extends BaseSchema<TType, TContext, TOut> {
        noEnclosingSpaces(message?: string): this;
    }

    interface BaseSchema<TCast, TContext, TOutput> {
        sameAs(referenceFieldName: string, referenceFieldLabel: string): this;
    }

    interface DateSchema<TType extends Maybe<Date>, TContext extends AnyObject = AnyObject, TOut extends TType = TType>
        extends BaseSchema<TType, TContext, TOut> {
        sameAs(referenceFieldName: string, referenceFieldLabel: string): this;
        startOfMonth(): this;
    }
}

Yup.addMethod(Yup.mixed, 'sameAs', function (referenceFieldName, referenceFieldLabel) {
    return this.oneOf([Yup.ref(referenceFieldName)], ({ label }) => `${label || 'Field'} must be the same as ${referenceFieldLabel}.`);
});

Yup.addMethod(Yup.date, 'startOfMonth', function () {
    return this.test({ name: 'start-of-month', message: 'The date must be 1st of a month.', test: (value) => !value || value.getDate() === 1 });
});

// we should be able to just use sameAs defined on the mixed schema.
// there is a bug in yup.oneOf implementation which breaks oneOf for Date types:
// https://github.com/jquense/yup/issues/1822
// so instead we are using min/max to emulate oneOf
Yup.addMethod(Yup.date, 'sameAs', function (referenceFieldName, referenceFieldLabel) {
    const messageBuilder = ({ label }) => `${label || 'Field'} must be the same as ${referenceFieldLabel}.`;
    return this.min(Yup.ref(referenceFieldName), messageBuilder).max(Yup.ref(referenceFieldName), messageBuilder);
});

Yup.addMethod(Yup.string, 'noEnclosingSpaces', function (message) {
    return this.test({
        name: 'no-enclosing-spaces',
        message: message || 'No leading or trailing spaces are allowed.',
        test: (value) => typeof value !== 'string' || (!value.startsWith(' ') && !value.endsWith(' ')),
    });
});
