import { POLICY_RUBRIC, PLAN_RUBRIC, EXECUTION_RUBRIC } from '../config';

export const getWellGroupIds = (assessment) => (assessment.wellGroups ? assessment.wellGroups.map((wg) => wg.id) : []);

export const getSampleWells = (assessment) => (assessment.wells ? assessment.wells.filter((well) => well.sample_well) : []);

export const formatDocRefDeleteRequest = (rubricType, wellGroupIds, sampleWells, asset, isCheckAll, question, assessmentId) => {
    const docUpdateArray = [];
    const newDocObj = {
        assessmentId: assessmentId,
        rubricId: question.rubricId,
        rubricQuestionId: question.rubricQuestionId,
        rubricVersionId: question.rubricVersionId,
        rubricQuestionVersionId: question.rubricVersionId,
    };
    if (rubricType === POLICY_RUBRIC) {
        newDocObj.policy = null;
        newDocObj.policyDocumentName = null;
        for (let id of wellGroupIds) {
            docUpdateArray.push({ ...newDocObj, wellgroupId: id });
        }
    } else if (rubricType === PLAN_RUBRIC) {
        newDocObj.plan = null;
        newDocObj.planDocumentName = null;

        if (isCheckAll) {
            for (let id of wellGroupIds) {
                docUpdateArray.push({ ...newDocObj, wellgroupId: id });
            }
        } else if (!isCheckAll) {
            newDocObj.wellgroupId = asset.id;
            docUpdateArray.push({ ...newDocObj });
        }
    } else if (rubricType === EXECUTION_RUBRIC) {
        newDocObj.execution = null;
        newDocObj.executionDocumentName = null;
        if (isCheckAll) {
            for (let well of sampleWells) {
                docUpdateArray.push({ ...newDocObj, wellId: well.id });
            }
        } else if (!isCheckAll) {
            newDocObj.wellId = asset.id;
            docUpdateArray.push({ ...newDocObj });
        }
    }
    return docUpdateArray;
};

export const formatArrayOfUpdatedDocRefObjs = (
    rubricType,
    refName,
    docName,
    wellGroupIds,
    sampleWells,
    asset,
    isCheckAll,
    question,
    assessmentId,
    documentReferences
) => {
    const docUpdateArray = [];
    const newDocObj = {
        assessmentId: assessmentId,
        rubricId: question.rubricId,
        rubricQuestionId: question.rubricQuestionId,
        rubricVersionId: question.rubricVersionId,
        rubricQuestionVersionId: question.rubricVersionId,
    };
    const existingDocument = documentReferences.find(
        (ref) => ref.rubricId === question.rubricId && ref.rubricVersionId === question.rubricVersionId && ref.rubricQuestionId === question.rubricQuestionId
    );
    if (rubricType === POLICY_RUBRIC) {
        newDocObj.policy = refName;
        newDocObj.policyDocumentName = docName;
        newDocObj.plan = existingDocument?.plan;
        newDocObj.planDocumentName = existingDocument?.planDocumentName;
        for (let id of wellGroupIds) {
            docUpdateArray.push({ ...newDocObj, wellgroupId: id });
        }
    } else if (rubricType === PLAN_RUBRIC) {
        newDocObj.plan = refName;
        newDocObj.planDocumentName = docName;
        newDocObj.policy = existingDocument?.policy;
        newDocObj.policyDocumentName = existingDocument?.policyDocumentName;
        if (isCheckAll) {
            for (let id of wellGroupIds) {
                docUpdateArray.push({ ...newDocObj, wellgroupId: id });
            }
        } else if (!isCheckAll) {
            newDocObj.wellgroupId = asset.id;
            docUpdateArray.push({ ...newDocObj });
        }
    } else if (rubricType === EXECUTION_RUBRIC) {
        newDocObj.execution = refName;
        newDocObj.executionDocumentName = docName;
        if (isCheckAll) {
            for (let well of sampleWells) {
                docUpdateArray.push({ ...newDocObj, wellId: well.id });
            }
        } else if (!isCheckAll) {
            newDocObj.wellId = asset.id;
            docUpdateArray.push({ ...newDocObj });
        }
    }
    return docUpdateArray;
};

export const getUpdateRubricQuestionScoreAssetIds = (rubricType, isCheckAllBtn, assetId, wellGroupIds, sampleWells) => {
    switch (rubricType) {
        case POLICY_RUBRIC:
            // at policy level - no assets are being identified
            return undefined;
        case PLAN_RUBRIC:
            // at plan level - assets are well groups
            return isCheckAllBtn ? wellGroupIds : [assetId];
        case EXECUTION_RUBRIC:
            // at execution level - assets are individual wells
            return isCheckAllBtn ? sampleWells.map((well) => well.id) : [assetId];
    }
};

export const filterRubricQuestionsByRubricId = (rubricType, rubricQuestions, rubricId) => {
    if (rubricType === POLICY_RUBRIC) {
        return rubricQuestions.filter((question) => question.policy && question.rubricId === rubricId);
    } else if (rubricType === PLAN_RUBRIC) {
        return rubricQuestions.filter((question) => question.plan && question.rubricId === rubricId);
    } else if (rubricType === EXECUTION_RUBRIC) {
        return rubricQuestions.filter((question) => question.execution && question.rubricId === rubricId);
    }
};
export const getAssessedAssets = (rubricType, wellGroups, sampleWells) => {
    if (rubricType === PLAN_RUBRIC) return wellGroups;
    if (rubricType === EXECUTION_RUBRIC)
        return sampleWells.map((well) => {
            const wellgroup = wellGroups.find((wg) => wg.id === well.wellgroupId);
            return {
                well,
                wellgroup,
            };
        });
    if (rubricType === POLICY_RUBRIC) return [{ id: 0, name: 'All Wells' }];
};

export const sortAssetsAlphabetically = (assets, rubricType) => {
    if (rubricType === EXECUTION_RUBRIC) {
        assets.sort((a, b) => {
            const aProxy = a.name === '' ? a.api10 : a.name;
            const bProxy = b.name === '' ? b.api10 : b.name;
            if (aProxy > bProxy) return 1;
            if (aProxy < bProxy) return -1;
            return 0;
        });
        return assets;
    }
    return assets;
};

export const sortQuestionsByBinValue = (filteredQuestions) =>
    filteredQuestions.sort((a, b) => {
        if (a.bin > b.bin) return -1;
        if (a.bin < b.bin) return 1;
        if (a.bin === b.bin) {
            if (a.text > b.text) return 1;
            if (a.text < b.text) return -1;
        }
        return 0;
    });

export const getCheckedStatus = (asset, question, rubricType, questionScores) => {
    if (rubricType === POLICY_RUBRIC) {
        return questionScores.some((score) => score.rubricQuestionId === question.rubricQuestionId && score.policy !== null);
    } else if (rubricType === PLAN_RUBRIC) {
        return questionScores.some((score) => score.wellgroupId === asset.id && score.plan !== null);
    } else if (rubricType === EXECUTION_RUBRIC) {
        return questionScores.some((score) => score.wellId === asset.id && score.execution !== null);
    }
};

export const getFilteredDocReferences = (asset, question, rubricType, documentReferences) => {
    if (rubricType === POLICY_RUBRIC) {
        const filteredDocs = documentReferences.filter(
            (reference) => reference.rubricQuestionId === question.rubricQuestionId && !!reference.policyDocumentName
        );
        return filteredDocs.length === 0 ? {} : filteredDocs[0];
    } else if (rubricType === PLAN_RUBRIC) {
        const filteredDocs = documentReferences.filter(
            (reference) => reference.wellgroupId === asset.id && reference.rubricQuestionId === question.rubricQuestionId && !!reference.planDocumentName
        );
        return filteredDocs.length === 0 ? {} : filteredDocs[0];
    } else if (rubricType === EXECUTION_RUBRIC) {
        const filteredDocs = documentReferences.filter(
            (reference) =>
                'execution' in reference &&
                reference.wellId === asset.id &&
                reference.rubricQuestionId === question.rubricQuestionId &&
                !!reference.executionDocumentName
        );
        return filteredDocs.length === 0 ? {} : filteredDocs[0];
    }
};

export const isFullRowChecked = (questionScores, assessedAssets, rubricType) => {
    if (questionScores.length !== assessedAssets.length) return false;
    if (rubricType === PLAN_RUBRIC) {
        return questionScores.every((question) => question.plan === questionScores[0].plan && questionScores[0].plan !== null);
    } else if (rubricType === EXECUTION_RUBRIC) {
        return questionScores.every((question) => question.execution === questionScores[0].execution && questionScores[0].execution !== null);
    }
};

export const getSharedDocumentReferenceForRow = (documentReferences, assessedAssets, rubricType) => {
    if (documentReferences.length === assessedAssets.length) {
        if (rubricType === PLAN_RUBRIC) {
            const hasSharedDoc = documentReferences.every(
                (doc) =>
                    doc.planDocumentName === documentReferences[0].planDocumentName &&
                    doc.plan === documentReferences[0].plan &&
                    !!documentReferences[0].planDocumentName
            );
            if (hasSharedDoc) return documentReferences[0];
        } else if (rubricType === EXECUTION_RUBRIC) {
            const hasSharedDoc = documentReferences.every(
                (doc) =>
                    doc.executionDocumentName === documentReferences[0].executionDocumentName &&
                    doc.execution === documentReferences[0].execution &&
                    !!documentReferences[0].executionDocumentName
            );
            if (hasSharedDoc) return documentReferences[0];
        }
    }
    return {};
};
