import React, { useCallback, useContext, useMemo } from 'react';
import { QuestionnairesQuestionnaire, QuestionnairesQuestionnaireQuestion, UnifiedAssetsAsset } from '@projectcanary/trustwell-server-client-ts';
import { DataGridPro } from '@mui/x-data-grid-pro/DataGridPro';
import { GridColumns } from '@mui/x-data-grid-pro';
import { QMatrixCell } from './QMatrixCell';
import _ from 'lodash';
import { GridEnrichedColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { QContext, QContextContext } from './QContext';
import { Avatar, Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { QMatrixDevTools } from './QMatrixDevTools';
import { DevNote, DevSection } from 'Controls/useDevTools';

export type QMatrixProps<TQuestion extends QuestionnairesQuestionnaireQuestion> = {
    assetAnnotationMode?: 'disallowed' | 'allowed' | 'required';
    enableCellAnnotation?: boolean;
    assets: UnifiedAssetsAsset[];
    questionnaire: Omit<QuestionnairesQuestionnaire, 'questions'> & { questions: TQuestion[] };
    assessmentId: number;
    moduleId: number;
    assetLabel?: string;
    headerBadgeRenderer?: (q: TQuestion) => { content: React.ReactNode; tooltip?: string };
    enableCopyToAll?: boolean;
    variant?: 'default' | 'short';
};

type Row = { asset: UnifiedAssetsAsset };

const tallHeaderStyles = {
    '& .MuiDataGrid-columnSeparator > svg.MuiDataGrid-iconSeparator': {
        transform: ['scaleY(5)', 'translateX(1px)'],
    },
    '& .MuiDataGrid-columnHeaders': {
        maxHeight: 'inherit !important',
    },
    '& .MuiDataGrid-columnHeader': {
        height: '6rem !important',
    },
    '& .MuiDataGrid-virtualScroller': {
        marginTop: '6rem !important',
    },
    '& .MuiDataGrid-columnHeaderTitleContainerContent': {
        width: '100%',
    },
};

const hideInputUnderlines = {
    '& .MuiDataGrid-row .MuiTextField-root > .MuiInput-underline::before, & .MuiDataGrid-row .MuiInput-underline:has(.MuiSelect-icon):not(:hover)::before': {
        borderStyle: 'none',
    },
};

const dataGridStyles = { ...tallHeaderStyles, ...hideInputUnderlines };

export const QMatrixInner = <TQuestion extends QuestionnairesQuestionnaireQuestion = QuestionnairesQuestionnaireQuestion>({
    assetAnnotationMode,
    enableCellAnnotation,
    questionnaire,
    assets,
    assetLabel,
    headerBadgeRenderer,
    enableCopyToAll = false,
    variant,
}: Required<QMatrixProps<TQuestion>>) => {
    const { annotatedAssets, editAssetAnnotation, cellStates } = useContext(QContextContext);

    const columns: GridColumns<Row> = useMemo(() => {
        const questions: GridColumns<Row> = questionnaire
            ? questionnaire.questions.map(
                  (q, i) =>
                      ({
                          field: q.text,
                          minWidth: 500,
                          disableColumnMenu: true,
                          flex: 2,
                          filterable: false,
                          sortable: false,
                          hideable: false,
                          valueGetter: (params) => {
                              const cellState = cellStates[params.row.asset.id]?.[q.questionnaireQuestionId];
                              if (!cellState) {
                                  return undefined;
                              } else if (cellState.value !== undefined) {
                                  return cellState.value;
                              } else {
                                  return (cellState.isNA && '<n/a>') || (cellState.isOmitted && '<omitted>') || undefined;
                              }
                          },
                          renderCell: ({ row }) =>
                              React.createElement(QMatrixCell, {
                                  enableCellAnnotation: enableCellAnnotation,
                                  enableCopyToAll: enableCopyToAll,
                                  assetId: row.asset.id,
                                  question: q,
                              }),
                          renderHeader: () => (
                              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1} sx={{ width: '100%' }}>
                                  <Box sx={{ textOverflow: 'clip', whiteSpace: 'break-spaces', lineHeight: 1.3, fontWeight: 900 }} flexGrow={1}>
                                      <DevNote>
                                          ({q.cid})<br />
                                      </DevNote>
                                      {q.text}
                                  </Box>
                                  <Box flexGrow={0}>
                                      {headerBadgeRenderer && (
                                          <Tooltip title={headerBadgeRenderer(q).tooltip}>
                                              <Avatar
                                                  sx={{
                                                      bgcolor: '#024062',
                                                      width: 32,
                                                      height: 32,
                                                  }}
                                              >
                                                  {headerBadgeRenderer(q).content}
                                              </Avatar>
                                          </Tooltip>
                                      )}
                                  </Box>
                              </Stack>
                          ),
                      } as GridEnrichedColDef<Row>)
              )
            : [];

        return [
            {
                field: 'asset',
                headerName: assetLabel ?? 'Asset',
                valueGetter: ({ row }) => row.asset.name,
                renderCell: ({ row: { asset } }) => (
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        {assetAnnotationMode !== 'disallowed' && (
                            <IconButton onClick={() => editAssetAnnotation(asset.id)}>
                                <DescriptionIcon color={annotatedAssets.includes(asset.id) ? 'inherit' : 'disabled'} />
                            </IconButton>
                        )}
                        <Typography variant={'body1'}>{asset.name}</Typography>
                        <DevNote>({asset.id})</DevNote>
                    </Stack>
                ),
                minWidth: 350,
                filterable: true,
            }, // question text goes into column header
            ...questions,
        ];
    }, [questionnaire?.questions, cellStates, annotatedAssets]);

    const rows: Row[] = useMemo(() => _.map(assets, (asset) => ({ asset: asset })), [assets]);

    const getRowId = useCallback(({ asset }) => asset.id, []);

    return (
        <>
            <DevSection>
                <QMatrixDevTools assets={assets} questions={questionnaire.questions} />
            </DevSection>
            <DataGridPro<Row>
                columns={columns}
                rows={rows}
                getRowId={getRowId}
                sx={dataGridStyles}
                pagination={variant !== 'short'}
                hideFooter={variant === 'short'}
            />
        </>
    );
};

export const QMatrix = <TQuestion extends QuestionnairesQuestionnaireQuestion = QuestionnairesQuestionnaireQuestion>({
    assetAnnotationMode = 'disallowed',
    enableCellAnnotation = false,
    questionnaire,
    assets,
    assessmentId,
    moduleId,
    assetLabel,
    headerBadgeRenderer,
    enableCopyToAll,
    variant = 'default',
}: QMatrixProps<TQuestion>) => {
    return (
        <QContext assessmentId={assessmentId} assets={assets} moduleId={moduleId} questionnaire={questionnaire} assetAnnotationMode={assetAnnotationMode}>
            <QMatrixInner
                assetAnnotationMode={assetAnnotationMode}
                enableCopyToAll={enableCopyToAll}
                enableCellAnnotation={enableCellAnnotation}
                questionnaire={questionnaire}
                assets={assets}
                assessmentId={assessmentId}
                moduleId={moduleId}
                assetLabel={assetLabel}
                headerBadgeRenderer={headerBadgeRenderer}
                variant={variant}
            />
        </QContext>
    );
};
