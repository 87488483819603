import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { Button } from 'Controls/Button';
import { Button as MuiButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useLoader } from 'Utils/loader';
import { RulesValidationIssue, RulesSeverity } from '@projectcanary/trustwell-server-client-ts';

type AssestImportModalProps = {
    bypassWarnings: boolean;
    isModalOpen: boolean;
    handleImportAssets: () => void;
    handleCloseModal: () => void;
    handleUploadFile: (e: any) => void;
    handleClearFile: () => void;
    log: RulesValidationIssue[];
    newImportFile: File;
};

const AssetImportModal = ({
    bypassWarnings,
    isModalOpen,
    handleImportAssets,
    handleCloseModal,
    handleUploadFile,
    handleClearFile,
    log,
    newImportFile,
}: AssestImportModalProps) => {
    const [isLoading, withLoader] = useLoader();
    return (
        <Dialog open={isModalOpen} onClose={handleCloseModal}>
            <DialogTitle>Import Assets</DialogTitle>
            <DialogContent>
                <div style={{ marginBottom: '5px' }}>
                    {newImportFile && (
                        <>
                            <span style={{ marginRight: '5px' }}>{newImportFile.name}</span>
                            <Button variant="secondary" onClick={handleClearFile}>
                                Clear
                            </Button>
                        </>
                    )}
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <MuiButton variant="outlined" component="label">
                        <AttachFileIcon />
                        Select a File
                        <input onChange={handleUploadFile} type="file" accept=".xlsx" style={{ display: 'none' }} />
                    </MuiButton>
                </div>
                <TableContainer>
                    <Table sx={{ minWidth: 550 }} aria-label="simple table">
                        <TableHead sx={{ minWidth: 550 }}>
                            <TableRow>
                                <TableCell>Sample Template</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    Wellgroup Name
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    XX-XXX-XXXXX
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    Well Name
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    TRUE / FALSE
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                <DialogContentText>*Sample well 'TRUE/FALSE' is an optional field</DialogContentText>
                <DialogContentText>*.xlsx files only</DialogContentText>
                <DialogContentText>*Header field row required</DialogContentText>
                <DialogContentText>
                    <Link href="/AssetImportTemplate.xlsx" download="AssetImportTemplate.xlsx" target="_blank" rel="noreferrer">
                        Download Template
                    </Link>
                </DialogContentText>
                {log.filter((l) => l.severity === RulesSeverity.Error).length > 0 && (
                    <>
                        <Typography color={'red'} variant="body1">
                            Error[s] Importing Assets
                        </Typography>
                        <ul style={{ color: 'red' }}>
                            {log
                                .filter((l) => l.severity === RulesSeverity.Error)
                                .map((logItem) => {
                                    return <li key={'error-' + logItem.explanation}>{logItem.explanation}</li>;
                                })}
                        </ul>
                    </>
                )}
                {log.filter((l) => l.severity === RulesSeverity.Warning).length > 0 && (
                    <>
                        <Typography color={'orange'} variant="body1">
                            Warning[s] Importing Assets
                        </Typography>
                        <ul style={{ color: 'orange' }}>
                            {log
                                .filter((l) => l.severity === RulesSeverity.Warning)
                                .map((logItem) => {
                                    return <li key={'warning-' + logItem.explanation}>{logItem.explanation}</li>;
                                })}
                        </ul>
                    </>
                )}

                <div style={{ paddingLeft: '10px' }}>
                    <DialogActions>
                        <Button
                            onClick={withLoader(handleImportAssets)}
                            disabled={!newImportFile || isLoading}
                            variant="primary"
                            style={{ marginBottom: '20px' }}
                        >
                            {bypassWarnings ? 'Continue' : 'Import Assets'}
                        </Button>
                        <Button variant="secondary" onClick={handleCloseModal} style={{ margin: '0 10px 20px 10px' }}>
                            Cancel
                        </Button>
                    </DialogActions>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default AssetImportModal;
