import React, { useContext } from 'react';
import { QContextContext } from './QContext';
import _ from 'lodash';
import { QuestionnairesAnswerFormat } from '@projectcanary/trustwell-server-client-ts';
import { nowait } from 'Utils/nowait';
import { Stack } from '@mui/material';
import { Button } from 'Controls/Button';

export const QMatrixDevTools = ({ assets, questions }) => {
    const qContext = useContext(QContextContext);
    const answerAllFlags = (answer: boolean | undefined) => () => {
        const cells = _.chain(questions)
            .filter((q) => q.answerFormat === QuestionnairesAnswerFormat.Flag)
            .flatMap((q) => _.map(assets, (a) => ({ assetId: a.id, qqId: q.questionnaireQuestionId })))
            .value();
        for (const cell of cells) {
            nowait(qContext.setAnswer(cell.assetId, cell.qqId, answer, true));
        }
    };
    return (
        <Stack direction={'row'} spacing={2} marginBottom={-4}>
            <Button onClick={answerAllFlags(true)} variant={'secondary'}>
                Answer all YES
            </Button>
            <Button onClick={answerAllFlags(false)} variant={'secondary'}>
                Answer all NO
            </Button>
            <Button onClick={answerAllFlags(undefined)} variant={'secondary'}>
                Unanswer all
            </Button>
        </Stack>
    );
};
