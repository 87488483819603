import { gridFilteredSortedRowIdsSelector, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid-pro';
import React from 'react';
import Box from '@mui/material/Box';
import { GridToolbarQuickFilter } from 'Controls/GridToolbarQuickFilter';

function DataGridCustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarQuickFilter
                sx={{ width: '400px' }}
                debounceMs={200}
                placeholder={'Search... (Ctrl+Shift+F)'}
                keyboardShortcut={{ ctrlKey: true, shiftKey: true, key: 'F' }}
            />
            <Box sx={{ flex: 1 }} />
            <GridToolbarExport
                csvOptions={{
                    allColumns: true,
                    utf8WithBom: true,
                    getRowsToExport: ({ apiRef }) => gridFilteredSortedRowIdsSelector(apiRef),
                }}
                printOptions={{
                    disableToolbarButton: true,
                }}
            />
        </GridToolbarContainer>
    );
}

export default DataGridCustomToolbar;
