import { useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { FormControl as MuiFormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { buildTestId } from 'Utils/testid';
import { StringDisabledProps, withDisabledMessage } from 'Utils/withDisabledMessage';

const noSelectionKey = '';
const noSelectionPlaceholder = 'None';

type InnerDropdownFieldProps<T> = {
    name: string;
    label: string;
    options: T[] | Promise<T[]>;
    keySelector?: ((T) => any) | keyof T;
    valueRenderer?: ((T) => React.ReactNode) | keyof T;
    menuItemRenderer?: ((T) => React.ReactNode) | keyof T | undefined;
    includeEmptyOption?: boolean;
    disabled?: boolean;
};

// if accessor is:
// string - access property with that name
// function - use function as an accessor
// undefined - use identity function
function normalizeAccessor<T, R>(accessor: keyof T | ((T) => R) | undefined) {
    return accessor ? _.iteratee(accessor) : _.identity;
}

/**
 * @param name - field name for formik tracking
 * @param label - placeholder and field label
 * @param options - an array of objects to show in the drop-down
 * @param keySelector - a function to select a value from the option to use as a key (accepts a prop name also)
 * @param valueRenderer - a function used to render selected value (accepts a prop name also)
 * @param menuItemRenderer - a function used to render options in the selector (accepts a prop name and defaults to
 *     valueRenderer)
 * @param includeEmptyOption - if true (default) will include `None` option. Forced to true if current `value` value
 * is undefined.
 * @param disabled - defaults to false. Disables the field when set to true.
 */
const InnerDropdownField = <T extends any>({
    name,
    label,
    options,
    keySelector,
    valueRenderer,
    menuItemRenderer,
    includeEmptyOption,
    disabled,
}: InnerDropdownFieldProps<T>): JSX.Element => {
    const { values, errors, touched, setFieldTouched, setFieldValue } = useFormikContext();
    const value = values[name];
    const error = errors[name];
    const testId = buildTestId({ dropdown: name });

    includeEmptyOption = includeEmptyOption === undefined || includeEmptyOption;

    const normalizedKeySelector = useMemo(() => {
        return keySelector ? _.iteratee(keySelector) : _.identity;
    }, [keySelector]);
    const normalizedValueRenderer = useMemo(() => normalizeAccessor(valueRenderer), [valueRenderer]);
    const normalizedMenuItemRenderer = useMemo(
        () => normalizeAccessor(menuItemRenderer ?? normalizedValueRenderer),
        [menuItemRenderer, normalizedValueRenderer]
    );

    const labelId = useMemo(() => `${name}_${InnerDropdownField.nextUniqueId++}_label`, [name]);

    const [optionsMap, setOptionsMap] = useState<Record<string, T>>();

    useEffect(() => {
        (async () => {
            // `options` object could be a promise, so we wait until it is resolved and then build an options map.
            // note that if options is *not* a promise, it will be converted into a resolved promise automatically.
            const resolvedOptions = await options;
            setOptionsMap(() => _.keyBy(resolvedOptions, normalizedKeySelector));
        })();
    }, [normalizedKeySelector, options]);

    function renderOptionsElements(optionsMap) {
        return [
            includeEmptyOption ? (
                <MenuItem value={noSelectionKey} key={noSelectionKey} data-testid={buildTestId(testId, { option: 'none' })}>
                    <em>{noSelectionPlaceholder}</em>
                </MenuItem>
            ) : null,
            ..._.map(optionsMap, (option, key) => (
                <MenuItem value={key} key={key} data-testid={buildTestId(testId, { option: key })}>
                    {normalizedMenuItemRenderer(option)}
                </MenuItem>
            )),
        ];
    }

    function handleValueChange(newValue: string) {
        setFieldValue(name, newValue === noSelectionKey ? undefined : optionsMap[newValue]);
    }

    return (
        <MuiFormControl error={touched[name] && !!error} variant={'standard'} margin={'none'} fullWidth={true} data-testid={testId} disabled={disabled}>
            <InputLabel id={labelId}>{optionsMap ? label : '(loading...)'}</InputLabel>
            <Select
                labelId={labelId}
                displayEmpty={true}
                value={value && optionsMap ? normalizedKeySelector(value) : noSelectionKey}
                renderValue={(value) => {
                    return optionsMap?.[value] ? normalizedValueRenderer(optionsMap[value]) : '';
                }}
                onChange={(e) => handleValueChange(e.target.value)}
                onBlur={() => setFieldTouched(name)}
                disabled={disabled}
            >
                {optionsMap ? renderOptionsElements(optionsMap) : undefined}
            </Select>
            <FormHelperText>{touched[name] && error}</FormHelperText>
        </MuiFormControl>
    );
};

export const TextWithDescriptionMenuItem = ({ description, title }: { title: string; description: string }) => {
    return (
        <Stack>
            <div>{title}</div>
            <Typography variant={'caption'}>{description}</Typography>
        </Stack>
    );
};

InnerDropdownField.nextUniqueId = 1;

export type DropdownFieldProps<T> = StringDisabledProps<InnerDropdownFieldProps<T>>;
export const DropdownField: <T extends any>(props: DropdownFieldProps<T>) => JSX.Element = withDisabledMessage(InnerDropdownField) as <T extends any>(
    props: DropdownFieldProps<T>
) => JSX.Element;
