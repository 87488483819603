import { GridValueFormatterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { LmrLmrRating, ScoresAllEventWellScoresResponse, ScoresGetRatingMedalOverridesByAssessmentResponse } from '@projectcanary/trustwell-server-client-ts';
import { formatLmrRating, medalRankingSortComparator } from 'Utils/formats';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem, GridColTypeDef, GridColumns, GridEnrichedColDef } from '@mui/x-data-grid-pro';
import RestartAlt from '@mui/icons-material/RestartAlt';
import EditIcon from '@mui/icons-material/Edit';
import _ from 'lodash';
import { emptyDataGridCellClass } from 'Apperance/Theme';
import { ModulesModuleType as ModuleType } from '@projectcanary/trustwell-server-client-ts';


export type Row = ScoresAllEventWellScoresResponse & {
    ratingMedalOverride: ScoresGetRatingMedalOverridesByAssessmentResponse;
    lmrScore: number | undefined;
    lmrRating: LmrLmrRating | undefined;
};

function withNA(colDef?: GridColTypeDef): GridColTypeDef {
    return {
        ...colDef,
        valueFormatter(params: GridValueFormatterParams) {
            if (params.value === undefined) {
                return undefined;
            } else if (colDef?.valueFormatter) {
                return colDef.valueFormatter(params);
            } else {
                return params.value;
            }
        },
        cellClassName(params) {
            return params.value === undefined ? emptyDataGridCellClass : undefined;
        },
    };
}

const scoreFormat: GridColTypeDef = withNA({
    type: 'number',
    valueFormatter: ({ value }) => (value === undefined ? '-' : _.ceil(value, 0)),
});

const controlStrengthFormat: GridColTypeDef = withNA({
    type: 'number',
    valueFormatter: ({ value }) => (value === undefined ? '-' : _.round(value, 1).toFixed(1)),
});

const lmrRatingFormat: GridColTypeDef = withNA({
    type: 'string',
    valueFormatter: ({ value }: GridValueFormatterParams<LmrLmrRating>) => {
        formatLmrRating(value);
    },
});


export const ScoresTableColumns: (editMedal: (row: Row) => void, resetRatingMedalOverride: (row: Row) => void, activeModules: ModuleType[]) => GridColumns<Row> = (
    editMedal,
    resetRatingMedalOverride,
    activeModules
) => {

    const allColumns: (GridEnrichedColDef<Row> & { modules?: ModuleType[] })[] = [
        {
            headerName: 'Well Group',
            field: 'wellGroupName',
            type: 'string',
            width: 250,
        },
        {
            headerName: 'Well Identifier (API/UWI/NTS)',
            field: 'api10',
            type: 'string',
            width: 250,
        },
        {
            headerName: 'Well Id',
            field: 'id',
            type: 'number',
            width: 150,
        },
        {
            headerName: 'Inherent Risk Profile',
            field: 'inherentRiskProfile',
            width: 200,
            ...scoreFormat,
            modules: [ModuleType.Opr, ModuleType.Lmva],
        },
        {
            headerName: 'Control Strength',
            field: 'controlStrength',
            width: 150,
            ...controlStrengthFormat,
            modules: [ModuleType.Opr, ModuleType.Lmva],
        },
        {
            headerName: 'Performance Score',
            field: 'performanceScore',
            width: 150,
            ...scoreFormat,
            modules: [ModuleType.Opr, ModuleType.Lmva],
        },
        {
            headerName: 'LMR Score',
            field: 'lmrScore',
            type: 'number',
            width: 150,
            ...withNA({}),
            modules: [ModuleType.Lmr],
        },
        {
            headerName: 'LMR Rating',
            field: 'lmrRating',
            width: 150,
            ...lmrRatingFormat,
            modules: [ModuleType.Lmr],
        },
        {
            headerName: 'Medal',
            align: 'right',
            field: 'ratingMedal',
            width: 150,
            type: 'string',
            sortable: true,
            modules: [ModuleType.Opr, ModuleType.Lmva],
            sortComparator: medalRankingSortComparator,
            renderCell: ({ row }) => {
                return row.ratingMedalOverride ? (
                    <>
                        <Tooltip title={row.ratingMedalOverride.notes} placement="bottom-start">
                            <div>{row.ratingMedal}</div>
                        </Tooltip>
                        <GridActionsCellItem icon={<RestartAlt />} label="Reset Rating Medal" onClick={() => resetRatingMedalOverride(row)} color="inherit" />
                    </>
                ) : (
                    <>
                        <div>{row.ratingMedal}</div>
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Edit"
                            sx={{ '&:hover': { opacity: '100%' }, opacity: '20%' }}
                            onClick={() => editMedal(row)}
                            color="inherit"
                        />
                    </>
                );
            },
        },
        {
            headerName: 'Water',
            field: 'waterScore',
            width: 100,
            ...scoreFormat,
            modules: [ModuleType.Opr, ModuleType.Lmva],
        },
        {
            headerName: 'Air',
            field: 'airScore',
            width: 100,
            ...scoreFormat,
            modules: [ModuleType.Opr, ModuleType.Lmva],
        },
        {
            headerName: 'Land',
            field: 'landScore',
            width: 100,
            ...scoreFormat,
            modules: [ModuleType.Opr, ModuleType.Lmva],
        },
        {
            headerName: 'Community',
            field: 'communityScore',
            width: 150,
            ...scoreFormat,
            modules: [ModuleType.Opr, ModuleType.Lmva],
        },
    ]

    const filteredColumns = _.chain(allColumns)
    .filter(column => 
        _.isUndefined(column.modules) || 
        _.intersection(column.modules, activeModules).length > 0
    )
    .map(column => _.omit(column, 'modules'))
    .value();

    return filteredColumns;
}

