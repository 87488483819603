import { default as MuiLinearProgress } from '@mui/material/LinearProgress';
import { Box, Stack, Typography } from '@mui/material';
import * as _ from 'lodash';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles/createTheme';

export type LinearProgressProps = { tickCount: number; label: string; value: number; sx?: SxProps<Theme> };

const LinearProgressWithTickMarks = ({ value, sx, tickCount }: { value: number; tickCount: number; sx?: SxProps<Theme> }) => {
    return (
        <Box sx={{ ...sx, position: 'relative' }}>
            <Stack
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1,
                    '&>*': { borderColor: 'black', borderWidth: '2px', width: '0px', borderRightStyle: 'solid' },
                }}
                direction={'row'}
                padding={0}
                justifyContent={'space-evenly'}
            >
                {_.map(Array(tickCount), (item, index) => (
                    <div key={index} />
                ))}
            </Stack>
            <MuiLinearProgress variant={'determinate'} value={value} sx={{ width: '100%', mr: 1.5, borderRadius: 5, height: 10 }} />
        </Box>
    );
};

export const LinearProgress = ({ sx, label, tickCount, value }: LinearProgressProps) => {
    return (
        <Stack direction={'row'} alignItems={'center'} sx={{ position: 'relative', ...sx }} spacing={1.5}>
            <LinearProgressWithTickMarks tickCount={tickCount} sx={{ width: '100%' }} value={value} />
            <Box sx={{ width: 100 }}>
                <Typography variant={'body2'} align={'right'}>
                    {label}
                </Typography>
            </Box>
        </Stack>
    );
};
