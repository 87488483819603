import React, { useState, useEffect } from 'react';
import { AssessmentDefinitionsGetAllAssessmentDefinitionsResponse } from '@projectcanary/trustwell-server-client-ts';
import { Button } from 'Controls/Button';
import PageTitle from 'Controls/PageTitle';
import { assessmentDefinitionApi } from 'Services/TrustwellApiService';
import AssessmentDefinitionModal from 'Components/AssessmentDefinitionModal';
import OverflowContainer from 'Components/OverflowContainer';
import { useMessageBox } from 'Controls/MessageBox';

const AssessmentDefinitionList = () => {
    const messageBox = useMessageBox();
    const [definitions, setDefinitions] = useState<AssessmentDefinitionsGetAllAssessmentDefinitionsResponse>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [logMessages, setLogMessages] = useState<string[]>([]);
    const [newDefName, setNewDefName] = useState<string>('');
    const [newDefFile, setNewDefFile] = useState<File | undefined>(undefined);

    async function getDefs() {
        try {
            const api = await assessmentDefinitionApi();
            const defs = await api.getAllAssessmentDefinitions();
            setDefinitions(defs);
        } catch (error) {
            messageBox.error('Error fetching the assessment definitions. ' + error);
        }
    }

    useEffect(() => {
        getDefs();
    }, []);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleChangeName = (e) => {
        setNewDefName(e.target.value);
    };

    const handleFileUpload = (e) => {
        if (e.target.files.length) {
            setNewDefFile(e.target.files[0]);
        }
        e.target.value = '';
    };

    const handleClearFile = () => {
        setNewDefFile(undefined);
        setLogMessages([]);
    };

    const handleCloseModal = () => {
        setNewDefFile(undefined);
        setLogMessages([]);
        setNewDefName('');
        setIsModalOpen(false);
    };

    const handleCreateAssessmentDefintion = async () => {
        const api = await assessmentDefinitionApi();
        try {
            const newDef = await api.importAssessmentDefinition(newDefFile, newDefName);

            if (newDef.log.length > 0) {
                setLogMessages(newDef.log);
                messageBox.error(`${newDef.log.length} error[s] occured. Fix errors and reattempt.`);
            } else {
                const newDefs = [...definitions['assessmentDefinitions'], { id: newDef.createdAssessmentDefinitionId, name: newDefName }];
                messageBox.success(`Assessment Definition ${newDefName} was successfully created.`);
                setDefinitions({ assessmentDefinitions: newDefs });

                getDefs();
                handleCloseModal();
            }
        } catch (error) {
            messageBox.error(`Failed to create new Assessment Definition. ` + error);
        }
    };

    return (
        <OverflowContainer>
            <PageTitle title="Assessment Definitions" />
            <Button variant="primary" onClick={handleOpenModal} style={{ margin: '0 0 20px 20px' }}>
                Create New Assessment Definition
            </Button>
            <ul>
                {definitions?.assessmentDefinitions.map((d) => (
                    <li key={d.id}>{d.name}</li>
                ))}
            </ul>

            <AssessmentDefinitionModal
                isModalOpen={isModalOpen}
                handleCreateAssessmentDefintion={handleCreateAssessmentDefintion}
                handleCloseModal={handleCloseModal}
                handleChangeName={handleChangeName}
                handleFileUpload={handleFileUpload}
                handleClearFile={handleClearFile}
                log={logMessages}
                newDefFile={newDefFile}
                newDefName={newDefName}
            />
        </OverflowContainer>
    );
};

export default AssessmentDefinitionList;
