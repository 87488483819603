import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { getBearerToken } from 'Services/TrustwellApiService';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Button } from 'Controls/Button';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'left',
        fontSize: 14,
    },
}));

const TokenPage = () => {
    const [token, setToken] = useState<string>();
    const classes = useStyles();

    async function getToken() {
        setToken(await getBearerToken());
    }

    useEffect(() => {
        getToken();
    }, []);

    return (
        <Grid container className={classes.root} padding={2}>
            <Grid item xs={4}>
                <textarea style={{ wordBreak: 'break-all', width: '100%', height: 200 }} readOnly={true} value={token}></textarea>
            </Grid>
            <Grid item xs={12}>
                <br />
            </Grid>
            <Grid item xs={12}>
                <Button variant={'primary'} onClick={() => navigator.clipboard.writeText(token)}>
                    <ContentCopyOutlinedIcon />
                    Copy to clipboard
                </Button>
            </Grid>
            <Grid item xs={12}>
                <br />
            </Grid>
            <Grid item xs={12}>
                Give Emissions ‘the Bird’.
            </Grid>
        </Grid>
    );
};

export default TokenPage;
