import _ from 'lodash';

// duck-type check the value for being a Promise
function isPromise<T>(value: any): value is Promise<T> {
    return !!value && typeof (value as Promise<T>).then === 'function';
}

_.mixin({
    isPromise: isPromise,
});

declare module 'lodash' {
    interface LoDashStatic {
        isPromise<T>(value: any): value is Promise<T>;
    }
}

export default _;
