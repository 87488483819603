import { ReactElement } from 'react';
import { Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { LmrLmrRating, OprRatingMedal } from '@projectcanary/trustwell-server-client-ts';
import { formatLmrRating } from 'Utils/formats';

export type IssueRatingsSummaryData = {
    totalWellGroups: number;
    totalWells: number;
    oprMedals: { [medal in OprRatingMedal]: number };
    lmrRatings: { [rating in LmrLmrRating]: number };
    isLmrDisplayed: boolean;
};

function formatLmrBreakdown(lmrRatings: any): string {
    return _.chain(lmrRatings)
        .pickBy((count) => count > 0)
        .map((count, category) => `${formatLmrRating(LmrLmrRating[category])}/${count}`)
        .join(', ')
        .value();
}

function formatOprBreakdown(oprMedals: any): string {
    return _.chain(oprMedals)
        .pickBy((count) => count > 0)
        .map((count, category) => `${category}/${count}`)
        .join(', ')
        .value();
}

export type IssueRatingsSummaryDetailsViewProps = { issueRatingsSummaryData: IssueRatingsSummaryData };

export const IssueRatingsSummaryDetailsView = ({ issueRatingsSummaryData: issueRatingsSummaryData }: IssueRatingsSummaryDetailsViewProps): ReactElement => {
    return (
        <Stack direction="row" spacing={5} sx={{ pb: 1 }}>
            {_.map(
                {
                    ['Total Pads']: issueRatingsSummaryData.totalWellGroups,
                    ['Total Wells']: issueRatingsSummaryData.totalWells,
                    ['OPR Medal Counts (Wells)']: formatOprBreakdown(issueRatingsSummaryData.oprMedals),
                },
                (value, label) => (
                    <Typography key={label} variant="body2" fontWeight="500">
                        {label}: {value}
                    </Typography>
                )
            )}
            {issueRatingsSummaryData.isLmrDisplayed && (
                <>
                    <Typography variant="body2" fontWeight="500">
                        {['LMR Counts (Pads)']}: {formatLmrBreakdown(issueRatingsSummaryData.lmrRatings)}
                    </Typography>
                </>
            )}
        </Stack>
    );
};
