import React, { FC } from 'react';
import { QMatrixJsonCell } from './QMatrixJsonCell';
import { QuestionnairesAnswerFormat, QuestionnairesQuestionnaireQuestion as Question } from '@projectcanary/trustwell-server-client-ts';
import { QMatrixNumberCell } from './QMatrixNumberCell';
import { QMatrixStringCell } from './QMatrixStringCell';
import { QMatrixFlagCell } from './QMatrixFlagCell';
import { QMatrixChoiceCell } from './QMatrixChoiceCell';
import { QMatrixConstantCell } from './QMatrixConstantCell';
import { QMatrixDateRangeCell } from './QMatrixDateRangeCell';
import { QMatrixDateCell } from './QMatrixDateCell';

const cellElementMap: { [answerFormat: string]: FC<QMatrixCellProps> } = {
    [QuestionnairesAnswerFormat.Flag]: QMatrixFlagCell,
    [QuestionnairesAnswerFormat.Number]: QMatrixNumberCell,
    [QuestionnairesAnswerFormat.String]: QMatrixStringCell,
    [QuestionnairesAnswerFormat.Choice]: QMatrixChoiceCell,
    [QuestionnairesAnswerFormat.Constant]: QMatrixConstantCell,
    [QuestionnairesAnswerFormat.DateRange]: QMatrixDateRangeCell,
    [QuestionnairesAnswerFormat.Date]: QMatrixDateCell,
};
const defaultElement = QMatrixJsonCell;

export type QMatrixCellProps = {
    assetId: number;
    question: Question;
    enableCellAnnotation: boolean;
    enableCopyToAll?: boolean;
};
export const QMatrixCell: (props: QMatrixCellProps) => JSX.Element = ({ assetId, question, enableCellAnnotation, enableCopyToAll }) => {
    const cellElement = cellElementMap[question.answerFormat] ?? defaultElement;
    return React.createElement(cellElement, { assetId, question, enableCellAnnotation, enableCopyToAll });
};
