import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress, Paper, Table, TableContainer } from '@mui/material';
import { withStyles } from '@mui/styles';
import QuestionsTableHeader from './QuestionTableHeader';
import QuestionsTableBody from './QuestionsTableBody';
import _ from 'lodash';
import { EXECUTION_RUBRIC } from '../config';
import {
    filterRubricQuestionsByRubricId,
    formatArrayOfUpdatedDocRefObjs,
    formatDocRefDeleteRequest,
    getAssessedAssets,
    getUpdateRubricQuestionScoreAssetIds,
    sortAssetsAlphabetically,
} from './rubricHelperFunctions';
import { rubricApi } from 'Services/TrustwellApiService';
import { useLoader } from 'Utils/loader';
import { useModal } from 'Controls/Modal';
import { useMessageBox } from 'Controls/MessageBox';

const QuestionsTable = ({ rubricPageData, getAssessmentDocuments, classes, assessment, rubricType, rubricQuestions = [], rubricId }) => {
    const [isLoading, withLoader] = useLoader();
    const [questionScores, setQuestionScores] = useState([]);
    const [documentReferences, setDocumentReferences] = useState([]);
    const [documentNames, setDocumentNames] = useState([]);
    const messageBox = useMessageBox();

    const wellGroupIds = rubricPageData?.wellGroups?.map((wg) => wg.id);
    const sampleWells = rubricPageData?.sampleWells;
    const modal = useModal();

    const getScoreData = async () => {
        try {
            const api = await rubricApi();
            const scores = await api.getRubricQuestionScores(rubricId, assessment.id);
            const execScores = await api.getRubricQuestionExecScores(rubricId, assessment.id);
            setQuestionScores([...scores, ...execScores]);

            const _documentNames = await api.getAllDocumentNamesByAssessment(assessment.id);

            const formattedDocumentNames = _documentNames
                .map((name) => {
                    return { label: name };
                })
                .reduce((unique, o) => {
                    if (!unique.some((obj) => obj.label === o.label)) {
                        unique.push(o);
                    }
                    return unique;
                }, []);

            setDocumentNames(formattedDocumentNames);

            if (rubricType === 'Execution') {
                const _documentReferences = await api.getAllExecDocumentReferencesByAssessment(assessment.id);
                setDocumentReferences(_documentReferences);
            } else {
                const _documentReferences = await api.getAllDocumentReferencesByAssessment(assessment.id);
                setDocumentReferences(_documentReferences);
            }
        } catch (error) {
            messageBox.error('Error the score data. ' + error);
        }
    };

    useEffect(withLoader(getScoreData), [rubricType, assessment, rubricId]);

    const handleDocumentUpdate = async (
        isChecked,
        assessmentId,
        isDeleteRequest,
        isCheckAll,
        asset,
        question,
        docName,
        refName,
        documentNames,
        handleShowModal,
        isRequired
    ) => {
        let docReference;
        const api = await rubricApi();
        const docUpdateArray = formatArrayOfUpdatedDocRefObjs(
            rubricType,
            refName,
            docName,
            wellGroupIds,
            sampleWells,
            asset,
            isCheckAll,
            question,
            assessmentId,
            documentReferences
        );

        const isExec = rubricType === EXECUTION_RUBRIC;
        try {
            if (isDeleteRequest) {
                if (!(isCheckAll && isChecked && isRequired)) {
                    const deleteRequest = formatDocRefDeleteRequest(rubricType, wellGroupIds, sampleWells, asset, isCheckAll, question, assessmentId);
                    if (isExec) {
                        await api.updateRubricQuestionExecComments(deleteRequest);
                    } else {
                        await api.updateRubricQuestionComments(deleteRequest);
                    }
                }
            } else {
                docReference = docUpdateArray[0] ? docUpdateArray[0] : {};
                if (isExec) {
                    await api.updateRubricQuestionExecComments(docUpdateArray);
                } else {
                    await api.updateRubricQuestionComments(docUpdateArray);
                }
            }
            getScoreData();
            if (!isChecked && !isDeleteRequest) {
                handleCheck(assessmentId, question, asset, isCheckAll, isChecked, rubricType, docReference, isCheckAll, handleShowModal);
            }
        } catch (error) {
            messageBox.error('Error updating the document reference. ' + error);
        }

        // if successful and new document label, add it to selection choices
        // if (response && response.length > 0 && !documentNames.map((doc) => doc.label).includes(docName)) {
        // const _documentNames = await api.getAllDocumentNamesByAssessment(assessment.id);
    };

    const handleCheck = withLoader(
        async (assessmentId, question, asset, isCheckAllBtn, isChecked, rubricType, docReference, sharedDocument, handleOpenModal) => {
            const wellGroupsSampleWellCounts = _.countBy(sampleWells, (sw) => sw.wellgroupId);
            if ((isCheckAllBtn && _.some(wellGroupsSampleWellCounts, (count) => count > 1)) || (asset && wellGroupsSampleWellCounts[asset.wellgroupId] > 1)) {
                modal.show(
                    {
                        title: 'Multiple Sample Wells Warning',
                    },
                    `We no longer support multiple sample wells. Please go to the assets page and pick one sample well.`
                );
                return;
            }

            // if missing required document reference, display modal
            if (question.documentRequired && docReference && Object.keys(docReference).length === 0) {
                handleOpenModal();
                return;
            }
            // if missing shared document reference on check all, display modal
            if (question.documentRequired && isCheckAllBtn && sharedDocument && Object.keys(sharedDocument).length === 0 && !isChecked) {
                handleOpenModal();
                return;
            }

            const request = {
                assessmentId: assessmentId,
                rubricId: question.rubricId,
                rubricQuestionId: question.rubricQuestionId,
                rubricVersionId: question.rubricVersionId,
                rubricQuestionVersionId: question.rubricVersionId,
                assetIds: getUpdateRubricQuestionScoreAssetIds(rubricType, isCheckAllBtn, asset?.id, wellGroupIds, sampleWells),
                score: isChecked ? null : question.bin,
                assessmentLevel: rubricType,
            };
            try {
                const api = await rubricApi();
                await api.updateRubricQuestionScores(request);

                await getScoreData();

                // if action is to uncheck, remove document reference when unchecked
                if (!isCheckAllBtn && isChecked && Object.keys(docReference).length > 0) {
                    await handleDocumentUpdate(true, assessmentId, true, isCheckAllBtn, asset, question, '', '', []);
                }
            } catch (error) {
                messageBox.error('Error updating the rubric score. ' + error);
            }
        }
    );

    const filteredQuestions = filterRubricQuestionsByRubricId(rubricType, rubricQuestions, rubricId);
    const assessedAssets = getAssessedAssets(rubricType, rubricPageData.wellGroups, rubricPageData.sampleWells);
    const sortedAssets = sortAssetsAlphabetically(assessedAssets, rubricType);

    return (
        <TableContainer className={classes.root} component={Paper}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Table size="small">
                <QuestionsTableHeader assessmentId={assessment?.id} rubricType={rubricType} assessedAssets={sortedAssets} />
                <QuestionsTableBody
                    classes={classes}
                    assessmentId={assessment?.id}
                    rubricType={rubricType}
                    filteredQuestions={filteredQuestions}
                    questionScores={questionScores}
                    documentReferences={documentReferences}
                    assessedAssets={sortedAssets}
                    handleCheck={handleCheck}
                    handleDocumentUpdate={handleDocumentUpdate}
                    documentNames={documentNames}
                />
            </Table>
        </TableContainer>
    );
};

const styles = (theme) => ({
    root: { overflow: 'auto' },
});

export default withStyles(styles)(QuestionsTable);
