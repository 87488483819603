import React, { useCallback, useMemo, useRef } from 'react';
import { AssessmentsAssessment, AssessmentsLmvaDetails, QuestionnairesQuestionnaire } from '@projectcanary/trustwell-server-client-ts';
import { useNavigation } from 'Utils/Navigation';
import { assessmentApi, questionnaireApi } from 'Services/TrustwellApiService';
import { Page } from './Page';
import { useMessageBox } from 'Controls/MessageBox';
import { withInitializers } from 'Utils/withInitializers';
import { useLoader } from 'Utils/loader';
import { EditLmvaForm, emissionProtocols, methaneIntensityProtocols } from './EditLmvaForm';
import { Button } from 'Controls/Button';
import { Stack } from '@mui/material';

type AddLmvaProps = {
    assessment: AssessmentsAssessment; //
    questionnaire: QuestionnairesQuestionnaire; //
};

function getSingleOrDefault(collection) {
    return collection.length === 1 ? collection[0] : undefined;
}

const InnerAddLmvaPage = ({ assessment, questionnaire }: AddLmvaProps) => {
    const { site, navigateTo } = useNavigation();
    const messageBox = useMessageBox();
    const [isLoading, withLoader] = useLoader();

    const initialValues: Partial<AssessmentsLmvaDetails> = useMemo(
        () => ({
            name: '',
            methaneIntensity: undefined,
            vintage: undefined,
            methaneIntensityProtocol: getSingleOrDefault(methaneIntensityProtocols[assessment.countryCode]),
            emissionProtocol: getSingleOrDefault(emissionProtocols[assessment.countryCode]),
            segment: 'Production',
            production: 'XXX,XXX',
            benchmark: 'NETL',
            granularity: 'Basin-Level',
        }),
        [assessment.countryCode]
    );

    const createLmva = useCallback(
        withLoader(async (lmva: any) => {
            const assessmentApiInstance = await assessmentApi();
            try {
                const request = {
                    assessmentId: assessment.id,
                    name: lmva.name,
                    vintage: lmva.vintage,
                    methaneIntensity: Number(lmva.methaneIntensity),
                    segment: lmva.segment,
                    emissionProtocol: lmva.emissionProtocol,
                    methaneIntensityProtocol: lmva.methaneIntensityProtocol,
                    granularity: lmva.granularity,
                    production: lmva.production,
                    benchmark: lmva.benchmark,
                    questionnaireId: questionnaire.id,
                    questionnaireResponses: lmva.responses,
                };
                await assessmentApiInstance.createLmva(request);
                navigateTo(site.assessment(assessment.id).lmva);
            } catch (error) {
                messageBox.error("Couldn't create an LMVA. " + error);
            }
        }),
        [assessment.id]
    );

    const formRef = useRef<any>();

    return (
        <Page title={'Create New LMVA'} isLoading={isLoading}>
            <Stack direction={'column'} maxWidth={1200}>
                <EditLmvaForm onSubmit={createLmva} lmva={initialValues} assessment={assessment} questionnaire={questionnaire} innerRef={formRef} />
                <Stack spacing={3} direction="row" justifyContent="end">
                    <Button onClick={() => formRef.current.resetForm()} variant={'secondary'}>
                        Reset
                    </Button>
                    <Button onClick={() => navigateTo(site.assessment(assessment.id).lmva)} variant={'secondary'}>
                        Cancel
                    </Button>
                    <Button variant={'primary'} onClick={() => formRef.current.submitForm()}>
                        Add LMVA
                    </Button>
                </Stack>
            </Stack>
        </Page>
    );
};

export const AddLmvaPage = withInitializers(
    {
        async questionnaire() {
            const api = await questionnaireApi();
            const questionnaire = await api.getCurrentLmvaQuestionnaire();
            return questionnaire;
        },
    },
    InnerAddLmvaPage
);
