import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Input, Typography } from '@mui/material';
import { Button } from 'Controls/Button';
import { Button as MuiButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const AssessmentDefinitionModal = ({
    isModalOpen,
    handleCreateAssessmentDefintion,
    handleCloseModal,
    handleChangeName,
    handleFileUpload,
    handleClearFile,
    log,
    newDefFile,
    newDefName,
}) => {
    return (
        <Dialog open={isModalOpen} onClose={handleCloseModal}>
            <DialogTitle>Create Assessment Definition</DialogTitle>
            <DialogContent>
                <div style={{ paddingLeft: '10px' }}>
                    <div>
                        <Input onChange={handleChangeName} type="text" placeholder="Name" style={{ marginBottom: '20px' }} />
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                        {newDefFile && (
                            <>
                                <span style={{ marginRight: '5px' }}>{newDefFile.name}</span>
                                <Button variant="secondary" onClick={handleClearFile}>
                                    Clear
                                </Button>
                            </>
                        )}
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <MuiButton variant="outlined" component="label">
                            <AttachFileIcon />
                            Select a File
                            <input onChange={handleFileUpload} type="file" accept=".xlsx" style={{ display: 'none' }} />
                        </MuiButton>
                    </div>
                    {log.length > 0 && (
                        <>
                            <Typography color={'red'} variant="body1">
                                Error[s] Creating Assessment Definition
                            </Typography>
                            <ul style={{ color: 'red' }}>
                                {log.map((logItem) => {
                                    return <li key={logItem}>{logItem}</li>;
                                })}
                            </ul>
                        </>
                    )}
                    <DialogActions>
                        <Button
                            onClick={handleCreateAssessmentDefintion}
                            disabled={!newDefName || !newDefFile}
                            variant="primary"
                            style={{ marginBottom: '20px' }}
                        >
                            Create Assessment Definition
                        </Button>
                        <Button variant="secondary" onClick={handleCloseModal} style={{ margin: '0 10px 20px 10px' }}>
                            Cancel
                        </Button>
                    </DialogActions>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default AssessmentDefinitionModal;
