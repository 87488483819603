import _ from 'lodash';
import { Tooltip } from '@mui/material';
import React from 'react';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { LmrLmrRating } from '@projectcanary/trustwell-server-client-ts';
import clsx from 'clsx';
import { tableClassNames } from 'Pages/Ratings/IssuedRatingsTableStyles';
import { GridCellParams } from '@mui/x-data-grid';

const medalRanking = ['Evaluated', 'Silver', 'Gold', 'Platinum'];

export const medalRankingSortComparator = (valueA, valueB) => medalRanking.indexOf(valueA) - medalRanking.indexOf(valueB);

export function formatDateOnly(date: Date) {
    return `${date.getUTCMonth() + 1}/${String(date.getUTCDate()).padStart(2, '0')}/${date.getUTCFullYear()}`;
}

export function utcDateFormatter({ value: date }: { value?: Date }) {
    return date ? formatDateOnly(date) : '';
}

export function vintageFormatter({ value: vintage }: { value?: number }) {
    return vintage ? vintage.toString() : '-';
}

export function decimalPlacesFormatter(decimalPlaces: number) {
    return ({ value }): number | undefined => (value === undefined ? '-' : value.toFixed(decimalPlaces));
}

export const emptyPlaceholder = ({ formattedValue, value, rowNode }: GridCellParams) =>
    clsx({
        [tableClassNames.emptyCell]: rowNode.children === undefined && ((formattedValue ?? value) === '' || (formattedValue ?? value) === undefined),
    });

/**
 * Try to convert project canary email address in format bob.doe@company.com to "Bob Doe"
 * otherwise returns value unchanged.
 */
export function formatPersonName(value: string, format: 'FullName' | 'FirstNamePlusLastNameInitial' = 'FullName') {
    const pattern = /^(.)(.*)\.(.)(.*)@projectcanary.com$/;
    return value.match(pattern)
        ? value.replace(pattern, (full: string, firstNameInitial: string, firstNameTail: string, lastNameInitial: string, lastNameTail: string) => {
              switch (format) {
                  case 'FullName':
                      return `${firstNameInitial.toUpperCase()}${firstNameTail} ${lastNameInitial.toUpperCase()}${lastNameTail}`;
                  case 'FirstNamePlusLastNameInitial':
                      return `${firstNameInitial.toUpperCase()}${firstNameTail} ${lastNameInitial.toUpperCase()}.`;
              }
          })
        : value;
}

export const formatLocalFactorScores = (value: number | undefined) => (value === undefined ? '-' : _.ceil(value, 0));

export const personNameColumn: Partial<GridColDef> = {
    type: 'string',
    width: 150,
    renderCell: (params) =>
        params.value ? (
            <Tooltip title={params.value}>
                <span>{formatPersonName(params.value, 'FirstNamePlusLastNameInitial')}</span>
            </Tooltip>
        ) : (
            <></>
        ),
};

export const formatOprScore = (value: number | undefined): string | undefined => (value === undefined ? undefined : _.ceil(value).toString());

export const oprScoreColumn: Partial<GridColDef> = {
    type: 'number',
    valueFormatter: ({ value }) => formatOprScore(value),
};

export function formatLmrRating(rating: LmrLmrRating): string {
    switch (rating) {
        case LmrLmrRating.Mr:
            return 'MR';
        case LmrLmrRating.MrQ:
            return 'MR-Q';
        case LmrLmrRating.A:
            return 'A';
        case LmrLmrRating.Aa:
            return 'AA';
        case LmrLmrRating.Aaa:
            return 'AAA';
        case LmrLmrRating.AQ:
            return 'A-Q';
        case LmrLmrRating.AaQ:
            return 'AA-Q';
        case LmrLmrRating.AaaQ:
            return 'AAA-Q';
        case undefined:
            return 'DNQ';
        default:
            throw new Error(`Unknown LMR rating value '${rating}'.`);
    }
}

export const LmrRatingColumn: Partial<GridColDef> = {
    type: 'string',
    valueFormatter: ({ value }) => formatLmrRating(value),
};
