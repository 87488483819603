import { GridColumns } from '@mui/x-data-grid';
import { CertificationsLmrCertificatePublication } from '@projectcanary/trustwell-server-client-ts';
import { decimalPlacesFormatter, emptyPlaceholder, formatPersonName, utcDateFormatter, vintageFormatter } from 'Utils/formats';

const padNameColumnDimensions = {
    minWidth: 320,
    maxWidth: 450,
    flex: 1,
};

const dateColumnDimensions = {
    width: 200,
};

const numberColumnDimensions = {
    width: 200,
};

const ratingColumnDimensions = {
    width: 200,
};

const producerColumnDimensions = {
    width: 200,
};

export const columns: GridColumns<CertificationsLmrCertificatePublication> = [
    {
        field: 'padAssetName',
        headerName: 'Pad Name',
        type: 'string',
        ...padNameColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
    {
        field: 'publicationDateTime',
        headerName: 'Issued Date',
        type: 'date',
        ...dateColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
    {
        field: 'certificateStartDate',
        headerName: 'Rating Start',
        type: 'date',
        ...dateColumnDimensions,
        valueFormatter: utcDateFormatter,
        cellClassName: emptyPlaceholder,
    },
    {
        field: 'certificateEndDate',
        headerName: 'Rating End',
        type: 'date',
        valueFormatter: utcDateFormatter,
        ...dateColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
    {
        field: 'lmrRating',
        headerName: 'Rating',
        type: 'string',
        ...ratingColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
    {
        field: 'lmrScore',
        headerName: 'Score',
        type: 'number',
        ...numberColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
    {
        field: 'basinMethaneIntensity',
        headerName: 'Basin MI',
        type: 'number',
        valueFormatter: decimalPlacesFormatter(4),
        ...numberColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
    {
        field: 'padMethaneIntensity',
        headerName: 'Pad MI',
        type: 'number',
        valueFormatter: decimalPlacesFormatter(4),
        ...numberColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
    {
        field: 'vintage',
        headerName: 'Vintage',
        type: 'string',
        valueFormatter: vintageFormatter,
        ...numberColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
    {
        field: 'lmrModuleVersionName',
        headerName: 'Version',
        type: 'string',
        ...numberColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
    {
        field: 'producerName',
        headerName: 'Producer',
        type: 'string',
        ...producerColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
    {
        field: 'publisher',
        headerName: 'Issued By',
        type: 'string',
        valueFormatter: ({ value }) => value && formatPersonName(value),
        ...producerColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
];
