import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Button } from 'Controls/Button';
import { Link } from 'react-router-dom';
import { useSite } from './Site';
import { buildTestId } from 'Utils/testid';
import Box from '@mui/material/Box';

export const Header = () => {
    const [username, setUsername] = useState('');
    const { sectionName } = useSite();
    const testId = buildTestId('header');

    useEffect(() => {
        const getUser = async () => {
            const currentUser = await Auth.currentUserInfo();
            const name = currentUser.username.split('.')[0];
            setUsername(name);
        };
        getUser();
    }, []);

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '65px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            }}
        >
            <Box
                component="h1"
                data-testid={testId}
                sx={{
                    width: '100%',
                    height: '65px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                <Link
                    to="/assessments"
                    style={{
                        color: 'white',
                        textDecoration: 'none',
                        marginLeft: '20px',
                    }}
                    data-test={buildTestId(testId, { link: 'home' })}
                >
                    TrustWell {sectionName ? ` (${sectionName})` : ''}
                </Link>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginLeft: '20px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '20px',
                    }}
                    data-testid={buildTestId(testId, { text: 'username' })}
                >
                    {username}
                </Box>
                <Button
                    onClick={() => Auth.signOut()}
                    variant="primary"
                    data-testid={buildTestId(testId, { button: 'signOut' })}
                    sx={{ /* Add any styles you want for the button here */ }}
                >
                    Sign out
                </Button>
            </Box>
        </Box>
    );
};

