import { Route, Routes } from 'react-router-dom';
import { AssessmentsAssessmentDetails, ModulesModuleType as ModuleType } from '@projectcanary/trustwell-server-client-ts';
import { Feature } from 'Config';
import AssetsListPage from 'Pages/AssetsListPage';
import DeductionsPage from 'Pages/DeductionsPage';
import EventsPage from 'Pages/EventsPage';
import { LmrPage } from 'Pages/Lmr/LmrPage';
import { LmvaPage } from 'Pages/LmvaPage';
import { AddLmvaPage } from 'Components/AddLmvaPage';
import { EditLmvaPage } from 'Components/EditLmvaPage';
import { LocalFactorsPage } from 'Pages/LocalFactorsPage';
import { IssuedRatingsListPage } from 'Pages/Ratings/IssuedRatingsListPage';
import RubricsPage from 'Pages/RubricsPage';
import { ScoresPage } from 'Pages/Scores/ScoresPage';
import React, { ReactElement } from 'react';
import AddDeductionForm from 'Components/Deductions/AddDeductionForm';
import { IssueRatingsSetupPage } from 'Pages/Ratings/IssueRatingsSetupPage';
import { WellGroupReview } from 'Pages/Ratings/WellGroupReview';

export type AssessmentSectionPage = {
    isDefaultPage?: boolean;
    label: string;
    path: string;
    pathPattern?: string;
    feature?: Feature;
    modules?: ModuleType[];
    page: (assessment: AssessmentsAssessmentDetails) => ReactElement;
};

export const assessmentSectionPages: AssessmentSectionPage[] = [
    {
        isDefaultPage: true,
        label: 'Assets',
        path: '/assets',
        page: (assessment) => <AssetsListPage assessment={assessment} />,
    },
    {
        label: 'Local Factors',
        path: '/local-factors',
        modules: [ModuleType.Opr],
        page: (assessment) => <LocalFactorsPage assessmentId={assessment.id} />,
    },
    {
        label: 'Rubrics',
        path: '/rubrics',
        modules: [ModuleType.Opr],
        page: (assessment) => <RubricsPage assessment={assessment} />,
    },
    {
        label: 'Events',
        path: '/events',
        modules: [ModuleType.Opr],
        page: (assessment) => <EventsPage assessment={assessment} />,
    },
    {
        label: 'Deductions',
        path: '/deductions',
        pathPattern: '/*',
        feature: Feature.Deductions,
        modules: [ModuleType.Opr],
        page: (assessment) => (
            <Routes>
                <Route path="/" element={<DeductionsPage assessment={assessment} />} />
                <Route path="/edit/:deductionId" element={<AddDeductionForm assessment={assessment} isEditing={true} />} />
                <Route path="/add" element={<AddDeductionForm assessment={assessment} />} />
            </Routes>
        ),
    },
    {
        label: 'LMVA',
        path: '/lmva',
        pathPattern: '/*',
        feature: Feature.CertificateGeneration,
        modules: [ModuleType.Lmva],
        page: (assessment) => (
            <Routes>
                <Route path="/" element={<LmvaPage assessment={assessment} />} />
                <Route path="/add" element={<AddLmvaPage assessment={assessment} />} />
                <Route path="/:lmvaId/edit" element={<EditLmvaPage assessment={assessment} />} />
            </Routes>
        ),
    },
    {
        label: 'LMR',
        path: '/lmr',
        pathPattern: '/:section?',
        modules: [ModuleType.Lmr],
        page: (assessment) => <LmrPage assessment={assessment} />,
    },
    {
        label: 'Scores',
        path: '/scores',
        page: (assessment) => <ScoresPage assessment={assessment} />,
    },
    {
        label: 'Ratings',
        path: '/ratings',
        pathPattern: '/*',
        feature: Feature.CertificateGeneration,
        page: (assessment) => (
            <Routes>
                <Route path="/" element={<IssuedRatingsListPage assessmentId={assessment.id} />} />
                <Route path="/new" element={<IssueRatingsSetupPage assessmentId={assessment.id} />} />
                <Route path="/review" element={<WellGroupReview assessment={assessment} />} />
            </Routes>
        ),
    },
];
