import React, { useEffect, useMemo, useState } from 'react';
import { ModalFormik } from 'Controls/Modal';
import * as Yup from 'Utils/yup';
import { Form } from 'formik';
import { Stack, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import { CheckboxField } from 'Controls/Fields/CheckboxField';
import { formatPersonName } from 'Utils/formats';
import { nowait } from 'Utils/nowait';
import { TextField } from 'Controls/Fields/TextField';
import { DevSection, useDevTools } from 'Controls/useDevTools';

const passphrase = 'PUBLISH FINAL VERSION';
const schema = Yup.object().shape({
    verifiedChecked: Yup.bool().isTrue('Please check the checkbox').required(),
    passphrase: Yup.string().required('Please enter the passphrase').uppercase().oneOf([passphrase], `Passphrase has to be ${passphrase}`),
});

export const CertifyAssetFinalReviewModal: () => JSX.Element = () => {
    const [username, setUsername] = useState<string>();
    const devToolsEnabled = useDevTools();

    useEffect(() => {
        const getUser = async () => {
            const { username } = await Auth.currentUserInfo();
            setUsername(formatPersonName(username));
        };
        nowait(getUser());
    }, [devToolsEnabled]);

    const initialValues = useMemo(
        () => ({
            verifiedChecked: devToolsEnabled,
            passphrase: devToolsEnabled ? passphrase : undefined,
            skipFileUpload: false,
        }),
        [devToolsEnabled]
    );

    return (
        <ModalFormik initialValues={initialValues} validationSchema={schema}>
            <Form>
                <Stack spacing={1}>
                    <Typography variant={'critical'}>
                        Issuing ratings with the assessment scores will finalize the assessment and allow you to access the final report and ratings.
                    </Typography>
                    <Typography variant={'critical'}>Please double-check that everything is correct before continuing.</Typography>
                    <CheckboxField
                        name="verifiedChecked"
                        label="By selecting this checkbox, you verify that you have reviewed all assets and are prepared to issue ratings"
                    />
                    <TextField name={'passphrase'} label={`Please enter ${passphrase}`} disableAutoComplete />
                    <Typography>Name: {username ?? '(loading)'} </Typography>
                    <DevSection>
                        <CheckboxField name={'skipFileUpload'} label={'Skip file upload'} />
                    </DevSection>
                </Stack>
            </Form>
        </ModalFormik>
    );
};
