import React from 'react';
import { Autocomplete, createFilterOptions, TextField as MuiTextField } from '@mui/material';
import { useField } from 'formik';
import { FilterOptionsState } from '@mui/base/useAutocomplete';

export type NewOption = { inputValue: string; isNew: true };

export function isNewOption(option: any | NewOption): option is NewOption {
    return (option as any)?.isNew === true;
}

const filter = createFilterOptions();

function filterOptions<TOption>(options: TOption[], state: FilterOptionsState<TOption>) {
    const filtered = filter(options, state);
    const exists = options.some((option) => state.inputValue === state.getOptionLabel(option));
    if (state.inputValue !== '' && !exists) {
        filtered.push({ inputValue: state.inputValue, isNew: true });
    }
    return filtered as TOption[];
}

export type AutocompleteFieldProps<TOption, AllowNewOption> = {
    options: (AllowNewOption extends true ? TOption | NewOption : TOption)[];
    name: string;
    label: string;
    disabled?: boolean;
    variant?: 'outlined' | 'standard' | 'filled';
    labelSelector?: (param: TOption) => string;
    isRequired?: boolean;
    allowNewOption?: AllowNewOption;
};

export function AutocompleteField<TOption, AllowNewOption = false>({
    options,
    name,
    label,
    disabled,
    variant,
    labelSelector,
    isRequired,
    allowNewOption,
}: AutocompleteFieldProps<TOption, AllowNewOption>) {
    const [field, meta, helper] = useField<AllowNewOption extends true ? TOption | NewOption : TOption>(name);

    return (
        <Autocomplete<AllowNewOption extends true ? TOption | NewOption : TOption, false, false, true>
            filterOptions={allowNewOption ? filterOptions : undefined}
            fullWidth
            multiple={false}
            selectOnFocus
            clearOnBlur
            getOptionLabel={(option) => (typeof option === 'string' ? '' : isNewOption(option) ? option.inputValue : labelSelector(option as TOption))}
            renderOption={(props, option) => {
                return <li {...props}>{isNewOption(option) ? `Create: "${option.inputValue}"` : labelSelector(option as TOption)}</li>;
            }}
            renderInput={(params) => (
                <MuiTextField
                    required={isRequired}
                    helperText={meta.touched && meta.error}
                    error={meta.touched && !!meta.error}
                    label={label}
                    variant={variant ?? 'standard'}
                    {...params}
                />
            )}
            value={field.value ?? ''}
            onBlur={() => {
                helper.setTouched(true);
            }}
            onChange={(e: any, newValue: (AllowNewOption extends true ? TOption | NewOption : TOption) | string) => {
                helper.setTouched(true);
                if (typeof newValue !== 'string') {
                    helper.setValue(newValue ?? undefined);
                }
            }}
            freeSolo={true}
            disabled={disabled}
            options={options ?? []}
        />
    );
}
