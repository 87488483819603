import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import {
    DocumentsDocument,
    QuestionnairesQuestionnaire,
    QuestionnairesQuestionnaireQuestion,
    UnifiedAssetsAsset,
} from '@projectcanary/trustwell-server-client-ts';
import { useLoader } from 'Utils/loader';
import { annotationApi, documentApi, questionnaireApi } from 'Services/TrustwellApiService';
import _ from 'lodash';
import { nowait } from 'Utils/nowait';
import { useMessageBox } from 'Controls/MessageBox';
import { useModal } from 'Controls/Modal';
import { AnnotationModal } from 'Components/AnnotationModal';
import { QMatrixProps } from './QMatrix';
import { isNewOption, NewOption } from 'Controls/Fields/AutocompleteField';
import { useAssetAnnotations } from './useAssetAnnotations';
import { isEmptyResponse, QCellState, QCellStateMatrix, useCellStates } from './useCellStates';

export type QContextProps = {
    assets: UnifiedAssetsAsset[];
    questionnaire: QuestionnairesQuestionnaire;
    assessmentId: number;
    moduleId: number;
    children: ReactNode;
    assetAnnotationMode?: QMatrixProps<QuestionnairesQuestionnaireQuestion>['assetAnnotationMode'];
};

export type QCellSyncStatus = //
    // the value is being fetched from the server
    | 'fetching'
    // the value has been retrieved but might be out of sync with the server
    | 'stale'
    // the value has been recently fetched
    | 'fresh'
    // failed to fetch the value from the server
    | 'failedToFetch'
    // failed to send new value (the value, response, or annotation) to the server
    | 'failedToSend'
    // updated value, response, or annotation is being sent to the server
    | 'sending';

export type QContextType = {
    assets: UnifiedAssetsAsset[];
    questionnaire: QuestionnairesQuestionnaire;
    cellStates: QCellStateMatrix;
    annotatedAssets: number[];
    setAnswer(assetId: number, questionnaireQuestionId: number, answer: any, skipAnnotationPrompt?: boolean): Promise<void>; //
    editQuestionAnnotation(assetId: number, questionnaireQuestionId: number): Promise<void>;
    editAssetAnnotation(assetId: number): Promise<void>;
    copyToAll(assetId: number, questionnaireQuestionId: number, bypassConfirmation?: boolean): Promise<void>;
    setIsNA(assetId: number, questionnaireQuestionId: number, isNA: boolean): Promise<void>;
    setIsOmitted(assetId: number, questionnaireQuestionId: number, isOmitted: boolean): Promise<void>;
};

export type Annotation = {
    documentReference?: {
        documentName: string; //
        documentId: number; //
        citation: string;
    }; //
    note?: string;
};

export const QContextContext = React.createContext<QContextType>({
    assets: undefined!,
    questionnaire: undefined!,
    cellStates: undefined!,
    editQuestionAnnotation: undefined!,
    editAssetAnnotation: undefined!,
    copyToAll: undefined!,
    annotatedAssets: [],
    setAnswer: undefined!,
    setIsNA: undefined!,
    setIsOmitted: undefined!,
});

type Response = { answer: any; isNA: boolean; isExplicitlyOmitted: boolean };

export const QContext = ({ questionnaire, assets, assessmentId, moduleId, children, assetAnnotationMode }: QContextProps) => {
    const { cellStateCounter, cellStates, loadCellStates, mutateCellState } = useCellStates();
    const [isLoading, withLoader] = useLoader();
    const messageBox = useMessageBox();
    const modal = useModal();
    const { assetAnnotations, annotatedAssets, loadAssetAnnotations, setAssetAnnotation } = useAssetAnnotations();

    useEffect(
        withLoader(async () => {
            const questionnaireApiInstance = await questionnaireApi();
            const annotationApiInstance = await annotationApi();

            try {
                const [assessmentEvaluation, assetQuestionAnnotationsResponse, assetAnnotationsResponse] = await Promise.all([
                    questionnaireApiInstance.getAssessmentEvaluation({
                        assessmentId: assessmentId,
                        questionnaireId: questionnaire.id,
                    }),
                    annotationApiInstance.getEvaluationScopeAssetQuestionAnnotations(questionnaire.evaluationScopeId, assessmentId),
                    annotationApiInstance.getEvaluationScopeAssetAnnotations(questionnaire.evaluationScopeId, assessmentId),
                ]);

                loadAssetAnnotations(assetAnnotationsResponse);

                loadCellStates(questionnaire.questions, assets, assessmentEvaluation, assetQuestionAnnotationsResponse);
            } catch (error) {
                messageBox.error('Failed to load questionnaire data. ' + error);
            }
        }),
        [assessmentId, questionnaire.id, questionnaire.questions, questionnaire.evaluationScopeId, assets]
    );

    const saveDocument = async (documentName: string): Promise<DocumentsDocument> => {
        const api = await documentApi();
        const createDocumentsResponse = await api.createDocuments({
            assessmentId,
            documents: [{ name: documentName }],
        });
        if (createDocumentsResponse.documents.length !== 1) {
            throw new Error(`Failed to create document '${documentName}'.`);
        }
        return createDocumentsResponse.documents[0];
    };

    async function createDocumentAndAnnotationObject(document: DocumentsDocument | NewOption, citation, note) {
        let documentId;
        let documentName;
        if (isNewOption(document)) {
            const createdDocument = await saveDocument(document.inputValue);
            documentId = createdDocument.id;
            documentName = createdDocument.name;
        } else {
            documentId = document?.id;
            documentName = document?.name;
        }

        const annotation: Annotation = {
            documentReference: document
                ? {
                      documentName: documentName,
                      documentId: documentId,
                      citation: citation,
                  }
                : undefined,
            note: note,
        };
        return annotation;
    }

    async function doSaveAnnotations(
        batch: {
            annotation: Annotation | undefined;
            assetId: number;
            questionnaireQuestionId: number;
        }[]
    ) {
        const annotationApiInstance = await annotationApi();
        const saveAnnotationsBatch = [];
        for (const { annotation, assetId, questionnaireQuestionId } of batch) {
            mutateCellState(assetId, questionnaireQuestionId, {
                annotation: annotation,
            });
            saveAnnotationsBatch.push({
                assessmentId: assessmentId,
                assetId: assetId,
                questionnaireQuestionId: questionnaireQuestionId,
                evaluationScopeId: questionnaire.evaluationScopeId,
                annotation: {
                    documentReference: annotation?.documentReference,
                    note: annotation?.note,
                },
            });
        }
        await annotationApiInstance.saveAssetQuestionAnnotations(saveAnnotationsBatch);
    }

    async function promptAndSaveAnnotation(
        question: QuestionnairesQuestionnaireQuestion,
        response: Response,
        assetId: number,
        questionnaireQuestionId: number,
        currentAnnotation: Annotation,
        alwaysOpen: boolean = false
    ): Promise<void> {
        const {
            valuesRequiringDocRef,
            valuesRequiringNote,
            isNoteRequiredWhenExplicitlyOmitted,
            isDocRefRequiredWhenExplicitlyOmitted,
            isNoteRequiredWhenNA,
            isDocRefRequiredWhenNA,
        } = question;

        // open annotation modal if new response is one requiring an annotation

        const isNoteRequired =
            (isNoteRequiredWhenExplicitlyOmitted && response.isExplicitlyOmitted) ||
            (isNoteRequiredWhenNA && response.isNA) ||
            (response.answer !== undefined && valuesRequiringNote?.includes(response.answer));

        const isDocRefRequired =
            (isDocRefRequiredWhenExplicitlyOmitted && response.isExplicitlyOmitted) ||
            (isDocRefRequiredWhenNA && response.isNA) ||
            (response.answer !== undefined && valuesRequiringDocRef?.includes(response.answer));

        if (alwaysOpen || isNoteRequired || isDocRefRequired) {
            return new Promise<void>(async (resolve, reject) => {
                const documentApiInstance = await documentApi();
                const documents = await documentApiInstance.getDocuments(assessmentId);

                modal
                    .form<{
                        document?: DocumentsDocument | NewOption;
                        citation?: string;
                        note?: string;
                    }>(
                        {
                            title: 'Annotations',
                            buttons: ['Save', 'Cancel'],
                        },
                        AnnotationModal,
                        {
                            annotation: currentAnnotation,
                            isDocumentReferenceRequired: isDocRefRequired,
                            isNoteRequired: isNoteRequired,
                            assessmentId: assessmentId,
                        }
                    )
                    .onSubmit(async ({ document, citation, note }) => {
                        try {
                            const annotation = await createDocumentAndAnnotationObject(document, citation, note);
                            mutateCellState(assetId, questionnaireQuestionId, { syncStatus: 'sending' });
                            await doSaveAnnotations([
                                {
                                    annotation: annotation,
                                    assetId: assetId,
                                    questionnaireQuestionId: questionnaireQuestionId,
                                },
                            ]);
                            mutateCellState(assetId, questionnaireQuestionId, { syncStatus: 'fresh' });
                            resolve();
                        } catch (error) {
                            mutateCellState(assetId, questionnaireQuestionId, { syncStatus: 'failedToSend' });
                            messageBox.error('Failed to save annotation. ' + error);
                            reject('Failed to save annotation. ' + error);
                        }
                    })
                    .onCancel(() => {
                        resolve();
                    });
            });
        }
    }

    async function doSaveResponses(batch: { assetId: number; questionnaireQuestionId: number; response: Response }[]) {
        const saveEvaluationBatch = [];
        const assetBatches = _.groupBy(batch, 'assetId');
        for (const batchKey in assetBatches) {
            const assetBatch = assetBatches[batchKey];
            const assetId = assetBatch[0].assetId;
            const assetResponses = _.map(assetBatch, (updateOperation) => ({
                // HACK: using same DTO for create and get operations. Need to come up with a better strategy.
                id: 0,
                questionnaireQuestionId: updateOperation.questionnaireQuestionId,
                answer: updateOperation.response.answer,
                isNA: updateOperation.response.isNA,
                isExplicitlyOmitted: updateOperation.response.isExplicitlyOmitted, //
            }));
            assetBatch.forEach((updateOperation) => {
                mutateCellState(updateOperation.assetId, updateOperation.questionnaireQuestionId, {
                    value: updateOperation.response.answer,
                    isNA: updateOperation.response.isNA,
                    isOmitted: updateOperation.response.isExplicitlyOmitted,
                });
            });
            saveEvaluationBatch.push({
                moduleId: moduleId,
                assessmentId: assessmentId,
                assetId: assetId,
                questionnaireId: questionnaire.id,
                responses: assetResponses,
            });
        }

        const qnrApi = await questionnaireApi();
        await qnrApi.saveEvaluation(saveEvaluationBatch);
    }

    async function saveResponse(
        assetId: number,
        questionnaireQuestionId: number,
        response: Response,
        question: QuestionnairesQuestionnaireQuestion,
        skipAnnotationPrompt: boolean = false
    ) {
        const cellState = cellStates[assetId][questionnaireQuestionId];
        mutateCellState(assetId, questionnaireQuestionId, {
            syncStatus: 'sending',
        });
        try {
            if (!skipAnnotationPrompt) {
                await promptAndSaveAnnotation(question, response, assetId, questionnaireQuestionId, cellState.annotation);
            }
            await doSaveResponses([{ assetId, questionnaireQuestionId, response }]);
            mutateCellState(assetId, questionnaireQuestionId, { syncStatus: 'fresh' });
        } catch (error) {
            mutateCellState(assetId, questionnaireQuestionId, { syncStatus: 'failedToSend' });
            messageBox.error('Failed to save response.' + error);
        }
    }

    const setAnswer = useCallback(
        async (assetId: number, questionnaireQuestionId: number, answer: any, skipAnnotationPrompt: boolean = false) =>
            saveResponse(
                assetId,
                questionnaireQuestionId,
                { answer: answer, isNA: false, isExplicitlyOmitted: false },
                questionnaire.questions.find((q) => q.questionnaireQuestionId === questionnaireQuestionId),
                skipAnnotationPrompt
            ),
        [cellStates]
    );

    const setIsNA = useCallback(
        (assetId: number, questionnaireQuestionId: number, isNA: boolean) => {
            nowait(
                saveResponse(
                    assetId,
                    questionnaireQuestionId,
                    { answer: undefined, isNA: isNA, isExplicitlyOmitted: false },
                    questionnaire.questions.find((q) => q.questionnaireQuestionId === questionnaireQuestionId)
                )
            );
        },
        [cellStates]
    );

    const setIsOmitted = useCallback(
        (assetId: number, questionnaireQuestionId: number, isOmitted: boolean) => {
            nowait(
                saveResponse(
                    assetId,
                    questionnaireQuestionId,
                    { answer: undefined, isNA: false, isExplicitlyOmitted: isOmitted },
                    questionnaire.questions.find((q) => q.questionnaireQuestionId === questionnaireQuestionId)
                )
            );
        },
        [cellStates]
    );

    const editQuestionAnnotation = useCallback(
        async (assetId: number, questionnaireQuestionId: number) => {
            const question = _.find(questionnaire.questions, { questionnaireQuestionId: questionnaireQuestionId });
            const cellState = cellStates[assetId][questionnaireQuestionId];
            nowait(
                promptAndSaveAnnotation(
                    question,
                    { answer: cellState.value, isNA: cellState.isNA, isExplicitlyOmitted: cellState.isOmitted },
                    assetId,
                    questionnaireQuestionId,
                    cellState.annotation,
                    true
                )
            );
        },
        [cellStates]
    );

    const editAssetAnnotation = useCallback(
        async function (assetId: number) {
            modal
                .form(
                    {
                        title: 'Annotations',
                        buttons: ['Save', 'Cancel'],
                    },
                    AnnotationModal,
                    {
                        annotation: assetAnnotations[assetId],
                        isDocumentReferenceRequired: assetAnnotationMode === 'required',
                        isNoteRequired: false,
                        assessmentId: assessmentId,
                    }
                )
                .onSubmit(async ({ document, citation, note }) => {
                    try {
                        const annotation = await createDocumentAndAnnotationObject(document, citation, note);
                        const annotationApiInstance = await annotationApi();
                        await annotationApiInstance.saveAssetAnnotation(assessmentId, questionnaire.evaluationScopeId, assetId, annotation);
                        await setAssetAnnotation(assetId, annotation);
                    } catch (error) {
                        messageBox.error('Failed to save annotation. ' + error);
                    }
                });
        },
        [cellStates]
    );

    const copyToAll = useCallback(
        async function (assetId: number, qtnId: number, bypassConfirmation: boolean = false) {
            async function doCopyResponses(copyToEmptyOnly: boolean) {
                const sourceCellState = cellStates[assetId][qtnId];
                const saveResponsesBatch: Parameters<typeof doSaveResponses>[0] = [];
                const saveAnnotationsBatch: Parameters<typeof doSaveAnnotations>[0] = [];
                const affectedCells: ((mutation: Partial<QCellState>) => void)[] = [];

                const dstCells: QCellStateMatrix = copyToEmptyOnly ? _.pickBy(cellStates, (assetCells) => isEmptyResponse(assetCells[qtnId])) : cellStates;

                for (const dstAssetKey in dstCells) {
                    const dstAssetId = Number(dstAssetKey);
                    affectedCells.push(_.partial(mutateCellState, dstAssetId, qtnId));
                    saveResponsesBatch.push({
                        assetId: dstAssetId,
                        questionnaireQuestionId: qtnId,
                        response: {
                            isExplicitlyOmitted: sourceCellState.isOmitted,
                            isNA: sourceCellState.isNA,
                            answer: sourceCellState.value,
                        },
                    });
                    saveAnnotationsBatch.push({
                        annotation: sourceCellState.annotation,
                        assetId: dstAssetId,
                        questionnaireQuestionId: qtnId,
                    });
                }

                _.forEach(affectedCells, (f) => f({ syncStatus: 'sending' }));

                try {
                    await Promise.all([doSaveResponses(saveResponsesBatch), doSaveAnnotations(saveAnnotationsBatch)]);
                    _.forEach(affectedCells, (f) => f({ syncStatus: 'fresh' }));
                } catch (error) {
                    _.forEach(affectedCells, (f) => f({ syncStatus: 'failedToSend' }));
                    messageBox.error('Failed to save response. ' + error);
                }
            }

            if (bypassConfirmation) {
                await doCopyResponses(false);
            } else {
                modal
                    .show(
                        {
                            title: 'Confirmation',
                            buttons: [
                                {
                                    id: 'copyToAll',
                                    title: 'Copy to all',
                                    isAccept: true,
                                },
                                {
                                    id: 'copyToEmpty',
                                    title: 'Copy to empty',
                                    isAccept: true,
                                },
                                {
                                    title: 'Cancel',
                                    isDefault: true,
                                },
                            ],
                        },
                        'Do you want copy this response to all the assets?'
                    )
                    .onAccept(async (data, buttonId) => {
                        const copyToEmptyOnly = buttonId !== 'copyToAll';
                        await doCopyResponses(copyToEmptyOnly);
                    });
            }
        },
        [cellStates]
    );

    const qContextValue: QContextType = useMemo(() => {
        return {
            assets: assets,
            questionnaire: questionnaire,
            cellStates: cellStates,
            annotatedAssets: annotatedAssets,
            setAnswer: setAnswer,
            editQuestionAnnotation: editQuestionAnnotation,
            editAssetAnnotation: editAssetAnnotation,
            copyToAll: copyToAll,
            setIsNA: setIsNA,
            setIsOmitted: setIsOmitted,
        } as QContextType;
    }, [cellStates, assets, questionnaire, assetAnnotations, annotatedAssets, cellStateCounter]);
    return <QContextContext.Provider value={qContextValue}>{children}</QContextContext.Provider>;
};
