import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Tooltip, Badge, Typography, Dialog, DialogTitle, DialogContent, TextField, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DescriptionIcon from '@mui/icons-material/Description';

const filter = createFilterOptions();

const DocumentReferenceModal = ({
    assessmentId,
    asset,
    question,
    isCheckAll,
    documentNames,
    classes,
    isOpen,
    handleShowModal,
    isChecked,
    isRequired,
    rubricType,
    documentReference = {},
    handleDocumentUpdate,
    sharedDocument = {},
}) => {
    const [currentDocName, setCurrentDocName] = useState({ value: '' });
    const [currentRefName, setCurrentRefName] = useState({ value: '' });
    const [isCheckAllOption, setIsCheckAllOption] = useState(false);

    const referenceKey = `${rubricType.toLowerCase()}`;
    const nameKey = `${referenceKey}DocumentName`;

    useEffect(() => {
        const referenceKey = `${rubricType.toLowerCase()}`;
        const nameKey = `${referenceKey}DocumentName`;
        if (Object.keys(documentReference).length > 0) {
            setCurrentDocName({ value: documentReference[nameKey] });
            setCurrentRefName({ value: documentReference[referenceKey] });
        } else if (isCheckAll && Object.keys(sharedDocument).length > 0 && referenceKey === 'execution') {
            setCurrentDocName({ value: sharedDocument.executionDocumentName });
            setCurrentRefName({ value: sharedDocument.execution });
            setIsCheckAllOption(true);
        } else if (isCheckAll && Object.keys(sharedDocument).length > 0 && referenceKey === 'plan') {
            setCurrentDocName({ value: sharedDocument.planDocumentName });
            setCurrentRefName({ value: sharedDocument.plan });
            setIsCheckAllOption(true);
        } else {
            setCurrentDocName({ value: '' });
            setCurrentRefName({ value: '' });
        }
    }, [documentReference, sharedDocument, isCheckAll, rubricType]);

    // Handle changes to reference field. Update component state.
    const handleReferenceChange = (e) => {
        setCurrentRefName({ value: e.target.value });
    };

    // Handle changes to document name field. Update component state.
    const handleNameChange = (e, input) => {
        setCurrentDocName({ value: input.value, label: input.value });
    };

    // Has the document reference been changed?
    const isUnchanged =
        Object.keys(documentReference).length > 0 &&
        currentDocName.value === documentReference[nameKey] &&
        currentRefName.value === documentReference[referenceKey];

    // Handle updated, created, or deleted document references and initiate API calls in QuestionsTable
    const handleSavedChanges = (action, isCheckAll) => {
        const isDeleteRequest = action === 'delete';
        // if no change and not delete request, don't update
        if (isUnchanged && !isDeleteRequest && !isCheckAll) {
            return;
        }

        handleDocumentUpdate(
            isChecked,
            assessmentId,
            isDeleteRequest,
            isCheckAll,
            asset,
            question,
            currentDocName.value,
            currentRefName.value,
            documentNames,
            handleShowModal,
            isRequired
        );
        closeModal();
    };

    const closeModal = () => {
        if (Object.keys(documentReference).length > 0) {
            setCurrentDocName({ value: documentReference[nameKey] });
            setCurrentRefName({ value: documentReference[referenceKey] });
        } else {
            setCurrentDocName({ value: '' });
            setCurrentRefName({ value: '' });
        }
        handleShowModal();
    };

    // Prevent 'enter' default. Close modal and erase changes.
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleShowModal();
        }
    };

    const docExists = Object.keys(documentReference).length > 0;
    const sharedDocExists = Object.keys(sharedDocument).length > 0;
    const showAsterisk = (isCheckAllOption && sharedDocExists) || docExists;

    return (
        <React.Fragment>
            <Badge badgeContent={question.documentRequired ? '*' : ''}>
                <Tooltip title={currentDocName.value?.length ? currentDocName.value : 'Create Reference'}>
                    <Typography onClick={handleShowModal} style={{ cursor: 'pointer' }}>
                        {showAsterisk ? <DescriptionIcon /> : <DescriptionOutlinedIcon />}
                    </Typography>
                </Tooltip>
            </Badge>
            <Dialog open={isOpen} onClose={closeModal} PaperProps={{ style: { minHeight: '200px', minWidth: '600px' } }}>
                <DialogTitle className={classes.dialogTitle}>Document Reference</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.document}>
                        <span>Document</span>
                        <Autocomplete
                            style={{ width: '100%' }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const { inputValue } = params;
                                const exists = options.some((option) => inputValue === option.value);
                                if (inputValue !== '' && !exists) {
                                    filtered.push({ value: inputValue, label: `Create: ${inputValue}` });
                                }
                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.label;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                            renderInput={(params) => <TextField {...params} />}
                            defaultValue={currentDocName.value}
                            value={currentDocName.value}
                            onChange={handleNameChange}
                            options={documentNames.map(({ label }) => {
                                return { value: label, label };
                            })}
                        />
                    </div>
                    <div className={classes.document}>
                        <span>Reference</span>
                        <TextField
                            className={classes.textField}
                            autoFocus
                            margin="dense"
                            fullWidth
                            defaultValue={currentRefName.value}
                            value={currentRefName.value}
                            onChange={handleReferenceChange}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                    <div className={classes.displayButton}>
                        {!docExists && !isCheckAllOption ? null : (
                            <Button
                                className={classes.deleteButton}
                                disabled={isChecked && isRequired}
                                onClick={() => handleSavedChanges('delete', isCheckAll, isRequired)}
                            >
                                Delete
                            </Button>
                        )}
                        <Button
                            className={`${classes.button} ${classes.deleteButton}`}
                            disabled={isUnchanged || currentDocName.value === ''}
                            onClick={() => handleSavedChanges('save', isCheckAll)}
                        >
                            {docExists ? 'Update' : 'Create'}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

DocumentReferenceModal.propTypes = {
    assessmentId: PropTypes.number.isRequired,
    asset: PropTypes.object,
    question: PropTypes.object.isRequired,
    isCheckAll: PropTypes.bool.isRequired,
    documentNames: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleShowModal: PropTypes.func.isRequired,
    isChecked: PropTypes.bool.isRequired,
    isRequired: PropTypes.bool.isRequired,
    rubricType: PropTypes.string.isRequired,
    documentReference: PropTypes.object,
    handleDocumentUpdate: PropTypes.func.isRequired,
    sharedDocument: PropTypes.object,
};

const styles = (theme) => ({
    dialogContent: {
        width: 500,
        overflow: 'hidden',
        marginTop: 20,
    },
    select: {
        width: 350,
    },
    document: {
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    textField: {
        width: 350,
    },
    button: {
        float: 'right',
    },
    deleteButton: {
        color: '#0d2541',
        backgroundColor: ' transparent',
    },
    dialogTitle: {
        fontFamily: 'sans-serif',
    },
    displayButton: {
        marginTop: 100,
    },
    disabled: {
        color: 'rgba(0, 0, 0, 0.87)',
    },
});

export default withStyles(styles)(DocumentReferenceModal);
