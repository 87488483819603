import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { formatDateOnly } from 'Utils/formats';
import { Certification } from './IssueRatingsSetupPage';
import { differenceInDays } from 'date-fns';
import { questionnaireApi } from 'Services/TrustwellApiService';
import _ from 'lodash';
import { AssessmentsAssessment, ModulesModuleType } from '@projectcanary/trustwell-server-client-ts';
import { withInitializers } from 'Utils/withInitializers';

const LabeledValue = ({ label, value }: { label: string; value: number | string }) => (
    <>
        <Grid key={label} item xs={1}>
            <Typography variant={'bold'}>{label}</Typography>
        </Grid>
        <Grid key={value} item xs={1}>
            {value}
        </Grid>
    </>
);

const InnerCertificationSetupConfirmationModal = ({
    values,
    modules,
    assessment,
}: {
    values: Certification;
    assessment: AssessmentsAssessment;
    modules: string[];
}) => {
    const fields: { label: string; value: string | number | undefined }[] = [
        { label: 'Rating Start Date', value: formatDateOnly(values.certStartDate) },
        { label: 'Rating End Date', value: formatDateOnly(values.certEndDate) },
        { label: 'Rating Period (days)', value: differenceInDays(values.certEndDate, values.certStartDate) },
        { label: 'Report Date', value: formatDateOnly(values.reportDate) },
        { label: 'Producer Name', value: values.producerName },
        { label: 'Assessment Configuration', value: modules.join(', ') },
        { label: 'Low Methane VA', value: values.selectedLmva?.name },
        {
            label: 'LMVA will be issued',
            value: assessment.activeModules.includes(ModulesModuleType.Lmva) ? (values.shouldIssueLmva ? 'Yes' : 'No') : undefined,
        },
    ];

    return (
        <Stack spacing={2}>
            <Grid container spacing={2} columns={2} sx={{ p: 2 }}>
                {fields
                    .filter((f) => f.value !== undefined)
                    .map((field) => (
                        <LabeledValue key={field.label} label={field.label} value={field.value} />
                    ))}
            </Grid>
            <Typography variant={'bold'}>
                The selected dates and well groups cannot be changed once the issue rating process starts. Please double-check the selections before continuing.
            </Typography>
            <Typography variant={'bold'}>Are you sure you wish to proceed?</Typography>
        </Stack>
    );
};

export const CertificationSetupConfirmationModal = withInitializers(
    {
        modules: async ({ assessment }) => {
            const { modules: allModules } = await (await questionnaireApi()).getActiveModules();
            return _.map(assessment.activeModules, (activeModuleType) => _.find(allModules, { moduleType: activeModuleType }).moduleName);
        },
    },
    InnerCertificationSetupConfirmationModal
);
