import { LmrSectionData } from './LmrPage';
import React, { useMemo } from 'react';
import {
    EntitiesAssetType,
    QuestionnairesQuestionnaireQuestionWithScoreInt32,
    QuestionnairesQuestionnaireType,
    QuestionnairesQuestionnaireWithScoreInt32,
} from '@projectcanary/trustwell-server-client-ts';
import { QMatrix } from 'Components/QMatrix/QMatrix';
import _ from 'lodash';
import { Stack } from '@mui/material';

type Annotation = {
    docRef?: {
        docRefId: number;
        documentId: number;
        documentName: string;
        citation: string;
        changer: string;
        changeDateTime: Date;
    };
    comment?: { text: string; commenter: string; commentDateTime: Date };
};

export const EmissionControlSectionComponent = (data: LmrSectionData) => {
    const pads = useMemo(() => _.filter(data.assets, { assetType: EntitiesAssetType.Pad }), [data.assets]);
    const basins = useMemo(() => _.filter(data.assets, { assetType: EntitiesAssetType.Basin }), [data.assets]);

    const padQuestionnaire = useMemo(
        () =>
            _.find(data.questionnaires, {
                assetType: EntitiesAssetType.Pad,
            }),
        [data.questionnaires]
    );

    const basinQuestionnaire = useMemo(
        () =>
            _.find(data.questionnaires, {
                assetType: EntitiesAssetType.Basin,
            }),
        [data.questionnaires]
    );

    const headerBadgeRenderer = useMemo(() => {
        const uniqueQuestionnaireTypes = _.uniqBy(data.questionnaires, 'questionnaireType');
        if (uniqueQuestionnaireTypes.length !== 1) {
            throw `All questionnaires within an emission control section must be of the same type.`;
        }

        if (data.questionnaires[0].questionnaireType === QuestionnairesQuestionnaireType.LmrEmissionControl) {
            return (q) => ({
                content: q.score === 0 ? 'M' : q.score,
                tooltip: q.score === 0 ? 'Minimum Requirement' : 'Level ' + q.score + ' Practice',
            });
        } else {
            return undefined;
        }
    }, [data.questionnaires]);

    return (
        <Stack direction={'column'} spacing={5}>
            {basinQuestionnaire && (
                <QMatrix<QuestionnairesQuestionnaireQuestionWithScoreInt32>
                    enableCellAnnotation={true}
                    headerBadgeRenderer={headerBadgeRenderer}
                    assessmentId={data.assessmentId}
                    assets={basins}
                    assetLabel={'Basin'}
                    questionnaire={basinQuestionnaire as QuestionnairesQuestionnaireWithScoreInt32}
                    moduleId={data.moduleId}
                    variant={'short'}
                />
            )}
            {padQuestionnaire && (
                <QMatrix<QuestionnairesQuestionnaireQuestionWithScoreInt32>
                    enableCellAnnotation={true}
                    headerBadgeRenderer={headerBadgeRenderer}
                    assessmentId={data.assessmentId}
                    assets={pads}
                    assetLabel={'Pad'}
                    questionnaire={padQuestionnaire as QuestionnairesQuestionnaireWithScoreInt32}
                    moduleId={data.moduleId}
                    enableCopyToAll={true}
                />
            )}
        </Stack>
    );
};
