import React from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';
import { LocalFactorsWellGroupComplete } from '@projectcanary/trustwell-server-client-ts';

type WellGroupListProps = {
    wellGroups: any;
    selectedWellGroup: any;
    wellGroupsComplete: LocalFactorsWellGroupComplete[];
    handleChangeWellGroup: (wellGroup: any) => void;
};

const WellGroupList = ({ wellGroups, selectedWellGroup, wellGroupsComplete, handleChangeWellGroup }: WellGroupListProps) => {
    const getCompletionStatus = (id: number) => {
        const selectedWg = wellGroupsComplete.find((wellGroup) => wellGroup.wellGroupId === id);
        return selectedWg ? selectedWg.wellGroupComplete : false;
    };

    return (
        <>
            <FormControl sx={{ m: 1, minWidth: 250 }}>
                <Select onChange={(e) => handleChangeWellGroup(e.target.value)} defaultValue={selectedWellGroup}>
                    {wellGroups.map((wellGroup) => (
                        <MenuItem
                            value={wellGroup}
                            key={wellGroup.id}
                            sx={getCompletionStatus(wellGroup.id) ? { backgroundColor: 'lightgrey' } : { backgroundColor: '#A2F2D1' }}
                        >
                            {wellGroup.name} {getCompletionStatus(wellGroup.id) ? ': Complete' : ''}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};

export default WellGroupList;
