type LoggerReturnType = {
    log: (...args: any[]) => void;
    info: (...args: any[]) => void;
    warn: (...args: any[]) => void;
    error: (...args: any[]) => void;
};

const logger = ((originalConsole: Console): LoggerReturnType => {
    return {
        ...originalConsole,
        log: originalConsole.log.bind(originalConsole),
        info: originalConsole.info.bind(originalConsole),
        warn: originalConsole.warn.bind(originalConsole),
        error: originalConsole.error.bind(originalConsole),
    };
})(window.console);

export default logger;
