export function setFavicon(text: string, fontSize: number, x: number, y: number) {
    const canvas = document.createElement('canvas');
    canvas.height = 64;
    canvas.width = 64;
    const ctx = canvas.getContext('2d');
    ctx.font = fontSize.toString() + 'px serif';
    ctx.fillText(text, x, y);

    const iconLink = document.querySelector('link[rel=icon]');
    if (iconLink) {
        iconLink.setAttribute('href', canvas.toDataURL());
    }
}

export function getFaviconHref() {
    return document.querySelector('link[rel=icon]')?.getAttribute('href');
}

export function setFaviconHref(href: string) {
    document.querySelector('link[rel=icon]')?.setAttribute('href', href);
}
