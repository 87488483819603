import {
    CertificationsGetInProgressCertificationStatus,
    CertificationsInProgressCertificationStatus as InProgressCertificationStatus,
} from '@projectcanary/trustwell-server-client-ts';
import React, { useMemo } from 'react';
import { useNavigation } from 'Utils/Navigation';
import { formatDateOnly } from 'Utils/formats';
import { Button } from 'Controls/Button';
import differenceInDays from 'date-fns/differenceInDays';
import { Stack, Typography } from '@mui/material';

export const IssueRatingsButton = ({ assessmentId, issuance }: { assessmentId: number; issuance?: CertificationsGetInProgressCertificationStatus }) => {
    type ViewModel = {
        buttonTitle: string; //
        buttonDisabledMessage?: string; //
        buttonLink?: string; //
        message?: {
            tone: 'success' | 'info' | 'error'; //
            text: (ipc: CertificationsGetInProgressCertificationStatus) => React.ReactNode; //
            expires?: boolean; //
        };
    };

    const NoInProgressCertification = Symbol();
    const { site } = useNavigation();
    const { new: newIssuanceLink, review: reviewIssuanceLink } = useMemo(() => site.assessment(assessmentId).certification, [assessmentId, site]);

    const ipcStateViewModels: {
        [key in InProgressCertificationStatus | typeof NoInProgressCertification]: ViewModel;
    } = {
        [InProgressCertificationStatus.InReview]: {
            buttonTitle: 'Continue Rating',
            buttonLink: reviewIssuanceLink,
        },
        [InProgressCertificationStatus.PublicationFailed]: {
            buttonTitle: 'Continue Rating',
            buttonLink: reviewIssuanceLink,
            message: {
                tone: 'error',
                text: (ipc) =>
                    `Issued rating on ${formatDateOnly(ipc.statusChangeDateTime)} has failed to upload. ${
                        ipc.statusDetails ?? 'Please try again and if the problem persists, contact the product team for assistance.'
                    }`,
            },
        },
        [InProgressCertificationStatus.Publishing]: {
            buttonTitle: 'Continue Rating',
            buttonDisabledMessage: 'Action disabled while issue rating is in progress.',
            message: {
                tone: 'info',
                text: (ipc) => `Issue rating is in progress. ${ipc.statusDetails ?? ''}`,
            },
        },
        [InProgressCertificationStatus.Published]: {
            buttonTitle: 'Issue Rating',
            buttonLink: newIssuanceLink,
            message: {
                tone: 'success',
                expires: true,
                text: (ipc) => (
                    <>
                        Issued rating on {formatDateOnly(ipc.statusChangeDateTime)} was successful! You can access the issued rating(s)
                        <Button variant="link" externalLink={ipc.statusDetails}>
                            here
                        </Button>
                        .
                    </>
                ),
            },
        },
        [NoInProgressCertification]: {
            buttonTitle: 'Issue Rating',
            buttonLink: newIssuanceLink,
        },
    };

    const stateViewModel = ipcStateViewModels[issuance?.status ?? NoInProgressCertification];
    const today = new Date();
    const statusAgeInDays = differenceInDays(today, issuance?.statusChangeDateTime);
    const showMessage = stateViewModel.message && (!stateViewModel.message.expires || statusAgeInDays <= 7);
    return (
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Button variant={'primary'} href={stateViewModel.buttonLink} disabled={stateViewModel.buttonDisabledMessage}>
                {stateViewModel.buttonTitle}
            </Button>
            {showMessage && <Typography color={stateViewModel.message.tone + '.main'}>{stateViewModel.message.text(issuance!)}</Typography>}
        </Stack>
    );
};
