import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { useCallback, useMemo, useState } from 'react';
import { GridCallbackDetails } from '@mui/x-data-grid/models/api';

/**
 * Adds default sort to a DataGrid.
 * @example:
 *
 * const gridSortModel = useGridDefaultSort([{ field: 'creationDateTime', sort: 'asc' }]);
 * <DataGridPro<Lmva>
 *   rows={ lmvas ?? []}
 *   columns={ columns }
 *   loading={ isLoading }
 *   getRowId={ (row) => row.id }
 *   {...gridSortModel}
 * />
 */
export const useGridDefaultSort = (defaultSortModel: GridSortModel) => {
    const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);
    const onSortModelChange = useCallback((model: GridSortModel, details: GridCallbackDetails) => {
        setSortModel(model.length === 0 ? defaultSortModel : model);
    }, []);
    return useMemo(() => {
        return { sortModel, onSortModelChange };
    }, [sortModel, onSortModelChange]);
};
