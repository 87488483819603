import { GridToolbarQuickFilter as MuiGridToolbarQuickFilter, GridToolbarQuickFilterProps as MuiGridToolbarQuickFilterProps } from '@mui/x-data-grid-pro';
import React, { useRef } from 'react';
import { useKeyboardShortcut } from './useKeyboardShortcut';

type GridToolbarQuickFilterProps = MuiGridToolbarQuickFilterProps & { keyboardShortcut?: any };

export const GridToolbarQuickFilter = (props: GridToolbarQuickFilterProps) => {
    const { keyboardShortcut, ...muiProps } = props;
    const inputRef = useRef<{ focus: () => void }>();
    useKeyboardShortcut(keyboardShortcut, () => {
        if (props.keyboardShortcut) {
            inputRef.current?.focus();
        }
    });

    return <MuiGridToolbarQuickFilter inputRef={inputRef} {...muiProps} />;
};
