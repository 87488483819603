import React from 'react';
import PropTypes from 'prop-types';
import { TableHead, TableCell, TableRow, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import { EXECUTION_RUBRIC, PLAN_RUBRIC } from '../config';
import { Warning } from '@mui/icons-material';
import { useModal } from 'Controls/Modal';

const QuestionsTableHeader = ({ classes, rubricType, assessedAssets }) => {
    const checkAll = rubricType === EXECUTION_RUBRIC || rubricType === PLAN_RUBRIC;
    const modal = useModal();
    return (
        <TableHead>
            <TableRow className={classes.headerRow}>
                <TableCell style={{ color: 'white' }} className={`${classes.headerRow} ${classes.binColumn}`}>
                    Bin
                </TableCell>
                <TableCell style={{ color: 'white' }} className={`${classes.headerRow} ${classes.questionColumn}`}>
                    Question
                </TableCell>
                {checkAll && (
                    <TableCell style={{ color: 'white' }} className={classes.headerRow}>
                        Check All
                    </TableCell>
                )}
                {rubricType === 'Execution' && (
                    <>
                        {assessedAssets.map((asset) => {
                            return (
                                <Tooltip title={asset.well.api10} placement="bottom-start">
                                    <TableCell style={{ color: 'white' }} key={asset.well.id} className={classes.headerRow}>
                                        {asset.well.name === '' ? asset.wellgroup.name : asset.well.name}
                                        {assessedAssets.filter((a) => a.wellgroup.id === asset.wellgroup.id).length > 1 ? (
                                            <span
                                                onClick={() => {
                                                    modal.show(
                                                        { title: 'Multiple Sample Wells Warning' },
                                                        `We no longer support multiple sample wells. Please go to the assets page and pick one sample well for asset group '${asset.wellgroup.name}'.`
                                                    );
                                                }}
                                            >
                                                <Warning sx={{ color: 'yellow', verticalAlign: 'middle', fontSize: 'medium' }} />
                                            </span>
                                        ) : null}
                                    </TableCell>
                                </Tooltip>
                            );
                        })}
                    </>
                )}
                {rubricType !== 'Execution' && (
                    <>
                        {assessedAssets.map((asset) => {
                            return (
                                <TableCell style={{ color: 'white' }} key={asset.id} className={classes.headerRow}>
                                    {asset.name === '' ? asset.api10 : asset.name}
                                </TableCell>
                            );
                        })}
                    </>
                )}
            </TableRow>
        </TableHead>
    );
};

QuestionsTableHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    rubricType: PropTypes.string.isRequired,
    assessedAssets: PropTypes.object.isRequired,
};

const styles = (theme) => ({
    binColumn: {
        position: 'sticky',
        background: '#fff',
        left: 0,
        zIndex: 1,
    },
    questionColumn: {
        position: 'sticky',
        background: '#fff',
        left: 40,
        zIndex: 1,
    },
    tableCell: {
        padding: 5,
        paddingLeft: 20,
    },
    headerRow: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        minWidth: '100px',
    },
});

export default withStyles(styles)(QuestionsTableHeader);
