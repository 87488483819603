import React from 'react';
import { QMatrixCellBase } from './QMatrixCellBase';
import { QuestionnairesQuestionnaireQuestion as Question } from '@projectcanary/trustwell-server-client-ts';
import { useQCell } from './QCell';

export const QMatrixJsonCell = (props: { assetId: number; question: Question; enableCellAnnotation: boolean }) => {
    const { questionnaireQuestionId } = props.question;
    const field = useQCell(props.assetId, questionnaireQuestionId);

    return (
        <QMatrixCellBase {...props}>
            <pre>{JSON.stringify(field)}</pre>
        </QMatrixCellBase>
    );
};
