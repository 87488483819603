import { ReactElement, useEffect } from 'react';

/**
 * There is no an easy way to get the currently rendered route using react-router-dom library.
 * So instead of figuring out the route based on current URL, we let the rendered component to
 * report that it was rendered.
 */
export const SelfReportingRoute = ({ children, onSelected }: { children: ReactElement; onSelected: () => void }): ReactElement => {
    useEffect(() => {
        onSelected();
    }, [onSelected, children]);
    return children;
};
