import React, { ReactElement } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { formatLocalFactorScores, medalRankingSortComparator } from 'Utils/formats';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { certificationApi, scoreApi } from 'Services/TrustwellApiService';
import { GridColumns } from '@mui/x-data-grid/models/colDef/gridColDef';
import { emptyDataGridCellClass } from 'Apperance/Theme';
import _ from 'lodash';
import { Button } from 'Controls/Button';
import WarningIcon from '@mui/icons-material/Warning';
import { useModal } from 'Controls/Modal';
import { withInitializers } from 'Utils/withInitializers';
import { OprRatingMedal } from '@projectcanary/trustwell-server-client-ts';

export type WellScores = {
    registrationId: string;
    name: string;
    score: number | undefined;
    medal: OprRatingMedal | undefined;
    isOverlappingCertificate: boolean;
}[];

export type WellGroupScores = {
    water: number | undefined;
    air: number | undefined;
    land: number | undefined;
    community: number | undefined;
};

export type WellGroupOprReviewDetailsViewProps = {
    wellGroupId: number;
    assessmentId: number;
    scores: {
        wellScores: WellScores;
        wellGroupScores: WellGroupScores;
    };
};

export const InnerWellGroupOprReviewDetailsView = ({ scores: { wellGroupScores, wellScores } }: WellGroupOprReviewDetailsViewProps): ReactElement => {
    const modal = useModal();

    const handleShowOverlappingCertModal = () =>
        modal.info({
            title: 'Overlapping Issued Ratings Notice',
            message: 'An issued rating already exists for this well for the selected time period. This newly issued rating will supersede the existing rating.',
        });

    const renderWellIdCell = (params) => {
        if (params.row.isOverlappingCertificate) {
            return (
                <Button variant="link" color="inherit" onClick={handleShowOverlappingCertModal} startIcon={<WarningIcon color="error" />}>
                    {params.row.registrationId}
                </Button>
            );
        }
        return <Box sx={{ ml: 4 }}>{params.row.registrationId}</Box>;
    };

    const columns: GridColumns = [
        {
            headerName: 'Well Id',
            field: 'registrationId',
            width: 200,
            renderCell: renderWellIdCell,
        },
        { headerName: 'Well Name', field: 'name', flex: 1 },
        {
            headerName: 'Score',
            type: 'number',
            field: 'score',
            width: 100,
            valueFormatter: ({ value }) => value && Math.ceil(value),
            cellClassName: ({ value }) => (value === undefined ? emptyDataGridCellClass : undefined),
        },
        {
            headerName: 'Medal',
            field: 'medal',
            width: 200,
            cellClassName: ({ value }) => (value === undefined ? emptyDataGridCellClass : undefined),
            sortComparator: medalRankingSortComparator,
        },
    ];

    return (
        <>
            <Stack direction="row" spacing={5} sx={{ pb: 1 }}>
                {_.map(
                    {
                        Water: wellGroupScores.water,
                        Air: wellGroupScores.air,
                        Land: wellGroupScores.land,
                        Community: wellGroupScores.community,
                    },
                    (value, label) => (
                        <Typography key={label} variant="body2" fontWeight="500">
                            {label}: {formatLocalFactorScores(value)}
                        </Typography>
                    )
                )}
            </Stack>
            <DataGridPro getRowId={(row) => row.registrationId} columns={columns} rows={wellScores} autoHeight={false} autoPageSize={true} />
        </>
    );
};

export const WellGroupOprReviewDetailsView = withInitializers<WellGroupOprReviewDetailsViewProps, 'scores'>(
    {
        scores: loadScores,
    },
    InnerWellGroupOprReviewDetailsView,
    'component'
);

async function loadScores({ assessmentId, wellGroupId }) {
    const scoreApiInstance = await scoreApi();
    const certificationApiInstance = await certificationApi();

    const [wellScores, priorWellRatings, ipc] = await Promise.all([getWellScores(), getPriorWellRatings(), getIpc()]);

    async function getIpc() {
        return (await certificationApiInstance.getInProgressCertification(assessmentId)).inProgressCertification;
    }

    async function getPriorWellRatings() {
        return (await certificationApiInstance.getWellGroupCertifications(assessmentId)).wellCertifications;
    }

    async function getWellScores() {
        return _.filter(await scoreApiInstance.getAllEventWellScoresByAssessment(assessmentId), { wellGroupId: wellGroupId });
    }

    const firstWellScores = wellScores[0];
    const wellGroupScores: WellGroupScores = {
        water: firstWellScores.waterScore ?? undefined,
        air: firstWellScores.airScore ?? undefined,
        land: firstWellScores.landScore ?? undefined,
        community: firstWellScores.communityScore ?? undefined,
    };

    const wellScoreRows: WellScores = _.map(wellScores, (ws) => ({
        wellId: ws.wellId,
        registrationId: ws.api10,
        name: ws.wellName,
        score: ws.performanceScore,
        medal: ws.ratingMedal,
        isOverlappingCertificate: _.some(priorWellRatings, (pwr) => pwr.wellRegistrationId == ws.api10 && pwr.certificateEndDate > ipc.certificateStartDate),
    }));

    return { wellScores: wellScoreRows, wellGroupScores };
}
