import React from 'react';
import { Stack } from '@mui/material';
import { Button } from 'Controls/Button';

export const TopButtons = (params: { children: React.ReactElement<any, typeof Button>[] | React.ReactElement<any, typeof Button> }) => {
    return (
        <Stack spacing={2} direction={'row'}>
            {params.children}
        </Stack>
    );
};
