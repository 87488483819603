import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableCell, Checkbox, Grid } from '@mui/material';
import DocumentReferenceModal from './DocumentReferenceModal.jsx';

const QuestionCheckBox = ({
    question,
    assessmentId,
    asset,
    rubricType,
    handleCheck,
    isCheckAllBtn,
    docReference,
    handleDocumentUpdate,
    sharedDocument,
    documentNames,
    isChecked,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleOpenModal = () => setIsOpen(!isOpen);
    return (
        <TableCell>
            <Grid container={true} justify="center" alignItems="center" spacing={0}>
                <Grid item xs={3}>
                    <DocumentReferenceModal
                        assessmentId={assessmentId}
                        asset={asset}
                        question={question}
                        isCheckAll={isCheckAllBtn}
                        isOpen={isOpen}
                        handleShowModal={handleOpenModal}
                        isChecked={isChecked}
                        isRequired={question.documentRequired}
                        rubricType={rubricType}
                        documentReference={docReference ? docReference : {}}
                        handleDocumentUpdate={handleDocumentUpdate}
                        sharedDocument={sharedDocument ? sharedDocument : {}}
                        documentNames={documentNames}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Checkbox
                        onChange={() =>
                            handleCheck(assessmentId, question, asset, isCheckAllBtn, isChecked, rubricType, docReference, sharedDocument, handleOpenModal)
                        }
                        color="primary"
                        checked={isChecked}
                    />
                </Grid>
            </Grid>
        </TableCell>
    );
};

QuestionCheckBox.propTypes = {
    question: PropTypes.object.isRequired,
    assessmentId: PropTypes.number.isRequired,
    asset: PropTypes.object,
    rubricType: PropTypes.string.isRequired,
    handleCheck: PropTypes.func.isRequired,
    isChecked: PropTypes.bool.isRequired,
    isCheckAllBtn: PropTypes.bool.isRequired,
    docReference: PropTypes.object,
    documentNames: PropTypes.array.isRequired,
    sharedDocument: PropTypes.object,
};

export default QuestionCheckBox;
