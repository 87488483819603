import React, { useState } from 'react';
import { Card, List, ListSubheader, IconButton, Menu, MenuItem, ListItem, ListItemText, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { POLICY_RUBRIC, PLAN_RUBRIC, EXECUTION_RUBRIC } from './config';
import _ from 'lodash';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const rubricTypes = { 0: POLICY_RUBRIC, 1: PLAN_RUBRIC, 2: EXECUTION_RUBRIC };
const ITEM_HEIGHT = 56;

const RubricDrawer = ({ classes, rubricData, changeRubric, updateCompleteness, changeRubricType, currentRubricType }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItem, setSelectedItem] = useState(1);

    const handleChangeRubric = (rubricId) => {
        setSelectedItem(rubricId);
        changeRubric(rubricId);
        return rubricId;
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRubricTypeChange = (rubricKey) => {
        var rubricType = rubricTypes[rubricKey];
        changeRubricType(rubricType);
        handleClose();
    };

    const isRubricComplete = (rubricId) => {
        let selectedRubric = _.filter(rubricData.rubricCompletes, {
            rubricId: rubricId,
        });
        if (selectedRubric.length === 0) {
            return '';
        }
        if (currentRubricType === POLICY_RUBRIC) {
            return selectedRubric[0].policyRubricComplete ? 'completed' : '';
        } else if (currentRubricType === PLAN_RUBRIC) {
            return selectedRubric[0].planRubricComplete ? 'completed' : '';
        } else if (currentRubricType === EXECUTION_RUBRIC) {
            return selectedRubric[0].execRubricComplete ? 'completed' : '';
        }
        return '';
    };

    const showRubric = (rubric) => {
        return !rubric.verifiedAttributes ?? true;
    };

    const open = Boolean(anchorEl);

    return (
        <Card className={classes.root}>
            <List
                style={{ overflowY: 'scroll', overFlowX: 'hidden', height: '100%' }}
                className={classes.list}
                disablePadding={true}
                subheader={
                    <ListSubheader className={classes.listSection} style={{ backgroundColor: '#3a4d6c', color: 'white' }}>
                        <span style={{ fontSize: '18px' }}>{`Level - ` + currentRubricType}</span>
                        <IconButton aria-label="More" aria-owns={open ? 'long-menu' : null} aria-haspopup="true" onClick={handleClick} className={classes.icon}>
                            <MoreVertIcon style={{ color: 'white' }} />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: 250,
                                },
                            }}
                        >
                            {Object.keys(rubricTypes).map((rubricKey) => (
                                <MenuItem
                                    className={classes.menuItem}
                                    key={rubricKey}
                                    selected={rubricTypes[rubricKey] === currentRubricType}
                                    onClick={() => handleRubricTypeChange(rubricKey)}
                                >
                                    <span className={classes.menuLabel}>{rubricTypes[rubricKey]}</span>
                                </MenuItem>
                            ))}
                        </Menu>
                    </ListSubheader>
                }
            >
                {rubricData?.rubrics?.map((row) => {
                    if (showRubric(row) === true) {
                        return (
                            <ListItem
                                key={row.rubricId}
                                dense
                                button
                                style={selectedItem === row.rubricId ? { backgroundColor: 'lightgrey' } : {}}
                                className={selectedItem === row.rubricId ? classes.selectedItem : classes.listItem}
                                onClick={() => handleChangeRubric(row.rubricId)}
                            >
                                <ListItemText
                                    primary={
                                        <Typography className={selectedItem === row.rubricId ? classes.listItemTextSelected : classes.listItemText}>
                                            {row.name}
                                        </Typography>
                                    }
                                />
                                <ListItemText inset primary={isRubricComplete(row.rubricId)} />
                            </ListItem>
                        );
                    }
                    return null;
                })}
            </List>
        </Card>
    );
};

const styles = (theme) => ({
    root: {
        height: '300px',
        overflow: 'auto',
    },
    listSection: {
        color: theme.palette.primary.contrastText,
        paddingRight: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    selectedItem: {
        backgroundColor: 'rgb(235,235,235)',
        paddingRight: 0,
    },
    icon: {
        color: 'white',
        float: 'right',
    },
    completeness: {
        float: 'right',
    },
    menuItem: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    menuLabel: {
        flex: 0.8,
    },
    listItemTextSelected: {
        fontWeight: 'bold',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    listItemText: {
        fontSize: '13px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    content: {
        flex: 7,
        paddingRight: 0,
    },
    listItem: {
        paddingRight: 0,
    },
});

export default withStyles(styles)(RubricDrawer);
