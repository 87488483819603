import React, { useState, useEffect } from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Backdrop, CircularProgress } from '@mui/material';
import { Button } from 'Controls/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ScoringRow from './ScoringRow';
import { AssessmentsWellGroup, LocalFactorsLocalFactor } from '@projectcanary/trustwell-server-client-ts';
import { LocalFactorScope, LocalFactorScore } from './LocalFactorConfig';

type LocalFactorAccordionsProps = {
    localFactors: LocalFactorsLocalFactor[];
    selectedWellGroup: AssessmentsWellGroup;
    assessmentLevelDefaultScores: object;
    wellGroupScores: object;
    overrideScores: object;
    isMarkedComplete: boolean;
    handleCommitScore: (_: any, value: LocalFactorScore, factor: LocalFactorsLocalFactor, target: 'checkbox' | 'slider' | 'reset') => void;
    handleMarkComplete: (selectedWellGroup: AssessmentsWellGroup, isMarkedComplete: boolean) => void;
    loading: boolean;
};

const LocalFactorAccordions = ({
    localFactors,
    selectedWellGroup,
    assessmentLevelDefaultScores,
    wellGroupScores,
    overrideScores,
    isMarkedComplete,
    handleCommitScore,
    handleMarkComplete,
    loading,
}: LocalFactorAccordionsProps) => {
    const [wellGroupAccordionOpen, setWellGroupAccordionOpen] = useState<boolean>(false);
    const [overrideAccordionOpen, setOverrideAccordionOpen] = useState<boolean>(false);
    // Close both accordions when marked complete, and have well group accordion default to open if not

    useEffect(() => {
        if (!isMarkedComplete) {
            setWellGroupAccordionOpen(true);
        }
    }, [selectedWellGroup, isMarkedComplete]);

    const handleOpenAccordion = (target) => {
        if (target === LocalFactorScope.wellGroup) {
            setWellGroupAccordionOpen(!wellGroupAccordionOpen);
        } else if (target === LocalFactorScope.override) {
            setOverrideAccordionOpen(!overrideAccordionOpen);
        }
    };

    const wellGroupFactorDescriptions = localFactors.filter((factor) => wellGroupScores.hasOwnProperty(factor.localFactorId));
    const assessmentAndNotApplicableFactorDescriptions = localFactors.filter((factor) => assessmentLevelDefaultScores.hasOwnProperty(factor.localFactorId));
    const numAssessmentLevel = Object.keys(assessmentLevelDefaultScores).length;
    const numWellGroupFactors = Object.keys(wellGroupScores).length;
    const numWellGroupScored = Object.entries(wellGroupScores).filter((score) => score[1]).length;
    const numOverridden = Object.keys(assessmentLevelDefaultScores).filter((score) => assessmentLevelDefaultScores[score] !== overrideScores[score]).length;

    return (
        <Grid item xs={12}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Accordion expanded={wellGroupAccordionOpen} onChange={() => handleOpenAccordion(LocalFactorScope.wellGroup)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >{`${selectedWellGroup.name} Local Factors (${numWellGroupScored}/${numWellGroupFactors} Complete)`}</AccordionSummary>
                <AccordionDetails>
                    <ScoringRow
                        scope={LocalFactorScope.wellGroup}
                        scopedLocalFactors={wellGroupFactorDescriptions}
                        currentScopedScores={wellGroupScores}
                        handleCommitScore={handleCommitScore}
                        isMarkedComplete={isMarkedComplete}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={overrideAccordionOpen} onChange={() => handleOpenAccordion(LocalFactorScope.override)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >{`Assessment Level Local Factors (${numOverridden}/${numAssessmentLevel} Overridden)`}</AccordionSummary>
                <AccordionDetails>
                    <ScoringRow
                        scope={LocalFactorScope.override}
                        scopedLocalFactors={assessmentAndNotApplicableFactorDescriptions}
                        currentScopedScores={overrideScores}
                        assessmentLevelDefaultScores={assessmentLevelDefaultScores}
                        handleCommitScore={handleCommitScore}
                        isMarkedComplete={isMarkedComplete}
                    />
                </AccordionDetails>
            </Accordion>
            <Button
                variant="primary"
                style={{ marginTop: '20px' }}
                disabled={numWellGroupFactors !== numWellGroupScored && !isMarkedComplete}
                onClick={() => handleMarkComplete(selectedWellGroup, isMarkedComplete)}
            >
                {`Mark ${selectedWellGroup.name} ${isMarkedComplete ? 'Incomplete' : 'Complete'}`}
            </Button>
        </Grid>
    );
};

export default LocalFactorAccordions;
