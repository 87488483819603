import { Box, Input, List, ListItemButton, ListItemIcon, Stack, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ListItemText from '@mui/material/ListItemText';
import _ from 'lodash';
import LinearProgress from '@mui/material/LinearProgress';

export type TaskListItem = {
    id: string;
    isDisabled?: boolean;
    label: string;
    icon?: 'error' | 'warning' | undefined;
    isComplete: boolean;
    tooltip?: string | undefined;
};

export const TaskList = <T extends TaskListItem>(props: { tasks: T[]; selectedTask?: TaskListItem; onItemClick: (task: T) => void }) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [normalizedSearchValue, setNormalizedSearchValue] = useState<string>(searchValue);
    const delayedSetNormalizedSearchValue = useCallback(
        _.debounce((value: string) => {
            setNormalizedSearchValue(value.trim().toLowerCase());
        }, 200),
        [setNormalizedSearchValue]
    );

    useEffect(() => {
        delayedSetNormalizedSearchValue(searchValue);
    }, [searchValue]);

    const filteredTasks = useMemo(
        () => props.tasks.filter((t) => searchValue.length === 0 || t.label.toLowerCase().includes(searchValue.toLowerCase())),
        [props.tasks, normalizedSearchValue]
    );

    const completeTaskCount = useMemo(() => {
        return _.filter(props.tasks, { isComplete: true }).length;
    }, [props.tasks]);

    return (
        <>
            <Box sx={{ p: 1 }}>
                <Input id="outlined" type="text" placeholder="Search" value={searchValue} onChange={({ target }) => setSearchValue(target.value)} fullWidth />
            </Box>
            <Stack direction="row" alignItems="center" justifyContent="space-around">
                <LinearProgress variant="determinate" value={(completeTaskCount / props.tasks.length) * 100} sx={{ width: '65%' }} />
                <Typography variant="body2">
                    {completeTaskCount} / {props.tasks.length} Reviewed
                </Typography>
            </Stack>
            <List dense>
                {filteredTasks.map((t) => (
                    <ListItemButton
                        key={t.id}
                        selected={t.id === props.selectedTask?.id}
                        disabled={t.isDisabled ?? false}
                        onClick={() => {
                            props.onItemClick(t);
                        }}
                    >
                        <ListItemIcon sx={{ minWidth: '36px' }}>
                            {(t.icon !== undefined || t.isComplete) && (
                                <Tooltip title={t.tooltip}>
                                    {t.icon === 'error' ? (
                                        <ErrorOutlineIcon color="error" />
                                    ) : t.icon === 'warning' ? (
                                        <WarningAmberOutlinedIcon color={'warning'} />
                                    ) : t.isComplete ? (
                                        <CheckIcon color="success" />
                                    ) : undefined}
                                </Tooltip>
                            )}
                        </ListItemIcon>
                        <ListItemText>{t.label}</ListItemText>
                    </ListItemButton>
                ))}
            </List>
        </>
    );
};
