import React, { useEffect, useState } from 'react';
import {
    AssessmentDefinitionsAssessmentDefinition,
    AssessmentsBasin,
    CountriesCountry,
    ModulesModuleType as ModuleType,
} from '@projectcanary/trustwell-server-client-ts';
import { ModalFormik } from 'Controls/Modal';
import { Form } from 'formik';
import { Box, Stack, Typography } from '@mui/material';
import { TextField } from 'Controls/Fields/TextField';
import { DropdownField } from 'Controls/Fields/DropdownField';
import * as Yup from 'Utils/yup';
import { assessmentApi } from 'Services/TrustwellApiService';
import { RadioButtonGroupField, RadioButtonGroupOption } from '../Controls/Fields/RadioButtonGroupField';
import _ from 'lodash';
import { nowait } from 'Utils/nowait';
import { Feature, isFeatureEnabled } from '../Config';

type UpsertAssessmentFormAssessment = {
    id?: number;
    name?: string;
    responsibleEngineer: string;
    country?: CountriesCountry;
    activeModules?: ModuleType[];
    canModifyActiveModules: boolean;
};

export type UpsertAssessmentProps = {
    assessment: UpsertAssessmentFormAssessment;
    assessmentDefinitions: AssessmentDefinitionsAssessmentDefinition[];
    currentEngineer: string;
    countries: CountriesCountry[];
    isEditing: boolean;
};

const validAssessmentConfigurations: RadioButtonGroupOption<ModuleType[]>[] = [
    { label: 'Onshore Production with LMVA', value: [ModuleType.Opr, ModuleType.Lmva] },
    { label: 'Onshore Production with LMR', value: [ModuleType.Opr, ModuleType.Lmr] },
    { label: 'LMR', value: [ModuleType.Lmr] },
    {
        label: 'LCR (coming soon)',
        value: [ModuleType.Lcr],
        disabled: !isFeatureEnabled(Feature.Lcr),
    },
];

/**
 * A drop-down field uses reference-equality, so we have to find a matching configuration from the options list.
 */
function findMatchingAssessmentConfiguration(activeModules: ModuleType[]) {
    const matchingConfiguration = _.find(validAssessmentConfigurations, ({ value: optionModules }) =>
        _.isEqual(_.sortBy(optionModules), _.sortBy(activeModules))
    )?.value;
    if (!matchingConfiguration) {
        throw new Error(`No matching assessment configuration found. Actual configuration: ${JSON.stringify(activeModules)}`);
    }
    return matchingConfiguration;
}

const UpsertAssessmentForm = ({ assessment, assessmentDefinitions, currentEngineer, isEditing, countries }: UpsertAssessmentProps) => {
    const [basins, setBasins] = useState<AssessmentsBasin[]>();

    if (isEditing) {
        assessment.activeModules = findMatchingAssessmentConfiguration(assessment.activeModules);
    }

    const fetchBasins = async () => {
        const _assessmentApi = await assessmentApi();

        const basinData = await Promise.all(
            countries.map((c) => {
                return _assessmentApi.getBasinsByCountry(c.countryCode);
            })
        );
        setBasins(basinData.map((data) => data.basins).flat());
    };

    useEffect(() => {
        nowait(fetchBasins());
    }, []);

    const schema = Yup.object().shape({
        name: Yup.string().noEnclosingSpaces().min(3).max(100).required('Please enter a name for the new assessment.').label('Assessment name'),
        responsibleEngineer: Yup.mixed().required('Please select a responsible engineer.'),
        assessmentDefinition: isEditing ? undefined : Yup.mixed().required('Please select an assessment definition.'),
        country: isEditing ? undefined : Yup.mixed().required('Please select a country.'),
        basin: isEditing ? undefined : Yup.mixed().required(),
        activeModules: Yup.array().required('Please select a configuration.'),
    });

    return (
        <ModalFormik initialValues={assessment} validationSchema={schema}>
            {({ values }) => (
                <Form>
                    <Stack spacing={{ xs: 1 }}>
                        <TextField name={'name'} label={'Assessment name'} />
                        <DropdownField includeEmptyOption={false} name={`responsibleEngineer`} label={`Responsible engineer`} options={[currentEngineer]} />
                        {!isEditing && (
                            <DropdownField
                                includeEmptyOption={false}
                                name={`assessmentDefinition`}
                                label={`Assessment definition`}
                                options={assessmentDefinitions}
                                keySelector={'id'}
                                valueRenderer={'name'}
                            />
                        )}
                        {!isEditing && (
                            <DropdownField
                                includeEmptyOption={false}
                                name={`country`}
                                label={`Country`}
                                options={countries}
                                keySelector={'countryCode'}
                                valueRenderer={'countryName'}
                            />
                        )}
                        {!isEditing && (
                            <DropdownField
                                includeEmptyOption={false}
                                name={`basin`}
                                label={`Basin`}
                                options={basins?.filter((b) => b.countryCode === values?.country?.countryCode)}
                                keySelector={'id'}
                                valueRenderer={(b) => `${b.name}${b.registrationId ? ` - ${b.registrationId}` : ''}`}
                            />
                        )}
                        <RadioButtonGroupField<ModuleType[]>
                            sx={{ paddingTop: 1 }}
                            name={'activeModules'}
                            label={'Assessment Configuration'}
                            options={validAssessmentConfigurations}
                            disabled={
                                !assessment.canModifyActiveModules
                                    ? `Can't change assessment configuration (possibly because a rating is being issued).`
                                    : false
                            }
                        />
                    </Stack>
                </Form>
            )}
        </ModalFormik>
    );
};

export default UpsertAssessmentForm;
