import React, { useMemo } from 'react';
import { useQCell } from './QCell';
import { CellBaseProps, QMatrixCellBase } from './QMatrixCellBase';
import { MenuItem, Select, Typography } from '@mui/material';

const unanswered = '__unanswered__';

export const QMatrixChoiceCell = (props: CellBaseProps) => {
    const { questionnaireQuestionId, answerConstraints } = props.question;
    const { value, isNA, isOmitted, setAnswer } = useQCell(props.assetId, questionnaireQuestionId);

    return useMemo(
        () => (
            <QMatrixCellBase {...props}>
                <Select
                    onChange={(e) => setAnswer(e.target.value === unanswered ? undefined : e.target.value)}
                    disabled={isNA || isOmitted}
                    value={value ?? ''}
                    fullWidth={true}
                    variant={'standard'}
                >
                    <MenuItem key={unanswered} value={unanswered}>
                        <Typography sx={{ color: '#AAA', fontStyle: 'italic' }}>Unanswered</Typography>
                    </MenuItem>
                    {answerConstraints.options.map((option) => (
                        <MenuItem key={option.label} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </QMatrixCellBase>
        ),
        [value, isNA, isOmitted, setAnswer, answerConstraints]
    );
};
