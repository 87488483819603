import React, { useRef } from 'react';
import { DatePickerField } from 'Controls/Fields/DatePickerField';
import { TextField } from 'Controls/Fields/TextField';
import { Button } from 'Controls/Button';
import { Form, Formik } from 'formik';
import * as Yup from 'Utils/yup';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useModal } from 'Controls/Modal';
import * as apis from 'Services/TrustwellApiService';
import { ValidationSchemas } from 'Utils/ValidationSchemas';
import { Pad, WellGroupList } from './WellGroupList';
import { formatDateOnly } from 'Utils/formats';
import { useMessageBox } from 'Controls/MessageBox';
import { useNavigation } from 'Utils/Navigation';
import { DropdownField } from 'Controls/Fields/DropdownField';
import _ from 'lodash';
import { useDevTools } from 'Controls/useDevTools';
import { Page } from 'Components/Page';
import { CertificationSetupConfirmationModal } from './IssueRatingsSetupConfirmationModal';
import { isBefore, startOfYear, startOfDay, addMonths } from 'date-fns';
import {
    AssessmentsAssessmentDetails,
    CertificationsCalculateNewCertificationConstraintsResponse as DateConstraints,
    ModulesModuleType,
} from '@projectcanary/trustwell-server-client-ts';
import { CheckboxField } from 'Controls/Fields/CheckboxField';
import { withInitializers } from 'Utils/withInitializers';

const rules = [
    'The well group must have one sample well.',
    'The well group must have all rubrics (all rubrics including policy, plan, and execution) marked as complete.',
    'All local factors must have a selection (score or N/A).',
    'The Rating Start Date must be equal to or after the latest Rating Start Dates on all selected well groups.',
    'The Rating End Date must be equal to or after the latest Rating End Dates on all selected well groups.',
    'An amortization decision must be made for wells that have a deduction that is ready to amortize.',
];

export type Certification = {
    certStartDate: Date | undefined;
    certEndDate: Date | undefined;
    reportDate: Date | undefined;
    certStartDateConfirmation: Date | undefined;
    certEndDateConfirmation: Date | undefined;
    reportDateConfirmation: Date | undefined;
    producerName: string | undefined;
    producerNameConfirmation: string | undefined;
    selectedLmva: Lmva | undefined;
    selectedWellGroups: number[];
    shouldIssueLmva: boolean;
};

export type IssueRatingsSetupPageProps = {
    assessmentId: number;
    assessment: AssessmentsAssessmentDetails;
    pads: Pad[];
    lmvas: Lmva[] | undefined;
};

type Lmva = { name: string; id: number; meetsLmvaRequirements: boolean };

const today = new Date();
const devModeDefaultInputs: Partial<Certification> = {
    certStartDate: startOfYear(today),
    certEndDate: addMonths(startOfYear(today), 1),
    reportDate: startOfDay(today),
    producerName: '#Test Producer',
};

const InnerIssueRatingsSetupPage = ({ assessmentId, assessment, pads, lmvas: availableLmvaList }: IssueRatingsSetupPageProps) => {
    const modal = useModal();

    const dateConstraintsRef = useRef<{ wellGroupIds: number[]; constraints: Promise<DateConstraints> }>();

    async function getDateConstraints(wellGroupIds: number[]): Promise<DateConstraints> {
        const shouldRefresh = dateConstraintsRef.current === undefined || _.xor(dateConstraintsRef.current.wellGroupIds, wellGroupIds).length;

        if (shouldRefresh) {
            dateConstraintsRef.current = {
                wellGroupIds: wellGroupIds,
                constraints: (async () => (await apis.certificationApi()).calculateNewCertificationConstraints({ wellGroupIds }))(),
            };
        }

        return await dateConstraintsRef.current.constraints;
    }

    const validationSchema = Yup.object().shape({
        certStartDate: Yup.date()
            .label('Rating start date')
            .required()
            .startOfMonth()
            .when('selectedWellGroups', {
                is: (selectedWellGroups) => selectedWellGroups.length > 0,
                then: (schema) =>
                    schema.test('ipc-start-date', async (value: Date, options) => {
                        const constraints = await getDateConstraints(options.parent.selectedWellGroups);
                        if (constraints.minStartDate !== undefined && isBefore(value, constraints.minStartDate)) {
                            return options.createError({
                                message: `The rating start date must be equal to or after the latest start date of the most recent rating of all the wells on the selected well groups: (${formatDateOnly(
                                    constraints.minStartDate
                                )}).`,
                            });
                        } else {
                            return true;
                        }
                    }),
            }),

        certEndDate: Yup.date()
            .label('Rating end date')
            .required()
            .startOfMonth()
            .when('certStartDate', {
                is: (certStartDate) => certStartDate !== undefined,
                then: (schema) =>
                    schema.min(Yup.ref('certStartDate'), (params) => {
                        return `The rating end date must be on or after the rating start date (${formatDateOnly(params.min as Date)}).`;
                    }),
            })
            .when('selectedWellGroups', {
                is: (selectedWellGroups) => selectedWellGroups.length > 0,
                then: (schema) =>
                    schema.test('ipc-end-date', async (value: Date, options) => {
                        const constraints = await getDateConstraints(options.parent.selectedWellGroups);
                        if (constraints.minEndDate !== undefined && isBefore(value, constraints.minEndDate)) {
                            return options.createError({
                                message: `The rating end date must be equal to or after the latest end date of the most recent rating of all the wells on the selected well groups: (${formatDateOnly(
                                    constraints.minEndDate
                                )}).`,
                            });
                        } else {
                            return true;
                        }
                    }),
            }),

        reportDate: Yup.date().required('Required'),
        certStartDateConfirmation: Yup.date().required('Required').sameAs('certStartDate', 'Rating Start Date'),
        certEndDateConfirmation: Yup.date().required('Required').sameAs('certEndDate', 'Rating End Date'),
        reportDateConfirmation: Yup.date().required('Required').sameAs('reportDate', 'Report Date'),
        producerName: ValidationSchemas.producerName().required(),
        producerNameConfirmation: ValidationSchemas.producerName().required().sameAs('producerName', 'Producer Name'),
        selectedWellGroups: Yup.array().min(1, 'Please select at least one well group to issue ratings.'),
        selectedLmva: assessment.activeModules.includes(ModulesModuleType.Lmva) ? Yup.object().required('Please select an LMVA.') : undefined,
    });

    const { site, navigateTo } = useNavigation();
    const messageBox = useMessageBox();

    const certification: Certification = {
        certStartDate: undefined,
        certEndDate: undefined,
        reportDate: undefined,
        certStartDateConfirmation: undefined,
        certEndDateConfirmation: undefined,
        reportDateConfirmation: undefined,
        producerName: '',
        producerNameConfirmation: '',
        selectedLmva: undefined,
        selectedWellGroups: [],
        shouldIssueLmva: false,
    };

    const devToolsEnabled = useDevTools();

    const handleInfoClick = () => {
        return modal.show(
            { title: 'Requirements To Issue Ratings', buttons: ['Exit'] },
            <Stack spacing={3}>
                {rules.map((rule) => (
                    <Typography variant="body1">{rule}</Typography>
                ))}
            </Stack>
        );
    };

    const handleFormOnSubmit = async (values: Certification) => {
        modal
            .show(
                {
                    title: 'Verification',
                    buttons: ['Yes', 'Cancel'],
                },
                <CertificationSetupConfirmationModal assessment={assessment} values={values} />
            )
            .onAccept(async () => {
                const api = await apis.certificationApi();
                try {
                    await api.startCertification({
                        assessmentId: assessmentId,
                        certificateStartDate: values.certStartDate,
                        certificateEndDate: values.certEndDate,
                        reportDateTime: values.reportDate,
                        wellGroupsIds: values.selectedWellGroups,
                        producerName: values.producerName,
                        lmvaId: values.selectedLmva?.id ?? null,
                        shouldIssueLmva: values.shouldIssueLmva,
                    });
                    navigateTo(site.assessment(assessmentId).certification.review);
                } catch (error) {
                    messageBox.error("Couldn't start a new rating issuance. " + error);
                }
            });
    };

    return (
        <Page title={'Issue Ratings'}>
            <Formik<Certification> initialValues={certification} validationSchema={validationSchema} onSubmit={handleFormOnSubmit} validateOnChange>
                {({ errors, touched, resetForm, values, setFieldValue, setFieldTouched }) => {
                    const referenceFields = ['certStartDate', 'certEndDate', 'reportDate', 'producerName'];
                    const showConfirmationFields = referenceFields.every((f) => errors[f] === undefined && touched[f]);

                    const confirmationFieldNameSuffix = 'Confirmation';

                    // Reset shouldIssueLmva flag if we switch to non-qualifying LMVA.
                    if (values.shouldIssueLmva && values.selectedLmva?.meetsLmvaRequirements !== true) {
                        setFieldValue('shouldIssueLmva', false);
                    }

                    function copyReferenceFieldsToConfirmationFields() {
                        referenceFields.forEach((referenceFieldName) => {
                            const confirmationFieldName = referenceFieldName + confirmationFieldNameSuffix;
                            if (values[confirmationFieldName] !== values[referenceFieldName]) {
                                setFieldValue(confirmationFieldName, values[referenceFieldName]);
                                setFieldTouched(confirmationFieldName, true);
                            }
                        });
                    }

                    if (devToolsEnabled) {
                        _.forEach(devModeDefaultInputs, (defaultValue, fieldName) => {
                            if (values[fieldName] === undefined || values[fieldName] === '') {
                                setFieldValue(fieldName, defaultValue);
                                // Workaround for validation bug in Formik
                                _.defer(() => {
                                    setFieldTouched(fieldName);
                                });
                            }
                        });
                        copyReferenceFieldsToConfirmationFields();
                    }

                    const disableInputMessage = !values.selectedWellGroups.length && 'Please select at least one well group.';

                    return (
                        <Form>
                            <Stack spacing={3} padding={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={5}>
                                        <WellGroupList name={'selectedWellGroups'} pads={pads} label={'Select well groups to issue ratings'} />
                                        <Stack direction="row" justifyContent="end">
                                            <Typography variant="body1">
                                                <IconButton onClick={handleInfoClick}>
                                                    <InfoIcon />
                                                </IconButton>
                                                What are the requirements for issuing rating(s)?
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={1} />
                                    <Grid item xs={6} padding={14}>
                                        <Stack direction={'column'} justifyContent={'flex-start'} alignItems={'stretch'} spacing={3}>
                                            <Typography variant="h6" sx={{ mb: 1 }}>
                                                Enter rating and report details
                                            </Typography>
                                            <Grid container>
                                                <Grid item xs={5}>
                                                    <Stack spacing={3} sx={{ mr: 10 }}>
                                                        <DatePickerField
                                                            name="certStartDate"
                                                            label="Rating Start Date"
                                                            specificity={'month'}
                                                            initialView={'month'}
                                                            disabled={disableInputMessage}
                                                        />
                                                        <DatePickerField
                                                            name="certEndDate"
                                                            label="Rating End Date"
                                                            specificity={'month'}
                                                            initialView={'year'}
                                                            disabled={disableInputMessage}
                                                        />
                                                        <DatePickerField label="Report Date" name={'reportDate'} disabled={disableInputMessage} />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={2}></Grid>
                                                <Grid item xs={5}>
                                                    {showConfirmationFields && (
                                                        <Stack spacing={3} sx={{ mr: 10 }}>
                                                            <DatePickerField
                                                                name={'certStartDate' + confirmationFieldNameSuffix}
                                                                label="Re-enter Rating Start Date"
                                                                specificity={'month'}
                                                                initialView={'month'}
                                                                disabled={disableInputMessage}
                                                            />
                                                            <DatePickerField
                                                                name={'certEndDate' + confirmationFieldNameSuffix}
                                                                label="Re-enter Rating End Date"
                                                                specificity={'month'}
                                                                initialView={'year'}
                                                                disabled={disableInputMessage}
                                                            />
                                                            <DatePickerField
                                                                label="Re-enter Confirm Report Date"
                                                                name={'reportDate' + confirmationFieldNameSuffix}
                                                            />
                                                        </Stack>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Box>
                                                <TextField label="Producer Name" name="producerName" disabled={disableInputMessage} />
                                                {showConfirmationFields && (
                                                    <TextField
                                                        label="Re-enter Producer Name"
                                                        name={'producerName' + confirmationFieldNameSuffix}
                                                        disablePaste={true}
                                                        disabled={disableInputMessage}
                                                    />
                                                )}
                                            </Box>

                                            {availableLmvaList && (
                                                <Stack spacing={1} sx={{ mr: 20 }}>
                                                    <Box sx={{ mb: 2 }}>
                                                        <Typography variant="h6">Select LMVA</Typography>
                                                        <Typography variant="body1" sx={{ fontSize: '60%', color: '#888' }}>
                                                            If LMVA minimum requirements are not met, selected LMVA will only be used as a source of methane
                                                            intensity information.
                                                        </Typography>
                                                    </Box>

                                                    <DropdownField<Lmva>
                                                        name={'selectedLmva'}
                                                        label={'Low Methane Verified Attribute'}
                                                        keySelector={'id'}
                                                        valueRenderer={'name'}
                                                        includeEmptyOption={false}
                                                        options={availableLmvaList}
                                                        disabled={disableInputMessage || !availableLmvaList}
                                                    />

                                                    <CheckboxField
                                                        name={'shouldIssueLmva'}
                                                        label={'Issue LMVA'}
                                                        disabled={
                                                            disableInputMessage ||
                                                            (!values.selectedLmva && 'Please select an LMVA first.') ||
                                                            (!values.selectedLmva.meetsLmvaRequirements &&
                                                                `Selected LMVA does not meet minimum requirements, either methane intensity is above the threshold or LMVA questionnaire hasn't been complete.`)
                                                        }
                                                    />
                                                </Stack>
                                            )}
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Stack spacing={3} direction="row" justifyContent="end" mr={10}>
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        resetForm();
                                    }}
                                >
                                    Reset
                                </Button>
                                <Button href={site.assessment(assessmentId).certification} variant={'secondary'}>
                                    Exit
                                </Button>
                                <Button variant="primary" type={'submit'}>
                                    Next
                                </Button>
                            </Stack>
                        </Form>
                    );
                }}
            </Formik>
        </Page>
    );
};

export const IssueRatingsSetupPage = withInitializers(
    {
        assessment: async ({ assessmentId }) => {
            const assessmentApi = await apis.assessmentApi();
            const assessment = await assessmentApi.getAssessmentById(assessmentId);
            return assessment;
        },
    },
    withInitializers<IssueRatingsSetupPageProps, 'lmvas' | 'pads'>(
        {
            lmvas: async ({ assessment, assessmentId }) => {
                if (assessment.activeModules.includes(ModulesModuleType.Lmva)) {
                    const assessmentApi = await apis.assessmentApi();
                    return await assessmentApi.getAllLmvasByAssessment(assessmentId);
                } else {
                    return undefined;
                }
            },
            pads: async ({ assessmentId }) => {
                const certificationApi = await apis.certificationApi();
                const readinessCheckResult = await certificationApi.checkAssessmentCertificationReadiness(assessmentId);
                const wellGroups = readinessCheckResult.wellGroupValidationResult.map(
                    (result) =>
                        ({
                            padName: result.padName,
                            wellGroupId: result.wellGroupId,
                            padAssetId: result.padAssetId,
                            issues: result.issues,
                        } as Pad)
                );
                return wellGroups;
            },
        },
        InnerIssueRatingsSetupPage
    )
);
