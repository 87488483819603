import React, { useMemo, useState } from 'react';
import { useMessageBox } from 'Controls/MessageBox';
import { useLoader } from 'Utils/loader';
import { assessmentApi } from 'Services/TrustwellApiService';
import { AssessmentApi, AssessmentsAssessment, AssessmentsLmva as Lmva } from '@projectcanary/trustwell-server-client-ts';
import { useNavigation } from 'Utils/Navigation';
import { useGridDefaultSort } from 'Utils/useGridDefaultSort';
import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem } from 'Controls/GridActionsCellItem';
import { Page } from 'Components/Page';
import { TopButtons } from 'Components/TopButtons';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { columns } from './LmvaPageColumns';
import { Button } from 'Controls/Button';
import { withInitializers } from 'Utils/withInitializers';
import _ from 'lodash';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { nowait } from 'Utils/nowait';
import { useModal } from 'Controls/Modal';

type LmvaPageProps = {
    assessment: AssessmentsAssessment;
    lmvas: Lmva[];
    assessmentApi: AssessmentApi;
};

const InnerLmvaPage = ({ assessment, lmvas: initialLmvas, assessmentApi }: LmvaPageProps) => {
    const messageBox = useMessageBox();
    const modal = useModal();
    const [isLoading, withLoader] = useLoader();
    const { site, navigateTo } = useNavigation();
    const [lmvas, setLmvas] = useState<Lmva[]>(initialLmvas);

    const gridSortModel = useGridDefaultSort([{ field: 'creationDateTime', sort: 'asc' }]);

    const actions = useMemo(() => {
        const deleteLmva = async (lmva: Lmva) => {
            modal
                .show(
                    {
                        title: 'Delete LMVA',
                        style: 'critical',
                        buttons: [
                            { title: 'Yes, delete', icon: 'Delete', isAccept: true },
                            { title: 'Cancel', isDefault: true },
                        ],
                    },
                    `Are you sure you want to delete LMVA '${lmva.name}'?`
                )
                .onAccept(
                    withLoader(async () => {
                        try {
                            await assessmentApi.deleteLmva(lmva.id);
                            setLmvas((current) => _.reject(current, { id: lmva.id }));
                            messageBox.success(`LMVA '${lmva.name}' was deleted successfully.`);
                        } catch (error) {
                            messageBox.error(`Couldn't delete LMVA '${lmva.name}'. ${error}`);
                        }
                    })
                );
        };

        return {
            headerName: 'Actions',
            type: 'actions',
            field: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                    onClick={() => {
                        navigateTo(site.assessment(assessment.id).lmva(params.row.id).edit);
                    }}
                    showInMenu={false}
                    label={'Edit LMVA'}
                    icon={<EditIcon />}
                    disabled={params.row.isLocked && 'LMVA is either being used in an in-progress rating publication, or was included in a issued rating.'}
                />,
                <GridActionsCellItem
                    showInMenu={false}
                    onClick={() => {
                        nowait(deleteLmva(params.row));
                    }}
                    label={'Delete LMVA'}
                    icon={<DeleteIcon />}
                    disabled={params.row.isLocked && 'LMVA is either being used in an in-progress rating publication, or was included in a issued rating.'}
                />,
            ],
        };
    }, [assessment.id, lmvas]);

    return (
        <Page title={'Low Methane Verified Attributes'}>
            <TopButtons>
                <Button href={site.assessment(assessment.id).lmva.add} variant="primary">
                    Add LMVA
                </Button>
            </TopButtons>
            <DataGridPro<Lmva>
                rows={lmvas ?? []}
                columns={[...columns, actions]}
                loading={isLoading}
                getRowId={(row) => row.id}
                {...gridSortModel}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            segment: false,
                            granularity: false,
                            benchmark: false,
                            production: false,
                            emissionProtocol: false,
                        },
                    },
                }}
            />
        </Page>
    );
};

export const LmvaPage = withInitializers<LmvaPageProps, 'lmvas' | 'assessmentApi'>(
    {
        async lmvas(props) {
            try {
                const api = await assessmentApi();
                const lmvas = await api.getAllLmvasByAssessment(props.assessment.id);
                return lmvas;
            } catch (error) {
                throw new Error('There was an error retrieving the LMVAs. ' + error);
            }
        },
        async assessmentApi() {
            return await assessmentApi();
        },
    },
    InnerLmvaPage
);
