import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormControl,
    Button,
} from '@mui/material';
import { LocalFactorsLocalFactor } from '@projectcanary/trustwell-server-client-ts';
import { LocalFactorConfigStatus, LocalFactorScope } from './LocalFactorConfig';

type LocalFactorsConfigurationProps = {
    configStep: LocalFactorConfigStatus;
    localFactors: LocalFactorsLocalFactor[];
    configSelections: object;
    handleConfigSelection: (e: any) => void;
    handleCancelConfig: () => void;
    handleConfigNextStep: () => void;
    handleGoBack: () => void;
    handleSaveConfig: () => void;
};

const LocalFactorsConfiguration = ({
    configStep,
    localFactors,
    configSelections,
    handleConfigSelection,
    handleCancelConfig,
    handleConfigNextStep,
    handleGoBack,
    handleSaveConfig,
}: LocalFactorsConfigurationProps) => {
    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Local Factor</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell width="500px" align="center">
                                Local Factor Scope
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {localFactors?.map((factor: LocalFactorsLocalFactor) => (
                            <TableRow key={factor.localFactorId}>
                                <TableCell>{factor.name}</TableCell>
                                <TableCell>{factor.description}</TableCell>
                                <TableCell>
                                    {(configStep === LocalFactorConfigStatus.Unconfigured || configStep === LocalFactorConfigStatus.SelectConfig) && (
                                        <FormControl>
                                            <RadioGroup row onChange={handleConfigSelection}>
                                                <FormControlLabel
                                                    control={<Radio color="primary" />}
                                                    label="Assessment"
                                                    value={`${factor.localFactorId}_Assessment`}
                                                    checked={configSelections[factor.localFactorId] === LocalFactorScope.assessment}
                                                />

                                                <FormControlLabel
                                                    control={<Radio color="primary" />}
                                                    label="N/A"
                                                    // "Value" format allows event handler to get a string of the local factor id
                                                    // and factor name and split them into two variables without the need for another
                                                    // function. Value is split at underscore.
                                                    value={`${factor.localFactorId}_Local Factor N/A`}
                                                    checked={configSelections[factor.localFactorId] === LocalFactorScope.notApplicable}
                                                />

                                                <FormControlLabel
                                                    control={<Radio color="primary" />}
                                                    label="Well Groups"
                                                    value={`${factor.localFactorId}_Well Group`}
                                                    checked={configSelections[factor.localFactorId] === LocalFactorScope.wellGroup}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    )}
                                    {configStep === LocalFactorConfigStatus.ConfirmConfig ? configSelections[factor.localFactorId] : null}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {(configStep === LocalFactorConfigStatus.Unconfigured || configStep === LocalFactorConfigStatus.SelectConfig) && (
                <div>
                    <Button variant="outlined" size="large" onClick={handleCancelConfig} style={{ marginRight: '20px' }}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        disabled={Object.keys(configSelections).length !== localFactors.length}
                        onClick={handleConfigNextStep}
                    >
                        Next
                    </Button>
                </div>
            )}
            {configStep === LocalFactorConfigStatus.ConfirmConfig && (
                <div>
                    <div>
                        IMPORTANT: Assessment Local Factors may only be configured once, please make sure that your Local Factors are properly configured.
                    </div>
                    <Button variant="outlined" size="large" onClick={handleGoBack} style={{ marginRight: '20px' }}>
                        Back
                    </Button>
                    <Button variant="contained" size="large" onClick={handleSaveConfig}>
                        Continue
                    </Button>
                </div>
            )}
        </>
    );
};

export default LocalFactorsConfiguration;
