import React, { useState, useEffect } from 'react';
import { Grid, TableRow, TableCell, Typography, Slider, Checkbox, Button } from '@mui/material';
import { LocalFactorsLocalFactor } from '@projectcanary/trustwell-server-client-ts';
import { LocalFactorScope, LocalFactorScore } from './LocalFactorConfig';

type ScoringSliderAndCheckboxProps = {
    scope: LocalFactorScope;
    localFactor: LocalFactorsLocalFactor;
    sliderScore: LocalFactorScore;
    defaultScore: LocalFactorScore;
    isMarkedComplete: boolean;
    handleCommitScore: (e: any, value: LocalFactorScore, lf: LocalFactorsLocalFactor, type: string) => void;
};

const ScoringSliderAndCheckbox = ({ scope, localFactor, sliderScore, defaultScore, isMarkedComplete, handleCommitScore }: ScoringSliderAndCheckboxProps) => {
    const [currentScore, setCurrentScore] = useState<LocalFactorScore>(sliderScore);

    useEffect(() => {
        setCurrentScore(sliderScore);
    }, [sliderScore]);

    return (
        <TableRow key={localFactor.localFactorId}>
            <TableCell>
                <Typography variant="body1" gutterBottom align="left">
                    {localFactor.name}
                </Typography>

                <Typography variant="caption" gutterBottom align="left">
                    {localFactor.description}
                </Typography>
            </TableCell>
            <TableCell style={{ width: '80%' }}>
                <Grid container direction="row">
                    <Grid item xs={10}>
                        <Typography variant="overline" gutterBottom>
                            {scope === 'Override' && sliderScore === defaultScore ? 'Assessment Level' : scope}
                        </Typography>
                    </Grid>
                    {scope !== LocalFactorScope.assessment && (
                        <Grid item xs={2}>
                            <Typography variant="overline" gutterBottom>
                                N/A
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid container direction="row">
                    <Grid item xs={2}>
                        {scope === 'Override' && currentScore !== defaultScore && (
                            <Button disabled={isMarkedComplete} variant="contained" onClick={(e) => handleCommitScore(e, defaultScore, localFactor, 'reset')}>
                                Reset
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="subtitle2" gutterBottom>
                            {currentScore ? currentScore : 'Select Local Factor Score'}
                        </Typography>
                    </Grid>
                    {scope !== LocalFactorScope.assessment && (
                        <Grid item xs={2}>
                            <Checkbox
                                data-testid={`${localFactor.localFactorId}-checkbox`}
                                disabled={isMarkedComplete}
                                color="primary"
                                checked={currentScore === 'N/A'}
                                onChange={(e) => handleCommitScore(e, currentScore, localFactor, 'checkbox')}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid container direction="row">
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <Slider
                            data-testid={`${localFactor.localFactorId}-slider`}
                            disabled={currentScore === 'N/A' || isMarkedComplete}
                            marks
                            min={1}
                            max={5}
                            step={0.5}
                            value={currentScore && currentScore !== 'N/A' ? currentScore : null}
                            onChange={(e, value) => setCurrentScore(value)}
                            onChangeCommitted={(e, value) => handleCommitScore(e, value, localFactor, 'slider')}
                        />
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
                <Grid container>
                    {[1, 2, 3, 4, 5].map((binNum) => {
                        return (
                            <Grid key={binNum} item xs={2.4} style={{ padding: '4px', textAlign: 'center' }}>
                                <Typography variant="caption" gutterBottom>
                                    {localFactor[`bin${binNum}`]}
                                </Typography>
                            </Grid>
                        );
                    })}
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default ScoringSliderAndCheckbox;
