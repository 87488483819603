import { GridActionsColDef, GridColumns } from '@mui/x-data-grid/models/colDef/gridColDef';
import { Radio } from '@mui/material';
import React from 'react';
import { AssetsAsset } from '@projectcanary/trustwell-server-client-ts';

export type RowBase = {
    hierarchy: string[];
    isWellGroupRow?: boolean;
    isWellRow?: boolean;
    hasIssuedCert?: boolean;
    hasInProgressCert?: boolean;
    isOnlyWellInGroup?: boolean;
};
export type WellGroupRow = RowBase & AssetsAsset;
export type WellRow = RowBase & AssetsAsset & { isDuplicateWellId?: boolean };
export type Row = WellGroupRow | WellRow;

export const buildColumns: (toggleIsSampleWellFlag: (well: Row) => void, actionsBuilder: GridActionsColDef['getActions']) => GridColumns<Row> = (
    toggleIsSampleWellFlag,
    actionsBuilder
) =>
    [
        {
            headerName: 'Well Id',
            field: 'id',
            type: 'string',
            width: 75,
        },
        {
            headerName: 'Well Name',
            field: 'name',
            type: 'string',
            width: 250,
        },
        {
            headerName: 'Well Group',
            field: 'wellGroupName',
            type: 'string',
            width: 250,
            valueGetter: ({ row }) => (row.isWellRow ? row.wellGroupName : undefined),
        },
        {
            headerName: 'Sample Well',
            field: 'isSampleWell',
            width: 250,
            filterable: false,
            renderCell: ({ row }) => (row.isWellRow ? <Radio checked={row.isSampleWell} onClick={() => toggleIsSampleWellFlag(row)} /> : undefined),
        },
        {
            headerName: 'Actions',
            field: 'actions',
            type: 'actions',
            width: 100,
            cellClassName: 'actions',
            align: 'left',
            getActions: actionsBuilder,
        },
    ] as GridColumns<Row>;
