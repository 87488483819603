import { createTheme } from '@mui/material/styles';
import '@mui/x-data-grid-pro/themeAugmentation';
import DataGridCustomToolbar from 'Controls/DataGridCustomToolbar';
import { Feature, isFeatureEnabled } from '../Config';

declare module '@mui/material/Button' {
    export interface ButtonPropsVariantOverrides {
        primary: true;
        secondary: true;
        link: true;
    }
}

declare module '@mui/material/Typography/Typography' {
    export interface TypographyPropsVariantOverrides {
        hint: true;
        dev: true;
        bold: true;
        critical: true;
    }
}

declare module '@mui/material/styles/createPalette' {
    export interface SimplePaletteColorOptions {
        hintText?: string;
    }

    export interface PaletteColor {
        hintText?: string;
    }
}

export const emptyDataGridCellClass = 'DataGrid-cell--empty';

export const theme = createTheme({
    palette: {
        primary: {
            light: '#3a4d6c',
            main: '#024062',
            dark: '#00001b',
            contrastText: '#fff',
            hintText: '#999',
        },
        secondary: {
            main: '#A2F2D1', // teal
            light: '#FED636', // yellow
        },
        error: {
            main: '#D70040',
        },
        success: {
            main: '#008000',
        },
        info: {
            main: '#0000FF',
        },
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'primary' },
                    style: ({ theme }) => ({
                        color: theme.palette.primary.main,
                    }),
                },
            ],
        },
        MuiTypography: {
            variants: [
                {
                    props: { variant: 'hint' },
                    style: ({ theme }) => ({
                        color: theme.palette.primary.hintText,
                        fontSize: '90%',
                    }),
                },
                {
                    props: { variant: 'dev' },
                    style: ({ theme }) => ({
                        color: '#333',
                        backgroundColor: '#FF8',
                        fontFamily: 'monospace',
                        fontSize: '90%',
                        fontWeight: 'normal',
                    }),
                },
                {
                    props: { variant: 'bold' },
                    style: ({ theme }) => ({
                        fontWeight: 500,
                    }),
                },
                {
                    props: { variant: 'critical' },
                    style: ({ theme }) => ({
                        color: 'red',
                    }),
                },
            ],
            defaultProps: {
                variantMapping: {
                    hint: 'span',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundImage: isFeatureEnabled(Feature.NonProdHeaderBackground)
                        ? "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('/nonProdHeaderBackground.jpg')"
                        : undefined,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                toolbarContainer: {
                    borderBottom: 'solid thin #EEE',
                    padding: 10,
                },
                actionsCell: {
                    color: 'green',
                    backgroundColor: '#FAA',
                },
                columnHeaderTitle: {
                    fontWeight: 500,
                },
            },
            defaultProps: {
                hideFooterSelectedRowCount: true,
                autoHeight: true,
                autoPageSize: false,
                pagination: true,
                initialState: {
                    pagination: {
                        pageSize: 25,
                    },
                },
                components: { Toolbar: DataGridCustomToolbar },
                rowsPerPageOptions: [25, 100, 1000],
                disableSelectionOnClick: true,
                density: 'compact',
                sx: {
                    ['& .' + emptyDataGridCellClass + '::before']: {
                        content: "'N/A'",
                    },
                    ['& .' + emptyDataGridCellClass]: {
                        color: '#AAA',
                    },
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    color: 'white',
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                colorPrimary: 'background-color: #A3C8ED',
                barColorPrimary: 'background-color: #1976D2',
            },
        },
    },
});
