import { GridColDef, GridColumns } from '@mui/x-data-grid/models/colDef/gridColDef';
import { emptyDataGridCellClass } from 'Apperance/Theme';
import { decimalPlacesFormatter, LmrRatingColumn, oprScoreColumn } from 'Utils/formats';
import _ from 'lodash';
import { ModulesModuleType, OprRatingMedal } from '@projectcanary/trustwell-server-client-ts';

const naIfEmpty: Partial<GridColDef> = {
    cellClassName: ({ value }) => value === undefined && emptyDataGridCellClass,
};

const commonColumns: GridColumns = [
    {
        headerName: 'Pad Asset Id',
        field: 'padAssetId',
        type: 'string',
        width: 200,
        filterable: true,
    },
    {
        headerName: 'Well Group Name',
        field: 'wellGroupName',
        type: 'string',
        flex: 1,
        filterable: true,
    },
    {
        headerName: 'Well Count',
        field: 'wellCount',
        type: 'number',
        width: 100,
        filterable: false,
    },
];

const oprColumns: GridColumns = [
    {
        headerName: 'Min Score',
        field: 'minScore',
        width: 100,
        ...naIfEmpty,
        ...oprScoreColumn,
        filterable: false,
    },
    {
        headerName: 'Max Score',
        field: 'maxScore',
        width: 100,
        ...naIfEmpty,
        ...oprScoreColumn,
        filterable: false,
    },
    {
        headerName: 'Average Score',
        field: 'avgScore',
        type: 'number',
        width: 120,
        valueFormatter: ({ value }) => (value !== undefined ? _.round(value, 1) : undefined),
        ...naIfEmpty,
        filterable: false,
    },
    {
        headerName: 'Score Range',
        field: 'scoreRange',
        type: 'number',
        width: 120,
        valueFormatter: ({ value }) => (value !== undefined ? _.round(value, 1) : undefined),
        ...naIfEmpty,
        filterable: false,
    },
    {
        headerName: 'Medals (E/S/G/P)',
        headerAlign: 'right',
        field: 'medalCounts',
        type: 'string',
        align: 'right',
        width: 150,
        valueFormatter: ({ value }) => {
            const medals = [OprRatingMedal.Evaluated, OprRatingMedal.Silver, OprRatingMedal.Gold, OprRatingMedal.Platinum];

            return _.map(medals, (medal) => value[OprRatingMedal[medal]]).join('/');
        },
        sortable: false,
        filterable: false,
    },
];

const lmrColumns: GridColumns = [
    {
        headerName: 'LMR Pad MI',
        field: 'methaneIntensity',
        type: 'number',
        width: 100,
        ...naIfEmpty,
        valueFormatter: decimalPlacesFormatter(4),
        filterable: false,
    },
    {
        headerName: 'LMR Score',
        field: 'lmrScore',
        width: 100,
        ...naIfEmpty,
        filterable: false,
    },
    {
        headerName: 'LMR Rating',
        field: 'lmrRating',
        width: 120,
        ...LmrRatingColumn,
        filterable: false,
    },
];

export const buildColumns: (activeModules: ModulesModuleType[]) => GridColumns = (activeModules: ModulesModuleType[]) => [
    ...commonColumns,
    ...(activeModules.includes(ModulesModuleType.Opr) ? oprColumns : []),
    ...(activeModules.includes(ModulesModuleType.Lmr) ? lmrColumns : []),
];
