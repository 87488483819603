import React from 'react';
import { DeductionRow } from './DeductionsPage';
import { DeductionsAmortizationDecision as AmortizationDecision } from '@projectcanary/trustwell-server-client-ts';
import { ModalFormik } from 'Controls/Modal';
import { RadioButtonGroupField } from 'Controls/Fields/RadioButtonGroupField';
import Stack from '@mui/material/Stack';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import * as Yup from 'Utils/yup';

const validationSchema = Yup.object().shape({
    decision: Yup.mixed().required('Please select a decision for handling this deduction amortization.'),
});

export function AmortizeDeductionModal(props: { deduction: DeductionRow; decision: AmortizationDecision }) {
    const { deduction } = props;
    const { deductionDefinition, amortizationDetails } = deduction;

    const isLastDecisionTentativeAndAmortize = _.last(deduction.amortizationDetails.history).decision === AmortizationDecision.Amortize;

    const toPoints = (amortizationCount: number) => _.round(amortizationCount * amortizationDetails.pointsPerAmortization, 1);

    return (
        <ModalFormik
            validationSchema={validationSchema}
            initialValues={{
                decision: props.decision,
            }}
        >
            <Stack direction={'column'} spacing={1.5}>
                <Box>
                    <Typography variant={'body2'}>{deduction.severityDescription}</Typography>
                </Box>
                <Box>
                    Amortization Period: <Typography variant={'bold'}>{deductionDefinition.amortizationPeriod} years</Typography>
                </Box>
                <Box>
                    The deduction has previously been amortized:{' '}
                    <Typography variant={'bold'}>
                        {amortizationDetails.amortizationCount} year[s] for a total of {toPoints(amortizationDetails.amortizationCount)} points (out of{' '}
                        {toPoints(deductionDefinition.amortizationPeriod)})
                    </Typography>
                </Box>
                <Box>
                    <Typography variant={'bold'}>
                        If you choose to amoritze {toPoints(amortizationDetails.tentativeAmortizationCount)} points will be amortized, for the total of{' '}
                        {isLastDecisionTentativeAndAmortize
                            ? toPoints(amortizationDetails.amortizationCount)
                            : toPoints(amortizationDetails.tentativeAmortizationCount + amortizationDetails.amortizationCount)}{' '}
                        points (out of {toPoints(deductionDefinition.amortizationPeriod)})
                    </Typography>
                </Box>
                <Box>
                    Amortize: This selection will update the well's performance score with the additional points, which will be effective on the well's next
                    issued rating.
                </Box>

                <Box>
                    Do Not Amortize: This selection will{' '}
                    <Typography variant={'bold'} component={'span'}>
                        not
                    </Typography>{' '}
                    update the well's performance score with the additional points, which will be effective on the well's next issued rating. This{' '}
                    <Typography variant={'bold'} component={'span'}>
                        {' '}
                        will restart the amortization progress
                    </Typography>{' '}
                    and{' '}
                    <Typography variant={'bold'} component={'span'}>
                        will require another year of ratings
                    </Typography>{' '}
                    to be issued before you can choose to amortize again.
                </Box>
                <Box>
                    Postpone: This selection will{' '}
                    <Typography variant={'bold'} component={'span'}>
                        not
                    </Typography>{' '}
                    update the well's performance score with the additional points, which will be effective on the well's next issued rating. This will{' '}
                    <Typography variant={'bold'} component={'span'}>
                        {' '}
                        not restart the amortization progress
                    </Typography>{' '}
                    and{' '}
                    <Typography variant={'bold'} component={'span'}>
                        will not require another year of ratings
                    </Typography>{' '}
                    to be issued before you can choose to amortize again.
                </Box>
                <RadioButtonGroupField<AmortizationDecision>
                    name={'decision'}
                    fullWidth={true}
                    row={true}
                    options={{
                        Amortize: AmortizationDecision.Amortize,
                        Postpone: AmortizationDecision.Postpone,
                        'Do Not Amortize': AmortizationDecision.Reject,
                    }}
                />
            </Stack>
        </ModalFormik>
    );
}
