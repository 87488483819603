import { add, differenceInMilliseconds, startOfWeek } from 'date-fns';
import { ReactElement, useEffect, useRef } from 'react';
import { getFaviconHref, setFavicon, setFaviconHref } from 'Utils/favicon';

const whiskeyDateTimeRange = { weekDay: 3, startHour: 16, endHour: 20 };
// For testing:
// const whiskeyDateTimeRange = {
//     weekDay: 1,
//     startHour: new Date().getHours() + new Date().getMinutes() / 60 + (new Date().getSeconds() + 3) / 3600,
//     endHour: new Date().getHours() + new Date().getMinutes() / 60 + (new Date().getSeconds() + 6) / 3600,
// };

export const WhiskeyTime = (props: { children: ReactElement }): ReactElement => {
    const timerRef = useRef<NodeJS.Timeout>();
    const originalIconHrefRef = useRef<string>();

    function armTimer(timeToRangeBoundary: number) {
        if (timerRef.current !== undefined) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(updateWhiskeyTime, timeToRangeBoundary);
    }

    function updateWhiskeyTime() {
        const now = new Date();

        const timeToRangeBoundary = getTimeToRangeBoundary(whiskeyDateTimeRange, now);
        if (timeToRangeBoundary.inRange === true) {
            setFavicon('🥃', 52, 0, 48);
            armTimer(timeToRangeBoundary.endsIn);
        } else {
            setFaviconHref(originalIconHrefRef.current);
            armTimer(timeToRangeBoundary.startsIn);
        }
    }

    useEffect(() => {
        originalIconHrefRef.current = getFaviconHref();
        updateWhiskeyTime();
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = undefined;
            }
            setFaviconHref(originalIconHrefRef.current);
        };
    });

    return props.children;
};

function convertHoursToSeconds(hours: number): number {
    return hours * 60 * 60;
}

// returns number of milliseconds until the range boundary:
// positive until the range begins
// negative until the current range ends
function getTimeToRangeBoundary(
    dateTimeRange: { weekDay: number; startHour: number; endHour: number },
    dateTime: Date
):
    | {
          startsIn: number;
          inRange: false;
      }
    | { endsIn: number; inRange: true } {
    if (dateTimeRange.endHour > 24) {
        throw new Error('Crossing midnight is not allowed.');
    }
    if (dateTimeRange.startHour >= dateTimeRange.endHour) {
        throw new Error('End hour must be greater than start hour.');
    }

    const weekStart = startOfWeek(dateTime, { weekStartsOn: 0 });
    const startDateTime = add(weekStart, { days: dateTimeRange.weekDay, seconds: convertHoursToSeconds(dateTimeRange.startHour) });
    const endDateTime = add(weekStart, { days: dateTimeRange.weekDay, seconds: convertHoursToSeconds(dateTimeRange.endHour) });

    if (dateTime < startDateTime) {
        return { inRange: false, startsIn: differenceInMilliseconds(startDateTime, dateTime) };
    } else if (dateTime < endDateTime) {
        return { inRange: true, endsIn: differenceInMilliseconds(endDateTime, dateTime) };
    } else {
        const nextWeekStartDateTime = add(startDateTime, { weeks: 1 });
        return { inRange: false, startsIn: differenceInMilliseconds(nextWeekStartDateTime, dateTime) };
    }
}
