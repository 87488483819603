import React, { useEffect, useMemo, useState } from 'react';
import { QMatrixCellBase } from './QMatrixCellBase';
import { TextField, Tooltip } from '@mui/material';
import { QuestionnairesQuestionnaireQuestion as Question } from '@projectcanary/trustwell-server-client-ts';
import _ from 'lodash';
import InfoIcon from '@mui/icons-material/Info';
import { useQCell } from './QCell';

function getNumberOfDecimalPlaces(valueAsString: string) {
    return valueAsString.split('.')[1]?.length ?? 0;
}

function formatNumber(value: number | undefined, minRequiredDecimalPlaces: number | undefined) {
    const formatOptions = { minimumFractionDigits: minRequiredDecimalPlaces, maximumFractionDigits: 20, useGrouping: false };
    return value === undefined ? '' : Intl.NumberFormat(undefined, formatOptions).format(value);
}

export const QMatrixNumberCell = (props: { assetId: number; question: Question; enableCellAnnotation: boolean }) => {
    const { questionnaireQuestionId, answerConstraints } = props.question;
    const { value, isNA, isOmitted, setAnswer } = useQCell(props.assetId, questionnaireQuestionId);

    const [typingValue, setTypingValue] = useState<string>('');

    useEffect(() => {
        const newTypingValue = formatNumber(value, answerConstraints?.minRequiredDecimalPlaces);
        if (newTypingValue !== typingValue) {
            setTypingValue(newTypingValue);
        }
    }, [value]);

    const validationError: string | undefined = useMemo(() => {
        if (_.isEmpty(answerConstraints) || typingValue === '') {
            return undefined;
        }

        const valueAsNumber = parseFloat(typingValue);

        const { minValue, maxValue, minRequiredDecimalPlaces, maxAllowedDecimalPlaces } = answerConstraints;

        if (minValue !== undefined && valueAsNumber < minValue) {
            return `Value must be greater than ${minValue}.`;
        }

        if (maxValue !== undefined && valueAsNumber > maxValue) {
            return `Value must be less than ${maxValue}.`;
        }

        const numberOfDecimalPlaces = getNumberOfDecimalPlaces(typingValue);
        if (minRequiredDecimalPlaces !== undefined && numberOfDecimalPlaces < minRequiredDecimalPlaces) {
            // example is a repeating number 1.234...789123...789123
            const example = (123456789 / 99999999).toString().substring(0, 1 + (minRequiredDecimalPlaces && 1) + minRequiredDecimalPlaces);
            return `Value must have exactly ${minRequiredDecimalPlaces} digits of precision. (ex: ${example})`;
        }

        if (maxAllowedDecimalPlaces !== undefined && numberOfDecimalPlaces > maxAllowedDecimalPlaces) {
            return maxAllowedDecimalPlaces === 0
                ? `The value must be an integer number.`
                : `The number can have at most ${maxAllowedDecimalPlaces} decimal places.`;
        }
    }, [answerConstraints, typingValue]);

    const handleOnBlur = () => {
        const isValid = validationError === undefined;
        if (isValid) {
            const newValue = typingValue === '' ? undefined : parseFloat(typingValue);
            setAnswer(newValue);
            setTypingValue(formatNumber(newValue, answerConstraints?.minRequiredDecimalPlaces));
        }
    };

    return useMemo(
        () => (
            <QMatrixCellBase {...props}>
                <TextField
                    sx={{ border: validationError ? '1px solid red' : 'inherent' }}
                    disabled={isNA || isOmitted}
                    type={'number'}
                    variant={'standard'}
                    margin={'none'}
                    fullWidth={true}
                    value={typingValue}
                    onChange={({ target }) => {
                        setTypingValue(target.value);
                    }}
                    onBlur={handleOnBlur}
                    InputProps={{
                        endAdornment: validationError ? (
                            <Tooltip title={validationError}>
                                <InfoIcon color="error" />
                            </Tooltip>
                        ) : undefined,
                    }}
                />
            </QMatrixCellBase>
        ),
        [isNA, isOmitted, typingValue, validationError, setAnswer, answerConstraints]
    );
};
