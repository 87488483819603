import React from 'react';
import PropTypes from 'prop-types';
import QuestionTableRow from './QuestionsTableRow';
import TableBody from '@mui/material/TableBody';
import { sortQuestionsByBinValue } from './rubricHelperFunctions';
import { EXECUTION_RUBRIC } from '../config';

const QuestionsTableBody = ({
    assessmentId,
    rubricType,
    filteredQuestions,
    questionScores,
    documentReferences,
    assessedAssets,
    handleDocumentUpdate,
    handleCheck,
    documentNames,
}) => {
    // Sort current questions by bin number, then alphabetical order for questions
    const sortedQuestions = sortQuestionsByBinValue(filteredQuestions);
    const formatAssessedAssets = (assessedAssets, rubricType) => {
        if (rubricType === EXECUTION_RUBRIC) {
            return assessedAssets.map((asset) => asset.well);
        }
        return assessedAssets;
    };

    const formattedAssessedAssets = formatAssessedAssets(assessedAssets, rubricType);
    return (
        <TableBody>
            {sortedQuestions.map((question) => {
                const filteredQuestionScores =
                    rubricType === 'Plan' || rubricType === 'Policy'
                        ? questionScores.filter((q) => q.rubricQuestionId === question.rubricQuestionId && 'plan' in q)
                        : questionScores.filter((q) => q.rubricQuestionId === question.rubricQuestionId && 'execution' in q);
                return (
                    <QuestionTableRow
                        key={question.rubricQuestionId}
                        assessmentId={assessmentId}
                        question={question}
                        rubricType={rubricType}
                        questionScores={filteredQuestionScores}
                        documentReferences={documentReferences.filter((d) => d.rubricQuestionId === question.rubricQuestionId)}
                        assessedAssets={formattedAssessedAssets}
                        handleDocumentUpdate={handleDocumentUpdate}
                        handleCheck={handleCheck}
                        documentNames={documentNames}
                    />
                );
            })}
        </TableBody>
    );
};

QuestionsTableBody.propTypes = {
    assessmentId: PropTypes.number.isRequired,
    rubricType: PropTypes.string.isRequired,
    filteredQuestions: PropTypes.array.isRequired,
    questionScores: PropTypes.array.isRequired,
    documentReferences: PropTypes.array.isRequired,
    assessedAssets: PropTypes.object.isRequired,
    documentNames: PropTypes.array.isRequired,
};

export default QuestionsTableBody;
