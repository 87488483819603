import { Box, CircularProgress, IconButton as MuiIconButton } from '@mui/material';
import React from 'react';

export type IconButtonProps = Omit<Parameters<typeof MuiIconButton>[0], 'onClick'> & { onClick: () => Promise<any> };

export const IconButton = ({ onClick, children, disabled, ...originalIconButtonProps }: IconButtonProps) => {
    const [isBusy, setIsBusy] = React.useState<boolean>(false);

    return (
        <Box sx={{ cursor: isBusy ? 'wait !important' : undefined }}>
            <MuiIconButton
                disabled={isBusy || disabled}
                onClick={async () => {
                    setIsBusy(true);
                    try {
                        await onClick();
                    } finally {
                        setIsBusy(false);
                    }
                }}
                {...originalIconButtonProps}
            >
                {isBusy ? <CircularProgress size={24} /> : children}
            </MuiIconButton>
        </Box>
    );
};
