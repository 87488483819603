export const POLICY_RUBRIC = 'Policy';
export const PLAN_RUBRIC = 'Plan';
export const EXECUTION_RUBRIC = 'Execution';
export const DEFAULT_RUBRIC = 'Policy';

export enum AssessmentLevel {
    overall,
    policy,
    plan,
    execution,
}
