import { QuestionnairesAnswerConstraints, QuestionnairesAnswerFormat } from '@projectcanary/trustwell-server-client-ts';
import { TextField } from 'Controls/Fields/TextField';
import * as Yup from 'Utils/yup';
import { CheckboxField } from 'Controls/Fields/CheckboxField';
import { RadioButtonGroupField } from 'Controls/Fields/RadioButtonGroupField';
import _ from 'lodash';
import { Stack } from '@mui/material';
import { MultiCheckboxField } from 'Controls/Fields/MultiCheckboxField';
import React from 'react';
import BaseSchema from 'yup/lib/schema';

export function getQuestionFieldBuilders(question): { validationBuilder: () => BaseSchema; componentBuilder: (name) => JSX.Element; defaultValue: any } {
    switch (question.answerFormat) {
        case QuestionnairesAnswerFormat.String:
            const stringParams = question.answerConstraints as unknown as QuestionnairesAnswerConstraints['string'];
            return {
                componentBuilder(name) {
                    return <TextField label="none" name={name} />;
                }, //
                validationBuilder() {
                    let schema = Yup.string();
                    if (stringParams.minLength) {
                        schema = schema.min(stringParams.minLength);
                    }
                    if (stringParams.maxLength) {
                        schema = schema.max(stringParams.maxLength);
                    }
                    return schema;
                },
                defaultValue: undefined,
            };
        case QuestionnairesAnswerFormat.Flag:
            return {
                componentBuilder(name) {
                    return <CheckboxField label={''} name={name} />;
                }, //
                validationBuilder() {
                    return Yup.boolean();
                }, //
                defaultValue: false,
            };
        case QuestionnairesAnswerFormat.Choice:
            const choiceParams = question.answerConstraints as unknown as QuestionnairesAnswerConstraints['choice'];
            return {
                componentBuilder(name) {
                    return (
                        <RadioButtonGroupField
                            name={name}
                            row={true}
                            options={_.chain(choiceParams.options)
                                .keyBy((o) => o.label)
                                .mapValues((v) => v.value)
                                .value()}
                        />
                    );
                }, //
                validationBuilder() {
                    return Yup.mixed();
                }, //
                defaultValue: undefined,
            };
        case QuestionnairesAnswerFormat.Set:
            const setParams = question.answerConstraints as unknown as QuestionnairesAnswerConstraints['set'];
            return {
                componentBuilder(name) {
                    return (
                        <Stack direction={'row'}>
                            <MultiCheckboxField name={name} label={'label'} options={setParams.options} />
                        </Stack>
                    );
                }, //
                validationBuilder() {
                    let schema = Yup.array();
                    if (typeof setParams.minCount === 'number') {
                        schema = schema.min(setParams.minCount, 'Please select at least ${min} option[s].');
                    }
                    if (typeof setParams.maxCount === 'number') {
                        schema = schema.max(setParams.maxCount, 'You can select at most ${max} option[s].');
                    }
                    return schema;
                }, //
                defaultValue: undefined,
            };
        default:
            throw new Error(`Unexpected response type: '${question.answerFormat}'.`);
    }
}
