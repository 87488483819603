import { Dispatch, SetStateAction, useState } from 'react';

type WithLoaderFunction<TArgs> = (cb: (...params: TArgs[]) => (Promise<void> | void)) => (...params: TArgs[]) => void;

/**
 * A hook to wrap async operation to add loading flag.
 *
 * @example:
 *      const [isLoading, withLoader] = useLoader();
 *      ...
 *      useEffect(withLoader(loadMyData));
 *      ...
 *      <DataGrid loading={isLoading}...>
 */
export const useLoader = <TArgs>(): [isLoading: boolean, withLoader: WithLoaderFunction<TArgs>, setLoading: Dispatch<SetStateAction<boolean>>] => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // set/clear loading flag around given async callback function.
    const withLoader: WithLoaderFunction<TArgs> = (cb) => (...params: TArgs[]): void => {
        (async () => {
            setIsLoading(true);
            try {
                await cb.apply(this, params);
            } finally {
                setIsLoading(false);
            }
        })();
    };

    return [isLoading, withLoader, setIsLoading];
};
