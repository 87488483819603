import React, { BaseSyntheticEvent, useMemo } from 'react';
import { IconButton, InputAdornment, TextField as MuiTextField, Tooltip } from '@mui/material';
import { useField } from 'formik';
import { buildTestId } from 'Utils/testid';
import { useMessageBox } from 'Controls/MessageBox';
import _ from 'lodash';
import { withDisabledMessage } from 'Utils/withDisabledMessage';

export type TextFieldButton = { icon: JSX.Element; onClick: () => void; title: string };

export type TextFieldProps = {
    disableAutoComplete?: boolean;
    name: string;
    label: string;
    isTextArea?: boolean;
    disabled?: boolean;
    hidden?: boolean;
    disablePaste?: boolean;
    variant?: 'outlined' | 'standard' | 'filled';
    type?: string;
    readonly?: boolean;
    buttons?: TextFieldButton[] | TextFieldButton;
    isRequired?: boolean;
};

export const TextField = withDisabledMessage((props: TextFieldProps) => {
    const [field, meta, helpers] = useField(props.name);
    const testId = buildTestId({ textfield: props.name });
    const messageBox = useMessageBox();

    const onPasteHandler = useMemo(() => {
        if (props.disablePaste) {
            return (e: BaseSyntheticEvent) => {
                e.preventDefault();
                messageBox.error(`Copy-pasting is disabled for this control, please type in the value.`);
                return false;
            };
        } else {
            return undefined;
        }
    }, [props.disablePaste, messageBox]);

    const adornmentButtons = useMemo(
        () =>
            props.buttons && (
                <InputAdornment position={'end'}>
                    {_.map(_.castArray(props.buttons), (b) => (
                        <Tooltip title={b.title} key={b.title}>
                            <IconButton
                                size={'small'}
                                onClick={() => {
                                    b.onClick();
                                }}
                            >
                                {b.icon}
                            </IconButton>
                        </Tooltip>
                    ))}
                </InputAdornment>
            ),
        [props.buttons]
    );

    return (
        <MuiTextField
            required={props.isRequired}
            sx={{ visibility: props.hidden ? 'hidden' : undefined }}
            variant={props.variant ?? 'standard'}
            margin={'normal'}
            data-testid={testId}
            fullWidth={true}
            minRows={props.isTextArea ? 3 : 0}
            name={props.name}
            label={props.label}
            value={field.value ?? ''}
            type={props.type ?? 'text'}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            onChange={(e) => helpers.setValue(e.target.value !== '' ? e.target.value : undefined)}
            onBlur={() => helpers.setTouched(true)}
            multiline={props.isTextArea}
            disabled={props.disabled}
            onPaste={onPasteHandler}
            onDrop={onPasteHandler}
            InputProps={{ endAdornment: adornmentButtons, readOnly: props.readonly }}
            autoComplete={props.disableAutoComplete ? 'off' : undefined}
        />
    );
});
