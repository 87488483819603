import React, { useEffect, useState } from 'react';
import {
    AssessmentsAssessment,
    DeductionsAmortizationStatus,
    DeductionsAmortizationStatus as AmortizationStatus,
    DeductionsDeductionAmortizationDetails,
    DeductionsDeductionDefinition,
    DeductionsDeductionDetails as DeductionDetails,
} from '@projectcanary/trustwell-server-client-ts';
import PageTitle from 'Controls/PageTitle';
import { deductionApi } from 'Services/TrustwellApiService';
import { Button } from 'Controls/Button';
import { useLoader } from 'Utils/loader';
import { useNavigation } from 'Utils/Navigation';
import { Stack } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useModal } from 'Controls/Modal';
import { useMessageBox } from 'Controls/MessageBox';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro';
import { Backdrop } from 'Controls/Backdrop';
import { formatDateOnly, personNameColumn, utcDateFormatter } from 'Utils/formats';
import { nowait } from 'Utils/nowait';
import { buildTestId } from 'Utils/testid';
import {
    CheckCircleOutline,
    HourglassEmptyOutlined,
    NotInterestedOutlined,
    ThumbDownOutlined,
    ThumbsUpDownOutlined,
    ThumbUpOutlined,
} from '@mui/icons-material';
import _ from 'lodash';
import { AmortizeDeductionModal } from './AmortizeDeductionModal';
import { LinearProgress } from 'Controls/LinearProgress';
import { DeductionDetailsPanel } from 'Components/Deductions/DeductionDetailsPanel';
import { GridActionsCellItem } from 'Controls/GridActionsCellItem';

type DeductionProps = {
    assessment: AssessmentsAssessment;
};

export type DeductionRow = DeductionDetails & { amortizationDetails: DeductionsDeductionAmortizationDetails } & {
    deductionDefinition: DeductionsDeductionDefinition;
};

const amortizationStatusIcons = {
    [AmortizationStatus.Amortized]: {
        icon: <ThumbUpOutlined />,
        tooltip: 'Amortization has been applied for the rating period.',
    },
    [AmortizationStatus.Available]: { icon: <ThumbsUpDownOutlined />, tooltip: 'Amortization is available.' },
    [AmortizationStatus.Unavailable]: {
        icon: <ThumbsUpDownOutlined color={'disabled'} />,
        tooltip: 'Amortization is not yet available.',
    },
    [AmortizationStatus.FullyAmortized]: {
        icon: <CheckCircleOutline color={'success'} />,
        tooltip: 'The deduction has been fully amortized.',
    },
    [AmortizationStatus.Rejected]: {
        icon: <ThumbDownOutlined />,
        tooltip: 'Amortization has been rejected for the current rating period.',
    },
    [AmortizationStatus.Postponed]: {
        icon: <HourglassEmptyOutlined />,
        tooltip: 'Amortization decision has been postponed.',
    },
};

const DeductionsPage = ({ assessment }: DeductionProps) => {
    const [isLoading, withLoader] = useLoader();
    const modal = useModal();
    const messageBox = useMessageBox();
    const { site, navigateTo } = useNavigation();

    const [deductions, setDeductions] = useState<DeductionRow[]>();
    const [deductionDefinitions, setDeductionDefinitions] = useState<DeductionsDeductionDefinition[]>();
    const [pageError, setPageError] = useState<boolean>(false);

    async function showAmortizeDeductionModal(deduction: DeductionRow) {
        const latestDecision = _.last(deduction.amortizationDetails.history).decision;

        modal
            .form(
                {
                    title: `Amortize ${deduction.category} Deduction for well ${deduction.wellRegistrationId}`,
                    buttons: ['Save', 'Cancel'],
                },
                <AmortizeDeductionModal deduction={deduction} decision={latestDecision} />
            )
            .onSubmit(async ({ decision }) => {
                const api = await deductionApi();
                try {
                    await api.amortizeDeduction(deduction.id, decision);
                    nowait(fetchPageData(assessment));
                } catch (error) {
                    messageBox.error('Failed to update amortization status. ' + error);
                }
            });
    }

    async function handleAmortizeClick(deduction: DeductionRow) {
        switch (deduction.amortizationDetails?.status) {
            case DeductionsAmortizationStatus.Available:
            case DeductionsAmortizationStatus.Amortized:
            case DeductionsAmortizationStatus.Rejected:
            case DeductionsAmortizationStatus.Postponed:
                await withLoader(showAmortizeDeductionModal)(deduction);
                break;
            case DeductionsAmortizationStatus.Unavailable:
                modal.show({ title: 'Amortization is unavailable', buttons: ['OK'] }, 'This deduction can not yet be amortized.');
                break;
            case undefined:
                modal.show({ title: 'Amortization is non-amortizable', buttons: ['OK'] }, 'This deduction is non-amortizable.');
                break;
            case DeductionsAmortizationStatus.FullyAmortized:
                modal.show({ title: 'Amortization is fully amortized', buttons: ['OK'] }, 'This deduction has already been fully amortized.');
                break;
        }
    }

    const AmortizationProgress = ({ amortized, total }: { amortized: number; total: number }) => {
        const displayFraction = 12;
        return (
            <LinearProgress
                sx={{ width: '100%' }}
                value={(amortized / total) * 100}
                label={String(Math.floor(amortized * displayFraction)) + ' / ' + total * displayFraction}
                tickCount={total - 1}
            />
        );
    };

    const columns: GridColumns<DeductionRow> = [
        {
            headerName: 'Well Id',
            field: 'wellRegistrationId',
            minWidth: 150,
            type: 'string',
        },
        {
            headerName: 'Well Name',
            field: 'wellName',
            minWidth: 150,
            type: 'string',
        },
        {
            headerName: 'Well Group',
            field: 'wellGroupName',
            minWidth: 250,
            type: 'string',
        },
        {
            headerName: 'Category',
            field: 'category',
            minWidth: 150,
            type: 'string',
        },
        {
            headerName: 'Level of Severity',
            field: 'severityLevel',
            minWidth: 150,
            type: 'string',
        },
        {
            headerName: 'Deduction Amount (%)',
            field: 'proportionalPointReduction',
            minWidth: 200,
            type: 'number',
            valueGetter: (params) => params.row.deductionDefinition.proportionalPointReduction,
        },
        {
            headerName: 'Amortization (Years)',
            field: 'amortization',
            minWidth: 200,
            type: 'number',
            valueGetter: (params) => params.row.deductionDefinition.amortizationPeriod,
            renderCell: ({ value }) => value ?? 'Not Amortizable',
        },
        {
            headerName: 'Incident Date',
            field: 'incidentDate',
            minWidth: 150,
            type: 'date', //
            valueFormatter: ({ value }) => {
                return formatDateOnly(value);
            },
        },
        {
            headerName: 'Deduction Creation Date',
            field: 'creationDateTime',
            minWidth: 200,
            type: 'date',
            valueFormatter: utcDateFormatter,
        },
        {
            headerName: 'Created By',
            field: 'creator',
            minWidth: 150,
            ...personNameColumn,
        },
        {
            headerName: 'Amortization Progress (months)',
            field: 'amortizationProgress',
            type: 'string',
            width: 300,
            valueGetter: (params) => ({
                amortized: params.row.amortizationDetails?.amortizationProgress,
                total: params.row.deductionDefinition.amortizationPeriod,
            }),
            renderCell: (params) =>
                params.row.amortizationDetails ? <AmortizationProgress amortized={params.value.amortized} total={params.value.total} /> : '',
        },
        {
            headerName: 'Actions',
            field: 'actions',
            type: 'actions',
            width: 200,
            cellClassName: 'actions',
            getActions: ({ row: deduction }) => {
                const amortization = deduction.amortizationDetails
                    ? amortizationStatusIcons[deduction.amortizationDetails.status]
                    : {
                          icon: <NotInterestedOutlined color={'disabled'} />,
                          tooltip: 'The deduction is non-amortizable.',
                      };

                const disabled = deduction.isLocked ? 'This deduction is now locked because a deduction amortization decision has already been made.' : false;

                return [
                    <GridActionsCellItem
                        showInMenu={false}
                        icon={<Tooltip title={amortization.tooltip}>{amortization.icon}</Tooltip>}
                        onClick={() => handleAmortizeClick(deduction)}
                        label={'Amortization'}
                    />,
                    <GridActionsCellItem
                        showInMenu={false}
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => handleDeleteClick(deduction.id)}
                        disabled={disabled}
                    />,
                    <GridActionsCellItem
                        showInMenu={false}
                        icon={<EditIcon />}
                        label="Edit"
                        disabled={disabled}
                        onClick={() => navigateTo(site.assessment(assessment.id).deductions.edit(deduction.id))}
                    />,
                ];
            },
        },
    ];

    const fetchPageData = async (assessment: AssessmentsAssessment) => {
        try {
            const api = await deductionApi();
            const [_deductions, _amortizationDetails, _deductionDefinitions] = await Promise.all([
                api.getAllDeductionsByAssessment(assessment.id),
                api.getDeductionsAmortizationDetails(assessment.id),
                api.getDeductionDefinitionsByVersion(assessment.versionId),
            ]);

            const amortizationDetailsMap = _.keyBy(_amortizationDetails.deductionsAmortizationDetails, (ad) => ad.deductionId);
            const deductionDefinitionsMap = _.keyBy(_deductionDefinitions, (dd) => dd.id);

            const deductionRows = _.map(
                _deductions,
                (d) =>
                    ({
                        ...d, //
                        amortizationDetails: amortizationDetailsMap[d.id], //
                        deductionDefinition: deductionDefinitionsMap[d.deductionDefinitionId],
                    } as DeductionRow)
            );
            setDeductions(deductionRows);
            setDeductionDefinitions(_deductionDefinitions);
        } catch (error) {
            messageBox.error('Failed to fetch deductions from the server. ' + error);
            setPageError(true);
        }
    };

    useEffect(() => {
        withLoader(fetchPageData)(assessment);
    }, [assessment]);

    const handleDeleteClick = async (deductionId: number) => {
        modal
            .show(
                {
                    title: 'Delete deduction',
                    style: 'critical',
                    buttons: [
                        { title: 'Delete deduction', icon: 'Delete', isAccept: true },
                        { title: 'Cancel', isDefault: true },
                    ],
                },
                'This deduction will be permanently deleted from this asset. This is a permanent action that cannot be undone.'
            )
            .onAccept(async () => {
                try {
                    const api = await deductionApi();
                    await api.deleteDeduction(deductionId);
                    messageBox.success('Successfully deleted the deduction.');
                } catch (error) {
                    messageBox.error('Failed to delete the deduction. ' + error);
                }

                await fetchPageData(assessment);
            });
    };

    const versionHasDeductions = deductionDefinitions?.length > 0;

    const testId = buildTestId({ page: 'deductions' });

    return (
        <>
            <PageTitle title={'Deductions'} />
            <Backdrop isLoading={isLoading} />
            {pageError ? (
                <div>There was an issue on the server - you won't be able to view/add deductions until this is fixed.</div>
            ) : versionHasDeductions ? (
                <Stack spacing={2}>
                    <Stack direction={'row'} spacing={2}>
                        <Button href={site.assessment(assessment.id).deductions.add} variant="primary">
                            Add Deduction
                        </Button>
                    </Stack>
                    <DataGridPro<DeductionRow>
                        columns={columns}
                        rows={deductions}
                        loading={isLoading}
                        data-testid={buildTestId(testId, { table: 'deductions' })}
                        getRowId={(row) => row.id}
                        initialState={{ sorting: { sortModel: [{ field: 'creationDateTime', sort: 'desc' }] } }}
                        getDetailPanelContent={({ row: deduction }) => <DeductionDetailsPanel deduction={deduction} />}
                        getDetailPanelHeight={() => 'auto'}
                    />
                </Stack>
            ) : (
                <div>
                    Deductions cannot be made to this assessment because it was created with an assessment definition that did not include a deductions section.
                </div>
            )}
        </>
    );
};

export default DeductionsPage;
