import React, { useMemo } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { DeductionRow } from 'Pages/DeductionsPage';
import { columns, Row } from './DeductionDetailsPanelColumns';

export const DeductionDetailsPanel = ({ deduction }: { deduction: DeductionRow }) => {
    const rows = useMemo(() => {
        if (deduction.amortizationDetails) {
            return deduction.amortizationDetails.history.map((record, index) => ({
                ...record,
                id: index,
                totalAmortizedPoints: record.totalAmortizationCount * deduction.amortizationDetails.pointsPerAmortization,
                pointsAmortized: record.periodAmortizationCount * deduction.amortizationDetails.pointsPerAmortization,
            }));
        }
    }, [deduction.amortizationDetails?.pointsPerAmortization, deduction.amortizationDetails]);

    return (
        <Stack sx={{ pl: 8, pb: 4 }}>
            <Grid container spacing={2} sx={{ pt: 2, pb: 2 }}>
                <Grid item xs={1}>
                    <Typography variant={'bold'}>Description</Typography>
                </Grid>
                <Grid item xs={11}>
                    <Typography>{deduction.deductionDefinition.description}</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant={'bold'}>Engineer Notes</Typography>
                </Grid>
                <Grid item xs={11}>
                    <Typography>{deduction.notes}</Typography>
                </Grid>
            </Grid>
            {deduction.amortizationDetails && (
                <DataGridPro<Row>
                    columns={columns}
                    rows={rows}
                    components={{
                        Toolbar: null,
                    }}
                    pagination={false}
                    hideFooter={true}
                />
            )}
        </Stack>
    );
};
