import React from 'react';
import { Table, TableBody } from '@mui/material';
import ScoringSliderAndCheckbox from './ScoringSliderAndCheckbox';
import { LocalFactorsLocalFactor } from '@projectcanary/trustwell-server-client-ts';
import { LocalFactorScope } from './LocalFactorConfig';

type ScoringRowProps = {
    scope: LocalFactorScope;
    scopedLocalFactors: LocalFactorsLocalFactor[];
    currentScopedScores: object;
    assessmentLevelDefaultScores?: any;
    isMarkedComplete?: boolean;
    handleCommitScore: (e: any, value: any, lf: any, type: string) => void;
};

const ScoringRow = ({ scope, scopedLocalFactors, currentScopedScores, assessmentLevelDefaultScores, isMarkedComplete, handleCommitScore }: ScoringRowProps) => {
    return (
        <section>
            <Table>
                <TableBody>
                    {scopedLocalFactors.map((factor: LocalFactorsLocalFactor) => {
                        return (
                            <ScoringSliderAndCheckbox
                                key={`${scope}-${factor.localFactorId}`}
                                scope={scope}
                                localFactor={factor}
                                sliderScore={currentScopedScores[factor.localFactorId]}
                                defaultScore={assessmentLevelDefaultScores ? assessmentLevelDefaultScores[factor.localFactorId] : null}
                                isMarkedComplete={isMarkedComplete}
                                handleCommitScore={handleCommitScore}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </section>
    );
};

export default ScoringRow;
