function getFilename(response) {
    const filenameMatch = response.headers.get('content-disposition').match(/filename="(.*?)"/i);
    return filenameMatch?.[1];
}

export const downloadFile = async (response, defaultFilename) => {
    const url = window.URL.createObjectURL(await response.blob());
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = getFilename(response) ?? defaultFilename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
};
