import React, { useMemo } from 'react';
import { CellBaseProps, QMatrixCellBase } from './QMatrixCellBase';
import { TextField } from '@mui/material';

export const QMatrixConstantCell = (props: CellBaseProps) => {
    const responseTypeDescriptor = props.question.answerConstraints;
    const value: string = responseTypeDescriptor?.label ?? responseTypeDescriptor?.value?.toString() ?? '';
    return useMemo(
        () => (
            <QMatrixCellBase {...props}>
                <TextField value={value} fullWidth={true} margin={'none'} variant={'standard'} type={'text'} InputProps={{ readOnly: true }} />
            </QMatrixCellBase>
        ),
        [value]
    );
};
