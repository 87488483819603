import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Backdrop, Box, CircularProgress, LinearProgress, Stack, Typography } from '@mui/material';
import PageHeader from 'Controls/PageTitle';
import _ from 'lodash';
import { deductionApi, assetApi, WellGroup } from 'Services/TrustwellApiService';
import { DatePickerField } from 'Controls/Fields/DatePickerField';
import * as Yup from 'Utils/yup';
import {
    AssessmentsAssessment,
    AssessmentsWellGroup,
    DeductionsCreateDeductionRequest,
    DeductionsDeductionDefinition,
    DeductionsUpdateDeduction,
} from '@projectcanary/trustwell-server-client-ts';
import { useMessageBox } from 'Controls/MessageBox';
import { useNavigation } from 'Utils/Navigation';
import { DropdownField, TextWithDescriptionMenuItem } from 'Controls/Fields/DropdownField';
import { Button } from 'Controls/Button';
import { useLoader } from 'Utils/loader';
import { useParams } from 'react-router';
import { TextAreaField } from 'Controls/Fields/TextAreaField';
import { AutocompleteField } from 'Controls/Fields/AutocompleteField';

const validationSchema = Yup.object().shape({
    category: Yup.mixed().required('Required'),
    severity: Yup.mixed().required('Required'),
    wellGroup: Yup.mixed().required('Required'),
    well: Yup.mixed().required('Required'),
    incidentDate: Yup.date().required('Required'),
    notes: Yup.string().min(5, 'Please add more detail').max(300, 'Please do not exceed 300 characters.').required('Deduction notes are required'),
});

type AddDeductionFormProps = {
    assessment: AssessmentsAssessment;
    isEditing?: boolean;
};

type Category = {
    id: number;
    versionId: number;
    deductionCategory: string;
    deductionSeverity: string;
    absolutePointReduction: number;
    amortizationPeriod: undefined;
    description: string;
    performanceScoreLimit: undefined;
    proportionalPointReduction: number | undefined;
};

type Well = { id: number; name: string };

type FormValueProps = {
    category?: Category;
    severity?: Category;
    wellGroup?: { id: number; name: string };
    well?: Well;
    incidentDate?: Date;
    notes: string;
};

const AddDeductionForm = ({ assessment, isEditing }: AddDeductionFormProps) => {
    const [isLoading, withLoader] = useLoader();
    const messageBox = useMessageBox();
    const { navigateTo, site } = useNavigation();
    const [categories, setCategories] = useState<DeductionsDeductionDefinition[]>(undefined);
    const [severity, setSeverity] = useState<{ [key: string]: DeductionsDeductionDefinition[] }>(undefined);
    const [wellGroups, setWellGroups] = useState<AssessmentsWellGroup[]>(undefined);
    const [initialValues, setInitialValues] = useState<FormValueProps>({
        category: undefined,
        severity: undefined,
        wellGroup: undefined,
        well: undefined,
        incidentDate: undefined,
        notes: '',
    });

    const deductionId = Number(useParams().deductionId);

    const getDefinitions = async () => {
        try {
            const api = await deductionApi();
            const _assetApi = await assetApi();
            const definitions = await api.getDeductionDefinitionsByVersion(assessment.versionId);

            const _wellGroups: WellGroup[] = Object.values(await _assetApi.getAssessmentWellGroups(assessment.id));
            setWellGroups(_.sortBy(_wellGroups, 'name'));

            const _categories = _.uniqBy(definitions, 'deductionCategory');
            setCategories(_categories);

            const _severity = _.groupBy(definitions, 'deductionCategory');
            setSeverity(_severity);

            if (isEditing) {
                const editingDeduction = await api.getDeductionById(Number(deductionId));
                if (editingDeduction) {
                    const categoryAndSeverity = {
                        id: editingDeduction.deductionDefinitionId,
                        versionId: undefined,
                        deductionCategory: editingDeduction['category'],
                        deductionSeverity: editingDeduction['severityLevel'],
                        absolutePointReduction: undefined,
                        amortizationPeriod: undefined,
                        description: editingDeduction['severityDescription'],
                        performanceScoreLimit: undefined,
                        proportionalPointReduction: undefined,
                    };
                    setInitialValues({
                        category: categoryAndSeverity,
                        severity: categoryAndSeverity,
                        incidentDate: editingDeduction['incidentDate'],
                        notes: editingDeduction['notes'],
                        wellGroup: { id: undefined, name: editingDeduction['wellGroupName'] },
                        well: { id: undefined, name: editingDeduction['wellRegistrationId'] },
                    });
                }
            }
        } catch (error) {
            messageBox.error('Failed to load the deduction definitions. ' + error);
        }
    };

    useEffect(withLoader(getDefinitions), [assessment.id]);

    return (
        <Box sx={{ pb: 5 }}>
            <PageHeader title={isEditing ? 'Edit Deduction' : 'Add Deduction'} />

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={async (values) => {
                    if (isEditing) {
                        try {
                            const updatedDeduction: DeductionsUpdateDeduction = {
                                id: Number(deductionId),
                                incidentDate: values.incidentDate,
                                notes: values.notes,
                                deductionDefinitionId: values.severity['id'],
                            };
                            const api = await deductionApi();
                            await api.updateDeduction(updatedDeduction);

                            navigateTo(site.assessment(assessment.id).deductions);
                        } catch (error) {
                            messageBox.error(`Failed to update deduction. ${error}`);
                        }
                    } else {
                        try {
                            const newDeduction: DeductionsCreateDeductionRequest = {
                                deductionDefinitionId: values.severity['id'],
                                assessmentId: Number(assessment.id),
                                wellId: values.well['id'],
                                notes: values.notes,
                                incidentDate: values.incidentDate,
                            };
                            const api = await deductionApi();
                            await api.createDeduction(newDeduction);

                            navigateTo(site.assessment(assessment.id).deductions);
                        } catch (error) {
                            messageBox.error(`Failed to create deduction. ${error}`);
                        }
                    }
                }}
            >
                {({ values, submitForm, resetForm, isSubmitting }) => (
                    <Form>
                        <Stack width={500} ml={1} spacing={{ sx: 10 }}>
                            <DropdownField
                                name={'category'}
                                label={'Category'}
                                options={categories}
                                keySelector={'deductionCategory'}
                                valueRenderer={'deductionCategory'}
                                includeEmptyOption={false}
                                disabled={isEditing}
                            />

                            <DropdownField
                                name={'severity'}
                                label={'Severity'}
                                options={values.category && severity[values.category['deductionCategory']]}
                                keySelector={'deductionSeverity'}
                                valueRenderer={'deductionSeverity'}
                                menuItemRenderer={(option) => <TextWithDescriptionMenuItem title={option.deductionSeverity} description={option.description} />}
                                disabled={!values.category || isEditing}
                                includeEmptyOption={false}
                            />

                            <DropdownField
                                name={'wellGroup'}
                                label={'WellGroup'}
                                options={wellGroups}
                                keySelector={'name'}
                                valueRenderer={'name'}
                                disabled={!values.category || !values.severity || isEditing}
                                includeEmptyOption={false}
                            />
                            <AutocompleteField<Well>
                                disabled={!values.wellGroup || isEditing}
                                name="well"
                                label="Well"
                                labelSelector={(well: Well) => well.name}
                                options={
                                    isEditing
                                        ? [initialValues.well]
                                        : values.wellGroup &&
                                          wellGroups.find((w) => w.id === values.wellGroup.id)['wells'].map((well) => ({ id: well['id'], name: well['name'] }))
                                }
                            />
                            <Box mt={2}>
                                <DatePickerField label="Incident Date" name="incidentDate" disabled={isEditing} />
                            </Box>
                            <Box mt={2} mb={2}>
                                <TextAreaField name="notes" label="Notes" />
                                <Typography variant="body2">{values.notes?.length ?? 0} / 300 characters</Typography>
                            </Box>
                            {isSubmitting && <LinearProgress />}
                            <Stack direction="row" spacing={2}>
                                <Button variant="primary" disabled={isSubmitting} onClick={submitForm}>
                                    {isEditing ? 'Update Deduction' : 'Create Deduction'}
                                </Button>
                                <Button
                                    variant="secondary"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        resetForm();
                                    }}
                                >
                                    Reset
                                </Button>
                                <Button variant="secondary" href={site.assessment(assessment.id).deductions.toString()}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default AddDeductionForm;
