import { DataGridProProps, GridColDef, GridColumns, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { WellCertStatus } from './WellCertStatus';
import { Typography } from '@mui/material';
import { tableClassNames } from './IssuedRatingsTableStyles';
import clsx from 'clsx';
import { decimalPlacesFormatter, emptyPlaceholder, formatPersonName, utcDateFormatter, vintageFormatter } from 'Utils/formats';
import { CertificationCertificationStatus as CertStatus, CertificationWellCertification as WellCertification } from '@projectcanary/trustwell-server-client-ts';
import _ from 'lodash';

type RowBase = { hierarchy: string[] };
export type WellGroupRow = RowBase & Pick<WellCertification, 'timing' | 'status'>;
export type WellRow = RowBase & WellCertification;
export type Row = WellGroupRow | WellRow;

// how far in advance to start showing "expires in # days" status
const expiresSoonThreshold = 90;

const wellNameColumnDimensions = {
    minWidth: 320,
    maxWidth: 450,
    flex: 1,
};

const wellGroupColumnDimensions = wellNameColumnDimensions;

const dateColumnDimensions = {
    width: 200,
};

const issuedByColumnDimensions = {
    width: 200,
};

const statusColumnDimensions = {
    width: 200,
};

const medalColumnDimensions = {
    width: 200,
};

const numberColumnDimensions = {
    width: 200,
};

const versionColumnDimensions = {
    width: 200,
};

export const groupingColumns: DataGridProProps['groupingColDef'] = {
    headerName: 'Well Group / Well Id',
    hideDescendantCount: true,
    ...wellGroupColumnDimensions,
    sortable: true,
    cellClassName: ({ rowNode }) =>
        clsx({
            [tableClassNames.wellGroupCell]: rowNode.children !== undefined,
        }),
    disableColumnMenu: false,
    filterable: true,
};

function groupRowCellRendererBuilder(rollup: (params: GridRenderCellParams) => JSX.Element | string) {
    return (params: GridRenderCellParams) => {
        if (!Array.isArray(params.rowNode.children)) {
            return params.formattedValue ?? '';
        } else {
            return rollup(params);
        }
    };
}

function pluralize(count: number, objectName: string, objectNamePlural?: string) {
    return count === 1 ? objectName : objectNamePlural ?? objectName + 's';
}

const childrenCountCellRenderer = (objectName: string, objectNamePlural?: string) =>
    groupRowCellRendererBuilder(({ rowNode }) => {
        const childrenCount = rowNode.children!.length;
        return (
            <Typography variant={'hint'}>
                ({childrenCount} {pluralize(childrenCount, objectName, objectNamePlural)})
            </Typography>
        );
    });

// cell renderer that shows first child row value followed by (+# more) hint text.
const onePlusMoreCellRenderer = groupRowCellRendererBuilder(({ field, rowNode, id, api, colDef, value, formattedValue }) => {
    const childrenRowIds = rowNode.children;
    const valueFormatter = (value) =>
        colDef.valueFormatter
            ? colDef.valueFormatter({
                  id,
                  api,
                  field,
                  value,
              })
            : value;
    const uniqueChildrenValues = _.chain(childrenRowIds)
        .map((id) => api.getCellValue(id, field))
        .map((value) => valueFormatter(value))
        .filter(Boolean)
        .uniq()
        .value();
    return <PlusMoreHint firstValue={formattedValue ?? value ?? ''} totalCount={uniqueChildrenValues.length} />;
});

const PlusMoreHint: (params: { firstValue: string; totalCount: number }) => JSX.Element = ({ firstValue, totalCount }) => {
    return (
        <>
            {firstValue}
            {totalCount > 1 && (
                <Typography variant={'hint'} sx={{ ml: 1 }}>
                    (+{totalCount - 1} more){' '}
                </Typography>
            )}
        </>
    );
};

function getWellCertStatus(status: CertStatus, timeTillExpiration?: number): WellCertStatus {
    switch (status) {
        case CertStatus.Expired:
            return WellCertStatus.Expired;
        case CertStatus.Upcoming:
            return WellCertStatus.Upcoming;
        case CertStatus.Active:
            if (timeTillExpiration !== undefined && timeTillExpiration < expiresSoonThreshold) {
                return WellCertStatus.ExpiresSoon;
            } else {
                return WellCertStatus.Active;
            }
        case CertStatus.NeverCertified:
            return WellCertStatus.NeverCertified;
        default:
            throw new Error(`Unexpected status '${status}'.`);
    }
}

function sortBy<V>(selector: (V) => number): (v1: V, v2: V) => number {
    return (v1: V, v2: V) => selector(v1) - selector(v2);
}

function formatCertStatus({ value: { status, timeTillExpiration } }) {
    switch (status) {
        case WellCertStatus.Expired:
            return 'Expired';
        case WellCertStatus.Upcoming:
            return 'Upcoming';
        case WellCertStatus.Active:
            return 'Active';
        case WellCertStatus.ExpiresSoon:
            return `Expires in ${timeTillExpiration} ${timeTillExpiration === 1 ? 'day' : 'days'}`;
        case WellCertStatus.NeverCertified:
            return 'Never issued';
    }
}

function rollUpValueGetter<R, V>(groupRowValueGetterOrSortOrder: ((childRowValues: V[]) => V) | ('asc' | 'desc'), childRowValueGetter?: (row: R) => V) {
    return ({ row, rowNode, api, field }: GridValueGetterParams<V, R>) => {
        if (rowNode.children) {
            const childRowValues: V[] = _.chain(rowNode.children)
                .map((id) => api.getCellValue(id, field) as V)
                .filter(Boolean)
                .value();
            if (_.isString(groupRowValueGetterOrSortOrder)) {
                return _.orderBy(childRowValues, _.identity, groupRowValueGetterOrSortOrder)[0];
            } else {
                return groupRowValueGetterOrSortOrder(childRowValues);
            }
        } else {
            if (childRowValueGetter === undefined) {
                return row[field];
            } else {
                return childRowValueGetter(row);
            }
        }
    };
}

export const columns: GridColumns<Row> = [
    {
        field: 'wellName',
        headerName: 'Well Name',
        type: 'string',
        renderCell: childrenCountCellRenderer('well'),
        ...wellNameColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
    {
        field: 'publicationDateTime',
        headerName: 'Issued Date',
        type: 'date',
        valueFormatter: utcDateFormatter,
        valueGetter: rollUpValueGetter('asc'),
        renderCell: onePlusMoreCellRenderer,
        ...dateColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
    {
        field: 'status',
        headerName: 'Status',
        type: 'string',
        ...statusColumnDimensions,
        valueGetter: rollUpValueGetter(
            (childRowValues) => _.orderBy(childRowValues, (v) => v.timeTillExpiration, 'asc')[0],
            (row) => ({
                status: getWellCertStatus(row.status, row.timing?.timeTillExpiration),
                timeTillExpiration: row.timing?.timeTillExpiration,
            })
        ),
        valueFormatter: formatCertStatus,
        renderCell: onePlusMoreCellRenderer,
        cellClassName: ({ value }) => {
            return tableClassNames.wellCertStatus + ' ' + tableClassNames.certStatus(value.status);
        },
        sortComparator: sortBy((v) => v.timeTillExpiration ?? Number.MAX_SAFE_INTEGER),
    } as GridColDef<Row, { status: WellCertStatus; timeTillExpiration?: number; childCount?: number }, string>,
    {
        field: 'certificateStartDate',
        headerName: 'Rating Start',
        type: 'date',
        ...dateColumnDimensions,
        valueFormatter: utcDateFormatter,
        cellClassName: emptyPlaceholder,
        valueGetter: rollUpValueGetter('asc'),
        renderCell: onePlusMoreCellRenderer,
    },
    {
        field: 'certificateEndDate',
        headerName: 'Rating End',
        type: 'date',
        valueFormatter: utcDateFormatter,
        valueGetter: rollUpValueGetter('desc'),
        renderCell: onePlusMoreCellRenderer,
        ...dateColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
    {
        field: 'ratingMedal',
        headerName: 'Rating Medal',
        type: 'string',
        valueGetter: rollUpValueGetter('desc'),
        renderCell: onePlusMoreCellRenderer,
        ...medalColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
    {
        field: 'performanceScore',
        headerName: 'Performance Score',
        type: 'number',
        valueGetter: rollUpValueGetter('desc'),
        renderCell: onePlusMoreCellRenderer,
        ...numberColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
    {
        field: 'methaneIntensity',
        headerName: 'Basin MI',
        type: 'number',
        valueFormatter: decimalPlacesFormatter(4),
        valueGetter: rollUpValueGetter('desc'),
        renderCell: onePlusMoreCellRenderer,
        ...numberColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
    {
        field: 'methaneIntensityVintage',
        headerName: 'Vintage',
        type: 'string',
        valueFormatter: vintageFormatter,
        valueGetter: rollUpValueGetter('desc'),
        renderCell: onePlusMoreCellRenderer,
        ...numberColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
    {
        field: 'publisher',
        headerName: 'Issued by',
        type: 'string',
        valueGetter: rollUpValueGetter('asc'),
        valueFormatter: ({ value }) => value && formatPersonName(value),
        renderCell: onePlusMoreCellRenderer,
        ...issuedByColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
    {
        field: 'producer',
        headerName: 'Producer',
        type: 'string',
        valueGetter: rollUpValueGetter('asc'),
        renderCell: onePlusMoreCellRenderer,
        ...issuedByColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
    {
        field: 'oprVersion',
        headerName: 'Version',
        type: 'string',
        valueGetter: rollUpValueGetter('desc'),
        renderCell: onePlusMoreCellRenderer,
        ...versionColumnDimensions,
        cellClassName: emptyPlaceholder,
    },
];
