import { EntitiesAssetType, QuestionnairesQuestionnaire, QuestionnairesQuestionnaireWithScoreInt32 } from '@projectcanary/trustwell-server-client-ts';
import _ from 'lodash';
import { LmrSectionData } from './LmrPage';
import React, { useMemo } from 'react';
import { QMatrix } from 'Components/QMatrix/QMatrix';
import { QForm } from 'Components/QMatrix/QForm';
import { Stack } from '@mui/material';

function findQuestionnaire(questionnaires: QuestionnairesQuestionnaireWithScoreInt32[] | QuestionnairesQuestionnaire[], assetType: EntitiesAssetType) {
    return () => _.find(questionnaires, { assetType: assetType });
}

export const EmissionIntensitySectionComponent = (data: LmrSectionData) => {
    const company = useMemo(() => _.find(data.assets, { assetType: EntitiesAssetType.Company }), [data.assets]);
    const basins = useMemo(() => _.filter(data.assets, { assetType: EntitiesAssetType.Basin }), [data.assets]);
    const pads = useMemo(() => _.filter(data.assets, { assetType: EntitiesAssetType.Pad }), [data.assets]);

    const companyQuestionnaire = useMemo(findQuestionnaire(data.questionnaires, EntitiesAssetType.Company), [data.questionnaires]);
    const padQuestionnaire = useMemo(
        () =>
            _.find(data.questionnaires, {
                assetType: EntitiesAssetType.Pad,
            }),
        [data.questionnaires]
    );
    const basinQuestionnaire = useMemo(
        () =>
            _.find(data.questionnaires, {
                assetType: EntitiesAssetType.Basin,
            }),
        [data.questionnaires]
    );
    return (
        <Stack direction={'column'} spacing={5}>
            {/* top-level LMR attributes */}
            <QForm
                enableCellAnnotation={false}
                assessmentId={data.assessmentId}
                asset={company}
                questionnaire={companyQuestionnaire}
                moduleId={data.moduleId}
            />

            {/* basin-level, emission control */}
            <QMatrix
                assetAnnotationMode={'required'}
                assetLabel={'Basin'}
                assessmentId={data.assessmentId}
                assets={basins}
                questionnaire={basinQuestionnaire}
                moduleId={data.moduleId}
                variant={'short'}
            />

            {/* pad-level, emission control */}
            <QMatrix
                assetAnnotationMode={'required'}
                assetLabel={'Pad'}
                assessmentId={data.assessmentId}
                assets={pads}
                questionnaire={padQuestionnaire}
                moduleId={data.moduleId}
                enableCopyToAll={true}
            />
        </Stack>
    );
};
