import { Page } from 'Components/Page';
import { TopButtons } from 'Components/TopButtons';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { Stack } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import {
    AssetsAsset,
    CertificationCertificationStatus,
    CertificationsGetWellGroupCertificationsResponse,
    CertificationsInProgressCertification,
    CertificationsInProgressCertificationStatus,
    RulesValidationIssue,
    RulesSeverity,
    AssessmentsAssessmentDetails,
} from '@projectcanary/trustwell-server-client-ts';
import { assetApi, certificationApi } from 'Services/TrustwellApiService';
import { ModalFormik, useModal } from 'Controls/Modal';
import { Button } from 'Controls/Button';
import AssetImportModal from 'Components/AssetImportModal';
import { useMessageBox } from 'Controls/MessageBox';
import EditIcon from '@mui/icons-material/Edit';
import { TextField } from 'Controls/Fields/TextField';
import { Form } from 'formik';
import * as Yup from 'Utils/yup';
import { DataGridPro, DataGridProProps, GRID_TREE_DATA_GROUPING_FIELD, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { buildTestId } from 'Utils/testid';
import { useLoader } from 'Utils/loader';
import _ from 'lodash';
import AssetGroupUpdateModal from 'Components/AssetGroupUpdateModal';
import { nowait } from 'Utils/nowait';
import { useGridDefaultSort } from 'Utils/useGridDefaultSort';
import { buildColumns, Row, WellGroupRow, WellRow } from './AssetsListPageColumns';
import { GridActionsCellItem } from 'Controls/GridActionsCellItem';
import { useDevTools } from 'Controls/useDevTools';

const getTreeDataPath: DataGridProProps<Row>['getTreeDataPath'] = (row) => row.hierarchy;

const groupingColumns: DataGridProProps['groupingColDef'] = {
    headerName: 'Well Group / Well Identifier (API/UWI/NTS)',
    hideDescendantCount: true,
    width: 350,
    sortable: true,
    disableColumnMenu: false,
    filterable: true,
};

function findDuplicates(collection: string[]): string[] {
    return _.chain(collection)
        .groupBy(_.identity)
        .pickBy((v) => v.length > 1)
        .keys()
        .uniq()
        .value();
}

function checkDuplicateWellRegistrationIds(assets: AssetsAsset[]) {
    const duplicatedWellRegistrationIds = findDuplicates(_.map(assets, (a) => a.api10));

    if (duplicatedWellRegistrationIds.length > 0) {
        const topDuplicatedWellIds = _.take(duplicatedWellRegistrationIds, 3);
        const moreCount = duplicatedWellRegistrationIds.length - topDuplicatedWellIds.length;
        return `There are ${duplicatedWellRegistrationIds.length} duplicated well ids: ${topDuplicatedWellIds.join(`, `)}${
            moreCount > 0 ? ` (+${moreCount} more)` : ''
        }.`;
    } else {
        return undefined;
    }
}

const AssetsList = ({ assessment }: { assessment: AssessmentsAssessmentDetails }) => {
    const [assets, setAssets] = useState<AssetsAsset[]>([]);
    const [isLoading, withLoader] = useLoader();
    const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
    const [newImportFile, setNewImportFile] = useState<File | undefined>(undefined);
    const [logMessages, setLogMessages] = useState<RulesValidationIssue[]>([]);
    const [certifications, setCertifications] = useState<CertificationsGetWellGroupCertificationsResponse>();
    const [inProgressCertification, setInProgressCertification] = useState<CertificationsInProgressCertification>();
    const [bypassImportWarnings, setBypassImportWarnings] = useState<boolean>(false);

    const messageBox = useMessageBox();
    const modal = useModal();
    const assessmentId = assessment.id;
    const devToolsEnabled = useDevTools();

    const rows: Row[] = useMemo(() => {
        if (assets === undefined) {
            return [];
        }

        // track duplicated well groups unique indices: [wellGroupName] -> [ wgId1, wgId2, ... ]
        const duplicatedWellGroupUniqueIndices = _.chain(assets)
            .map((a) => ({ name: a.wellGroupName, id: a.wellGroupId }))
            .uniqBy((wg) => wg.id)
            .groupBy((a) => a.name)
            .pickBy((v) => v.length > 1)
            .mapValues((v) => _.map(v, 'id'))
            .mapValues((wgIds) => _.map(wgIds, (wgId, index) => [wgId, index + 1]))
            .values()
            .flatten()
            .fromPairs()
            .value();

        function getWellGroupDiscriminator(wellGroupId: number): string {
            const index = duplicatedWellGroupUniqueIndices[wellGroupId];
            return index ? ` (dup. #${index})` : '';
        }

        // create well group rows
        const wellGroups = _.groupBy(assets, (asset) => asset.wellGroupId);
        const wellGroupRows = _.map(wellGroups, (wells, wgId) => {
            const firstWell = wells[0]!;

            return {
                wellGroupId: Number(wgId),
                hierarchy: [firstWell.wellGroupName + getWellGroupDiscriminator(firstWell.wellGroupId)],
                wellGroupName: firstWell.wellGroupName,
                isWellGroupRow: true,
            } as WellGroupRow;
        });

        // track duplicated wells unique indices: duplicatedWellRegistrationIds[well API] -> index
        const duplicatedWellUniqueIndices = _.chain(findDuplicates(_.map(assets, (a) => a.api10)))
            .keyBy(_.identity)
            .mapValues(() => 1)
            .value();

        // create well rows
        const wellRows = _.map(assets, (well) => {
            const discriminator = duplicatedWellUniqueIndices[well.api10] && duplicatedWellUniqueIndices[well.api10]++;
            const hasInProgressCert =
                inProgressCertification &&
                inProgressCertification.status !== CertificationsInProgressCertificationStatus.Published &&
                inProgressCertification.wellGroups.some((wg) => wg.wellGroupId === well.wellGroupId);
            const hasIssuedCert = !!certifications?.wellCertifications.find(
                (c) => c.wellRegistrationId === well.api10 && c.status !== CertificationCertificationStatus.NeverCertified
            );

            const numberOfWellsInGroup = wellGroups[well.wellGroupId].length;

            return {
                hierarchy: [well.wellGroupName + getWellGroupDiscriminator(well.wellGroupId), well.api10 + (discriminator ? ` (dup. #${discriminator})` : '')],
                isWellRow: true,
                isDuplicateWellId: !!discriminator,
                hasInProgressCert,
                hasIssuedCert,
                isOnlyWellInGroup: numberOfWellsInGroup > 1,
                ...well,
            } as WellRow;
        });

        return [...wellRows, ...wellGroupRows];
    }, [assets, inProgressCertification, certifications]);

    const handleOpenModal = () => {
        setIsImportModalOpen(true);
    };

    const handleUploadFile = (e) => {
        if (e.target.files.length) {
            setNewImportFile(e.target.files[0]);
        }
        e.target.value = '';
    };

    const handleClearFile = () => {
        setNewImportFile(undefined);
        setLogMessages([]);
    };

    const handleCloseImportModal = () => {
        setNewImportFile(undefined);
        setLogMessages([]);
        setIsImportModalOpen(false);
    };

    const handleImportAssets = async () => {
        setLogMessages([]);
        if (newImportFile.name.split('.').pop() !== 'xlsx') {
            messageBox.error('Only .xlsx files are permitted - please upload an Excel Workbook');
        } else {
            try {
                const api = await assetApi();
                const results = await api._import(newImportFile, assessmentId, bypassImportWarnings);
                const errors = results.log.filter((l) => l.severity === RulesSeverity.Error);

                if (results.log.length > 0) {
                    setLogMessages(results.log);
                    if (!bypassImportWarnings && errors.length === 0) {
                        setBypassImportWarnings(true);
                    }
                } else if (errors.length === 0) {
                    messageBox.success('All assets were created.');
                    handleCloseImportModal();
                    await refreshAssets();
                }
            } catch (error) {
                messageBox.error('Error importing assets. ' + error);
            }
        }
    };

    async function refreshAssets() {
        try {
            const api = await assetApi();
            const _assets = (await api.getAllAssetsForAssessment(assessmentId)).assets;
            const errorMessage = checkDuplicateWellRegistrationIds(_assets);
            if (errorMessage) {
                messageBox.warning(errorMessage);
            }
            setAssets(_assets);
            const certApi = await certificationApi();

            const inProgressCert = await (await certApi.getInProgressCertification(assessmentId)).inProgressCertification;
            setInProgressCertification(inProgressCert);

            const certs = await certApi.getWellGroupCertifications(assessmentId);
            setCertifications(certs);
        } catch (error) {
            setAssets([]);
            messageBox.error('Error fetching the assets. ' + error);
        }
    }

    useEffect(withLoader(refreshAssets), [assessmentId]);

    const handleDeleteClick = (asset) => async () => {
        modal
            .show(
                {
                    title: 'Delete Asset',
                    buttons: ['Delete', 'Cancel'],
                },
                asset.wellGroupName && assets.filter((a) => a.wellGroupName === asset.wellGroupName).length > 1
                    ? 'This well and all of the scores attached to it will be deleted. This is a permanent action that cannot be undone.'
                    : 'This is the last well in a well group. The well group, well, and all of the scores attached to it will be deleted. This is a permanent action that cannot be undone.'
            )
            .onAccept(async () => {
                const api = await assetApi();
                await api.deleteAsset(asset.id);
                nowait(refreshAssets());
            });
    };

    const handleAssetEditClick = (asset) => async () => {
        const schema = Yup.object().shape({
            assetName: Yup.string()
                .noEnclosingSpaces()
                .label('Asset name')
                .min(2)
                .required('Please enter a name for the asset.')
                .test('assetDuplicateCheck', 'An asset with this name already exists. See error message for details.', async function (value, { createError }) {
                    if (value === asset.name || !value?.trim()) {
                        return true;
                    }

                    try {
                        const api = await assetApi();
                        const assetNameChangeValidationResult = await api.validateAssetNameChange(value, asset.id);
                        return (
                            assetNameChangeValidationResult.allowNameChange ||
                            createError({
                                message: `This asset name already exists in assessment '${assetNameChangeValidationResult.assessmentName}' (and possibly others).`,
                            })
                        );
                    } catch (error) {
                        messageBox.error(`Failed while checking if new asset name is available. ${error}`);
                    }
                }),
        });

        modal
            .form(
                {
                    title: 'Edit Asset',
                    buttons: ['Update Asset', 'Cancel'],
                },
                <ModalFormik initialValues={{ assetName: asset.name }} validationSchema={schema}>
                    <Form>
                        <Stack spacing={{ xs: 2 }} mt={2}>
                            <TextField name={'assetName'} label={'Asset Name'} />
                        </Stack>
                    </Form>
                </ModalFormik>
            )
            .onSubmit(async ({ assetName }) => {
                asset.name = assetName;
                const api = await assetApi();
                await api.updateAsset({ asset: asset });
                setAssets((assets) => {
                    const updatedIndex = assets.findIndex((a) => a.id === asset.id);
                    assets[updatedIndex].name = assetName;
                    return [...assets];
                });
            });
    };

    const handleGroupEditClick = (assetGroup: Row) => async () => {
        const schema = Yup.object().shape({
            assetGroupName: Yup.string().noEnclosingSpaces().label('Well group name').min(2).required('Please enter a name for the well group.'),
        });
        const initialValues = { assetGroupName: assetGroup.wellGroupName! };

        const handleSubmit = async ({ assetGroupName, bypassWarnings }) => {
            const api = await assetApi();

            const results = await api.updateAssetGroup({
                assetGroupId: assetGroup.wellGroupId,
                assetGroupName: assetGroupName,
                bypassWarnings: bypassWarnings,
            });
            if (results.logs && results.logs.length > 0 && !bypassWarnings) {
                const errorsExist = results.logs.some((log) => log.severity === RulesSeverity.Error);
                modal
                    .form(
                        {
                            title: 'Confirm Edit Well Group',
                            buttons: ['Confirm', 'Cancel'],
                        },
                        <AssetGroupUpdateModal assetGroup={initialValues} schema={schema} log={results.logs} />
                    )
                    .onSubmit(({ assetGroupName }) =>
                        handleSubmit({
                            assetGroupName: assetGroupName,
                            bypassWarnings: !errorsExist,
                        })
                    );
            } else {
                await refreshAssets();
            }
        };

        modal
            .form(
                {
                    title: 'Edit Well Group',
                    buttons: ['Update Well Group', 'Cancel'],
                },
                <AssetGroupUpdateModal assetGroup={initialValues} schema={schema} log={[]} />
            )
            .onSubmit(({ assetGroupName }) => handleSubmit({ assetGroupName: assetGroupName, bypassWarnings: false }));
    };

    const toggleIsSampleWellFlag = async (well) => {
        try {
            const api = await assetApi();
            await api.switchSampleWell({ sampleWellId: well.id, assetGroupId: well.wellGroupId });
            nowait(refreshAssets());
        } catch (error) {
            messageBox.error(`Failed to toggle sample well flag on well '${well.api10}'. ` + error);
        }
    };

    const actionsBuilder = ({ row }: GridRowParams<Row>) => {
        const disabledWellMessageDelete =
            (row.hasIssuedCert && `This well has an issued rating and cannot be deleted.`) ||
            (row.hasInProgressCert && 'This well has an in-progress rating publication and cannot be deleted.') ||
            (row.isSampleWell && row.isOnlyWellInGroup && 'This well is a sample well and cannot be deleted.');
        return row.isWellRow
            ? [
                  <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={handleAssetEditClick(row)} showInMenu={false} />,
                  <GridActionsCellItem
                      icon={<DeleteIcon />}
                      label="Delete"
                      onClick={handleDeleteClick(row)}
                      showInMenu={false}
                      disabled={disabledWellMessageDelete}
                  />,
              ]
            : [<GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={handleGroupEditClick(row)} showInMenu={false} />];
    };

    const columns = useMemo(() => buildColumns(toggleIsSampleWellFlag, actionsBuilder), [assets]);

    const testId = buildTestId({ page: 'assets' });

    const gridRef = useGridApiRef();
    useEffect(() => {
        if (devToolsEnabled) {
            const allColumns = gridRef.current.getAllColumns();
            allColumns.forEach((c) => gridRef.current.setColumnVisibility(c.field, true));
        }
    }, [devToolsEnabled]);

    const sortModel = useGridDefaultSort([{ field: GRID_TREE_DATA_GROUPING_FIELD, sort: 'asc' }]);

    return (
        <Page title={'Assets'}>
            <TopButtons>
                <Button variant="primary" onClick={handleOpenModal}>
                    Import
                </Button>
            </TopButtons>
            <DataGridPro<Row>
                apiRef={gridRef}
                treeData={true}
                rows={rows}
                columns={columns}
                getTreeDataPath={getTreeDataPath}
                groupingColDef={groupingColumns}
                loading={isLoading}
                getRowId={(row) => `wg:${row.wellGroupId}` + (row.isWellRow ? `|w:${row.id}` : '')}
                data-testid={buildTestId(testId, { table: 'assets' })}
                initialState={{
                    columns: { columnVisibilityModel: { id: false } },
                }}
                {...sortModel}
            />
            <AssetImportModal
                bypassWarnings={bypassImportWarnings}
                isModalOpen={isImportModalOpen}
                handleImportAssets={handleImportAssets}
                handleCloseModal={handleCloseImportModal}
                handleUploadFile={handleUploadFile}
                handleClearFile={handleClearFile}
                log={logMessages}
                newImportFile={newImportFile}
            />
        </Page>
    );
};

export default AssetsList;
