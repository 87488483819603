import React, { useEffect, useState } from 'react';
import LocalFactorsConfiguration from 'Components/LocalFactors/LocalFactorsConfiguration';
import AssessmentLevelScoringContainer from 'Components/LocalFactors/AssessmentLevelScoringContainer';
import * as apis from 'Services/TrustwellApiService';
import { Backdrop, CircularProgress, Grid } from '@mui/material';
import {
    AssessmentsAssessmentDetails,
    LocalFactorApi,
    LocalFactorsAssessmentLocalFactorScore,
    LocalFactorsLocalFactor,
} from '@projectcanary/trustwell-server-client-ts';
import { useMessageBox } from 'Controls/MessageBox';
import { formatAssessmentScoresForPost, FormattedScoresObject, LocalFactorConfigStatus } from 'Components/LocalFactors/LocalFactorConfig';
import WellGroupScoringContainer from 'Components/LocalFactors/WellGroupScoringContainer';

type LocalFactorsPageProps = {
    assessmentId: number;
};

export const LocalFactorsPage = ({ assessmentId }: LocalFactorsPageProps) => {
    const [assessment, setAssessment] = useState<AssessmentsAssessmentDetails>();
    const [localFactorsApi, setLocalFactorsApi] = useState<LocalFactorApi>();

    useEffect(() => {
        (async () => {
            const localFactorApi: LocalFactorApi = await apis.localFactorApi();
            const assessmentApi = await apis.assessmentApi();
            setLocalFactorsApi(localFactorApi);
            setAssessment(await assessmentApi.getAssessmentById(assessmentId));
        })();
    }, [assessmentId]);

    if (assessment) {
        return <InnerLocalFactorsPage assessment={assessment} localFactorsApi={localFactorsApi} />;
    } else {
        return <>Loading local factors...</>;
    }
};

type InnerLocalFactorsPageProps = {
    assessment: AssessmentsAssessmentDetails;
    localFactorsApi: LocalFactorApi;
};

const InnerLocalFactorsPage = ({ assessment, localFactorsApi }: InnerLocalFactorsPageProps) => {
    const messageBox = useMessageBox();

    const [configStep, setConfigStep] = useState<LocalFactorConfigStatus>(null);
    const [localFactors, setLocalFactors] = useState<LocalFactorsLocalFactor[]>();
    const [configSelections, setConfigSelections] = useState<object>({});
    const [selectedAssessmentFactorDescriptions, setSelectedAssessmentFactorDescriptions] = useState<LocalFactorsLocalFactor[]>();
    const [assessmentLevelScored, setAssessmentLevelScored] = useState<boolean>(false);
    // all assessment level local factor score objects (wellgroup and n/a are scored null)
    const [assessmentLevelScores, setAssessmentLevelScores] = useState<LocalFactorsAssessmentLocalFactorScore[]>();
    const [loading, setLoading] = useState<boolean>(false);

    // get all assessment level scores and local factors
    useEffect(() => {
        const getLocalFactorsAndScores = async () => {
            setLoading(true);
            try {
                const { localFactor } = await localFactorsApi.getLocalFactorsByAssessmentDefId(assessment.versionId);
                setLocalFactors(localFactor);

                const { assessmentLocalFactorScores } = await localFactorsApi.getAssessmentLocalfactorScores(assessment.id);

                if (assessmentLocalFactorScores && assessmentLocalFactorScores.length > 0) {
                    setAssessmentLevelScored(true);
                    setConfigStep(LocalFactorConfigStatus.Configured);
                    setAssessmentLevelScores(assessmentLocalFactorScores);
                } else {
                    setAssessmentLevelScored(false);
                    setConfigStep(LocalFactorConfigStatus.Unconfigured);
                }
            } catch (error) {
                messageBox.error('Failed to fetch local factor scores. ' + error);
            }

            setLoading(false);
        };
        void getLocalFactorsAndScores();
    }, [assessment?.id, assessment?.versionId]);

    const handleCancelConfig = () => {
        setConfigSelections({});
        setConfigStep(LocalFactorConfigStatus.Unconfigured);
    };

    const handleConfigSelection = (e: any) => {
        const [localFactorId, selectedScope] = e.target.value.split('_');
        setConfigSelections({
            ...configSelections,
            [localFactorId]: selectedScope,
        });
        setConfigStep(LocalFactorConfigStatus.SelectConfig);
    };

    const handleConfigGoBack = () => setConfigStep(LocalFactorConfigStatus.SelectConfig);

    const handleConfigNextStep = () => {
        if (Object.keys(configSelections).length === localFactors.length) {
            setConfigStep(LocalFactorConfigStatus.ConfirmConfig);
        }
    };

    const handleSaveConfig = () => {
        setConfigStep(LocalFactorConfigStatus.Configured);
        filterConfiguredLocalFactorsToScope(localFactors, configSelections);
    };

    // filter selected configuration to scope
    const filterConfiguredLocalFactorsToScope = (factors: LocalFactorsLocalFactor[], selections: object) => {
        const assessmentFactors = factors.filter((factor: LocalFactorsLocalFactor) => selections[factor.localFactorId] === 'Assessment');
        setSelectedAssessmentFactorDescriptions(assessmentFactors);
    };

    // go back to config
    const handleCancelAssessmentScores = () => {
        setConfigStep(LocalFactorConfigStatus.ConfirmConfig);
    };

    // api call to create assessment level scores and all well group scores for N/A and assessment level (not well
    // group)
    const handleSaveAssessmentLevelScores = async (assessmentScores: FormattedScoresObject) => {
        const assessmentScoreArray = formatAssessmentScoresForPost(assessmentScores, configSelections, assessment);
        try {
            await localFactorsApi.createAssessmentLocalfactorScores(assessmentScoreArray);

            const formattedAssessmentLevelScores = assessmentScoreArray.payload.map((a) => {
                return {
                    assessmentId: assessment.id,
                    localFactorVersionId: assessment.versionId,
                    assessmentFactor: a['AssessmentFactor'],
                    notApplicableFactor: a['NotApplicableFactor'],
                    wellGroupFactor: a['WellGroupFactor'],
                    localFactorId: a['LocalfactorId'],
                    score: a['Score'],
                };
            });

            setAssessmentLevelScores(formattedAssessmentLevelScores);

            messageBox.success('Assessment local factors successfully created.');
            setAssessmentLevelScored(true);
        } catch (error) {
            messageBox.error('There was an error creating the assessment local factors. ' + error);
            setAssessmentLevelScored(false);
        }
    };

    return (
        <div style={{ paddingBottom: '80px' }}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {/* Assessment local factors not configured -- show Assessment Configuration */}
            {configStep && configStep !== LocalFactorConfigStatus.Configured && (
                <Grid container>
                    <Grid item xs={12}>
                        <LocalFactorsConfiguration
                            configStep={configStep}
                            localFactors={localFactors}
                            configSelections={configSelections}
                            handleConfigSelection={handleConfigSelection}
                            handleCancelConfig={handleCancelConfig}
                            handleConfigNextStep={handleConfigNextStep}
                            handleGoBack={handleConfigGoBack}
                            handleSaveConfig={handleSaveConfig}
                        />
                    </Grid>
                </Grid>
            )}

            {/* Assessment local factors configured but not yet scored -- show assessment scoring container */}
            {!assessmentLevelScored && configStep === LocalFactorConfigStatus.Configured && (
                <Grid container>
                    <AssessmentLevelScoringContainer
                        scopedLocalFactors={selectedAssessmentFactorDescriptions}
                        handleCancelAssessmentScores={handleCancelAssessmentScores}
                        handleSaveAssessmentLevelScores={handleSaveAssessmentLevelScores}
                    />
                </Grid>
            )}
            {/* Configured and Assessment Local Factors Scored -- show wellgroup scoring*/}
            {assessmentLevelScored && (
                <WellGroupScoringContainer
                    assessment={assessment}
                    wellGroups={assessment.wellGroups.sort((a, b) => (a.name > b.name ? 1 : -1))}
                    localFactors={localFactors}
                    assessmentLevelScores={assessmentLevelScores}
                />
            )}
        </div>
    );
};
