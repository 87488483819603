import { randomUniqueId } from './uniqueIdGenerator';

const { REACT_APP_ENVIRONMENT: environment, REACT_APP_API_BASE_URL: baseUrl } = process.env;

// Show red bar on error
if (['Local', 'Development', 'Staging'].indexOf(environment) !== -1) {
    let errorBar: HTMLDivElement | undefined | true = undefined;
    const originalConsoleErrorFunction = window.console.error.bind(window.console);
    window.console.error = (...args: any[]) => {
        if (errorBar === undefined) {
            errorBar = document.createElement('div');
            errorBar.setAttribute('style', 'position: absolute; top: 0; left: 0; right: 0; height: 10px; background-color: red; z-index:2147483647;');
            document.body.append(errorBar);
        }
        return originalConsoleErrorFunction(...args);
    };
}

// send error messages to the server
if (['Development', 'Staging', 'Production'].indexOf(environment) !== -1) {
    const originalConsoleErrorFunction = window.console.error.bind(window.console);
    window.console.error = (...args: any[]) => {
        originalConsoleErrorFunction(...args);

        void new Promise(() => {
            const headers = new Headers();
            headers.append('x-correlation-id', randomUniqueId());
            headers.append('content-type', 'application/json');

            const raw = JSON.stringify({
                logLevel: 'Error',
                message: JSON.stringify(args),
            });

            const requestOptions: RequestInit = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'manual',
            };

            void fetch(baseUrl + '/ClientSideLogger/Log', requestOptions);
        });
    };
}

export {};
