import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

export type RouteParams<T, P extends keyof T> = {
    [K in P]?: (value: string) => T[K];
};

/**
 * Bind wrapped component properties to route parameters.
 * @example export const MyPage = withRouteParams({ itemId: Number }, InnerMyPage);
 */
export function withRouteParams<T, P extends keyof T>(routeParams: RouteParams<T, P>, innerComponent: FC<T>): (props: Omit<T, P>) => JSX.Element {
    return (props: Omit<T, P>) => {
        const params = useParams();
        const resolvedParams = _.mapValues(routeParams, (v, k) => v(params[k]));
        return React.createElement(innerComponent, { ...props, ...resolvedParams } as T);
    };
}
