import React, { useCallback, useEffect, useState } from 'react';
import { Card, Stack, Typography } from '@mui/material';
import { certificationApi, scoreApi } from 'Services/TrustwellApiService';
import { useLoader } from 'Utils/loader';
import {
    AssessmentsAssessmentDetails,
    CertificationsInProgressCertification,
    CertificationsInProgressCertificationStatus,
    ModulesModuleType,
} from '@projectcanary/trustwell-server-client-ts';
import _ from 'lodash';
import { Button } from 'Controls/Button';
import { utcDateFormatter } from 'Utils/formats';
import { useModal } from 'Controls/Modal';
import { CertifyAssetFinalReviewModal } from 'Components/CertifyAssetFinalReviewModal';
import { useMessageBox } from 'Controls/MessageBox';
import { useNavigation } from 'Utils/Navigation';
import { Page } from 'Components/Page';
import { TaskList, TaskListItem } from './TaskList';
import { WellGroupOprReviewDetailsView } from './OprWellGroupDetailsView';
import { FinalReviewDetailsView } from './FinalReviewDetailsView';
import { BasinEmissionsReviewDetailsView } from './BasinEmissionsReviewDetailsView';
import { LowMethaneRatingReviewDetailsView } from '../Lmr/LowMethaneRatingReviewDetailsView';
import { LoadingCircle } from 'Controls/LoadingCircle';
import { nowait } from 'Utils/nowait';
import { PreliminaryReportDownloadsStepView } from './PreliminaryReportDownloadsStep/PreliminaryReportDownloadsStepView';

type IssuanceReviewTask = TaskListItem & {
    detailsViewComponent: (props: { task: IssuanceReviewTask }) => JSX.Element;
    onCompleteButtonClick: () => void;
    completeButtonLabel?: [string, string] | string;
} & (
        | {
              wellGroupId: number;
              taskType: 'WellGroup';
          }
        | { taskType: 'FinalReview' | 'BasinEmissions' | 'LowMethaneRating' | 'PreliminaryReview' }
    );

function disableUnavailableTasks(tasks: IssuanceReviewTask[]) {
    // final review is only enabled if all other tasks are complete
    const readyForFinalReview = _.every(tasks, (t) => t.taskType === 'FinalReview' || t.isComplete);
    tasks
        .filter((t) => t.taskType === 'FinalReview')
        .forEach((t) => {
            t.isDisabled = !readyForFinalReview;
        });
}

function findNextIncompleteTask(tasks: IssuanceReviewTask[], currentTask?: IssuanceReviewTask): IssuanceReviewTask | undefined {
    if (currentTask) {
        const incompleteTaskAfterCurrentTask = _.chain(tasks)
            .dropWhile((t) => t !== currentTask)
            .find({ isComplete: false, isDisabled: false })
            .value();
        if (incompleteTaskAfterCurrentTask) return incompleteTaskAfterCurrentTask;
    }
    return _.find(tasks, { isComplete: false, isDisabled: false });
}

export const WellGroupReview = ({ assessment }: { assessment: AssessmentsAssessmentDetails }) => {
    const modal = useModal();
    const messageBox = useMessageBox();
    const { site, navigateTo } = useNavigation();

    const [isLoading, withLoader] = useLoader();
    const [inProgressCertification, setInProgressCertification] = useState<CertificationsInProgressCertification>();
    const [tasks, setTasks] = useState<IssuanceReviewTask[]>();
    const [selectedTask, setSelectedTask] = useState<IssuanceReviewTask>();

    const changeTaskCompleteStatus = useCallback(
        (task: IssuanceReviewTask, newIsCompleteStatus: boolean) => {
            setTasks((currentTasks) => {
                task.isComplete = newIsCompleteStatus;
                task.icon = undefined;
                disableUnavailableTasks(currentTasks);
                if (newIsCompleteStatus) {
                    setSelectedTask((currentSelectedTask) => findNextIncompleteTask(currentTasks, currentSelectedTask));
                }
                return [...currentTasks];
            });
        },
        [setTasks]
    );

    const refreshInProgressCertification = async () => {
        const certificationApiInstance = await certificationApi();
        const scoreApiInstance = await scoreApi();

        await scoreApiInstance.calculate(assessment.id);

        if (assessment.activeModules.includes(ModulesModuleType.Lmr)) {
            await scoreApiInstance.processLmrEvaluation(assessment.id);
        }

        const [{ inProgressCertification }, { invalidatedWellGroupIds: newlyInvalidatedWellGroupIds }] = await Promise.all([
            certificationApiInstance.getInProgressCertification(assessment.id),
            certificationApiInstance.invalidateStaleInProgressCertificationWellGroupApprovals(assessment.id),
        ]);

        if (inProgressCertification.status === CertificationsInProgressCertificationStatus.PublicationFailed) {
            try {
                await certificationApiInstance.resetInProgressCertificationToInReview(inProgressCertification.id);
                withLoader(refreshInProgressCertification)();
                messageBox.info(
                    "This in-progress rating publication has failed to publish earlier. Its status has been automatically changed back to 'in-review'."
                );
                nowait(refreshInProgressCertification());
            } catch (error) {
                messageBox.error(
                    "This in-progress rating publication has failed to publish earlier. We tried to change it's status back to in-review, but failed. Please refresh the page. " +
                        error
                );
            }
            return;
        }

        const tasks: IssuanceReviewTask[] = [];

        tasks.push({
            id: 'preliminaryReview',
            label: 'Download Preliminary Reports',
            isDisabled: false,
            taskType: 'PreliminaryReview',
            isComplete: false,
            detailsViewComponent: (props) => React.createElement(PreliminaryReportDownloadsStepView, { assessmentId: assessment.id }),
            onCompleteButtonClick(this: IssuanceReviewTask) {
                changeTaskCompleteStatus(this, !this.isComplete);
            },
        } as IssuanceReviewTask);

        const wellGroupReviewTasks: IssuanceReviewTask[] = _.orderBy(inProgressCertification.wellGroups, 'wellGroupName').map((wg) => {
            const shouldInvalidate = newlyInvalidatedWellGroupIds.includes(wg.wellGroupId);

            if (shouldInvalidate) {
                messageBox.warning(`Well group '${wg.wellGroupName}' approval has been revoked and will need to be reviewed again.`);
            }

            return {
                id: 'wellGroup-' + wg.wellGroupId,
                label: wg.wellGroupName,
                icon: shouldInvalidate ? 'warning' : undefined,
                tooltip: 'Well group approval has been revoked and will need to be reviewed again.',
                isDisabled: false,
                taskType: 'WellGroup',
                wellGroupId: wg.wellGroupId,
                isComplete: wg.isVerified && !shouldInvalidate,
                detailsViewComponent: () => React.createElement(WellGroupOprReviewDetailsView, { wellGroupId: wg.wellGroupId, assessmentId: assessment.id }),
                onCompleteButtonClick(this: IssuanceReviewTask): void {
                    changeWellGroupReviewCompletionStatus(this, wg.wellGroupId, !this.isComplete);
                },
                completeButtonLabel: ['Mark as Not Reviewed', 'Mark as Reviewed'],
            } as IssuanceReviewTask;
        });

        tasks.push(...wellGroupReviewTasks);

        const changeWellGroupReviewCompletionStatus = async (task: IssuanceReviewTask, wellGroupId: number, newIsComplete: boolean) => {
            try {
                const api = await certificationApi();
                await api.updateInProgressCertificationWellGroupVerificationStatus({
                    inProgressCertificationId: inProgressCertification.id,
                    inProgressCertificationWellGroupId: wellGroupId,
                    isVerified: newIsComplete,
                });
                changeTaskCompleteStatus(task, newIsComplete);
            } catch (error) {
                messageBox.error('Failed to update well group review status. ' + error);
            }
        };

        const confirmAndIssueRatings = () => {
            modal
                .form(
                    {
                        style: 'critical',
                        title: 'Issue Ratings Confirmation',
                        buttons: [
                            { title: 'Issue Ratings', isAccept: true, icon: 'Verified' },
                            { title: 'Cancel', isDefault: true, isAccept: false },
                        ],
                    },
                    CertifyAssetFinalReviewModal
                )
                .onSubmit(async ({ skipFileUpload }) => {
                    try {
                        const certificationApiInstance = await certificationApi();
                        const { inProgressCertification } = await certificationApiInstance.getInProgressCertification(assessment.id);

                        if (!inProgressCertification) {
                            throw new Error("Assessment doesn't have an in-progress rating publication.");
                        }

                        if (inProgressCertification.status !== CertificationsInProgressCertificationStatus.InReview) {
                            throw new Error(`In-progress rating publication is not in the correct state (${inProgressCertification.status}).`);
                        }

                        const hasInvalidatedWellGroups = _.some(inProgressCertification.wellGroups, { isVerified: false });
                        if (hasInvalidatedWellGroups) {
                            messageBox.error('One or move well groups have been changed and need to be re-approved.');
                        } else {
                            await certificationApiInstance.publishCertificates(inProgressCertification.id, skipFileUpload);
                            navigateTo(site.assessment(assessment.id).certification);
                        }
                    } catch (error) {
                        messageBox.error('Failed to initiate ratings publication. ' + error);
                    }
                });
        };

        tasks.push({
            id: 'basinEmissions',
            label: 'Basin Emissions',
            isDisabled: false,
            taskType: 'BasinEmissions',
            isComplete: false,
            detailsViewComponent: () => React.createElement(BasinEmissionsReviewDetailsView, { assessmentId: assessment.id }),
            onCompleteButtonClick(this: IssuanceReviewTask) {
                changeTaskCompleteStatus(this, !this.isComplete);
            },
        } as IssuanceReviewTask);

        if (assessment.activeModules.includes(ModulesModuleType.Lmr)) {
            tasks.push({
                id: 'lmr',
                label: 'Low Methane Rating',
                isDisabled: false,
                taskType: 'LowMethaneRating',
                isComplete: false,
                detailsViewComponent: () => React.createElement(LowMethaneRatingReviewDetailsView, { assessmentId: assessment.id }),
                onCompleteButtonClick(this: IssuanceReviewTask) {
                    changeTaskCompleteStatus(this, !this.isComplete);
                },
            } as IssuanceReviewTask);
        }

        tasks.push({
            id: 'finalReview',
            label: 'Final Review',
            isDisabled: false,
            taskType: 'FinalReview',
            isComplete: false,
            detailsViewComponent: () => React.createElement(FinalReviewDetailsView, { assessmentId: assessment.id }),
            onCompleteButtonClick: confirmAndIssueRatings,
            completeButtonLabel: 'Issue Ratings',
        } as IssuanceReviewTask);

        disableUnavailableTasks(tasks);
        setTasks(tasks);
        setSelectedTask(findNextIncompleteTask(tasks));

        setInProgressCertification(inProgressCertification);
    };

    useEffect(withLoader(refreshInProgressCertification), [assessment.id]);

    const discardIssuance = async () => {
        try {
            await (await certificationApi()).discardInProgressCertification(inProgressCertification.id);
            navigateTo(site.assessment(assessment.id).certification);
        } catch (error) {
            messageBox.error('Error discarding this rating issuance. ' + error);
        }
    };

    const confirmAndDiscardIssuance = () => {
        modal
            .show(
                {
                    title: 'Warning',
                    style: 'critical',
                    buttons: [
                        { title: 'Yes, discard', icon: 'Delete', isAccept: true },
                        { title: 'Cancel', isDefault: true },
                    ],
                },
                <>
                    Discarding these changes will permanently delete your currently being issued rating and cannot be undone.
                    <br />
                    <br />
                    Are you sure you wish to proceed?
                </>
            )
            .onAccept(discardIssuance);
    };

    function getButtonLabel(isComplete: boolean, completeButtonLabel: undefined | string | [string, string]) {
        if (completeButtonLabel === undefined) {
            return getButtonLabel(isComplete, ['Mark As Not Reviewed', 'Mark As Reviewed']);
        }
        if (_.isString(completeButtonLabel)) return completeButtonLabel;
        else return completeButtonLabel[isComplete ? 0 : 1];
    }

    if (!selectedTask) {
        return <LoadingCircle />;
    }

    return (
        <Page title={'Issue Ratings'} isLoading={isLoading}>
            <Stack direction={'column'} flexGrow={1} sx={{ maxWidth: '1600px', padding: '40px' }} spacing={2}>
                <Typography variant="h5" fontWeight="500">
                    {selectedTask.label}
                </Typography>

                <Stack direction={'row'} spacing={5} flexGrow={1}>
                    {/* Well Groups Selection List */}
                    <Stack spacing={2} sx={{ maxHeight: 'max(400px, calc(100vh - 380px))', minWidth: '400px' }} flexGrow={0}>
                        <Stack>
                            <Typography variant={'subtitle2'}>Overview</Typography>
                            <Stack sx={{ paddingLeft: '20px' }}>
                                <Typography variant="body2">Start Date: {utcDateFormatter({ value: inProgressCertification.certificateStartDate })}</Typography>
                                <Typography variant="body2">End Date: {utcDateFormatter({ value: inProgressCertification.certificateEndDate })}</Typography>
                                <Typography variant="body2">Assessment Configuration: {assessment.activeModules.join(', ')} </Typography>
                            </Stack>
                        </Stack>

                        <Card sx={{ flexGrow: 1, overflowY: 'auto' }} variant="outlined">
                            <TaskList<IssuanceReviewTask>
                                tasks={tasks}
                                selectedTask={selectedTask}
                                onItemClick={(t) => {
                                    setSelectedTask(t);
                                }}
                            />
                        </Card>
                    </Stack>
                    <Stack direction={'column'} alignItems={'stretch'} spacing={0} flexGrow={1}>
                        {React.createElement(selectedTask.detailsViewComponent, { task: selectedTask })}
                    </Stack>
                </Stack>
                <Stack justifyContent="end" direction="row" spacing={3} marginTop="10px" flexGrow={0}>
                    <Button variant="secondary" onClick={confirmAndDiscardIssuance}>
                        Discard
                    </Button>

                    <Button href={`${site.assessment(assessment.id).certification}/`} variant={'secondary'}>
                        Save and Exit
                    </Button>

                    <Button variant="primary" onClick={() => selectedTask.onCompleteButtonClick()}>
                        {getButtonLabel(selectedTask.isComplete, selectedTask.completeButtonLabel)}
                    </Button>
                </Stack>
            </Stack>
        </Page>
    );
};
