import { QContext } from './QContext';
import { QMatrixCell } from './QMatrixCell';
import React from 'react';
import { QuestionnairesQuestionnaire, UnifiedAssetsAsset } from '@projectcanary/trustwell-server-client-ts';
import { Box, InputLabel, Stack } from '@mui/material';

export type QFormProps = {
    asset: UnifiedAssetsAsset;
    questionnaire: QuestionnairesQuestionnaire;
    assessmentId: number;
    moduleId: number;
    enableCellAnnotation?: boolean;
};
export const QForm = ({ questionnaire, asset, assessmentId, moduleId, enableCellAnnotation }: QFormProps) => {
    return (
        <QContext assets={[asset]} questionnaire={questionnaire} assessmentId={assessmentId} moduleId={moduleId}>
            <Stack direction={'column'} spacing={2} width={500}>
                {questionnaire.questions.map((q) => (
                    <Box key={q.text}>
                        <InputLabel>{q.text}</InputLabel>
                        <QMatrixCell assetId={asset.id} question={q} enableCellAnnotation={enableCellAnnotation} />
                    </Box>
                ))}
            </Stack>
        </QContext>
    );
};
