import { useEffect, useMemo, useState } from 'react';
import { Annotation, QCellSyncStatus } from './QContext';
import {
    AnnotationsGetAnnotationsResponseEvaluationScopeAssetQuestionAnnotationTopicDto,
    QuestionnairesGetAssessmentEvaluationResponse,
    QuestionnairesQuestionnaireQuestion,
    UnifiedAssetsAsset,
} from '@projectcanary/trustwell-server-client-ts';

export type QCellState = {
    value: any;
    isNA: boolean;
    isOmitted: boolean;
    annotation: Annotation | undefined;
    syncStatus: QCellSyncStatus;
};

export function isEmptyResponse(cellState: QCellState) {
    return !cellState.isNA && !cellState.isOmitted && cellState.value === undefined && cellState.annotation === undefined;
}

export type QCellStateMatrix = { [assetId: number]: { [questionnaireQuestionId: number]: QCellState } };

function initializeCellStates(questions: QuestionnairesQuestionnaireQuestion[], assets: UnifiedAssetsAsset[]) {
    const newCellStates: QCellStateMatrix = {};
    for (const asset of assets) {
        for (const question of questions) {
            newCellStates[asset.id] ??= {};
            if (!newCellStates[asset.id]?.[question.questionnaireQuestionId]) {
                newCellStates[asset.id][question.questionnaireQuestionId] ??= {
                    syncStatus: 'fetching',
                    isOmitted: false,
                    isNA: false,
                    annotation: undefined,
                    value: undefined,
                };
            }
        }
    }
    return newCellStates;
}

export function useCellStates() {
    // cellStates is a map of cell states keyed by asset id and questionnaire question id
    // normally in React when a single value of an array changes we copy the entire array into a new array to trigger a re-render,
    // but to improve performance the changes are made in-place, and a cellStateCounter is incremented to indicate that re-rendering needs
    // to happen. We need to do it this way because many functions that depend on cellState would need to be recomputed when cellState is
    // copied, and that, in turn, would cause all QMatrix cells to be re-rendered unnecessarily.
    const [cellStates, setCellStates] = useState<QCellStateMatrix>({});
    const [cellStateCounter, setCellStateCounter] = useState(0);

    const loadCellStates = useMemo(
        () =>
            function (
                questions: Array<QuestionnairesQuestionnaireQuestion>,
                assets: UnifiedAssetsAsset[],
                assessmentEvaluation: QuestionnairesGetAssessmentEvaluationResponse,
                assetQuestionAnnotationsResponse: AnnotationsGetAnnotationsResponseEvaluationScopeAssetQuestionAnnotationTopicDto
            ) {
                const newCellStates = initializeCellStates(questions, assets);

                // load asset-question annotations
                for (const note of assetQuestionAnnotationsResponse.notes) {
                    const cellAnnotation = (newCellStates[note.topic.assetId][note.topic.questionnaireQuestionId].annotation ??= {});
                    cellAnnotation.note = note.text;
                }
                for (const documentReference of assetQuestionAnnotationsResponse.documentReferences) {
                    const cellAnnotation = (newCellStates[documentReference.topic.assetId][documentReference.topic.questionnaireQuestionId].annotation ??= {});
                    cellAnnotation.documentReference = {
                        documentName: documentReference.documentName,
                        documentId: documentReference.documentId,
                        citation: documentReference.citation,
                    };
                }

                // load responses
                for (const assetEvaluation of assessmentEvaluation.assetEvaluations) {
                    for (const response of assetEvaluation.responses) {
                        const cellState = newCellStates[assetEvaluation.assetId][response.questionnaireQuestionId];
                        cellState.syncStatus = 'fresh';
                        cellState.value = response.answer ?? undefined;
                        cellState.isOmitted = response.isExplicitlyOmitted;
                        cellState.isNA = response.isNA;
                    }
                }

                for (const assetId in newCellStates) {
                    for (const qqId in newCellStates[assetId]) {
                        const cellState = newCellStates[assetId][qqId];
                        // anything that is still fetching is probably missing a response - set to no response state.
                        if (cellState.syncStatus === 'fetching') {
                            cellState.syncStatus = 'fresh';
                        }
                    }
                }

                setCellStates(newCellStates);
            },
        [cellStates]
    );

    const mutateCellState = useMemo(
        () =>
            function (assetId: number, questionnaireQuestionId: number, mutations: Partial<QCellState>): void {
                setCellStates((currentCellStates) => {
                    const startTime = new Date().getTime();
                    const assetState = currentCellStates[assetId];
                    const newQuestionState = {
                        ...assetState?.[questionnaireQuestionId],
                        ...mutations,
                    };
                    // we do not store empty annotations
                    if (mutations.annotation && mutations.annotation.note === undefined && mutations.annotation.documentReference === undefined) {
                        delete newQuestionState.annotation;
                    }
                    const newStates = cellStates;
                    newStates[assetId][questionnaireQuestionId] = newQuestionState;
                    return newStates;
                });
                setCellStateCounter((value) => {
                    return value + 1;
                });
            },
        [cellStates]
    );

    return {
        cellStateCounter: cellStateCounter,
        cellStates,
        loadCellStates: loadCellStates,
        mutateCellState: mutateCellState,
    };
}
