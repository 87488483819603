import React, { useContext, useState } from 'react';
import { useKeyboardShortcut } from './useKeyboardShortcut';
import { Feature, isFeatureEnabled } from '../Config';
import Logger from 'Utils/Logger';
import { Typography } from '@mui/material';
import { getFaviconHref, setFavicon, setFaviconHref } from 'Utils/favicon';

const devToolsKeyboardShortcut = { key: 'E', ctrlKey: true, shiftKey: true };

function changeFavicon() {
    setFavicon('⚙', 84, -4, 64);
}

export type DevToolsContextType = { devToolsEnabled: boolean };

export const DevToolsContext = React.createContext<DevToolsContextType>({ devToolsEnabled: false });

const RealDevToolsProvider = ({ children }) => {
    const originalIconHref = getFaviconHref();
    const [devToolsEnabled, enableDevTools] = useState<boolean>(false);
    useKeyboardShortcut(devToolsKeyboardShortcut, () => {
        enableDevTools((currentIsEnabledStatus) => {
            const newIsEnabledStatus = !currentIsEnabledStatus;
            if (newIsEnabledStatus) {
                changeFavicon();
            } else {
                setFaviconHref(originalIconHref);
            }
            Logger.log(`Dev tools are ${newIsEnabledStatus ? 'ENABLED' : 'DISABLED'}.`);
            return newIsEnabledStatus;
        });
    });
    return <DevToolsContext.Provider value={{ devToolsEnabled }}>{children}</DevToolsContext.Provider>;
};

export const DevToolsProvider = ({ children }) => {
    if (isFeatureEnabled(Feature.DevTools)) {
        return <RealDevToolsProvider>{children}</RealDevToolsProvider>;
    } else {
        return children;
    }
};

/**
 * This hook returns a flag `devToolsEnabled` which can be switched on and off using a keyboard shortcut.
 */
export const useDevTools = () => {
    return useContext<DevToolsContextType>(DevToolsContext).devToolsEnabled;
};

export const DevSection: React.FC<{ children: React.ReactElement }> = ({ children }) => {
    const devToolsEnabled = useDevTools();
    return devToolsEnabled ? children : null;
};

export const DevNote: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <DevSection>
            <Typography variant={'dev'} flexGrow={1}>
                {children}
            </Typography>
        </DevSection>
    );
};
