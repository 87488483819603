import React, { useState, useEffect } from 'react';
import RubricDrawer from 'Components/Rubrics/RubricDrawer';
import RubricScores from 'Components/Rubrics/RubricScores';
import QuestionsTable from 'Components/Rubrics/QuestionScores/QuestionsTable';
import { Grid, Backdrop, CircularProgress } from '@mui/material';
import { Button } from 'Controls/Button';
import { rubricApi } from 'Services/TrustwellApiService';
import { DEFAULT_RUBRIC, POLICY_RUBRIC, PLAN_RUBRIC, EXECUTION_RUBRIC } from 'Components/Rubrics/config';
import { useMessageBox } from 'Controls/MessageBox';

const RubricsPage = ({ assessment }) => {
    const messageBox = useMessageBox();
    const [pageLoading, setPageLoading] = useState(false);
    const [rubricsLoaded, setRubricsLoaded] = useState(false);
    const [rubricData, setRubricData] = useState({});
    const [updateCompleteness, setUpdateCompleteness] = useState(false);
    const [currentRubricId, setCurrentRubricId] = useState(0);
    const [currentRubricType, setCurrentRubricType] = useState(DEFAULT_RUBRIC);
    const [rubricComments, setRubricComments] = useState([]);

    const changeCompleteness = (value) => {
        setUpdateCompleteness(value);
    };

    const getCompletionStatus = (completeRubrics, rubricId, rubricType) => {
        if (!completeRubrics || !rubricId || !rubricType) return;
        const selectedRubric = completeRubrics.filter((rubric) => rubric.rubricId === rubricId);
        if (selectedRubric.length > 0) {
            if (rubricType === POLICY_RUBRIC) {
                return selectedRubric[0].policyRubricComplete;
            } else if (rubricType === PLAN_RUBRIC) {
                return selectedRubric[0].planRubricComplete;
            } else if (rubricType === EXECUTION_RUBRIC) {
                return selectedRubric[0].execRubricComplete;
            }
        }
        // Update completeness object for current rubric and rubric type

        return false;
    };

    const fetchRubricData = async () => {
        try {
            if (assessment) {
                setPageLoading(true);
                const api = await rubricApi();
                const _rubricData = await api.getAllRubricTabData(assessment.id, assessment.versionId);
                setRubricData(_rubricData);
                setCurrentRubricId(_rubricData.rubrics[0].rubricId);
                setRubricsLoaded(true);
                setRubricComments(_rubricData.rubricComments);
                changeCompleteness(true);
                setPageLoading(false);
                return _rubricData;
            }
        } catch (error) {
            messageBox.error('Error fetching the rubric data. ' + error);
        }
    };

    useEffect(() => {
        fetchRubricData();
    }, [assessment]);

    const changeRubric = (rubricId) => {
        setCurrentRubricId(rubricId);
    };

    const changeRubricType = (rubricType) => {
        setCurrentRubricType(rubricType);
    };

    const changeCompletionStatus = async (currentRubricId, completedRubrics, rubricType, isMarkedComplete) => {
        const selectedRubric = completedRubrics.find((rubric) => rubric.rubricId === currentRubricId);
        // format completion object with prior completion status
        const rubricCompletionObj = {
            assessmentId: assessment.id,
            execRubricComplete: selectedRubric?.execRubricComplete ?? false,
            planRubricComplete: selectedRubric?.planRubricComplete ?? false,
            policyRubricComplete: selectedRubric?.policyRubricComplete ?? false,
            rubricComplete: false,
            rubricId: currentRubricId,
            rubricVersionId: assessment.versionId,
        };
        // update completion status for current rubric type that was marked complete/incomplete
        if (rubricType === POLICY_RUBRIC) {
            rubricCompletionObj.policyRubricComplete = !rubricCompletionObj.policyRubricComplete;
        } else if (rubricType === PLAN_RUBRIC) {
            rubricCompletionObj.planRubricComplete = !rubricCompletionObj.planRubricComplete;
        } else if (rubricType === EXECUTION_RUBRIC) {
            rubricCompletionObj.execRubricComplete = !rubricCompletionObj.execRubricComplete;
        }
        try {
            const api = await rubricApi();
            const completed = await api.updateRubricComplete(rubricCompletionObj);

            setRubricData((rubrics) => {
                const index = rubricData.rubricCompletes.findIndex((r) => r.rubricId === completed.rubricId);
                index > -1 ? (rubrics.rubricCompletes[index] = completed) : rubrics.rubricCompletes.push(completed);
                return {
                    ...rubrics,
                };
            });
        } catch (error) {
            messageBox.error('Error updating the rubric complete status. ' + error);
        }
    };

    const isMarkedComplete = getCompletionStatus(rubricData.rubricCompletes, currentRubricId, currentRubricType);
    return (
        <div style={{ paddingBottom: '75px', marginTop: '10px' }}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={pageLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={1} justify="space-around">
                <Grid item xs={3}>
                    <RubricDrawer
                        rubricData={rubricData}
                        changeRubric={changeRubric}
                        changeRubricType={changeRubricType}
                        currentRubricType={currentRubricType}
                    />
                </Grid>
                <Grid item xs={9}>
                    <RubricScores assessment={assessment} rubricId={currentRubricId} rubricType={currentRubricType} wellGroups={rubricData.wellGroups} />
                </Grid>
                <Grid item xs={3}>
                    {/* <RubricComments
                        assessment_id={assessment?.id}
                        rubricId={currentRubricId}
                        version_id={assessment?.versionId}
                        rubricComments={rubricComments}
                        updateComments={(updatedComments) => null}
                    /> */}
                </Grid>
            </Grid>
            <Grid container spacing={8}>
                <Grid item xs={12}>
                    <QuestionsTable
                        rubricPageData={rubricData}
                        assessment={assessment}
                        rubricType={currentRubricType}
                        rubricId={currentRubricId}
                        rubricQuestions={rubricData.rubricQuestions}
                    />
                </Grid>
            </Grid>
            <Grid>
                {rubricsLoaded && (
                    <Button
                        variant="primary"
                        onClick={() => changeCompletionStatus(currentRubricId, rubricData.rubricCompletes, currentRubricType, isMarkedComplete)}
                    >
                        {isMarkedComplete ? 'Mark Rubric as Incomplete' : 'Mark Rubric as Complete'}
                    </Button>
                )}
            </Grid>
        </div>
    );
};

export default RubricsPage;
