import React, { FC, ReactNode } from 'react';
import { QCellSyncStatus } from './QContext';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { QuestionnairesQuestionnaireQuestion as Question } from '@projectcanary/trustwell-server-client-ts';
import { DevSection, useDevTools } from 'Controls/useDevTools';
import DescriptionIcon from '@mui/icons-material/Description';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { useQCell } from './QCell';

const syncStatusIcons: Record<QCellSyncStatus, string> = {
    sending: '📤',
    fresh: '✅',
    stale: '🕒',
    failedToSend: '❌',
    fetching: '📥',
    failedToFetch: '🚫',
};

export const fadeOnUnhoverClassName = 'fadeOnUnhover';

const cellStyle = {
    ['&:not(:hover) .' + fadeOnUnhoverClassName]: {
        opacity: 0.25,
        transition: 'opacity 200ms',
    },
    ['&:hover .' + fadeOnUnhoverClassName]: {
        opacity: 1,
        transition: 'opacity 200ms',
    },
};

export type CellBaseProps = { assetId: number; question: Question; enableCellAnnotation: boolean; enableCopyToAll?: boolean } & { children: ReactNode };

export const QMatrixCellBase: FC<CellBaseProps> = ({ assetId, question, enableCellAnnotation, children, enableCopyToAll }) => {
    const { questionnaireQuestionId, canBeExplicitlyOmitted, canBeNA } = question;
    const { isNA, isOmitted, setIsNA, setIsOmitted, syncStatus, editAnnotation, isAnnotated, copyToAll } = useQCell(assetId, questionnaireQuestionId);
    const devToolsEnabled = useDevTools();

    return (
        <Stack direction={'row'} alignItems={'center'} spacing={0.5} flexGrow={1} sx={cellStyle}>
            <DevSection>
                <Box sx={{ flexGrow: 0 }}>{syncStatusIcons[syncStatus]}</Box>
            </DevSection>

            <Box sx={{ flexGrow: 1 }}>{children}</Box>

            {canBeNA && (
                <Tooltip title="Not Applicable" sx={{ flexGrow: 0 }} className={!isNA ? fadeOnUnhoverClassName : ''}>
                    <IconButton onClick={() => setIsNA(!isNA)}>
                        <RemoveCircleOutlineIcon color={isNA ? 'warning' : 'disabled'} />
                    </IconButton>
                </Tooltip>
            )}

            {canBeExplicitlyOmitted && (
                <Tooltip title="Not provided by operator" sx={{ flexGrow: 0 }}>
                    <IconButton onClick={() => setIsOmitted(!isOmitted)} className={!isOmitted ? fadeOnUnhoverClassName : ''}>
                        <RemoveCircleOutlineIcon color={isOmitted ? 'warning' : 'disabled'} />
                    </IconButton>
                </Tooltip>
            )}

            {enableCellAnnotation && (
                <IconButton onClick={() => editAnnotation()} className={!isAnnotated ? fadeOnUnhoverClassName : ''}>
                    <DescriptionIcon color={isAnnotated ? 'inherit' : 'disabled'} />
                </IconButton>
            )}

            {enableCopyToAll && (
                <IconButton onClick={(e) => copyToAll(devToolsEnabled && e.ctrlKey)} className={fadeOnUnhoverClassName}>
                    <CopyAllIcon />
                </IconButton>
            )}
        </Stack>
    );
};
