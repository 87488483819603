import { AssessmentsLmva as Lmva } from '@projectcanary/trustwell-server-client-ts';
import { formatDateOnly, personNameColumn } from 'Utils/formats';
import { GridColumns } from '@mui/x-data-grid/models/colDef/gridColDef';

export const columns: GridColumns<Lmva> = [
    {
        headerName: 'id',
        field: 'id',
        type: 'number',
        width: 60,
    },
    {
        headerName: 'LMVA Name',
        field: 'name',
        width: 300,
    },
    {
        headerName: 'Vintage',
        type: 'number',
        field: 'vintage',
        valueFormatter: ({ value }) => String(value),
        width: 70,
    },
    {
        headerName: 'Methane Intensity (%)',
        type: 'number',
        field: 'methaneIntensity',
        valueFormatter: ({ value }) => value.toFixed(4),
        width: 160,
    },
    { headerName: 'Segment', field: 'segment' },
    { headerName: 'Protocol (Emissions)', field: 'emissionProtocol', width: 150 },
    { headerName: 'Protocol (Intensity)', field: 'methaneIntensityProtocol', width: 150 },
    {
        headerName: 'Meets LMVA Requirements',
        field: 'meetsLmvaRequirements',
        align: 'right',
        type: 'string',
        valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
        width: 200,
    },
    {
        headerName: 'Granularity',
        field: 'granularity',
    },
    {
        headerName: 'Production MSCF',
        field: 'production',
    },
    {
        headerName: 'Benchmark',
        field: 'benchmark',
    },
    {
        headerName: 'Date Created',
        field: 'creationDateTime',
        type: 'date',
        width: 130,
        align: 'right',
        valueFormatter: ({ value }) => formatDateOnly(value),
    },
    {
        headerName: 'Created By',
        field: 'creator',
        width: 200,
        type: 'string',
        ...personNameColumn,
    },
];
