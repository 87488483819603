import React, { useState, useEffect } from 'react';
import { Checkbox, Grid } from '@mui/material';
import { eventApi } from 'Services/TrustwellApiService';
import { EventsAssessmentEventActive } from '@projectcanary/trustwell-server-client-ts';
import PageTitle from 'Controls/PageTitle';
import { useMessageBox } from 'Controls/MessageBox';
import { AssessmentsAssessmentDetails } from '@projectcanary/trustwell-server-client-ts';
import { DataGridPro } from '@mui/x-data-grid-pro';

type EventsProps = {
    assessment: AssessmentsAssessmentDetails;
};

const Events = ({ assessment }: EventsProps) => {
    const messageBox = useMessageBox();

    const [events, setEvents] = useState<EventsAssessmentEventActive[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const getEventData = async () => {
        setLoading(true);
        try {
            if (assessment?.id && assessment?.versionId) {
                const _eventApi = await eventApi();
                const _events = await _eventApi.getAllEventsByAssessmentDefId(assessment.versionId);
                const _assessmentEventsActive = await _eventApi.getAllActiveEventsByAssessmentId(assessment.id, assessment.versionId);

                const eventsWithActive = _events.map((event) => {
                    const isActive = _assessmentEventsActive.find((eventActive) => eventActive.eventId === event.eventId)?.active ?? true;
                    return {
                        ...event,
                        isActive,
                        assessmentId: assessment.id,
                    };
                });
                setEvents(eventsWithActive);
            }
        } catch (error) {
            messageBox.error('Error fetching the event data. ' + error);
        }

        setLoading(false);
    };

    useEffect(() => {
        getEventData();
    }, [assessment?.id]);

    const onCheckboxChange = async (event) => {
        try {
            const _eventApi = await eventApi();
            const eventToUpdate = { ...event, active: !event['isActive'] };
            await _eventApi.updateAssessmentEvent(eventToUpdate);
            messageBox.success(`Successfully updated ${event.name}.`);
            getEventData();
        } catch (error) {
            messageBox.error(`Failed to update active status of ${event.name}. ` + error);
        }
    };

    const columns = [
        {
            headerName: 'Name',
            field: 'name',
            type: 'string',
            width: 250,
        },
        {
            headerName: 'Active',
            field: 'isActive',
            width: 250,
            renderCell: (params) => {
                return <Checkbox checked={params.row?.isActive} onChange={() => onCheckboxChange(params.row)} />;
            },
        },
    ];

    return (
        <>
            <PageTitle title={'Events'} />
            <Grid container spacing={2}>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <DataGridPro loading={loading} rows={events} columns={columns} getRowId={(row) => row.eventId} />
                </Grid>
            </Grid>
        </>
    );
};

export default Events;
