import React, { ReactElement } from 'react';
import { TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { assessmentApi, certificationApi, questionnaireApi } from 'Services/TrustwellApiService';
import _ from 'lodash';
import {
    EntitiesAssetType,
    LmrLmrWellKnownQuestions,
    ModulesModuleType,
    QuestionnairesAssetResponses,
    QuestionnairesAssetResponsesResponse as QuestionResponse,
} from '@projectcanary/trustwell-server-client-ts';
import Table from '@mui/material/Table';
import { withInitializers } from 'Utils/withInitializers';

type BasinData = { methaneIntensity: QuestionResponse; carbonIntensity: QuestionResponse; vintage: QuestionResponse };

function getQuestionResponse(assetsResponses: QuestionnairesAssetResponses[], cid: LmrLmrWellKnownQuestions): QuestionResponse {
    // Note: there is only one basin or company for now, so we pick the first asset.
    const assetResponses = assetsResponses[0]?.responses;
    const response: QuestionResponse = _.find<QuestionResponse>(assetResponses, {
        cid: cid as unknown as string,
    });
    return response;
}

function formatNumericResponse(response: QuestionResponse): number | 'N/A' | 'N/I' | 'unresponded' {
    if (response === undefined) {
        return 'unresponded';
    } else if (response.isNA) {
        return 'N/A';
    } else if (response.isExplicitlyOmitted) {
        return 'N/I';
    } else {
        return Number(response.answer);
    }
}

export type BasinReviewDetailsViewProps = { assessmentId: number; basinData: BasinData };

export const InnerBasinReviewDetailsView = ({ basinData }: BasinReviewDetailsViewProps): ReactElement => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width={2}>
                        <Typography variant="subtitle2">Parameter</Typography>
                    </TableCell>
                    <TableCell width={1}>
                        <Typography variant="subtitle2">Value</Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Vintage</TableCell>
                    <TableCell>{formatNumericResponse(basinData.vintage)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Basin Methane Intensity (%)</TableCell>
                    <TableCell>{formatNumericResponse(basinData.methaneIntensity)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Basin Carbon Intensity (MT CO₂e/mboe)</TableCell>
                    <TableCell>{formatNumericResponse(basinData.carbonIntensity)}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export const BasinEmissionsReviewDetailsView = withInitializers<BasinReviewDetailsViewProps, 'basinData'>(
    {
        async basinData({ assessmentId }) {
            const assessment = await (await assessmentApi()).getAssessmentById(assessmentId);

            if (assessment.activeModules.includes(ModulesModuleType.Lmva)) {
                const inProgressCertification = await (await certificationApi()).getInProgressCertification(assessmentId);
                const lmva = await (await assessmentApi()).getLmva(inProgressCertification.inProgressCertification.lmvaId);

                return {
                    vintage: {
                        cid: '',
                        answer: lmva.vintage,
                        isNA: false,
                        isExplicitlyOmitted: false,
                    },
                    methaneIntensity: {
                        cid: '',
                        answer: lmva.methaneIntensity,
                        isNA: false,
                        isExplicitlyOmitted: false,
                    },
                    carbonIntensity: {
                        cid: '',
                        answer: undefined,
                        isNA: true,
                        isExplicitlyOmitted: false,
                    },
                } as BasinData;
            } else {
                const questionnaireApiInstance = await questionnaireApi();
                const [{ assetsResponses: companyResponses }, { assetsResponses: basinResponses }] = await Promise.all([
                    questionnaireApiInstance.getResponses({
                        assessmentId: assessmentId,
                        moduleType: ModulesModuleType.Lmr,
                        questionCids: [LmrLmrWellKnownQuestions.LMRVY],
                        assetType: EntitiesAssetType.Company,
                    }),
                    questionnaireApiInstance.getResponses({
                        assessmentId: assessmentId,
                        moduleType: ModulesModuleType.Lmr,
                        questionCids: [LmrLmrWellKnownQuestions.LMRCH4I, LmrLmrWellKnownQuestions.LMRCO2I],
                        assetType: EntitiesAssetType.Basin,
                    }),
                ]);

                return {
                    vintage: getQuestionResponse(companyResponses, LmrLmrWellKnownQuestions.LMRVY),
                    methaneIntensity: getQuestionResponse(basinResponses, LmrLmrWellKnownQuestions.LMRCH4I),
                    carbonIntensity: getQuestionResponse(basinResponses, LmrLmrWellKnownQuestions.LMRCO2I),
                };
            }
        },
    },
    InnerBasinReviewDetailsView,
    'component'
);
