import { useField, useFormikContext } from 'formik';
import { buildTestId } from 'Utils/testid';
import { Checkbox as MuiCheckbox, FormControl, FormControlLabel, FormHelperText, Stack } from '@mui/material';
import React from 'react';
import { QuestionnairesChoiceAnswerConstraintsOption as ChoiceOption } from '@projectcanary/trustwell-server-client-ts';
import _ from 'lodash';

export type MultiCheckboxFieldProps = { name: string; label: string; disabled?: boolean; options: ChoiceOption[] };

export function MultiCheckboxField<T>({ name, label, disabled, options }: MultiCheckboxFieldProps) {
    const [field, meta, helpers] = useField<T[]>(name);
    const { isValidating } = useFormikContext();
    const testId = buildTestId({ checkbox: name });

    const setNewValue = (value: T[]) => {
        // wrapping into _.defer as a workaround for a formik bug https://github.com/jaredpalmer/formik/issues/2457
        _.defer(() => helpers.setValue(value.length === 0 ? undefined : value));
    };

    return (
        <FormControl error={!isValidating && meta.touched && !!meta.error}>
            <Stack direction={'row'} justifyContent={'flex-start'}>
                {options.map((option) => (
                    <FormControlLabel
                        key={option.label}
                        control={
                            <MuiCheckbox
                                data-testid={testId}
                                title={'title'}
                                name={name}
                                checked={field.value?.includes(option.value) ?? false}
                                value={option.value}
                                disabled={disabled}
                                onChange={({ target }) => {
                                    if (target.checked) {
                                        if (!field.value?.includes(option.value)) {
                                            setNewValue([...(field.value ?? []), option.value]);
                                        }
                                    } else {
                                        if (field.value?.includes(option.value)) {
                                            setNewValue(field.value.filter((v) => v !== option.value));
                                        }
                                    }
                                }}
                                onBlur={() => helpers.setTouched(field.checked)}
                            />
                        }
                        label={option.label}
                    />
                ))}
            </Stack>
            <FormHelperText>{!isValidating && meta.touched && meta.error}</FormHelperText>
        </FormControl>
    );
}
