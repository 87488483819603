import React from 'react';
import PageTitle from 'Controls/PageTitle';
import { Stack } from '@mui/material';
import { Backdrop } from 'Controls/Backdrop';

export const Page = (params: { title: string; children: React.ReactNode; isLoading?: boolean; spacing?: number }) => {
    return (
        <Stack flexGrow={1}>
            <Backdrop isLoading={params.isLoading ?? false} />
            <PageTitle title={params.title} />
            <Stack flexGrow={1} spacing={params.spacing ?? 2}>
                {params.children}
            </Stack>
        </Stack>
    );
};
