import { Card, Checkbox, FormControl, FormHelperText, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import React, { useEffect } from 'react';
import { RulesValidationIssue as ValidationIssue } from '@projectcanary/trustwell-server-client-ts';
import { useModal } from 'Controls/Modal';
import { useField, useFormikContext } from 'formik';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';

export type Pad = { padName: string; wellGroupId: number; padAssetId: number; issues: ValidationIssue[] };

type WellGroupListProps = { name: string; pads: Pad[]; label: string };

export const WellGroupList = ({ label, name, pads }: WellGroupListProps) => {
    const modal = useModal();
    const [field, meta, helpers] = useField(name);
    const { isValidating } = useFormikContext();

    useEffect(() => {
        helpers.setTouched(false);
        helpers.setValue([]);
    }, [pads]);

    const openViewErrorsModal = (wellGroupName: string, errors: ValidationIssue[]) => {
        return modal.show(
            { title: `"${wellGroupName}" has ${errors.length} Error(s)`, buttons: ['Close'] },
            <ReactMarkdown>
                {errors
                    .map((e: ValidationIssue) => {
                        return e.explanation;
                    })
                    .join('\n\n***\n')}
            </ReactMarkdown>
        );
    };

    const handleToggle = (wellGroupId: number) => () => {
        const removedIds = _.remove(field.value, (id) => id === wellGroupId);
        if (removedIds.length === 0) {
            field.value.push(wellGroupId);
        }
        helpers.setValue(field.value);
        helpers.setTouched(true);
    };

    return (
        <FormControl error={!isValidating && meta.touched && !!meta.error} fullWidth={true}>
            <Typography variant="h6" sx={{ mb: 1 }}>
                {label}
            </Typography>
            <Card sx={{ height: '400px', overflowY: 'scroll' }}>
                <List
                    sx={{
                        width: '100%',
                        maxWidth: 360,
                        bgcolor: 'background.paper',
                    }}
                >
                    {pads &&
                        pads.map((pad) => (
                            <ListItem key={pad.wellGroupId} disablePadding>
                                {pad.issues.length ? (
                                    <ListItemButton role={undefined} onClick={() => openViewErrorsModal(pad.padName, pad.issues)} dense>
                                        <Tooltip title={`${pad.issues.length} error(s), please click to view`}>
                                            <ListItemIcon>
                                                <WarningIcon color="error" />
                                            </ListItemIcon>
                                        </Tooltip>
                                        <ListItemText id={pad.wellGroupId.toString()} primary={pad.padName} />
                                    </ListItemButton>
                                ) : (
                                    <ListItemButton role={undefined} onClick={handleToggle(pad.wellGroupId)} dense>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={!!field.value.includes(pad.wellGroupId)}
                                                tabIndex={-1}
                                                inputProps={{ 'aria-labelledby': pad.wellGroupId.toString() }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={pad.wellGroupId.toString()} primary={pad.padName} />
                                    </ListItemButton>
                                )}
                            </ListItem>
                        ))}
                </List>
            </Card>
            <FormHelperText>{!isValidating && meta.touched && meta.error}</FormHelperText>
        </FormControl>
    );
};
