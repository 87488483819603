import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import CreateCompanyForm from 'Components/UpsertForm';
import { Button } from 'Controls/Button';
import { useModal } from 'Controls/Modal';
import { CompaniesCompany } from '@projectcanary/trustwell-server-client-ts';
import { companyApi } from 'Services/TrustwellApiService';
import * as Yup from 'Utils/yup';
import OverflowContainer from 'Components/OverflowContainer';
import { useMessageBox } from 'Controls/MessageBox';
import { DataGridPro } from '@mui/x-data-grid-pro';

export type PageProps = {
    title: string;
};

export default function CompaniesListPage(props: PageProps) {
    const messageBox = useMessageBox();
    const [companies, setCompanies] = useState<CompaniesCompany[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const modal = useModal();
    const min = 2;
    const max = 256;

    const CreateCompanySchema = Yup.object().shape({
        companyName: Yup.string()
            .min(min, `Company name must be at least ${min} characters long`)
            .max(max, `Company name can not be longer than ${max} characters`)
            .required('Required')
            .test('companyDuplicateCheck', 'A company with this name already exists.', async function (value) {
                const api = await companyApi();
                const trimmedValue = value?.trim();
                if (trimmedValue?.length >= min && trimmedValue?.length <= max) {
                    try {
                        const isDuplicateExists = await api.companyExists(value);
                        return !isDuplicateExists;
                    } catch (error) {
                        messageBox.error('Error checking if company exists. ' + error);
                    }
                }
            }),
    });

    let fields = [
        {
            id: 'companyId',
            name: 'companyId',
            type: 'number',
            formikRef: 'companyId',
            label: 'Company Id',
            placeholder: '',
            hidden: true,
            hasValidation: false,
        },
        {
            id: 'companyName',
            name: 'companyName',
            type: 'string',
            formikRef: 'companyName',
            label: 'Company Name',
            placeholder: 'The Company',
            hidden: false,
            hasValidation: true,
        },
    ];

    function createClickHandler(company?) {
        modal
            .form(
                {
                    title: company?.companyId ? 'Edit Company' : 'Create Company',
                    buttons: company?.companyId ? ['Edit Company', 'Cancel'] : ['Create Company', 'Cancel'],
                },
                <CreateCompanyForm<CompaniesCompany>
                    editValue={company}
                    validationSchema={CreateCompanySchema}
                    createEmptyStructure={{ companyId: null, companyName: '' }}
                    fields={fields}
                />
            )
            .onSubmit(async function (company): Promise<void> {
                try {
                    const api = await companyApi();
                    if (company.companyId) {
                        await api.updateCompany({ payload: company });
                    } else {
                        await api.createCompany({ payload: company });
                    }
                    getCompanies();
                } catch (ex) {
                    throw JSON.stringify(await ex.text());
                }
            });
    }

    async function getCompanies() {
        setLoading(true);
        try {
            const api = await companyApi();
            const _companies = await api.getAllCompanies();
            setCompanies(_companies.companies);
        } catch (error) {
            messageBox.error('Error fetching the companies. ' + error);
        }

        setLoading(false);
    }

    useEffect(() => {
        getCompanies();
    }, []);

    let columns = [
        { headerName: 'Company Name', field: 'companyName', width: 250 },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <>
                    <Button variant="primary" onClick={() => createClickHandler(params.row)}>
                        Edit
                    </Button>
                </>
            ),
        },
    ];

    return (
        <OverflowContainer>
            <Grid container spacing={2} padding={2}>
                <Grid item xs={12}>
                    <h3>{props.title}</h3>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="primary" onClick={() => createClickHandler()}>
                        Create Company
                    </Button>
                </Grid>
                <Grid item xs={11}>
                    <DataGridPro
                        rows={companies}
                        columns={columns}
                        loading={loading}
                        getRowId={(row) => row.companyId}
                        rowsPerPageOptions={[25, 50, 100]}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'companyName', sort: 'asc' }],
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </OverflowContainer>
    );
}
