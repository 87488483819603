import React from 'react';
import { Box, Typography } from '@mui/material';

type PageHeaderProps = {
    title: string;
};

const PageHeader = ({ title }: PageHeaderProps) => (
    <Box sx={{ pl: 2.5, pt: 2, pb: 2 }}>
        <Typography fontSize={30}>{title}</Typography>
    </Box>
);

export default PageHeader;
