import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { nowait } from 'Utils/nowait';
import { assessmentApi, certificationApi, scoreApi } from 'Services/TrustwellApiService';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LmrLmrRating, ModulesModuleType, OprRatingMedal as OprRatingMedal } from '@projectcanary/trustwell-server-client-ts';
import { Box } from '@mui/material';
import { IssueRatingsSummaryData, IssueRatingsSummaryDetailsView } from './IssueRatingsSummaryDetailsView';
import { buildColumns } from './FinalReviewDetailsViewColumns';

export const FinalReviewDetailsView = (props: { assessmentId: number }) => {
    const [wellGroupScores, setWellGroupScores] = useState<any[]>();
    const [activeModules, setActiveModules] = useState<ModulesModuleType[]>([]);
    const [issueRatingsSummaryData, setDeliverableData] = useState<IssueRatingsSummaryData>();

    useEffect(() => {
        setWellGroupScores(undefined);
        nowait(
            (async () => {
                const inProgressCertification = (await (await certificationApi()).getInProgressCertification(props.assessmentId)).inProgressCertification;

                const scoreApiInstance = await scoreApi();

                const allWellScores = await scoreApiInstance.getAllEventWellScoresByAssessment(props.assessmentId);
                const wellScores = _.chain(allWellScores)
                    .filter((score) => inProgressCertification.wellGroups.map((group) => group.wellGroupId).includes(score.wellGroupId))
                    .groupBy('padAssetId')
                    .value();

                const assessmentApiInstance = await assessmentApi();
                const assessment = await assessmentApiInstance.getAssessmentById(props.assessmentId);
                const activeModules = assessment.activeModules;
                setActiveModules(activeModules);

                const totalOprMedalCounts: { [medalRating in OprRatingMedal]: number } = {
                    [OprRatingMedal[OprRatingMedal.Evaluated]]: 0,
                    [OprRatingMedal[OprRatingMedal.Silver]]: 0,
                    [OprRatingMedal[OprRatingMedal.Gold]]: 0,
                    [OprRatingMedal[OprRatingMedal.Platinum]]: 0,
                };

                const wellGroupScores = _.map(wellScores, (wellScores, padAssetId) => {
                    const oprMedalCounts: { [medalRating in OprRatingMedal]: number } = {
                        [OprRatingMedal[OprRatingMedal.Evaluated]]: 0,
                        [OprRatingMedal[OprRatingMedal.Silver]]: 0,
                        [OprRatingMedal[OprRatingMedal.Gold]]: 0,
                        [OprRatingMedal[OprRatingMedal.Platinum]]: 0,
                    };

                    _.forEach(wellScores, (wellScore) => {
                        if (wellScore.ratingMedal !== null) {
                            oprMedalCounts[wellScore.ratingMedal] += 1;
                            totalOprMedalCounts[wellScore.ratingMedal] += 1;
                        }
                    });

                    const minScore = _.minBy(wellScores, 'performanceScore')?.performanceScore;
                    const maxScore = _.maxBy(wellScores, 'performanceScore')?.performanceScore;
                    const avgScore = minScore !== undefined ? _.meanBy(wellScores, 'performanceScore') : undefined;
                    const wellGroup = wellScores[0];
                    const wellGroupName = wellGroup.wellGroupName;

                    return {
                        padAssetId: padAssetId,
                        wellGroupName: wellGroupName,
                        medalCounts: oprMedalCounts,
                        wellCount: wellScores.length,
                        minScore: minScore,
                        maxScore: maxScore,
                        scoreRange: maxScore !== undefined && minScore !== undefined ? maxScore - minScore : undefined,
                        avgScore: avgScore,
                    };
                });

                const lmrRatingCounts: { [lmrRating in LmrLmrRating]: number } = {
                    [LmrLmrRating[LmrLmrRating.Mr]]: 0,
                    [LmrLmrRating[LmrLmrRating.MrQ]]: 0,
                    [LmrLmrRating[LmrLmrRating.A]]: 0,
                    [LmrLmrRating[LmrLmrRating.AQ]]: 0,
                    [LmrLmrRating[LmrLmrRating.Aa]]: 0,
                    [LmrLmrRating[LmrLmrRating.AaQ]]: 0,
                    [LmrLmrRating[LmrLmrRating.Aaa]]: 0,
                    [LmrLmrRating[LmrLmrRating.AaaQ]]: 0,
                };

                if (activeModules.includes(ModulesModuleType.Lmr)) {
                    const scoreApiInstance = await scoreApi();
                    const lmrEvaluation = await scoreApiInstance.getLmrPadEvaluationResults(props.assessmentId);
                    const lmrs = _.chain(lmrEvaluation)
                        .filter((lmr) => inProgressCertification.wellGroups.map((group) => group.padAssetId).includes(lmr.padId))
                        .map((lmr) => ({ ...lmr, lmrRating: lmr.lmrRating ?? undefined, score: lmr.score ?? undefined }))
                        .value();

                    _.forEach(lmrs, (lmr) => {
                        if (lmr.lmrRating !== null) {
                            lmrRatingCounts[lmr.lmrRating] += 1;
                        }
                    });

                    const mergedWellGroupScores = wellGroupScores.map((wellGroupScore) => {
                        const found = lmrs.find((lmr) => lmr.padId.toString() == wellGroupScore.padAssetId);
                        return {
                            ...wellGroupScore,
                            lmrRating: found.lmrRating,
                            lmrScore: found.score,
                            methaneIntensity: found.methaneIntensity,
                        };
                    });

                    setWellGroupScores(mergedWellGroupScores);
                } else {
                    setWellGroupScores(wellGroupScores);
                }

                setDeliverableData({
                    totalWellGroups: wellGroupScores.length,
                    totalWells: _.sumBy(wellGroupScores, 'wellCount'),
                    oprMedals: totalOprMedalCounts,
                    lmrRatings: lmrRatingCounts,
                    isLmrDisplayed: activeModules.includes(ModulesModuleType.Lmr),
                });
            })()
        );
    }, [props.assessmentId]);

    if (wellGroupScores === undefined) {
        return <Box>Loading...</Box>;
    }

    return (
        <>
            <IssueRatingsSummaryDetailsView issueRatingsSummaryData={issueRatingsSummaryData} />
            <DataGridPro
                getRowId={(row) => row.padAssetId}
                columns={buildColumns(activeModules)}
                rows={wellGroupScores}
                autoHeight={false}
                autoPageSize={true}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            padAssetId: false,
                            lmrScore: activeModules.includes(ModulesModuleType.Lmr),
                            lmrRating: activeModules.includes(ModulesModuleType.Lmr),
                            methaneIntensity: activeModules.includes(ModulesModuleType.Lmr),
                        },
                    },
                }}
            />
        </>
    );
};
