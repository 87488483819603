import {
    AssessmentsAssessment,
    CertificationsInProgressCertification as Issuance,
    ReportGenerationReportFormat as ReportFormat,
} from '@projectcanary/trustwell-server-client-ts';
import { Button } from 'Controls/Button';
import { useMessageBox } from 'Controls/MessageBox';
import { assessmentApi, certificationApi } from 'Services/TrustwellApiService';
import { downloadFile } from 'Utils/downloadFile';
import { buildTestId } from 'Utils/testid';
import { useMemo } from 'react';
import { withInitializers } from 'Utils/withInitializers';
import Stack from '@mui/material/Stack';
import _ from 'lodash';
import DownloadIcon from '@mui/icons-material/Download';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { buildColumns } from './PreliminaryReportDownloadsStepViewColumns';
import { ReportDownloader, reportTypes } from './PreliminaryReportDownloadsStepViewReportTypes';

export const InnerPreliminaryReportDownloadsStepView = ({ assessment, issuance }: PreliminaryReportDownloadsStepViewProps) => {
    const testId = buildTestId({ page: 'preliminary' });
    const messageBox = useMessageBox();

    async function downloadReport(
        issuance: Issuance,
        wellGroupId: number | undefined,
        downloader: ReportDownloader,
        assessment: AssessmentsAssessment,
        reportName: string,
        reportFormat: ReportFormat
    ) {
        try {
            const assessmentApiInstance = await assessmentApi();
            document.body.style.cursor = 'wait !important';
            const reportResponse: Response = await downloader(assessmentApiInstance).call(assessmentApiInstance, {
                isPreliminary: true,
                assessmentId: assessment.id,
                // TODO: producer name and report date shouldn't need to be passed in here, this information
                // is available server-side.
                reportDate: issuance.reportDateTime,
                producerName: issuance.producerName,
                wellGroupId: wellGroupId,
                reportFormat: reportFormat,
            });
            document.body.style.cursor = undefined;
            const defaultFilename = `${issuance.producerName.trim()} - ${assessment.name} ${reportName}.pptx`;
            await downloadFile(reportResponse, defaultFilename);
            messageBox.success(`Report ${reportName} download has been started. Check your browser's "Downloads" list.`);
        } catch (e) {
            messageBox.error(`Failed to download ${reportName} for assessment '${assessment.name}'. ${e}`);
        }
    }

    const columns: GridColDef[] = useMemo(() => {
        return buildColumns(assessment, issuance, downloadReport, reportTypes);
    }, [assessment, issuance]);

    const rows = useMemo(
        () =>
            _.map(issuance.wellGroups, (pad) => ({
                wellGroupId: pad.wellGroupId,
                padName: pad.wellGroupName,
            })),
        [issuance.wellGroups]
    );

    return (
        <Stack direction={'column'} spacing={3}>
            <Stack direction={'row'} justifyItems={'flex-start'} spacing={2}>
                {_.chain(reportTypes)
                    .filter((rt) => !rt.isPadLevelReport && assessment.activeModules.includes(rt.module))
                    .map((rt) => (
                        <Button
                            key={rt.reportName}
                            variant={'secondary'}
                            onClick={() => downloadReport(issuance, undefined, rt.downloader, assessment, rt.reportName, rt.reportFormat)}
                            data-testid={buildTestId(testId, { button: rt.reportName })}
                            startIcon={<DownloadIcon />}
                        >
                            {rt.reportName}
                        </Button>
                    ))
                    .value()}
            </Stack>
            <DataGridPro columns={columns} rows={rows} getRowId={(row) => row.wellGroupId} />
        </Stack>
    );
};

export type PreliminaryReportDownloadsStepViewProps = {
    assessmentId: number;
    assessment: AssessmentsAssessment;
    issuance: Issuance;
};

export const PreliminaryReportDownloadsStepView = withInitializers<PreliminaryReportDownloadsStepViewProps, 'assessment' | 'issuance'>(
    {
        assessment: async ({ assessmentId }) => {
            const assessmentApiInstance = await assessmentApi();
            const assessment = await assessmentApiInstance.getAssessmentById(assessmentId);
            return assessment;
        },
        issuance: async ({ assessmentId }) => {
            const certificationApiInstance = await certificationApi();
            const { inProgressCertification: issuance } = await certificationApiInstance.getInProgressCertification(assessmentId);
            return issuance;
        },
    },
    InnerPreliminaryReportDownloadsStepView,
    'component'
);
