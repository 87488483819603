import React, { useMemo, useEffect, useState } from 'react';
import { startOfDay, addMonths, format as dateFnsFormatDate } from 'date-fns';
import { QMatrixCellBase } from './QMatrixCellBase';
import {
    QuestionnairesDateAnswerConstraints as DateConstraints,
    QuestionnairesDateResolution as DateResolution,
    QuestionnairesQuestionnaireQuestion as Question,
} from '@projectcanary/trustwell-server-client-ts';
import { useQCell } from './QCell';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { DateView } from '@mui/x-date-pickers/models/views';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

function getMonthsFromTimespan(timespanAsString: string): number {
    const regex = /^(-)?P(\d+)M$/i;
    const match = regex.exec(timespanAsString);
    if (!match) {
        throw `Could not parse timespan string provided to Date cell. Value: '${timespanAsString}', here are some examples: 'P5M' or '-P5M'.`;
    }
    const timespan = (match[1] === '-' ? -1 : +1) * parseInt(match[2]);
    return timespan;
}

const formatDate = (date: Date): string => dateFnsFormatDate(date, 'P');

const getDateFromTimespan = (today: Date, timespan: string) => addMonths(today, getMonthsFromTimespan(timespan));

const resolutionViews: { [key: string]: DateView[] } = {
    [DateResolution.Day]: ['year', 'month', 'day'],
    [DateResolution.Month]: ['year', 'month'],
    [DateResolution.Year]: ['year'],
};

export const QMatrixDateCell = (props: { assetId: number; question: Question; enableCellAnnotation: boolean }) => {
    const { questionnaireQuestionId } = props.question;
    const { value, setAnswer } = useQCell(props.assetId, questionnaireQuestionId);
    const [inputValue, setInputValue] = useState<Date>();
    const answerConstraints = props.question.answerConstraints as unknown as DateConstraints | undefined;

    const today = startOfDay(new Date());
    const { minDate, maxDate, resolution } = useMemo(() => {
        const minDate = answerConstraints?.minDate && getDateFromTimespan(today, answerConstraints?.minDate);
        const maxDate = answerConstraints?.maxDate && getDateFromTimespan(today, answerConstraints?.maxDate);
        const resolution = answerConstraints?.resolution || DateResolution.Day;
        if (minDate > maxDate) {
            throw `Min date (${formatDate(minDate)}) must not be greater than max date (${formatDate(maxDate)}).`;
        }
        return {
            minDate: minDate,
            maxDate: maxDate,
            resolution: resolution,
        };
    }, [answerConstraints?.minDate, answerConstraints?.maxDate, answerConstraints?.resolution, today.getTime()]);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleDateChange = (date: Date | null) => {
        if (date) {
            setInputValue(date);
        }
    };

    function saveAnswerIfValid(date: Date | undefined) {
        const isValid = getValidationError(date) === undefined;
        if (isValid) {
            setAnswer(date);
        }
    }

    function getValidationError(date: Date | undefined): string | undefined {
        if (date === undefined) {
            return undefined;
        }

        if (date < minDate) {
            return `Date must not be before ${formatDate(minDate)}.`;
        }

        if (date > maxDate) {
            return `Date must not be after ${formatDate(maxDate)}.`;
        }

        return undefined;
    }

    const validationError: string | undefined = useMemo(() => getValidationError(inputValue), [inputValue, answerConstraints]);

    return useMemo(
        () => (
            <QMatrixCellBase {...props}>
                <DatePicker<Date | null>
                    sx={{ width: '350px' }}
                    disableOpenPicker={false}
                    onAccept={saveAnswerIfValid}
                    onChange={handleDateChange}
                    value={inputValue ?? null}
                    slotProps={{
                        textField: {
                            variant: 'standard',
                            InputProps: {
                                onBlur: () => {
                                    saveAnswerIfValid(inputValue ?? undefined);
                                },
                                // TODO: adding an adornment removes the OOB calendar icon that opens the calendar.
                                endAdornment: validationError ? (
                                    <Tooltip title={validationError}>
                                        <InfoIcon color="error" />
                                    </Tooltip>
                                ) : undefined,
                            },
                        },
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                    views={resolutionViews[resolution]}
                />
            </QMatrixCellBase>
        ),
        [inputValue, setAnswer, setInputValue, props, minDate, maxDate]
    );
};
