import _ from 'lodash';
import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useTabTitle } from './TabTitle';
import { Box } from '@mui/material';

type SiteContextType = { sectionName?: string; setSectionName: (sectionName?: string) => void };
const siteDefaultContext: SiteContextType = {
    sectionName: undefined,
    setSectionName: _.noop,
};

const SiteContext = React.createContext<SiteContextType>(siteDefaultContext);

export const useSite = () => useContext(SiteContext);

/**
 * A context for site-level states.
 */
export const Site = ({ children }: { children?: ReactNode }) => {
    const [sectionName, setSectionName] = useState<string>();
    const { setTabTitle } = useTabTitle();

    const useSectionName: (sectionName?: string) => void = (sectionName?: string) => {
        useEffect(() => {
            setSectionName(sectionName);
            setTabTitle(sectionName);
            return () => {
                setSectionName(undefined);
            };
        }, [sectionName]);
    };

    const siteContext = useMemo(
        () => ({
            sectionName,
            setSectionName: useSectionName,
        }),
        [sectionName, useSectionName]
    );

    return (
        <SiteContext.Provider value={siteContext}>
            <Box sx={{ fontSize: '24px' }}>{children}</Box>
        </SiteContext.Provider>
    );
};
