import React, { useEffect, useState } from 'react';
import { nowait } from 'Utils/nowait';
import { Backdrop } from 'Controls/Backdrop';
import _ from 'lodash';
import { useMessageBox } from 'Controls/MessageBox';
import { LoadingCircle } from 'Controls/LoadingCircle';

export type Initializers<T, I extends keyof T> = {
    [K in I]?: (props: Partial<T>) => Promise<T[K]>;
};

const pendingState: unique symbol = Symbol('pending');

export function withInitializers<T, I extends keyof T>(
    initializers: Initializers<T, I>,
    innerComponent: React.FC<T>,
    loader?: undefined | React.ReactElement | 'component' | 'page'
): (props: Omit<T, I>) => JSX.Element {
    return (props: Omit<T, I>) => {
        const [data, setData] = useState<Partial<Record<keyof T, any>>>(_.mapValues(initializers, () => pendingState) as Record<keyof T, any>);

        const messageBox = useMessageBox();

        useEffect(() => {
            for (const initializerKey in initializers) {
                const initializer = initializers[initializerKey];
                setData((currentValue) => {
                    return { ...currentValue, [initializerKey]: pendingState };
                });
                nowait(
                    (async () => {
                        try {
                            const result = await initializer(props as Partial<T>);
                            setData((currentValue) => {
                                return { ...currentValue, [initializerKey]: result };
                            });
                        } catch (error) {
                            // Note: error will have full details describing exact operation that failed.
                            messageBox.error(error);
                        }
                    })()
                );
            }
        }, _.values(props));

        if (_.some(data, (value) => value === pendingState)) {
            if ((loader ?? 'page') === 'page') {
                return <Backdrop isLoading={true} />;
            } else if (loader === 'component') {
                return <LoadingCircle />;
            } else {
                return loader as React.ReactElement;
            }
        } else {
            return React.createElement(innerComponent, { ...props, ...data } as T);
        }
    };
}
