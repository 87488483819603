import React, { useEffect, useMemo, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { assessmentApi, assetApi, scoreApi } from 'Services/TrustwellApiService';
import { Button } from 'Controls/Button';
import { useModal } from 'Controls/Modal';
import _ from 'lodash';
import { useLoader } from 'Utils/loader';
import { useMessageBox } from 'Controls/MessageBox';
import { buildTestId } from 'Utils/testid';
import { EditRatingMedalModal } from 'Components/EditRatingMedalModal';
import { useGridDefaultSort } from 'Utils/useGridDefaultSort';
import { Page } from 'Components/Page';
import { TopButtons } from 'Components/TopButtons';
import { Row, ScoresTableColumns } from './ScoresTableColumns';
import { AssessmentsAssessmentDetails as Assessment } from '@projectcanary/trustwell-server-client-ts';

export const ScoresPage = ({ assessment }: { assessment: Assessment }) => {
    const [scores, setScores] = useState<Row[]>([]);
    const [isLoading, withLoader] = useLoader();

    const modal = useModal();
    const messageBox = useMessageBox();

    const refreshPageData = async () => {
        try {
            const scoreApiInstance = await scoreApi();
            const assetApiInstance = await assetApi();

            const [oprScores, wells, lmrScores, ratingMedalOverrides] = await Promise.all([
                await scoreApiInstance.getAllEventWellScoresByAssessment(assessment.id).then((response) => _.keyBy(response, 'wellId')),
                await assetApiInstance.getAllAssetsForAssessment(assessment.id).then((response) => _.keyBy(response.assets, 'id')),
                await scoreApiInstance.getLmrPadEvaluationResults(assessment.id).then((response) => _.keyBy(response, 'padId')),
                await scoreApiInstance.getRatingMedalOverridesByAssessment(assessment.id).then((response) => _.keyBy(response, 'wellId')),
            ]);

            setScores(
                _.map(wells, (well) => {
                    const lmrScore = lmrScores[well.padAssetId];
                    const oprScore = oprScores[well.id];
                    return {
                        wellId: well.id,
                        ...well,
                        ...oprScore,
                        performanceScore: oprScore?.performanceScore ?? undefined,
                        inherentRiskProfile: oprScore?.inherentRiskProfile ?? undefined,
                        controlStrength: oprScore?.controlStrength ?? undefined,
                        localfactorScore: oprScore?.localfactorScore ?? undefined,
                        ratingMedalOverride: ratingMedalOverrides[well.id],
                        lmrScore: lmrScore?.score ?? undefined,
                        lmrRating: lmrScore?.lmrRating ?? undefined,
                    };
                })
            );
        } catch (error) {
            messageBox.error('Error fetching the page data. ' + error);
        }
    };

    useEffect(withLoader(refreshPageData), [assessment.id]);

    const recalculateScores = withLoader(async () => {
        try {
            const api = await scoreApi();
            await api.calculate(assessment.id);
            await api.processLmrEvaluation(assessment.id);
            await refreshPageData();
        } catch (error) {
            messageBox.error('Error calculating the scores. ' + error);
        }
    });

    const editMedal = (row: Row) => {
        const initialValues = { ratingMedal: row.ratingMedal, notes: '' };
        modal
            .form(
                {
                    title: 'Edit Well Medal',
                    buttons: ['Update Medal', 'Cancel'],
                },
                <EditRatingMedalModal initialValues={initialValues} />
            )
            .onSubmit(async ({ ratingMedal, notes }) => {
                const api = await scoreApi();
                try {
                    await api.overrideRatingMedal({
                        wellId: row.wellId,
                        assessmentId: assessment.id,
                        ratingMedal: ratingMedal,
                        notes: notes,
                    });
                    await refreshPageData();
                } catch (error) {
                    messageBox.error('Error overriding the rating medal. ' + error);
                }
            });
    };

    const resetRatingMedalOverride: (row: Row) => void = (row: Row) => {
        modal
            .show(
                {
                    title: 'Reset Medal Rating Override',
                    buttons: ['Reset', 'Cancel'],
                },
                <>
                    Resetting this well's override will revert the rating medal to use the well's score to determine the rating medal.
                    <br />
                    <br />
                    Reason stated for override: "{row.ratingMedalOverride.notes}"
                    <br />
                    Override created by: {row.ratingMedalOverride.creator}
                </>
            )
            .onAccept(async () => {
                try {
                    const api = await scoreApi();
                    await api.removeRatingMedalOverride(row.wellId);
                    await refreshPageData();
                } catch (error) {
                    messageBox.error('Error removing the medal override. ' + error);
                }
            });
    };

    const columns = useMemo(() => ScoresTableColumns(editMedal, resetRatingMedalOverride, assessment.activeModules), [editMedal]);

    const testId = buildTestId({ page: 'scores' });
    const sortModel = useGridDefaultSort([{ field: 'wellGroupName', sort: 'asc' }]);

    return (
        <Page title={'Scores'}>
            <TopButtons>
                <Button variant="primary" onClick={recalculateScores} data-testid={buildTestId(testId, { button: 'refreshScores' })}>
                    Refresh scores
                </Button>
            </TopButtons>
            <DataGridPro<Row>
                rows={scores}
                columns={columns}
                loading={isLoading}
                getRowId={(well) => well.wellId}
                data-testid={buildTestId(testId, { table: 'scores' })}
                initialState={{
                    columns: { columnVisibilityModel: { id: false } },
                }}
                {...sortModel}
            />
        </Page>
    );
};
