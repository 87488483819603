import { DataGridPro } from '@mui/x-data-grid-pro';
import { CertificationsLmrCertificatePublication } from '@projectcanary/trustwell-server-client-ts';
import { tableStyles } from './IssuedRatingsTableStyles';
import { columns } from './IssuedLmrsTableColumns';
import { certificationApi } from 'Services/TrustwellApiService';
import { withInitializers } from 'Utils/withInitializers';

type IssuedLmrsTableProps = {
    assessmentId: number;
    forceRefreshCounter: number;
    issuedLmrs: CertificationsLmrCertificatePublication[];
};

const InnerIssuedLmrsTable: (props: IssuedLmrsTableProps) => JSX.Element = ({ issuedLmrs }) => {
    return (
        <DataGridPro<CertificationsLmrCertificatePublication> sx={tableStyles} columns={columns} rows={issuedLmrs} getRowId={(row) => row.certificateGuid} />
    );
};

export const IssuedLmrsTable = withInitializers(
    {
        issuedLmrs: async ({ assessmentId }) => {
            const api = await certificationApi();
            const issuedLmrs = (await api.getPadIssuedLmr(assessmentId)).lmrs;
            return issuedLmrs;
        },
    },
    InnerIssuedLmrsTable,
    'component'
);
