import React from 'react';
import MainMenu from 'Components/MainMenu';
import { Navigate, Route, Routes } from 'react-router';
import CompaniesListPage from 'Pages/CompaniesListPage';
import { AssessmentListPage } from 'Pages/Assessments/AssessmentListPage';
import { AssessmentContainer } from 'Pages/Assessments/AssessmentContainer';
import AssessmentDefinitionList from 'Pages/AssessmentDefinitonList';
import TokenPage from 'Pages/TokenPage';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'Components/ErrorFallback';
import { Site } from 'Components/Site';
import Stack from '@mui/material/Stack';

const Main = () => {
    return (
        <Site>
            <MainMenu />
            <Stack
                direction={'column'}
                sx={{
                    width: `calc(100% - 85px)`,
                    minHeight: 'max(600px, calc(100vh - 120px))',
                    ml: '75px',
                    mt: '70px',
                    mb: '35px',
                }}
            >
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Routes>
                        <Route path="/token" element={<TokenPage />} />
                        <Route path="/companies" element={<CompaniesListPage title={'Companies'} />} />
                        <Route path="/assessments" element={<AssessmentListPage />} />
                        <Route path="/assessments/:assessmentId/*" element={<AssessmentContainer />} />
                        <Route path="/assessmentDefinitions" element={<AssessmentDefinitionList />} />
                        <Route path="/*" element={<Navigate to="/assessments" replace />} />
                    </Routes>
                </ErrorBoundary>
            </Stack>
        </Site>
    );
};

export default Main;
