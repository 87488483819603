import React, { useEffect, useState } from 'react';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { AmplifySignIn } from '@aws-amplify/ui-react';
import { useLocation } from 'react-router-dom';
import { useMessageBox } from 'Controls/MessageBox';
import Main from './Main';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'Components/ErrorFallback';
import { registerMaterialUiLicense } from './materialUiLicense';

registerMaterialUiLicense();

Amplify.configure({
    Auth: {
        identityPoolId: 'us-east-2:2495672f-0368-415c-87cc-44a1ecb639e5',
        region: 'us-east-2',
        userPoolId: 'us-east-2_s3WwPHzfP',
        userPoolWebClientId: '44tlk6jpd4r8u16lgr27uug7ka',
        authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
});

const App = () => {
    const [authState, setAuthState] = useState('');

    const handleAuthStateChange = (nextAuthState) => {
        setAuthState(nextAuthState);
    };

    const messageBox = useMessageBox();

    const location = useLocation();

    useEffect(() => {
        if (location.pathname !== '/' && authState !== 'signedin' && authState !== '') {
            messageBox.error('Please sign in to view this page');
        }
    });

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <AmplifyAuthenticator handleAuthStateChange={handleAuthStateChange}>
                <div slot="sign-in">
                    <AmplifySignIn hideSignUp={true} />
                </div>
                {authState === 'signedin' && <Main />}
            </AmplifyAuthenticator>
        </ErrorBoundary>
    );
};

export default App;
