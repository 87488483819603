import React, { useEffect, useMemo, useState } from 'react';
import { QMatrixCellBase } from './QMatrixCellBase';
import { QuestionnairesQuestionnaireQuestion as Question } from '@projectcanary/trustwell-server-client-ts';
import { TextField } from '@mui/material';
import { useQCell } from './QCell';

export const QMatrixStringCell = ({ assetId, question, enableCellAnnotation }: { assetId: number; question: Question; enableCellAnnotation: boolean }) => {
    const { questionnaireQuestionId } = question;
    const { value, isNA, isOmitted, setAnswer } = useQCell(assetId, questionnaireQuestionId);
    const [typingValue, setTypingValue] = useState<string>(value ?? '');

    useEffect(() => {
        setTypingValue(value);
    }, [value]);

    return useMemo(
        () => (
            <QMatrixCellBase assetId={assetId} question={question} enableCellAnnotation={enableCellAnnotation}>
                <TextField
                    disabled={isNA || isOmitted}
                    variant={'standard'}
                    margin={'none'}
                    fullWidth={true}
                    value={typingValue ?? ''}
                    type={'text'}
                    onChange={({ target }) => setTypingValue(target.value)}
                    onBlur={() => setAnswer(typingValue)}
                />
            </QMatrixCellBase>
        ),
        [isNA, isOmitted, typingValue, setAnswer]
    );
};
