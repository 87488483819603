import React from 'react';
import { ModalFormik } from 'Controls/Modal';
import * as Yup from 'Utils/yup';
import { Form } from 'formik';
import { DropdownField } from 'Controls/Fields/DropdownField';
import { Box, Stack } from '@mui/material';
import { TextAreaField } from 'Controls/Fields/TextAreaField';
import { OprRatingMedal } from '@projectcanary/trustwell-server-client-ts';

const schema = Yup.object().shape({
    ratingMedal: Yup.string().required(),
    notes: Yup.string().min(5, 'Please enter a note of a least 5 characters').required('Please enter a note'),
});

export type EditMedalModalInput = {
    ratingMedal: OprRatingMedal;
    notes: string;
};
type EditMedalModalProps = { initialValues: EditMedalModalInput };

const medals: OprRatingMedal[] = [OprRatingMedal.Platinum, OprRatingMedal.Gold, OprRatingMedal.Silver, OprRatingMedal.Evaluated];

export const EditRatingMedalModal: (props: EditMedalModalProps) => JSX.Element = ({ initialValues }) => {
    return (
        <ModalFormik initialValues={initialValues} validationSchema={schema}>
            <Form>
                <Stack spacing={1}>
                    <DropdownField<OprRatingMedal> name={'ratingMedal'} label={'Medal'} options={medals} includeEmptyOption={false} />
                    <Box mt={2} mb={2}>
                        <TextAreaField name="notes" label="Notes" />
                    </Box>
                </Stack>
            </Form>
        </ModalFormik>
    );
};
