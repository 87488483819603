import React from 'react';
import { Feature, isFeatureEnabled } from '../Config';

export const ErrorFallback = ({ error }) => (
    <div role="alert" style={{ padding: '20px' }}>
        {isFeatureEnabled(Feature.FullErrorDetails) ? (
            <>
                <p>An error was caught:</p>
                <pre style={{ color: 'red' }}>{error.message}</pre>
                <pre style={{ color: 'red' }}>{error.stack}</pre>
            </>
        ) : (
            <span style={{ color: 'red' }}>An error has ocurred. Please refresh the page.</span>
        )}
    </div>
);
