import React, { useCallback, useRef } from 'react';
import {
    AssessmentsAssessment,
    AssessmentsLmvaDetails,
    QuestionnairesQuestionnaire,
    VerifiedAttributesUpdateLmvaData,
} from '@projectcanary/trustwell-server-client-ts';
import { useNavigation } from 'Utils/Navigation';
import { assessmentApi, questionnaireApi } from 'Services/TrustwellApiService';
import { Page } from './Page';
import { useMessageBox } from 'Controls/MessageBox';
import { withInitializers } from 'Utils/withInitializers';
import { useLoader } from 'Utils/loader';
import { EditLmvaForm, EditLmvaFormLmva } from './EditLmvaForm';
import { Button } from 'Controls/Button';
import { Stack } from '@mui/material';
import { withRouteParams } from 'Utils/withRouteParams';

type EditLmvaProps = {
    assessment: AssessmentsAssessment; //
    questionnaire: QuestionnairesQuestionnaire; //
    lmva: AssessmentsLmvaDetails; //
    lmvaId: number; //
};

const InnerEditLmvaPage = ({ assessment, questionnaire, lmva }: EditLmvaProps) => {
    const { site, navigateTo } = useNavigation();
    const messageBox = useMessageBox();
    const [isLoading, withLoader] = useLoader();

    const updateLmva = useCallback(
        withLoader(async (updatedLmva: EditLmvaFormLmva) => {
            const assessmentApiInstance = await assessmentApi();
            try {
                const request: VerifiedAttributesUpdateLmvaData = {
                    id: updatedLmva.id,
                    assessmentId: assessment.id,
                    name: updatedLmva.name,
                    vintage: updatedLmva.vintage,
                    methaneIntensity: Number(updatedLmva.methaneIntensity),
                    segment: updatedLmva.segment,
                    emissionProtocol: updatedLmva.emissionProtocol,
                    methaneIntensityProtocol: updatedLmva.methaneIntensityProtocol,
                    granularity: updatedLmva.granularity,
                    production: updatedLmva.production,
                    benchmark: updatedLmva.benchmark,
                    questionnaireId: questionnaire.id,
                    questionnaireResponses: updatedLmva.responses,
                    questionnaireInstanceId: updatedLmva.questionnaireInstanceId,
                };
                await assessmentApiInstance.updateLmva(request);
                navigateTo(site.assessment(assessment.id).lmva);
            } catch (error) {
                messageBox.error(`Couldn't update LMVA '${updatedLmva.name}'(#${updatedLmva.id}). ${error}`);
            }
        }),
        [assessment, lmva]
    );

    const formRef = useRef<any>();

    return (
        <Page title={'Update LMVA'} isLoading={isLoading}>
            <Stack direction={'column'} maxWidth={1200}>
                <EditLmvaForm onSubmit={updateLmva} lmva={lmva} assessment={assessment} questionnaire={questionnaire} innerRef={formRef} />
                <Stack spacing={3} direction="row" justifyContent="end">
                    <Button onClick={() => formRef.current.resetForm()} variant={'secondary'}>
                        Reset
                    </Button>
                    <Button onClick={() => navigateTo(site.assessment(assessment.id).lmva)} variant={'secondary'}>
                        Cancel
                    </Button>
                    <Button variant={'primary'} onClick={() => formRef.current.submitForm()}>
                        Update LMVA
                    </Button>
                </Stack>
            </Stack>
        </Page>
    );
};

export const EditLmvaPage = withRouteParams(
    { lmvaId: Number },
    withInitializers(
        {
            async questionnaire() {
                const api = await questionnaireApi();
                const questionnaire = await api.getCurrentLmvaQuestionnaire();
                return questionnaire;
            },
            async lmva({ lmvaId }) {
                const api = await assessmentApi();
                return await api.getLmva(Number(lmvaId));
            },
        },
        InnerEditLmvaPage
    )
);
