import React, { useEffect, useMemo, useState } from 'react';
import { TextField } from 'Controls/Fields/TextField';
import * as Yup from 'Utils/yup';
import { Stack } from '@mui/system';
import { Annotation } from './QMatrix/QContext';
import { ModalFormik } from 'Controls/Modal';
import { DocumentsDocument as Document } from '@projectcanary/trustwell-server-client-ts';
import _ from 'lodash';
import { AutocompleteField } from 'Controls/Fields/AutocompleteField';
import CloseIcon from '@mui/icons-material/Close';
import { documentApi } from '../Services/TrustwellApiService';
import { nowait } from 'Utils/nowait';

export type AnnotationModalProps = {
    annotation?: Annotation;
    isDocumentReferenceRequired: boolean;
    isNoteRequired: boolean;
    assessmentId: number;
};

export const AnnotationModal = ({ annotation, isDocumentReferenceRequired, isNoteRequired, assessmentId }: AnnotationModalProps) => {
    const schema = useMemo(
        () =>
            Yup.object().shape({
                document: isDocumentReferenceRequired ? Yup.mixed().required() : Yup.mixed(),
                citation: Yup.string().when('document', {
                    is: (document) => !!document,
                    then: (schema) => schema.required(),
                }),
                note: isNoteRequired ? Yup.string().required() : Yup.string(),
            }),
        [isDocumentReferenceRequired, isNoteRequired]
    );

    const [documents, setDocuments] = useState<Document[]>();

    const initialValues = useMemo(() => {
        return {
            document: annotation?.documentReference && _.find(documents, { id: annotation.documentReference.documentId }),
            citation: annotation?.documentReference?.citation,
            note: annotation?.note,
        };
    }, [annotation, documents]);

    useEffect(() => {
        nowait(
            (async () => {
                const documentApiInstance = await documentApi();
                setDocuments(await documentApiInstance.getDocuments(assessmentId));
            })()
        );
    }, [assessmentId]);

    return (
        <ModalFormik validationSchema={schema} initialValues={initialValues} enableReinitialize>
            {({ values, setFieldValue }) => (
                <Stack mt={2} mb={2} spacing={1}>
                    <AutocompleteField<Document, true>
                        allowNewOption={true}
                        name="document"
                        label="Document"
                        options={documents ?? []}
                        labelSelector={(d) => d.name}
                        isRequired={isDocumentReferenceRequired}
                    />
                    <TextField name="citation" label="Citation" disabled={!values.document} isRequired={isDocumentReferenceRequired} />
                    <TextField
                        name="note"
                        label="Note"
                        isRequired={isNoteRequired}
                        buttons={[
                            {
                                title: 'Clear text',
                                icon: <CloseIcon />,
                                onClick: () => setFieldValue('note', undefined),
                            },
                        ]}
                    />
                </Stack>
            )}
        </ModalFormik>
    );
};
