import React, { ReactElement } from 'react';
import { withInitializers } from 'Utils/withInitializers';
import { certificationApi, questionnaireApi, scoreApi } from 'Services/TrustwellApiService';
import { EntitiesAssetType, LmrLmrWellKnownQuestions, ModulesModuleType, QuestionnairesAssetResponses } from '@projectcanary/trustwell-server-client-ts';
import _ from 'lodash';
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro';
import { formatLmrRating } from 'Utils/formats';

type Row = {
    wellGroupId: number;
    padAssetId: number;
    wellGroupName: string;
    score: any;
    lmrRating: any;
    carbonIntensity: number;
    methaneIntensity: number;
};

export type LowMethaneRatingReviewDetailsViewProps = { assessmentId: number; lmrData: Row[] };

const columns: GridColumns<Row> = [
    {
        field: 'wellGroupId',
        headerName: 'Well Group Id',
        width: 100,
        type: 'number',
    },
    {
        field: 'padAssetId',
        headerName: 'Asset Id',
        width: 100,
        type: 'number',
    },
    {
        field: 'wellGroupName',
        headerName: 'Well Group Name',
        flex: 1,
        type: 'string',
    },
    {
        field: 'score',
        headerName: 'Score',
        width: 150,
        type: 'number',
        valueFormatter: ({ value }) => (value !== undefined ? value : '-'),
    },
    {
        field: 'lmrRating',
        headerName: 'LMR Rating',
        headerAlign: 'right',
        width: 150,
        type: 'string',
        align: 'right',
        valueFormatter: ({ value }) => (value ? formatLmrRating(value) : '-'),
    },
    {
        field: 'methaneIntensity',
        headerName: 'Methane Intensity',
        width: 200,
        type: 'number',
    },
    {
        field: 'carbonIntensity',
        headerName: 'Carbon Intensity',
        width: 200,
        type: 'number',
    },
];

export const InnerLowMethaneRatingReviewDetailsView = ({ lmrData }: LowMethaneRatingReviewDetailsViewProps): ReactElement => {
    return (
        <DataGridPro<Row>
            getRowId={(row) => row.padAssetId}
            columns={columns}
            rows={lmrData}
            autoHeight={false}
            autoPageSize={true}
            initialState={{ columns: { columnVisibilityModel: { wellGroupId: false, padAssetId: false } } }}
        />
    );
};

function getNumericAnswer(assetsResponses: QuestionnairesAssetResponses[], padAssetId: number, questionCid: LmrLmrWellKnownQuestions): number | undefined {
    const assetResponses = _.find(assetsResponses, { assetId: padAssetId });
    if (assetResponses === undefined) {
        return undefined;
    }
    return _.find(assetResponses.responses, (r) => r.cid === (questionCid as unknown as string))?.answer;
}

export const LowMethaneRatingReviewDetailsView = withInitializers<LowMethaneRatingReviewDetailsViewProps, 'lmrData'>(
    {
        async lmrData({ assessmentId }) {
            const questionnaireApiInstance = await questionnaireApi();
            const lmrResponses = await questionnaireApiInstance.getResponses({
                assessmentId: assessmentId,
                assetType: EntitiesAssetType.Pad,
                moduleType: ModulesModuleType.Lmr,
                questionCids: [LmrLmrWellKnownQuestions.LMRCH4I, LmrLmrWellKnownQuestions.LMRCO2I],
            });

            const scoreApiInstance = await scoreApi();
            const lmrEvaluation = await scoreApiInstance.getLmrPadEvaluationResults(assessmentId);

            const certificationApiInstance = await certificationApi();
            const ipc = (await certificationApiInstance.getInProgressCertification(assessmentId)).inProgressCertification;

            return _.map(ipc.wellGroups, (wg) => {
                const padLmr = _.find(lmrEvaluation, { padId: wg.padAssetId });
                const data: Row = {
                    wellGroupId: wg.wellGroupId,
                    wellGroupName: wg.wellGroupName,
                    padAssetId: wg.padAssetId,
                    score: padLmr.score ?? undefined,
                    lmrRating: padLmr.lmrRating ?? undefined,
                    methaneIntensity: getNumericAnswer(lmrResponses.assetsResponses, wg.padAssetId, LmrLmrWellKnownQuestions.LMRCH4I),
                    carbonIntensity: getNumericAnswer(lmrResponses.assetsResponses, wg.padAssetId, LmrLmrWellKnownQuestions.LMRCO2I),
                };
                return data;
            });
        },
    },
    InnerLowMethaneRatingReviewDetailsView,
    'component'
);
