import React, { useState, useEffect } from 'react';
import RubricScoreTableRow from './RubricScoreTableRow';
import { Table, TableBody, TableRow, TableCell, TableHead, Paper, Button, IconButton, Grid, CircularProgress } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { rubricApi } from 'Services/TrustwellApiService';
import { AssessmentLevel } from './config';
import { useMessageBox } from 'Controls/MessageBox';
import { nowait } from 'Utils/nowait';

const RubricScores = (props) => {
    const messageBox = useMessageBox();

    const [assessment, setAssessment] = useState({});
    const wellGroups = props.wellGroups ?? [];
    const [rubricId, setRubricId] = useState(props.rubricId);
    const [wellScoreDict, setWellScoreDict] = useState({});
    const [editing, setEditing] = useState(false);
    const [buttonText, setButtonText] = useState('Edit');
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [versionId, setVersionId] = useState(null);

    useEffect(() => {
        if (props.assessment && props.assessment.id) {
            setAssessment(props.assessment);
            setVersionId(props.assessment.versionId);
        }
        if (props.rubricId && props.rubricId !== 0 && props.rubricId !== rubricId) {
            setRubricId(props.rubricId);
        }
    }, [assessment, props.rubricId, props.assessment]);

    useEffect(() => {
        if (props.assessment && props.assessment.id && props.assessment.id !== assessment.id) {
            setAssessment(props.assessment);
            setVersionId(props.versionId);
        }
        if (props.versionId && props.versionId !== versionId) {
            setVersionId(props.versionId);
        }
        if (props.rubricId && props.rubricId !== 0 && props.rubricId !== rubricId) {
            setRubricId(props.rubricId);
        }
        if (props.assessment && props.rubricId && props.rubricId !== rubricId && isRefreshing === false) {
            nowait(handleRefreshRubricScores(props.rubricId));
        }
    }, [props.assessment, props.assessment?.id, props.versionId, wellGroups, wellGroups?.length, props.rubricId]);

    const handleRefreshRubricScores = async (rubricId) => {
        setIsRefreshing(true);
        const assessmentId = assessment.id;
        try {
            const api = await rubricApi();
            var rubricQuestionScores = await api.getRubricQuestionScores(rubricId, assessmentId);
            var rubricQuestionExecScores = await api.getRubricQuestionExecScores(rubricId, assessmentId);
            var overriddenRubricScores = await api.getRubricQuestionOverrideScores(rubricId, assessmentId);

            if (rubricQuestionScores === undefined) {
                rubricQuestionScores = [];
            }
            if (rubricQuestionExecScores === undefined) {
                rubricQuestionExecScores = [];
            }
            if (overriddenRubricScores === undefined) {
                overriddenRubricScores = [];
            }
            var rubricScoreMap = buildRubricScoreMap(overriddenRubricScores, rubricQuestionScores, rubricQuestionExecScores);
            calculateRubricScores(rubricScoreMap);
            setIsRefreshing(false);
        } catch (error) {
            messageBox.error('Error fetching the rubric score. ' + error);
        }
    };

    const buildRubricScoreMap = (overriddenRubricScores, rubricQuestionScores, rubricQuestionExecScores) => {
        let rubricScoreMap = {};
        setWellScoreDict(rubricScoreMap);
        for (let i = 0; i < wellGroups.length; i++) {
            const wellGroupId = wellGroups[i]['id'];
            rubricScoreMap[wellGroupId] = {
                policy: {
                    scores: [],
                    overridden: false,
                },
                plan: {
                    scores: [],
                    overridden: false,
                },
                execution: {
                    scores: [],
                    overridden: false,
                },
                overall: {
                    scores: [],
                    overridden: false,
                },
            };
        }

        for (let i = 0; i < overriddenRubricScores.length; i++) {
            let score = overriddenRubricScores[i];
            let wellGroupId = score['wellgroupId'];
            let policyScore = score['policy'];
            let planScore = score['plan'];
            let execScore = score['execution'];
            let overallScore = score['overall'];
            if (!(wellGroupId in rubricScoreMap)) {
                rubricScoreMap[wellGroupId] = {
                    policy: {
                        scores: [],
                        overridden: false,
                    },
                    plan: {
                        scores: [],
                        overridden: false,
                    },
                    execution: {
                        scores: [],
                        overridden: false,
                    },
                    overall: {
                        scores: [],
                        overridden: false,
                    },
                };
            }
            if (policyScore !== null) {
                rubricScoreMap[wellGroupId]['policy']['scores'].push(policyScore);
                rubricScoreMap[wellGroupId]['policy']['overridden'] = true;
            }
            if (planScore !== null) {
                rubricScoreMap[wellGroupId]['plan']['scores'].push(planScore);
                rubricScoreMap[wellGroupId]['plan']['overridden'] = true;
            }
            if (execScore !== null) {
                rubricScoreMap[wellGroupId]['execution']['scores'].push(execScore);
                rubricScoreMap[wellGroupId]['execution']['overridden'] = true;
            }
            if (overallScore !== null) {
                rubricScoreMap[wellGroupId]['overall']['scores'].push(overallScore);
                rubricScoreMap[wellGroupId]['overall']['overridden'] = true;
            }
        }
        for (var i = 0; i < rubricQuestionScores.length; i++) {
            var score = rubricQuestionScores[i];
            let wellGroupId = score['wellgroupId'];
            var policyScore = score['policy'];
            var planScore = score['plan'];
            if (!(wellGroupId in rubricScoreMap)) {
                rubricScoreMap[wellGroupId] = {
                    policy: {
                        scores: [],
                        overridden: false,
                    },
                    plan: {
                        scores: [],
                        overridden: false,
                    },
                    execution: {
                        scores: [],
                        overridden: false,
                    },
                    overall: {
                        scores: [],
                        overridden: false,
                    },
                };
            }

            if (policyScore && rubricScoreMap[wellGroupId]['policy']['overridden'] === false) {
                rubricScoreMap[wellGroupId]['policy']['scores'].push(policyScore);
                rubricScoreMap[wellGroupId]['policy']['overridden'] = false;
            }
            if (planScore && rubricScoreMap[wellGroupId]['plan']['overridden'] === false) {
                rubricScoreMap[wellGroupId]['plan']['scores'].push(planScore);
                rubricScoreMap[wellGroupId]['plan']['overridden'] = false;
            }
        }
        for (let i = 0; i < rubricQuestionExecScores.length; i++) {
            let score = rubricQuestionExecScores[i];
            let wellGroupId = score['wellgroupId'];
            var execScore = score['execution'];
            if (!(wellGroupId in rubricScoreMap)) {
                rubricScoreMap[wellGroupId] = {
                    policy: {
                        scores: [],
                        overridden: false,
                    },
                    plan: {
                        scores: [],
                        overridden: false,
                    },
                    execution: {
                        scores: [],
                        overridden: false,
                    },
                    overall: {
                        scores: [],
                        overridden: false,
                    },
                };
            }
            if (execScore && rubricScoreMap[wellGroupId]['execution']['overridden'] === false) {
                rubricScoreMap[wellGroupId]['execution']['scores'].push(execScore);
                rubricScoreMap[wellGroupId]['execution']['overridden'] = false;
            }
        }
        return rubricScoreMap;
    };

    const calculateRubricScores = (rubricScoreMap) => {
        var wellGroupIds = Object.keys(rubricScoreMap);
        var _wellScoreDict = {};
        for (var i = 0; i < wellGroupIds.length; i++) {
            var wellGroupId = wellGroupIds[i];
            var policyScores = rubricScoreMap[wellGroupId]['policy'];
            var planScores = rubricScoreMap[wellGroupId]['plan'];
            var executionScores = rubricScoreMap[wellGroupId]['execution'];
            var overallScores = rubricScoreMap[wellGroupId]['overall'];
            var policy = calculateAverage(policyScores['scores']);
            var plan = calculateAverage(planScores['scores']);
            var execution = calculateAverage(executionScores['scores']);
            var overall = calculateAverage([policy, plan, execution]);
            if (overallScores['overridden']) {
                overall = calculateAverage(overallScores['scores']);
            }
            _wellScoreDict[wellGroupId] = {
                policy: {
                    score: policy,
                    overridden: policyScores['overridden'],
                },
                plan: {
                    score: plan,
                    overridden: planScores['overridden'],
                },
                execution: {
                    score: execution,
                    overridden: executionScores['overridden'],
                },
                overall: {
                    score: overall,
                    overridden: overallScores['overridden'],
                },
            };
        }
        setWellScoreDict(_wellScoreDict);
    };

    const calculateAverage = (arr) => {
        var count = 0;
        var total = 0;
        var average = null;
        if (arr) {
            for (var i = 0; i < arr.length; i++) {
                var value = arr[i];
                if (value !== undefined && value !== null && !isNaN(value) && isFinite(value)) {
                    count += 1;
                    total += value;
                }
            }
        }
        if (count > 0) {
            average = total / count;
        }
        return average;
    };

    const updateRubricScore = async (wellGroupId, scoreType) => {
        let rubricScoreData = wellScoreDict[wellGroupId];
        var score = rubricScoreData[scoreType]['score'];
        var overriddenJson = {
            assessmentId: assessment.id,
            rubricId: rubricId,
            rubricVersionId: versionId,
            wellgroupId: wellGroupId,
            [scoreType]: score,
        };
        try {
            const api = await rubricApi();
            await api.updateRubricQuestionOverrideScores(overriddenJson, AssessmentLevel[scoreType]);
        } catch (error) {
            messageBox.error('Error updating the rubric score. ' + error);
        }
    };

    const handleBlur = (wellGroupId, scoreType, e) => {
        let scoreDict = wellScoreDict;
        const score = e.target.value ? parseFloat(e.target.value).toFixed(1) : null;

        scoreDict[wellGroupId][scoreType]['score'] = score;

        setWellScoreDict(scoreDict);
        updateRubricScore(wellGroupId, scoreType);
    };

    const handleChange = (wellGroupId, scoreType, e) => {
        let score = parseFloat(e.target.value);
        if (score < 0 || score > 5) {
            messageBox.error(`The override score must be between 0 and 5. You have entered ${score}`);
            return;
        }
        const scoreDict = wellScoreDict;
        scoreDict[wellGroupId][scoreType]['score'] = score;

        setWellScoreDict((prevScoreDict) => {
            prevScoreDict[wellGroupId][scoreType]['score'] = score;

            return {
                ...prevScoreDict,
            };
        });
    };

    const handleEditButton = () => {
        if (editing) {
            setEditing(false);
            setButtonText('Edit');
        } else {
            setEditing(true);
            setButtonText('Done');
        }
    };

    const classes = props.classes;

    var refresh = null;
    if (isRefreshing) {
        refresh = <CircularProgress className={classes.refreshButton} />;
    } else {
        refresh = (
            <IconButton aria-label="Refresh" className={classes.refreshButton} onClick={() => handleRefreshRubricScores(rubricId)}>
                <Refresh />
            </IconButton>
        );
    }

    return (
        <Paper className={classes.root}>
            <Table size="small">
                <TableHead>
                    <TableRow className={classes.headerRow}>
                        <TableCell style={{ color: 'white' }} key={-1} className={[classes.rubricTypeHeading, classes.headerRow].join(' ')}>
                            Scores
                        </TableCell>
                        {wellGroups.map((well) => {
                            return (
                                <TableCell style={{ color: 'white' }} key={well.id} className={[classes.wellGroupName, classes.headerRow].join(' ')}>
                                    {well.name}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <RubricScoreTableRow
                        wellGroups={wellGroups}
                        wellScoreDict={wellScoreDict}
                        scoreType={'policy'}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        editing={editing}
                    />
                    <RubricScoreTableRow
                        wellGroups={wellGroups}
                        wellScoreDict={wellScoreDict}
                        scoreType={'plan'}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        editing={editing}
                    />
                    <RubricScoreTableRow
                        wellGroups={wellGroups}
                        wellScoreDict={wellScoreDict}
                        scoreType={'execution'}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        editing={editing}
                    />
                    <RubricScoreTableRow
                        wellGroups={wellGroups}
                        wellScoreDict={wellScoreDict}
                        scoreType={'overall'}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        editing={editing}
                    />
                </TableBody>
            </Table>
            <Grid container justify="flex-start">
                <Button onClick={handleEditButton} size="small" className={classes.buttons} disabled={rubricId === 0}>
                    {buttonText}
                </Button>
                {refresh}
            </Grid>
        </Paper>
    );
};

const styles = (theme) => ({
    root: {
        height: 300,
        position: 'relative',
        overflow: 'auto',
    },
    table: {},
    tableCell: {
        padding: 0,
    },
    headerRow: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        paddingTop: '16px',
        paddingBottom: '16px',
    },
    rubricTypeHeading: {
        textAlign: 'left',
        paddingRight: 0,
        paddingLeft: 20,
    },
    wellGroupName: {
        textAlign: 'center',
        paddingRight: 0,
        paddingLeft: 5,
    },
    resize: {
        fontSize: 12,
        minWidth: 12,
        textAlign: 'center',
    },
});

export default withStyles(styles)(RubricScores);
