import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import WellGroupList from './WellGroupList';
import LocalFactorAccordions from './LocalFactorAccordions';
import {
    formatTemplateScoreObjects,
    formatOverrideAndWellGroupScores,
    formatNewScoreObjForPost,
    formatScoreUpdatesFromResponse,
    checkComplete,
    updateCompletionArray,
    LocalFactorScore,
    FormattedScoresObject,
} from './LocalFactorConfig';
import { localFactorApi } from 'Services/TrustwellApiService';
import {
    AssessmentsAssessment,
    LocalFactorsLocalFactor,
    LocalFactorsLocalFactorScore,
    LocalFactorsWellGroupComplete,
    AssessmentsWellGroup,
    LocalFactorsAssessmentLocalFactorScore,
    AssessmentsAssessmentDetails,
} from '@projectcanary/trustwell-server-client-ts';
import { useMessageBox } from 'Controls/MessageBox';

type WellGroupScoringContainerProps = {
    assessment: AssessmentsAssessmentDetails;
    wellGroups: AssessmentsWellGroup[];
    localFactors: LocalFactorsLocalFactor[];
    assessmentLevelScores: LocalFactorsAssessmentLocalFactorScore[];
};

const WellGroupScoringContainer = ({ assessment, wellGroups, localFactors, assessmentLevelScores }: WellGroupScoringContainerProps) => {
    // default to first wellgroup in assessment
    const [selectedWellGroup, setSelectedWellGroup] = useState<AssessmentsWellGroup>(wellGroups[0]);
    // object of assessment level and n/a local factors with keys as local factor ids
    const [assessmentDefaultScores, setAssessmentDefaultScores] = useState<FormattedScoresObject>({});
    // copy of above object but keeping state of overridden scores
    const [overrideScores, setOverrideScores] = useState<FormattedScoresObject>({});
    // unsorted array of well group scores for selected well group
    const [currentWellGroupScores, setCurrentWellGroupScores] = useState<LocalFactorsLocalFactorScore[]>([]);
    // templated well group score obj for moving between well groups
    const [templateScoreObj, setTemplateScoreObj] = useState<FormattedScoresObject>({});
    // object of well group scores
    const [wellGroupScoresObj, setWellGroupScoresObj] = useState<FormattedScoresObject>({});
    // array of all completion statuses for well groups in assessment
    const [wellGroupsComplete, setWellGroupsComplete] = useState<LocalFactorsWellGroupComplete[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const messageBox = useMessageBox();

    useEffect(() => {
        setLoading(true);
        const templateScores = formatTemplateScoreObjects(assessmentLevelScores);
        setAssessmentDefaultScores(templateScores.assessmentAndNotApplicable);
        setOverrideScores(templateScores.assessmentAndNotApplicable);
        setTemplateScoreObj(templateScores.wellGroup);

        const getCompletionData = async (assessmentId: number) => {
            try {
                const api = await localFactorApi();
                const wellGroupsMarkedComplete = await api.getWellGroupCompletesByAssessmentId(assessmentId);
                setWellGroupsComplete(wellGroupsMarkedComplete);
            } catch (error) {
                messageBox.error('Error fetching the completion data. ' + error);
            }
        };
        getCompletionData(assessment.id);
        setLoading(false);
    }, [assessmentLevelScores, assessment.id]);

    useEffect(() => {
        setLoading(true);
        const getWellGroupScoringData = async (wellGroupId: number) => {
            try {
                const api = await localFactorApi();
                const allScoresForCurrentWellGroup = await api.getLocalFactorScoresByWellGroupId(wellGroupId);
                setCurrentWellGroupScores(allScoresForCurrentWellGroup);
            } catch (error) {
                messageBox.error('Error fetching the well group scoring data. ' + error);
            }
        };
        getWellGroupScoringData(selectedWellGroup.id);
        setLoading(false);
    }, [selectedWellGroup, assessmentLevelScores]);

    useEffect(() => {
        setLoading(true);
        if (!templateScoreObj || !currentWellGroupScores) return;
        const updatedScoreObjects = formatOverrideAndWellGroupScores(templateScoreObj, assessmentDefaultScores, currentWellGroupScores);
        setWellGroupScoresObj(updatedScoreObjects.updatedWellGroupScores);
        setOverrideScores(updatedScoreObjects.updatedOverrideScores);
        setLoading(false);
    }, [templateScoreObj, assessmentDefaultScores, currentWellGroupScores]);

    const handleChangeWellGroup = (wellGroup: AssessmentsWellGroup) => setSelectedWellGroup(wellGroup);

    const handleCommitScore = async (_, value: LocalFactorScore, factor: LocalFactorsLocalFactor, target: 'reset' | 'checkbox' | 'slider') => {
        setLoading(true);
        try {
            const api = await localFactorApi();
            const scoreUpdateObj = formatNewScoreObjForPost(value, factor, target, selectedWellGroup.id, assessment.id, assessment.versionId);
            const response = await api.createLocalFactorScore(scoreUpdateObj);
            if (response) {
                const successfulScoreData = formatScoreUpdatesFromResponse(response, factor, currentWellGroupScores, wellGroupScoresObj);
                setCurrentWellGroupScores(successfulScoreData.updatedScoreArray);
                setWellGroupScoresObj(successfulScoreData.updatedScoreObj);
            }
        } catch (error) {
            messageBox.error('Error committing the score. ' + error);
        }

        setLoading(false);
    };

    const handleMarkComplete = async (wellGroup: AssessmentsWellGroup, isComplete: boolean) => {
        setLoading(true);
        const completionObj = {
            assessmentId: assessment.id,
            wellGroupComplete: !isComplete,
            wellGroupId: wellGroup.id,
        };
        try {
            const api = await localFactorApi();
            const response = await api.updateWellGroupComplete(completionObj);
            if (response) {
                const updatedArr = updateCompletionArray(response, wellGroupsComplete);
                setWellGroupsComplete(updatedArr);
            }
        } catch (error) {
            messageBox.error('Error updating the completion status. ' + error);
        }

        setLoading(false);
    };
    const isMarkedComplete = checkComplete(wellGroupsComplete, selectedWellGroup);

    return (
        <Grid container spacing={5} style={{ paddingTop: '20px' }}>
            <Grid item xs={12}>
                <WellGroupList
                    wellGroups={wellGroups}
                    selectedWellGroup={selectedWellGroup}
                    wellGroupsComplete={wellGroupsComplete}
                    handleChangeWellGroup={handleChangeWellGroup}
                />
            </Grid>
            <Grid item xs={12}>
                {templateScoreObj && (
                    <LocalFactorAccordions
                        localFactors={localFactors}
                        selectedWellGroup={selectedWellGroup}
                        assessmentLevelDefaultScores={assessmentDefaultScores}
                        wellGroupScores={wellGroupScoresObj}
                        overrideScores={overrideScores}
                        isMarkedComplete={isMarkedComplete}
                        handleCommitScore={handleCommitScore}
                        handleMarkComplete={handleMarkComplete}
                        loading={loading}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default WellGroupScoringContainer;
