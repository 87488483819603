import React, { useCallback, useEffect, useState } from 'react';
import { IssuedOprsTable } from './IssuedOprsTable';
import {
    CertificationsGetInProgressCertificationStatus,
    CertificationsInProgressCertificationStatus,
    ModulesModuleType,
} from '@projectcanary/trustwell-server-client-ts';
import { assessmentApi, certificationApi } from 'Services/TrustwellApiService';
import { Button } from 'Controls/Button';
import { Stack, Tab, Tabs } from '@mui/material';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import { useNavigation } from 'Utils/Navigation';
import { Page } from 'Components/Page';
import { useTimer } from 'Utils/useTimer';
import logger from 'Utils/Logger';
import { IssuedLmrsTable } from './IssuedLmrsTable';
import { withInitializers } from 'Utils/withInitializers';
import { IssueRatingsButton } from './IssueRatingsButton';
import { useCounter } from 'Utils/useCounter';
import _ from 'lodash';
import { nowait } from 'Utils/nowait';

const moduleDescriptors = [
    { moduleType: ModulesModuleType.Opr, label: 'Onshore Production Ratings', component: IssuedOprsTable },
    { moduleType: ModulesModuleType.Lmr, label: 'Low Methane Ratings', component: IssuedLmrsTable },
];

const statusRefreshPeriodInMs = 5;

type IssuedRatingsListPageProps = { activeModules: ModulesModuleType[]; assessmentId: number };

function getFirstAvailableModuleType(activeModules: ModulesModuleType[]): ModulesModuleType {
    return _.find(moduleDescriptors, (md) => activeModules.includes(md.moduleType)).moduleType;
}

const InnerIssuedRatingsListPage = ({ activeModules, assessmentId }: IssuedRatingsListPageProps) => {
    const [forceRefreshCounter, incrementForceRefreshCounter] = useCounter();
    const [issuance, setIssuance] = useState<CertificationsGetInProgressCertificationStatus>();
    const { site } = useNavigation();
    const [selectedTab, setSelectedTab] = useState<ModulesModuleType>(getFirstAvailableModuleType(activeModules));

    const fetchPublishingStatus = useCallback(async () => {
        try {
            const api = await certificationApi();
            const result = await api.getInProgressCertificationStatus(assessmentId);
            setIssuance(result.inProgressCertification);
            if (result.inProgressCertification && result.inProgressCertification.status === CertificationsInProgressCertificationStatus.Published) {
                incrementForceRefreshCounter();
            }
        } catch (error) {
            // Normally we show user an error message, but this is a repeating operation - showing
            // an error message every 5 seconds could be annoying. Logging an error will post it to the server
            // so we'll see it in the server-side logs.
            logger.error(`Failed to fetch in-progress rating issuance status for assessment #${assessmentId}. ${error}`);
        }
    }, [incrementForceRefreshCounter]);

    useEffect(() => {
        nowait(fetchPublishingStatus());
    }, [assessmentId]);

    useTimer(fetchPublishingStatus, statusRefreshPeriodInMs * 1000, issuance?.status === CertificationsInProgressCertificationStatus.Publishing);

    return (
        <Page title={'Latest Issued Ratings'}>
            <Stack spacing={2} direction={'row'} justifyContent="space-between">
                <IssueRatingsButton assessmentId={assessmentId} issuance={issuance} />
                <Button variant={'link'} externalLink={site.external.sharePoint.documentsRoot}>
                    <FolderSpecialIcon />
                </Button>
            </Stack>

            <Tabs value={selectedTab} onChange={(e, value) => setSelectedTab(value)}>
                {activeModules.map((am) => {
                    const moduleDescriptor = _.find(moduleDescriptors, { moduleType: am });
                    return moduleDescriptor && <Tab label={moduleDescriptor.label} key={moduleDescriptor.label} value={moduleDescriptor.moduleType} />;
                })}
            </Tabs>

            {((moduleType) => {
                const moduleDescriptor = _.find(moduleDescriptors, { moduleType: moduleType });
                return (
                    moduleDescriptor &&
                    React.createElement(
                        moduleDescriptor.component, //
                        {
                            assessmentId: assessmentId,
                            key: moduleDescriptor.label,
                            forceRefreshCounter: forceRefreshCounter,
                        }
                    )
                );
            })(selectedTab)}
        </Page>
    );
};

export const IssuedRatingsListPage = withInitializers(
    {
        activeModules: async ({ assessmentId }) => {
            const api = await assessmentApi();
            const activeModules = (await api.getAssessmentById(assessmentId)).activeModules;
            return activeModules;
        },
    },
    InnerIssuedRatingsListPage
);
