import React, { useEffect, useState } from 'react';
import { assessmentApi, assessmentDefinitionApi, countryApi } from 'Services/TrustwellApiService';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import UpsertAssessmentForm, { UpsertAssessmentProps } from 'Components/UpsertAssessmentForm';
import { Button } from 'Controls/Button';
import { AssessmentDefinitionsAssessmentDefinition, AssessmentsAssessment as Assessment, CountriesCountry } from '@projectcanary/trustwell-server-client-ts';
import { ModalFormik, useModal } from 'Controls/Modal';
import { useMessageBox } from 'Controls/MessageBox';
import _ from 'lodash';
import { useLoader } from 'Utils/loader';
import { DataGridPro, GridActionsCellItem, useGridApiRef } from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import { GridColumns } from '@mui/x-data-grid/models/colDef/gridColDef';
import { personNameColumn } from 'Utils/formats';
import { TopButtons } from 'Components/TopButtons';
import { Page } from 'Components/Page';
import { nowait } from 'Utils/nowait';
import { useNavigation } from 'Utils/Navigation';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { DevSection, useDevTools } from 'Controls/useDevTools';
import { useKeyboardShortcut } from 'Controls/useKeyboardShortcut';
import { TextField } from 'Controls/Fields/TextField';
import * as Yup from 'Utils/yup';
import { withInitializers } from 'Utils/withInitializers';

type AssessmentRow = Assessment & { isMine: boolean };

const InnerAssessmentListPage = ({ countries, assessmentDefinitions, currentUsername }: AssessmentListPageProps) => {
    const messageBox = useMessageBox();
    const modal = useModal();

    const [isLoading, withLoader] = useLoader();
    const [assessments, setAssessments] = useState<AssessmentRow[]>();
    const { site, navigateTo } = useNavigation();

    const refreshAssessments = async () => {
        const _assessmentApi = await assessmentApi();
        try {
            const _assessments = (await _assessmentApi.getAllAssessments()).assessments;
            setAssessments(
                _.chain(_assessments)
                    .map<AssessmentRow>((a) => ({
                        ...a,
                        isMine: a.responsibleEngineer === currentUsername,
                        configuration: a.activeModules,
                    }))
                    .orderBy(['isMine', 'name'], ['desc', 'asc'])
                    .value()
            );
        } catch (error) {
            messageBox.error('Failed to load assessments data. ' + error);
        }
    };

    useEffect(withLoader(refreshAssessments), []);

    const devToolsEnabled = useDevTools();

    const columns: GridColumns<AssessmentRow> = [
        {
            headerName: 'Assessment Id',
            field: 'id',
            type: 'number',
            width: 150,
        },
        {
            headerName: 'Assessment Name',
            field: 'name',
            width: 300,
            renderCell: (params) => <Link to={site.assessment(params.row.id).assets}>{params.row.name}</Link>,
        },
        {
            headerName: 'Responsible Engineer',
            field: 'responsibleEngineer',
            width: 300,
            ...personNameColumn,
        },
        { headerName: 'Assessment Definition', field: 'versionName', width: 350 },
        { headerName: 'Country', field: 'countryName', width: 150 },
        { headerName: 'Basin', field: 'basinName', width: 350 },
        {
            headerName: 'Configuration',
            field: 'configuration',
            valueFormatter: ({ value: configuration }) =>
                _.orderBy(
                    configuration.map((moduleName) => moduleName.toUpperCase()),
                    [],
                    ['desc']
                ).join(', '),
            width: 350,
        },
        {
            headerName: 'Actions',
            field: 'actions',
            type: 'actions',
            sortable: false,
            filterable: false,
            getActions: ({ row: assessment }) =>
                _.compact([
                    <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={() => openAssessmentModal(assessment)} />,
                    <DevSection>
                        <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => deleteAssessment(assessment)} />
                    </DevSection>,
                ]),
        },
    ];

    const deleteAssessment = (assessment: AssessmentRow) => {
        modal
            .show({ title: 'Delete Assessment', buttons: ['Yes, delete', 'Cancel'] }, `Delete assessment #${assessment.id}(${assessment.name})?`)
            .onAccept(async () => {
                try {
                    const assessmentApiInstance = await assessmentApi();
                    await assessmentApiInstance.deleteAssessment({
                        assessmentId: assessment.id,
                        assessmentName: assessment.name,
                    });
                    nowait(refreshAssessments());
                } catch (error) {
                    messageBox.error(`Failed to delete assessment ${assessment.name}. ` + error);
                }
            });
    };

    const openAssessmentModal = async (editingAssessment?: AssessmentRow) => {
        const isEditing = !!editingAssessment;

        const theBestCountryInTheWorld = 'USA';
        const defaultAssessment: UpsertAssessmentProps['assessment'] = {
            responsibleEngineer: currentUsername,
            country: _.find(countries, { countryCode: theBestCountryInTheWorld }),
            canModifyActiveModules: true,
        };

        const assessment: UpsertAssessmentProps['assessment'] = {
            ...defaultAssessment,
            ...(editingAssessment
                ? {
                      id: editingAssessment.id,
                      name: editingAssessment.name,
                      responsibleEngineer: editingAssessment.responsibleEngineer,
                      country: _.find(countries, { countryCode: editingAssessment.countryCode }),
                  }
                : undefined),
        };

        if (isEditing) {
            const assessmentApiInstance = await assessmentApi();
            const [{ activeModules }, canModifyActiveModules] = await Promise.all([
                assessmentApiInstance.getAssessmentById(editingAssessment.id),
                assessmentApiInstance.canModifyActiveModules(editingAssessment.id),
            ]);
            assessment.activeModules = activeModules;
            assessment.canModifyActiveModules = canModifyActiveModules;
        }

        return modal
            .form(
                {
                    title: isEditing ? 'Edit Assessment' : 'Create Assessment',
                    buttons: [isEditing ? 'Update Assessment' : 'Create Assessment', 'Cancel'],
                },
                <UpsertAssessmentForm
                    assessment={assessment}
                    assessmentDefinitions={assessmentDefinitions}
                    currentEngineer={currentUsername}
                    countries={countries}
                    isEditing={isEditing}
                />
            )
            .onSubmit(async ({ responsibleEngineer, assessmentDefinition, name: assessmentName, country, basin, activeModules }) => {
                const api = await assessmentApi();
                if (isEditing) {
                    try {
                        await api.updateAssessment({
                            id: assessment.id,
                            name: assessmentName,
                            responsibleEngineer: responsibleEngineer,
                            activeModules: activeModules,
                        });
                        messageBox.success(`Successfully updated assessment '${assessmentName}'.`);
                    } catch (error) {
                        messageBox.error(`Failed to update assessment '${assessmentName}'. ` + error);
                    }
                } else {
                    try {
                        await api.createAssessment({
                            name: assessmentName,
                            responsibleEngineer: responsibleEngineer,
                            versionId: assessmentDefinition.id,
                            countryCode: country.countryCode,
                            basinId: basin.id,
                            activeModules: activeModules,
                        });
                        messageBox.success(`Successfully created assessment '${assessmentName}'.`);
                    } catch (error) {
                        messageBox.error(`Failed to create assessment '${assessmentName}'. ` + error);
                    }
                }
                nowait(refreshAssessments());
            });
    };

    useKeyboardShortcut({ key: 'B', ctrlKey: true, shiftKey: true }, goToAssessment);

    function goToAssessment() {
        const validationSchema = Yup.object().shape({ assessmentId: Yup.number().required() });
        modal
            .form(
                { title: 'Go to assessment', buttons: ['Go', 'Cancel'] },
                <ModalFormik initialValues={{ assessmentId: '' }} validationSchema={validationSchema}>
                    <TextField label={'Assessment Id'} name={'assessmentId'} />
                </ModalFormik>
            )
            .onSubmit(({ assessmentId }) => {
                navigateTo(site.assessment(Number(assessmentId)).assets);
            });
    }

    const gridRef = useGridApiRef();
    useEffect(() => {
        if (devToolsEnabled) {
            const allColumns = gridRef.current.getAllColumns();
            allColumns.forEach((c) => gridRef.current.setColumnVisibility(c.field, true));
            gridRef.current.setSortModel([{ field: 'id', sort: 'desc' }]);
        }
    }, [devToolsEnabled]);

    return (
        <Page title={'Assessments'}>
            <TopButtons>
                <Button variant="primary" onClick={() => openAssessmentModal()}>
                    Create New Assessment
                </Button>
                <DevSection>
                    <Button variant="secondary" onClick={() => goToAssessment()}>
                        Go to Assessment
                    </Button>
                </DevSection>
            </TopButtons>
            <DataGridPro
                apiRef={gridRef}
                rows={assessments ?? []}
                columns={columns}
                loading={isLoading}
                getRowId={(row) => row.id}
                initialState={{
                    columns: { columnVisibilityModel: { id: false } },
                }}
            />
        </Page>
    );
};

type AssessmentListPageProps = {
    countries: CountriesCountry[];
    assessmentDefinitions: AssessmentDefinitionsAssessmentDefinition[];
    currentUsername: string;
};

export const AssessmentListPage = withInitializers<AssessmentListPageProps, 'countries' | 'assessmentDefinitions' | 'currentUsername'>(
    {
        async countries() {
            return await (await countryApi()).getAllCountries();
        },
        async assessmentDefinitions() {
            const asntDefs = (await (await assessmentDefinitionApi()).getAllAssessmentDefinitions()).assessmentDefinitions;
            return _.orderBy(asntDefs, (asntDef) => asntDef.name.toUpperCase());
        },
        async currentUsername() {
            return (await Auth.currentUserInfo()).username;
        },
    },
    InnerAssessmentListPage
);
