import React, { useMemo, useContext, useState, useRef, useEffect } from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';
import _ from 'lodash';
import { buildTestId } from 'Utils/testid';
import { Box, Stack } from '@mui/material';

type MessageBoxContextType = {
    success: (text: string) => void;
    error: (text: string) => void;
    warning: (text: string) => void;
    info: (text: string) => void;
};

const MessageBoxContext = React.createContext<MessageBoxContextType>({
    success: _.noop,
    error: _.noop,
    warning: _.noop,
    info: _.noop,
});

export type Severity = AlertColor;

type MessageBoxState = { text: string; severity: Severity; index: number; timeoutMoment?: number };

type MessageBoxOptions = {
    successTimeout: number;
};

export const DefaultMessageBoxOptions: MessageBoxOptions = {
    successTimeout: 3000,
};

// a not very graceful way to disarm fall-back errors.
const errorSubscribers: (() => void)[] = [];
export function subscribeToErrors(cb: () => void) {
    errorSubscribers.push(cb);
}

export const MessageBoxProvider = ({ children, options }: { children: React.ReactNode; options?: MessageBoxOptions }) => {
    const [messages, setMessages] = useState<MessageBoxState[]>([]);
    const setOptions = {
        ...DefaultMessageBoxOptions,
        ...options,
    };

    const indexRef = useRef<number>(0);
    const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

    const getNextIndex = () => ++indexRef.current;

    const addMessage = (text: string, severity: Severity, timeout?: number): void => {
        // sometimes the message text, especially for errors, comes from an exception handler
        // which doesn't enforce type checking. So we need this extra check, otherwise we get
        // a hard to debug page crash.
        if (!_.isString(text)) {
            window.alert(`An attempt was made to pass a non-string message to a message box:\n${JSON.stringify(text)}`);
            // this is a hard to debug error so this breakpoint is intentional
            debugger;
        }

        errorSubscribers.forEach((cb) => cb());

        setMessages((prev) => [
            ...prev,
            {
                text,
                severity: severity,
                index: getNextIndex(),
                timeoutMoment: timeout !== undefined ? Date.now() + timeout : undefined,
            },
        ]);
    };

    const closeMessage = (index: number): void => {
        setMessages((prevMessages) => prevMessages.filter((m) => m.index !== index));
    };

    const context = useMemo(
        () => ({
            success: (text: string) => addMessage(text, 'success', setOptions.successTimeout),
            error: (text: string) => addMessage(text, 'error'),
            warning: (text: string) => addMessage(text, 'warning'),
            info: (text: string) => addMessage(text, 'info'),
        }),
        []
    );

    useEffect(() => {
        // find a message that needs to be hidden next
        const nextExpiringMessage = _.chain(messages)
            .filter((m) => !!m.timeoutMoment)
            .minBy((m) => m.timeoutMoment)
            .value();
        if (nextExpiringMessage) {
            const delay = nextExpiringMessage.timeoutMoment - Date.now();
            timerRef.current = setTimeout(() => {
                closeMessage(nextExpiringMessage.index);
            }, delay);
        }

        return () => {
            if (!!timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [messages]);

    const testId = buildTestId('messageList');

    return (
        <MessageBoxContext.Provider value={context}>
            {children}
            <Stack
                direction={'column'}
                alignItems={'flex-start'}
                data-testid={testId}
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    paddingLeft: 0,
                    zIndex: 10000,
                }}
            >
                {messages.map((message) => (
                    <Box
                        sx={{
                            marginTop: '5px',
                            listStyleType: 'none',
                        }}
                        key={message.index}
                    >
                        <Alert
                            severity={message.severity}
                            onClose={() => closeMessage(message.index)}
                            data-testid={buildTestId(testId, { message: [message.text] })}
                        >
                            {message.text}
                        </Alert>
                    </Box>
                ))}
            </Stack>
        </MessageBoxContext.Provider>
    );
};

export const useMessageBox: () => MessageBoxContextType = () => {
    return useContext(MessageBoxContext);
};
