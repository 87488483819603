import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { TableCell, TableRow, TextField, Tooltip } from '@mui/material';

class RubricScoreTableRow extends Component {
    getDisplayScore = (wellGroupId, scoreType) => {
        if (this.props.wellScoreDict[wellGroupId] && this.props.wellScoreDict[wellGroupId][scoreType]) {
            return this.props.wellScoreDict[wellGroupId][scoreType]['score'] || '';
        } else {
            return '';
        }
    };

    isOverridden = (wellGroupId, scoreType) => {
        if (this.props.wellScoreDict[wellGroupId] && this.props.wellScoreDict[wellGroupId][scoreType]) {
            return this.props.wellScoreDict[wellGroupId][scoreType]['overridden'] || false;
        } else {
            return false;
        }
    };

    isScoreError = (wellGroupId, scoreType) => {
        if (this.props.wellScoreDict[wellGroupId] && this.props.wellScoreDict[wellGroupId][scoreType]) {
            return isNaN(parseFloat(this.props.wellScoreDict[wellGroupId][scoreType]['score']));
        } else {
            return false;
        }
    };

    getTextField = (wellGroup, isOverridden) => {
        const { scoreType } = this.props;
        return (
            <TextField
                variant="standard"
                disabled={!this.props.editing}
                type="number"
                InputProps={{
                    style: isOverridden ? { fontWeight: 1000, width: '50px', color: 'black' } : { width: '50px' },
                    inputProps: {
                        style: { textAlign: 'center', min: 0, max: 5 },
                    },
                }}
                value={this.getDisplayScore(wellGroup.id, scoreType)}
                onChange={(e) => this.props.handleChange(wellGroup.id, scoreType, e)}
                onBlur={(e) => this.props.handleBlur(wellGroup.id, scoreType, e)}
            />
        );
    };

    render() {
        const wellGroups = this.props.wellGroups;
        const scoreType = this.props.scoreType;
        const classes = this.props.classes;
        return (
            <TableRow>
                <TableCell key={0}>{scoreType}</TableCell>
                {wellGroups.map((wellGroup) => {
                    return (
                        <TableCell key={wellGroup.id} className={classes.tableCell}>
                            {this.isOverridden(wellGroup.id, scoreType) ? (
                                <Tooltip title="Score Overridden">{this.getTextField(wellGroup, true)}</Tooltip>
                            ) : (
                                this.getTextField(wellGroup, false)
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
        );
    }
}

const styles = (theme) => ({
    tableCell: {
        paddingRight: 0,
        paddingLeft: 5,
    },
    titleCell: {
        textTransform: 'Capitalize',
        paddingLeft: 10,
    },
    resize: {
        fontSize: 12,
        minWidth: 12,
        textAlign: 'center',
    },
    bold: {
        fontWeight: 1000,
    },
});

export default withStyles(styles)(RubricScoreTableRow);
