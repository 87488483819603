import makeStyles from '@mui/styles/makeStyles';
import { Form, Field, getIn } from 'formik';
import { theme } from 'Apperance/Theme';
import * as Yup from 'Utils/yup';
import { Grid } from '@mui/material';
import { ModalFormik } from 'Controls/Modal';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'left',
        padding: theme.spacing(1),
    },
    label: {
        padding: '6px',
    },
    button: {
        padding: '6px',
        color: theme.palette.primary.main,
    },
    error: {
        color: theme.palette.error.main,
    },
}));

export type FormField = {
    id: string;
    name: string;
    type: string;
    formikRef: string;
    label: string;
    placeholder: string;
    hidden: boolean;
    hasValidation: boolean;
};

export type UpsertFormProps<ObjectType> = {
    editValue?: ObjectType;
    validationSchema: Yup.AnyObjectSchema;
    createEmptyStructure: any;
    fields: FormField[];
};

export default function UpsertForm<ObjectType>(props: UpsertFormProps<ObjectType>) {
    const classes = useStyles();
    const data = props.editValue ?? props.createEmptyStructure;

    return (
        <ModalFormik initialValues={data} validationSchema={props.validationSchema}>
            {({ values, touched, errors }) => (
                <Form className={classes.root}>
                    <Grid>
                        {props.fields &&
                            _.map(props.fields, (field) => (
                                <Grid>
                                    <label htmlFor={field.name} className={classes.label} hidden={!!field.hidden}>
                                        {field.label}
                                    </label>
                                    <Field
                                        type={field.type}
                                        name={field.name}
                                        placeholder={field.placeholder}
                                        value={getIn(values, field.formikRef)}
                                        hidden={!!field.hidden}
                                    />
                                    {field.hasValidation && getIn(errors, field.formikRef) && getIn(touched, field.formikRef) ? (
                                        <strong className={classes.error}>{getIn(errors, field.formikRef)}</strong>
                                    ) : null}{' '}
                                </Grid>
                            ))}
                    </Grid>
                </Form>
            )}
        </ModalFormik>
    );
}
