import 'Utils/ConsoleErrorWrapper';
import React from 'react';
import './index.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { theme } from 'Apperance/Theme';
import { ThemeProvider } from '@mui/material/styles';
import { MessageBoxProvider } from 'Controls/MessageBox';
import { ModalProvider } from 'Controls/Modal';
import { KeyboardShortcutsProvider } from 'Controls/useKeyboardShortcut';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DevToolsProvider } from 'Controls/useDevTools';
import { WhiskeyTime } from './Components/WhiskeyTime';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <KeyboardShortcutsProvider>
                    <DevToolsProvider>
                        <WhiskeyTime>
                            <MessageBoxProvider>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <ModalProvider>
                                        <App />
                                    </ModalProvider>
                                </LocalizationProvider>
                            </MessageBoxProvider>
                        </WhiskeyTime>
                    </DevToolsProvider>
                </KeyboardShortcutsProvider>
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>
);
