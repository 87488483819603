import React, { useMemo } from 'react';
import { fadeOnUnhoverClassName, QMatrixCellBase } from './QMatrixCellBase';
import { Stack } from '@mui/material';
import { QuestionnairesQuestionnaireQuestion as Question } from '@projectcanary/trustwell-server-client-ts';
import { Check, Close } from '@mui/icons-material';
import { Button } from 'Controls/Button';
import { buildTestId } from 'Utils/testid';
import { useQCell } from './QCell';

export const QMatrixFlagCell = (props: { assetId: number; question: Question; enableCellAnnotation: boolean }) => {
    const { questionnaireQuestionId } = props.question;
    const { value, setAnswer } = useQCell(props.assetId, questionnaireQuestionId);

    const testId = buildTestId({ qMatrixCell: 'flag' }, { asset: props.assetId.toString() }, { question: props.question.questionnaireQuestionId.toString() });

    return useMemo(
        () => (
            <QMatrixCellBase {...props}>
                <Stack direction="row">
                    <Button
                        variant="link"
                        onClick={() => {
                            setAnswer(value === true ? undefined : true);
                        }}
                        data-testid={buildTestId(testId, { option: 'yes' })}
                        className={value !== true ? fadeOnUnhoverClassName : ''}
                    >
                        <Check color={value === true ? 'success' : 'disabled'} />
                    </Button>
                    <Button
                        variant="link"
                        onClick={() => {
                            setAnswer(value === false ? undefined : false);
                        }}
                        data-testid={buildTestId(testId, { option: 'no' })}
                        className={value !== false ? fadeOnUnhoverClassName : ''}
                    >
                        <Close color={value === false ? 'error' : 'disabled'} />
                    </Button>
                </Stack>
            </QMatrixCellBase>
        ),
        [value, setAnswer]
    );
};
