import CloseIcon from '@mui/icons-material/Close';
import React, { useCallback, useEffect, useMemo } from 'react';
import { fadeOnUnhoverClassName, QMatrixCellBase } from './QMatrixCellBase';
import {
    QuestionnairesDateRangeAnswerConstraints as DateRangeConstraints,
    QuestionnairesDateResolution as DateResolution,
    QuestionnairesQuestionnaireQuestion as Question,
} from '@projectcanary/trustwell-server-client-ts';
import { useQCell } from './QCell';
import { DateRangePicker, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import { IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { differenceInMonths, startOfDay } from 'date-fns';

function parseTimespan(timespanAsString: string) {
    if (timespanAsString === undefined) {
        return undefined;
    }
    // Note: only supporting min duration expressed in months (e.g. "P5M").
    const requiredMinDiffInMonths = parseInt(/(?<=^P.*)(\d+)M/i.exec(timespanAsString)?.[1]);
    return isNaN(requiredMinDiffInMonths) ? undefined : requiredMinDiffInMonths;
}

type DateRange = { start: Date; end: Date };

export const QMatrixDateRangeCell = (props: { assetId: number; question: Question; enableCellAnnotation: boolean }) => {
    const { questionnaireQuestionId } = props.question;
    const { value, setAnswer } = useQCell(props.assetId, questionnaireQuestionId);
    const [inputValue, setInputValue] = React.useState<DateRange | undefined>(value);

    const answerConstraints = props.question.answerConstraints as unknown as DateRangeConstraints | undefined;
    const resolution: DateResolution = answerConstraints?.resolution ?? DateResolution.Day;
    const { minDuration, maxDuration } = useMemo(
        () => ({
            minDuration: parseTimespan(answerConstraints?.minDuration),
            maxDuration: parseTimespan(answerConstraints?.maxDuration),
        }),
        [answerConstraints?.minDuration, answerConstraints?.maxDuration]
    );

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    function saveAnswerIfValid(dateRange: DateRange | undefined) {
        const isValid = getValidationError(dateRange) === undefined;
        if (isValid) {
            setAnswer(dateRange);
        }
    }

    function getValidationError(dateRange: DateRange | undefined): string | undefined {
        if (dateRange === undefined) {
            return undefined;
        }
        if (resolution === DateResolution.Month) {
            if (dateRange.start.getDate() !== 1) {
                return 'Start date must be the first day of the month';
            }
            if (dateRange.end.getDate() !== 1) {
                return 'End date must be the first day of the month';
            }
        }

        const actualDiffInMonths = differenceInMonths(dateRange.end, dateRange.start);
        if (minDuration !== undefined && actualDiffInMonths < minDuration) {
            return 'Date range must be at least ' + minDuration + ' month[s] long.';
        }
        if (maxDuration !== undefined && actualDiffInMonths > maxDuration) {
            return 'Date range must be no longer than ' + maxDuration + ' month[s] long.';
        }
        return undefined;
    }

    const validationError: string | undefined = useMemo(() => getValidationError(inputValue), [inputValue, answerConstraints]);

    const shouldDisableDate = useMemo(() => {
        if (answerConstraints.resolution === DateResolution.Month) {
            return (day: Date): boolean => {
                return day.getDate() !== 1;
            };
        } else {
            return undefined;
        }
    }, [answerConstraints?.resolution]);

    function muiValueToDateRange(value: [Date | null, Date | null] | undefined) {
        return value === undefined || value[0] === null || value[1] === null ? undefined : { start: startOfDay(value[0]), end: startOfDay(value[1]) };
    }

    const handleOnAccept = useCallback(
        (value) => {
            const dateRange = muiValueToDateRange(value);
            setInputValue(dateRange);
            saveAnswerIfValid(dateRange);
        },
        [setAnswer]
    );

    const handleOnChange = useCallback((value) => {
        const dateRange = muiValueToDateRange(value);
        setInputValue(dateRange);
    }, []);

    return useMemo(
        () => (
            <QMatrixCellBase {...props}>
                <DateRangePicker<Date>
                    sx={{ width: '350px' }}
                    shouldDisableDate={shouldDisableDate}
                    disableOpenPicker={false}
                    onAccept={handleOnAccept}
                    onChange={handleOnChange}
                    value={[inputValue?.start ?? null, inputValue?.end ?? null]}
                    slots={{ field: SingleInputDateRangeField }}
                    slotProps={{
                        textField: {
                            variant: 'standard',
                            InputProps: {
                                onBlur: () => {
                                    saveAnswerIfValid(inputValue);
                                },
                                endAdornment: validationError ? (
                                    <Tooltip title={validationError}>
                                        <InfoIcon color="error" />
                                    </Tooltip>
                                ) : undefined,
                            },
                        },
                    }}
                />
                {inputValue !== undefined && (
                    <IconButton size={'small'} onClick={() => saveAnswerIfValid(undefined)} className={fadeOnUnhoverClassName}>
                        <CloseIcon />
                    </IconButton>
                )}
            </QMatrixCellBase>
        ),
        [inputValue, validationError, shouldDisableDate, handleOnAccept, handleOnChange]
    );
};
