import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell } from '@mui/material';
import { withStyles } from '@mui/styles';
import QuestionCheckBox from './QuestionCheckBox';
import { EXECUTION_RUBRIC, PLAN_RUBRIC } from '../config';
import { getCheckedStatus, getFilteredDocReferences, isFullRowChecked, getSharedDocumentReferenceForRow } from './rubricHelperFunctions';

const QuestionTableRow = ({
    classes,
    assessmentId,
    question,
    rubricType,
    questionScores,
    documentReferences = [],
    assessedAssets,
    handleDocumentUpdate,
    handleCheck,
    documentNames,
}) => {
    const rowChecked = isFullRowChecked(questionScores, assessedAssets, rubricType);
    const sharedDocumentReference = getSharedDocumentReferenceForRow(documentReferences, assessedAssets, rubricType);
    const checkAll = rubricType === EXECUTION_RUBRIC || rubricType === PLAN_RUBRIC;
    return (
        <TableRow>
            <TableCell className={`${classes.tableCell} ${classes.binColumn}`}>{question.bin}</TableCell>
            <TableCell className={`${classes.tableCell} ${classes.questionColumn}`}>{question.text}</TableCell>
            {/* Render 'Check All' option for Execution and Plan */}
            {checkAll && (
                <QuestionCheckBox
                    question={question}
                    assessmentId={assessmentId}
                    assessedAssets={assessedAssets}
                    rubricType={rubricType}
                    isChecked={rowChecked}
                    handleCheck={handleCheck}
                    handleDocumentUpdate={handleDocumentUpdate}
                    isCheckAllBtn={true}
                    sharedDocument={sharedDocumentReference}
                    documentNames={documentNames}
                />
            )}
            {/* Render checkboxes for all assessed assets with mapped checked status and doc references */}
            {assessedAssets.map((asset) => {
                return (
                    <QuestionCheckBox
                        key={asset.id}
                        assessmentId={assessmentId}
                        asset={asset}
                        question={question}
                        rubricType={rubricType}
                        assessedAssets={assessedAssets}
                        isChecked={getCheckedStatus(asset, question, rubricType, questionScores)}
                        docReference={getFilteredDocReferences(asset, question, rubricType, documentReferences)}
                        handleCheck={handleCheck}
                        handleDocumentUpdate={handleDocumentUpdate}
                        isCheckAllBtn={false}
                        isSharedDocument={false}
                        documentNames={documentNames}
                    />
                );
            })}
        </TableRow>
    );
};
QuestionTableRow.propTypes = {
    classes: PropTypes.object.isRequired,
    assessmentId: PropTypes.number.isRequired,
    question: PropTypes.object.isRequired,
    rubricType: PropTypes.string.isRequired,
    questionScores: PropTypes.array,
    documentReferences: PropTypes.array,
    assessedAssets: PropTypes.object.isRequired,
    handleDocumentUpdate: PropTypes.func.isRequired,
    handleCheck: PropTypes.func.isRequired,
    documentNames: PropTypes.array,
};
const styles = (theme) => ({
    binColumn: {
        position: 'sticky',
        background: '#fff',
        left: 0,
        zIndex: 1,
    },
    questionColumn: {
        position: 'sticky',
        background: '#fff',
        left: 40,
        zIndex: 1,
    },
    tableCell: {
        padding: 5,
    },
});
export default withStyles(styles)(QuestionTableRow);
