import React, { useState } from 'react';
import { AppBar, Stack, Tab, Tabs } from '@mui/material';
import { Link, Outlet, Route, Routes } from 'react-router-dom';
import { assessmentApi } from 'Services/TrustwellApiService';
import { AssessmentsAssessmentDetails as Assessment } from '@projectcanary/trustwell-server-client-ts';
import { isFeatureEnabled } from 'Config';
import { useSite } from 'Components/Site';
import { assessmentSectionPages } from './AssessmentSectionPages';
import _ from 'lodash';
import { useNavigation } from 'Utils/Navigation';
import { SelfReportingRoute } from 'Controls/SelfReportingRoute';
import { withInitializers } from '../../Utils/withInitializers';
import { withRouteParams } from '../../Utils/withRouteParams';

const linkStyle = {
    '& a': {
        opacity: 1,
        color: 'rgb(154,179,192)',
        textShadow: '0px 0px 5px black',
        '&.Mui-selected': {
            color: '#FFF',
        },
    },
};

type AssessmentContainerProps = { assessmentId: number; assessment: Assessment };

const InternalAssessmentContainer = ({ assessment }: AssessmentContainerProps) => {
    const { site } = useNavigation();
    const assessmentBaseUrl = site.assessment(assessment.id);

    const defaultPage = _.find(assessmentSectionPages, { isDefaultPage: true });
    console.assert(defaultPage, 'No default page was defined.');
    const [tabValue, setTabValue] = useState<String>(defaultPage.label);

    const { setSectionName } = useSite();
    setSectionName(assessment?.name);

    return (
        <Stack direction={'column'} flexGrow={1}>
            <AppBar position="static">
                <Tabs value={tabValue} textColor="inherit" centered={true} sx={linkStyle}>
                    {assessmentSectionPages
                        .filter((item) => {
                            const passesFeatureFilter = item.feature === undefined || isFeatureEnabled(item.feature);
                            const passesModuleFilter = item.modules === undefined || _.intersection(item.modules, assessment.activeModules).length > 0;
                            return passesModuleFilter && passesFeatureFilter;
                        })
                        .map((item) => (
                            <Tab key={item.label} label={item.label} component={Link} to={`${assessmentBaseUrl}${item.path}`} value={item.label} />
                        ))}
                </Tabs>
            </AppBar>
            <Routes>
                {assessmentSectionPages.map((item) => (
                    <Route
                        key={item.path}
                        path={item.path + (item.pathPattern ?? '')}
                        element={<SelfReportingRoute onSelected={() => setTabValue(item.label)}>{item.page(assessment)}</SelfReportingRoute>}
                    />
                ))}
            </Routes>
            <Outlet />
        </Stack>
    );
};

export const AssessmentContainer = withRouteParams(
    { assessmentId: Number },
    withInitializers<AssessmentContainerProps, 'assessment'>(
        {
            assessment: async ({ assessmentId }) => {
                const _assessmentApi = await assessmentApi();
                const assessment = await _assessmentApi.getAssessmentById(assessmentId);
                return assessment;
            },
        },
        InternalAssessmentContainer
    )
);
