import React from 'react';
import { Checkbox as MuiCheckbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { buildTestId } from 'Utils/testid';
import { withDisabledMessage } from 'Utils/withDisabledMessage';

type CheckboxFieldProps = { name: string; label: string; disabled?: boolean };

const InnerCheckboxField = ({ name, label, disabled }: CheckboxFieldProps) => {
    const [field, meta, helpers] = useField<boolean>(name);
    const { isValidating } = useFormikContext();
    const testId = buildTestId({ checkbox: name });

    return (
        <FormControl error={!isValidating && meta.touched && !!meta.error}>
            <FormControlLabel
                control={
                    <MuiCheckbox
                        data-testid={testId}
                        name={name}
                        checked={field.value}
                        disabled={disabled}
                        onChange={({ target }) => helpers.setValue(target.checked)}
                        onBlur={() => helpers.setTouched(field.checked)}
                    />
                }
                label={label}
            />
            <FormHelperText>{!isValidating && meta.touched && meta.error}</FormHelperText>
        </FormControl>
    );
};

export const CheckboxField = withDisabledMessage(InnerCheckboxField);
