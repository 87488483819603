import _ from 'lodash';
import { useCallback, useEffect } from 'react';

export const useTimer = (callback: () => any, intervalInMs: number, condition?: boolean) => {
    const throttledCallback = useCallback(_.throttle(callback, intervalInMs, { leading: true, trailing: true }),
        [callback, intervalInMs],
    );

    useEffect(() => {
        function stopTimer(timerId: number | undefined) {
            if (timerId !== undefined) {
                throttledCallback.cancel();
                clearInterval(timerId);
            }
        }

        let timerId: number | undefined;

        if (condition) {
            // Note: for some reason my IDE thinks that setInterval returns Timer, so have to
            // convince it that it returns a number explicitly.
            timerId = setInterval(throttledCallback, intervalInMs) as unknown as number;
        } else {
            stopTimer(timerId);
            timerId = undefined;
        }

        return () => {
            stopTimer(timerId);
            timerId = undefined;
        };
    }, [throttledCallback, intervalInMs, condition]);
};
