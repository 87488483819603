import { useContext, useMemo } from 'react';
import { nowait } from 'Utils/nowait';
import { QContextContext } from './QContext';
import { QCellState } from './useCellStates';

export type QCellContext = Omit<QCellState, 'annotation'> & {
    setAnswer(answer: any): void;
    editAnnotation(): void;
    copyToAll(bypassConfirmation?: boolean): void;
    setIsNA(isNA: boolean): void;
    setIsOmitted(isOmitted: boolean): void;
    isAnnotated: boolean;
};

export function useQCell(assetId: number, questionnaireQuestionId: number): QCellContext {
    const qContext = useContext(QContextContext);

    const setAnswer = useMemo(() => (answer) => nowait(qContext.setAnswer(assetId, questionnaireQuestionId, answer)), [qContext.setAnswer]);
    const editAnnotation = useMemo(() => () => nowait(qContext.editQuestionAnnotation(assetId, questionnaireQuestionId)), [qContext.editQuestionAnnotation]);
    const copyToAll = useMemo(
        () =>
            (bypassConfirmation: boolean = false) =>
                nowait(qContext.copyToAll(assetId, questionnaireQuestionId, bypassConfirmation)),
        [qContext.copyToAll]
    );
    const setIsNA = useMemo(() => (isNA: boolean) => nowait(qContext.setIsNA(assetId, questionnaireQuestionId, isNA)), [qContext.setIsNA]);
    const setIsOmitted = useMemo(
        () => (isOmitted: boolean) => nowait(qContext.setIsOmitted(assetId, questionnaireQuestionId, isOmitted)),
        [qContext.setIsOmitted]
    );

    const cellContext = useMemo(() => {
        const cellState = qContext.cellStates[assetId]?.[questionnaireQuestionId] ?? {
            value: undefined,
            isNA: false,
            isOmitted: false,
            syncStatus: 'fresh',
            annotation: undefined,
        };
        return {
            value: cellState.value,
            isAnnotated: !!cellState.annotation,
            isNA: cellState.isNA,
            isOmitted: cellState.isOmitted,
            syncStatus: cellState.syncStatus,
            setAnswer: setAnswer,
            editAnnotation: editAnnotation,
            copyToAll: copyToAll,
            setIsNA: setIsNA,
            setIsOmitted: setIsOmitted,
        } as QCellContext;
    }, [setAnswer, editAnnotation, copyToAll, setIsNA, setIsOmitted, qContext.cellStates[assetId]?.[questionnaireQuestionId]]);
    return cellContext;
}
