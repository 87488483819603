import { Typography, Stack } from '@mui/material';
import { TextField } from 'Controls/Fields/TextField';
import { Form } from 'formik';
import { ModalFormik } from 'Controls/Modal';

const AssetGroupUpdateModal = ({ assetGroup, log, schema, disableEdit = false }) => {
    return (
        <ModalFormik initialValues={assetGroup} validationSchema={schema}>
            <Form>
                <Stack spacing={{ xs: 2 }} mt={2}>
                    <TextField name={'assetGroupName'} label={'Well Group Name'} disabled={disableEdit} />
                </Stack>
            </Form>

            {log.length > 0 && (
                <>
                    <Typography color={'red'} variant="body1">
                        Error[s]/Warning[s] Updating Wellgroup
                    </Typography>
                    <ul style={{ color: 'red' }}>
                        {log.map((logItem) => {
                            return <li key={logItem.explanation}>{logItem.explanation}</li>;
                        })}
                    </ul>
                </>
            )}
        </ModalFormik>
    );
};

export default AssetGroupUpdateModal;
