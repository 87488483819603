import { AssessmentApi, ModulesModuleType, ReportGenerationReportFormat as ReportFormat } from '@projectcanary/trustwell-server-client-ts';

export type DownloadReportData = {
    producerName: string;
    reportDate: Date;
    wellGroup?: { id: number; name: string };
};

export type ReportDownloader = (
    api: AssessmentApi
) =>
    | ((request: { isPreliminary: true; assessmentId: number; reportDate: Date; producerName: string; reportFormat: ReportFormat }) => Promise<Response>)
    | ((request: {
          isPreliminary: true;
          assessmentId: number;
          reportDate: Date;
          producerName: string;
          reportFormat: ReportFormat;
          wellGroupId: number;
      }) => Promise<Response>);

export type ReportType = {
    reportName: string;
    isPadLevelReport: boolean;
    module: ModulesModuleType;
    downloader: ReportDownloader;
    reportFormat: ReportFormat;
};

export const reportTypes: ReportType[] = [
    {
        reportName: 'OPR Summary Report',
        downloader: (api) => api.downloadWellGroupSummaryReport,
        isPadLevelReport: false,
        module: ModulesModuleType.Opr,
        reportFormat: ReportFormat.Pptx,
    },
    {
        reportName: 'OPR Well Group Report',
        downloader: (api) => api.downloadWellGroupReport,
        isPadLevelReport: true,
        module: ModulesModuleType.Opr,
        reportFormat: ReportFormat.Pptx,
    },
    {
        reportName: 'LMR Report',
        downloader: (api) => api.downloadLmrReport,
        isPadLevelReport: true,
        module: ModulesModuleType.Lmr,
        reportFormat: ReportFormat.Pptx,
    },
    {
        reportName: 'LMR Rating',
        downloader: (api) => api.downloadLmrCertificate,
        isPadLevelReport: true,
        module: ModulesModuleType.Lmr,
        reportFormat: ReportFormat.Pdf,
    },
];
