import React, { createContext, FC, ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Button } from './Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Formik as OriginalFormik } from 'formik';
import { FormikConfig, FormikProps, FormikValues } from 'formik/dist/types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { useMessageBox } from './MessageBox';
import logger from 'Utils/Logger';
import _ from 'lodash';
import { buildTestId } from 'Utils/testid';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import 'Utils/lodash';

const IconStyles = {
    Delete: <DeleteIcon />,
    Verified: <VerifiedIcon />,
} as const;

export type ModalButtonOptions = {
    title: string;
    isDefault?: boolean;
    isAccept?: boolean;
    id?: string;
    icon?: keyof typeof IconStyles;
};

type ModalOptions = {
    title: string; //
    buttons?: ModalButtonOptions[] | string[]; //
    style?: 'normal' | 'critical'; //
};

type InfoOptions = {
    title: string; //
    message: string; //
    style?: 'normal' | 'critical'; //
};

type OnAcceptFunction = (data: any, buttonId: string) => void | boolean | Promise<boolean | undefined | void>;

type OnFormSubmitFunction<FormFields = any> = (values: FormFields) => void | boolean | Promise<void | boolean>;

type ShowMethodFluidApi = {
    onAccept: (cb: OnAcceptFunction) => ShowMethodFluidApi;
    onCancel: (cb: (buttonId: string) => void) => ShowMethodFluidApi;
    onClose: (cb: () => void) => ShowMethodFluidApi;
};

type ModalContent = ReactNode | FC<{ initData: any; onDataChange: (data: any) => void }>;

// duck-type check the value for being a Promise
function isPromise<T>(value: any): value is Promise<T> {
    return !!value && typeof (value as Promise<T>).then === 'function';
}

// wrap a value into a resolved Promise, if it is not already a Promise
function asPromise<T extends any>(value: T | Promise<T>) {
    return isPromise<T>(value) ? value : Promise.resolve(value);
}

type FormMethodFluidApi<FormFields = any> = {
    onSubmit: (cb: OnFormSubmitFunction<FormFields>) => FormMethodFluidApi<FormFields>;
    onCancel: (cb: (buttonId: string) => void) => FormMethodFluidApi<FormFields>;
};

type ModalContextInternal = {
    show: (options: ModalOptions, modalElements: ModalContent, data?: Object) => ShowMethodFluidApi;
    form: <FormFields = any, FormProps = {}>(
        options: ModalOptions,
        form: ModalContent | FC<FormProps>,
        formProps?: FormProps
    ) => FormMethodFluidApi<FormFields>;
    info: (options: InfoOptions | string) => void;
    updateFormikState: (newFormikState: FormikProps<{}>) => void;
    onFormSubmit: (values: any, actions: any) => void | Promise<void>;
};

// some methods of the context are used to wire up formik forms, only include "public" methods in
// the "public" context API (returned by `useModal`).
type PublicModalContext = Pick<ModalContextInternal, 'show' | 'form' | 'info'>;

const ModalContext: React.Context<ModalContextInternal> = createContext<ModalContextInternal>(undefined);

type ModalState = ModalOptions & {
    onAcceptCallback?: OnAcceptFunction;
    onCancelCallback?: (buttonId: string) => void;
    onCloseCallback?: () => void;
} & {
    content?: ReactNode;
} & { innerData?: any } & {
    formikState?: any;
    onSubmitCallback?: (data: FormikProps<any>) => boolean | void | Promise<boolean | void>;
    closeModal: boolean;
};

export const ModalProvider = ({ children }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [isModalLocked, lockModal] = useState<boolean>(false);
    const [isFormikValid, setIsFormikValid] = useState<boolean | undefined>(undefined);

    const modalState = useRef<ModalState>();

    const messageBox = useMessageBox();

    const showMethodFluidApi: ShowMethodFluidApi = useMemo(
        () => ({
            onAccept: (cb) => {
                modalState.current.onAcceptCallback = cb;
                return showMethodFluidApi;
            },
            onCancel: (cb) => {
                modalState.current.onCancelCallback = cb;
                return showMethodFluidApi;
            },
            onClose: (cb) => {
                modalState.current.onCloseCallback = cb;
                return showMethodFluidApi;
            },
        }),
        [modalState.current?.onAcceptCallback, modalState.current?.onCancelCallback, modalState.current?.onCloseCallback]
    );

    function normalizeButtonOptions(options: ModalOptions) {
        let buttons = options.buttons;
        if (!options.buttons) {
            // use single default button
            buttons = [{ title: 'Ok', isDefault: true, isAccept: true, id: 'ok' }];
        } else if (_.every(options.buttons, _.isString)) {
            // if only titles are given - select first button as accept+default
            buttons = _.map(options.buttons as string[], (title) => ({ title }));
            buttons[0] = { ...buttons[0], isAccept: true, isDefault: true };
        }
        // default id to button title
        return _.map(buttons as ModalButtonOptions[], (button) => ({ id: button.title, ...button }));
    }

    const show = useCallback(
        (options: ModalOptions, modalElements: ModalContent, data?: any): ShowMethodFluidApi => {
            const buttons = normalizeButtonOptions(options);
            modalState.current = {
                title: options.title,
                style: options.style, // if content is a function - it has to accept a props object
                // with two fields
                // (initData, onDataChange)
                content:
                    typeof modalElements === 'function'
                        ? React.createElement(modalElements, {
                              initData: data,
                              onDataChange: (newValue: any) => {
                                  modalState.current.innerData = newValue;
                              },
                          })
                        : modalElements,
                innerData: data,
                onSubmitCallback() {
                    throw new Error('On submit callback is not set. Did you forget to call modal.form(...).onSubmit?');
                },
                closeModal: false,
                buttons,
            };

            lockModal(false);
            setOpen(true);

            return showMethodFluidApi;
        },
        [showMethodFluidApi]
    );

    // this is a method that ModalFormik calls when submit has been triggered
    const onFormSubmit = useCallback((values: any) => {
        // modalState.current.onSubmitCallback is the callback
        // modal.form(...).onSubmit( _that_came_from_here_ );
        return asPromise(modalState.current.onSubmitCallback(values)).then((closeModal) => {
            modalState.current.closeModal = closeModal !== false;
        });
    }, []);

    const form = useCallback(
        (options: ModalOptions, form: ModalContent | FC, formProps?: {}) => {
            let onCancelCallback;
            let api = {
                onSubmit: (cb: OnFormSubmitFunction) => {
                    modalState.current.onSubmitCallback = cb;
                    return api;
                },
                onCancel: (cb: (buttonId: string) => void) => {
                    onCancelCallback = cb;
                    return api;
                },
            };

            const normalizedForm = (form as any).type !== undefined ? form : React.createElement(form as any, formProps);
            show(options, normalizedForm)
                .onAccept(() => {
                    return modalState.current.formikState?.submitForm().then(
                        () => {
                            if (modalState.current.formikState?.isValid) {
                                // input was valid - return whatever onSubmit has decided.
                                return modalState.current.closeModal;
                            } else {
                                /*
                Setting field value to `undefined` breaks the validation logic on form submit :(
                But it seems better to loose validation than change all the code to use something other than undefined
                (e.g. empty string) everywhere.
                One more reason to move away from formik. 
                https://github.com/jaredpalmer/formik/issues/2332
                 */
                                _.forEach(modalState.current.formikState.errors, (error, fieldName) => {
                                    modalState.current.formikState.setFieldTouched(fieldName, true, false);
                                });
                                // form was invalid - do not close modal
                                return false;
                            }
                        },
                        (error) => {
                            // show error, do not close
                            messageBox.error(error);
                            return false;
                        }
                    );
                })
                .onCancel((buttonId) => {
                    onCancelCallback?.(buttonId);
                });

            setIsFormikValid(false);

            return api;
        },
        [messageBox, show]
    );

    const info = useCallback(
        (options: InfoOptions | string) => {
            if (typeof options === 'string') {
                show({ title: 'Information', buttons: ['Ok'] }, <Typography>{options}</Typography>);
            } else {
                show({ title: options.title, buttons: ['Ok'], style: options.style }, <Typography>{options.message}</Typography>);
            }
        },
        [messageBox, show]
    );

    const handleClose = () => {
        modalState.current.onCloseCallback?.();
        setOpen(false);
        lockModal(false);
    };

    const handleAccept = (buttonId: string) => {
        const onAcceptResult = modalState.current.onAcceptCallback?.(modalState.current.innerData, buttonId);
        if (isPromise<boolean | void>(onAcceptResult)) {
            lockModal(true);
            onAcceptResult.then(
                (shouldClose) => {
                    if (shouldClose !== false) {
                        handleClose();
                    } else {
                        lockModal(false);
                    }
                },
                () => {
                    // promise rejected, unlock the modal but don't close it
                    lockModal(false);
                }
            );
        } else if (onAcceptResult !== false) {
            handleClose();
        }
    };

    const handleCancel = (buttonId: string) => {
        modalState.current.onCancelCallback?.(buttonId);
        handleClose();
    };

    const updateFormikState = useCallback((newFormikState: FormikProps<{}>) => {
        modalState.current.formikState = newFormikState;
        // HACK: every time there is a change to the formik form state we need to update the buttons
        // which are outside the form, so we need to send new formik state up to the modal context
        // *while* formik form is rendering; which generates a react warning.
        // To work around that, we delay internal state update by using setTimeout with delay of 0.
        window.setTimeout(() => {
            setIsFormikValid(modalState.current.formikState.isValid);
        }, 0);
    }, []);

    const context: ModalContextInternal = useMemo(
        () => ({ show, form, info, updateFormikState, onFormSubmit }),
        [show, form, info, updateFormikState, onFormSubmit]
    );

    // id for the X button in the modal top corner
    const specialButtonXId = '_x';

    const testId = buildTestId({ modal: modalState.current?.title });

    return (
        <ModalContext.Provider value={context}>
            {isModalLocked && (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            {children}
            {modalState.current && (
                <Dialog open={open} onClose={handleCancel} fullWidth={true} maxWidth={'sm'} data-testid={testId}>
                    <DialogTitle sx={{ paddingRight: '56px' }}>
                        {modalState.current.title}
                        <IconButton
                            onClick={() => handleCancel(specialButtonXId)}
                            size="large"
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                            disabled={isModalLocked}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>{modalState.current.content}</DialogContent>
                    <DialogActions sx={{ mr: 2, mb: 2 }}>
                        {modalState.current.buttons
                            .map((button) => {
                                return (
                                    <Button
                                        sx={{ minWidth: '130px' }}
                                        startIcon={button.icon && IconStyles[button.icon]}
                                        color={button.isAccept && modalState.current.style === 'critical' ? 'error' : undefined}
                                        key={button.id}
                                        data-testid={buildTestId(testId, { button: button.id })}
                                        onClick={() => (button.isAccept ? handleAccept(button.id) : handleCancel(button.id))}
                                        variant={button.isAccept ? 'primary' : 'secondary'}
                                        autoFocus={button.isDefault}
                                        disabled={isModalLocked}
                                    >
                                        {button.title}
                                    </Button>
                                );
                            })
                            .reverse()}
                    </DialogActions>
                </Dialog>
            )}
        </ModalContext.Provider>
    );
};

/**
 * A Formik extension that is usable inside a Modal.
 */
export function ModalFormik<Values extends FormikValues = FormikValues, ExtraProps = {}>(
    props: Omit<FormikConfig<Values>, 'onSubmit'> & ExtraProps
): JSX.Element {
    const { updateFormikState, onFormSubmit } = useContext(ModalContext);
    return (
        <OriginalFormik {...props} onSubmit={onFormSubmit}>
            {(formikProps) => {
                updateFormikState(formikProps);
                if (typeof props.children === 'function') {
                    return props.children(formikProps);
                } else {
                    return props.children;
                }
            }}
        </OriginalFormik>
    );
}

// Note: only returning a "public" part of the context.
export const useModal: () => PublicModalContext = () => {
    const context = useContext(ModalContext);
    if (context === undefined) {
        logger.error('No modal context available yet. Did you forget to include <ModalProvider>?');
    }
    return context;
};
