import React, { FC, useEffect, useMemo, useState } from 'react';
import { withInitializers } from 'Utils/withInitializers';
import {
    AssessmentsAssessment,
    EntitiesAssetType,
    EntitiesAssetType as AssetType,
    QuestionnairesLmrModule,
    QuestionnairesQuestionnaire,
    QuestionnairesQuestionnaireWithScoreInt32,
    UnifiedAssetsAsset,
} from '@projectcanary/trustwell-server-client-ts';
import { questionnaireApi, unifiedAssetApi } from 'Services/TrustwellApiService';
import { Page } from 'Components/Page';
import { Card, MenuItem, Select, Stack } from '@mui/material';
import * as _ from 'lodash';
import { EmissionIntensitySectionComponent } from './EmissionIntensitySectionComponent';
import { EmissionControlSectionComponent } from './EmissionControlSectionComponent';
import { withRouteParams } from 'Utils/withRouteParams';
import { useNavigation } from 'Utils/Navigation';

export type LmrPageProps = {
    assessment: AssessmentsAssessment;
    lmrDefinition: QuestionnairesLmrModule;
    assets: UnifiedAssetsAsset[];
    section?: string;
};

type LmrSection = {
    title: string;
    questionnaires: QuestionnairesQuestionnaireWithScoreInt32[] | QuestionnairesQuestionnaire[];
    component: FC<LmrSectionData>;
};

export type LmrSectionData = {
    questionnaires: QuestionnairesQuestionnaireWithScoreInt32[] | QuestionnairesQuestionnaire[];
    assets: UnifiedAssetsAsset[];
    assessmentId: number;
    moduleId: number;
};

function buildPathFromTitle(title: string): string {
    let transformedString = title.toLowerCase();
    transformedString = transformedString.replace(/[^a-z0-9]+/g, '-');
    transformedString = transformedString.replace(/-+/g, '-');
    transformedString = transformedString.replace(/^-+/g, '');
    transformedString = transformedString.replace(/-+$/g, '');
    return transformedString;
}

function findSectionByPathParameter(sections: LmrSection[], sectionFromPath: string | undefined) {
    return sectionFromPath && _.find(sections, (s) => buildPathFromTitle(s.title) === sectionFromPath);
}

export const InnerLmrPage = (props: LmrPageProps) => {
    const sections = useMemo(
        () =>
            [
                {
                    title: 'Emission Intensity',
                    questionnaires: props.lmrDefinition.methaneIntensityQuestionnaires,
                    component: EmissionIntensitySectionComponent,
                },
                ..._.map(props.lmrDefinition.rubrics, (r) => ({
                    title: r.name,
                    questionnaires: r.questionnaires,
                    component: EmissionControlSectionComponent,
                })),
            ] as LmrSection[],
        [props.lmrDefinition]
    );

    const [selectedSection, selectSection] = useState<LmrSection>(findSectionByPathParameter(sections, props.section) ?? sections[0]);
    const { site, replacePath } = useNavigation();

    useEffect(() => {
        const sectionSelectedInPath = findSectionByPathParameter(sections, props.section);
        if (sectionSelectedInPath) {
            selectSection(sectionSelectedInPath);
        }
    }, [props.section]);

    useEffect(() => {
        const path = site.assessment(props.assessment.id).lmr(buildPathFromTitle(selectedSection.title));
        replacePath(path);
    }, [selectedSection, replacePath]);

    return (
        <Page title={'Low Methane Rating'}>
            <Card sx={{ pb: 2, pt: 2, pl: 5 }}>
                <Stack direction={'row'}>
                    <Select
                        sx={{ minWidth: 350 }}
                        labelId={'lmrSection'}
                        displayEmpty={false}
                        value={selectedSection.title}
                        onChange={(e) => selectSection(_.find(sections, { title: e.target.value }))}
                    >
                        {sections.map((s) => {
                            return (
                                <MenuItem key={s.title} value={s.title}>
                                    {s.title}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Stack>
            </Card>
            {React.createElement(selectedSection.component, {
                assets: props.assets,
                questionnaires: selectedSection.questionnaires,
                assessmentId: props.assessment.id,
                moduleId: props.lmrDefinition.moduleId,
            })}
        </Page>
    );
};

export const LmrPage = withInitializers<LmrPageProps, 'lmrDefinition' | 'assets'>(
    {
        async lmrDefinition(props) {
            const qApi = await questionnaireApi();
            const lmrDefinition = await qApi.getLmrDefinition();
            return lmrDefinition;
        },
        async assets(props) {
            const assetApiInstance = await unifiedAssetApi();
            const assets = await assetApiInstance.getAssets({
                assessmentId: props.assessment.id,
                assetTypes: [AssetType.Pad, AssetType.Basin, EntitiesAssetType.Company],
            });
            return assets.assets;
        },
    },
    withRouteParams({ section: String }, InnerLmrPage)
);
