import * as Yup from 'Utils/yup';

export const ValidationSchemas = {
    producerName(label?: string) {
        return Yup.string()
            .trim()
            .label(label ?? 'Producer name')
            .min(3)
            .max(100);
    },
    padName(label?: string) {
        return Yup.string()
            .label(label ?? 'Pad name')
            .min(3)
            .max(100);
    },
};
