import React, { ForwardedRef, forwardRef, FunctionComponent, ReactElement } from 'react';
import { Box, Tooltip } from '@mui/material';

export type DisablableComponentProps = { disabled?: boolean };

export type StringDisabledProps<T extends DisablableComponentProps> = Omit<T, 'disabled'> & { disabled?: boolean | string | undefined };

export const withDisabledMessage: <T extends DisablableComponentProps>(
    InnerComponent: React.FunctionComponent<T> | React.ReactElement
) => React.ForwardRefExoticComponent<React.PropsWithoutRef<StringDisabledProps<T>> & React.RefAttributes<T>> = <
    T extends {
        disabled?: boolean;
    }
>(
    InnerComponent: FunctionComponent<T> | ReactElement
) => {
    return forwardRef((wrapperProps: StringDisabledProps<T>, ref: ForwardedRef<T>) => {
        const { disabled, ...restProps } = wrapperProps;

        const newProps = {
            ...restProps,
            disabled: !!disabled,
        };
        const innerComponent: React.ReactElement<T> = React.isValidElement<T>(InnerComponent)
            ? InnerComponent
            : React.createElement(InnerComponent as FunctionComponent<T>, { ...(newProps as T), ref: ref });
        if (typeof disabled === 'string') {
            return (
                <Tooltip title={disabled} placement={'bottom-start'} arrow={true}>
                    {/* tooltip won't show on a disabled element, so we wrap disabled element in another element */}
                    <Box>{innerComponent}</Box>
                </Tooltip>
            );
        } else {
            return innerComponent;
        }
    });
};
