import { Auth } from 'aws-amplify';
import * as api from '@projectcanary/trustwell-server-client-ts';
import {
    AssessmentDefinitionsImportAssessmentDefinitionResponse,
    AssetsImportAssetsResponse,
    Configuration,
    DocumentsCreateDocumentsRequest,
    DocumentsDeleteDocumentRequest,
    DocumentsDocument,
    LmrLmrWellKnownQuestions,
    QuestionnairesGetResponsesData,
    QuestionnairesGetResponsesResponse,
} from '@projectcanary/trustwell-server-client-ts';
import * as isomorphicFetch from 'isomorphic-fetch';
import * as datefns from 'date-fns';
import * as _ from 'lodash';
import Logger from 'Utils/Logger';
import { subscribeToErrors } from 'Controls/MessageBox';
import { randomUniqueId } from 'Utils/uniqueIdGenerator';
import { Annotation } from 'Components/QMatrix/QContext';

const { REACT_APP_API_BASE_URL } = process.env;

export async function getBearerToken() {
    const session = await Auth.currentSession();
    const cognitoToken = session.getIdToken();
    return 'Bearer ' + cognitoToken.getJwtToken();
}

// This mechanism shows an (ugly) error message if no error messages
// were displayed within 100 ms of network operation failing.
// The purpose is ensure (or rather increase the likelihood) that user is notified of errors even if we forgot to
// catch API errors with a try-catch.
// Notice that we don't have react context at this point, so we can't use `useMessageBox` here.
let errorWasDisplayed = false;
subscribeToErrors(() => {
    errorWasDisplayed = true;
});

function setupFallbackError(errorMessage: string) {
    errorWasDisplayed = false;
    window.setTimeout(() => {
        if (errorWasDisplayed === false) {
            errorWasDisplayed = true;
            window.alert(errorMessage);
            Logger.error('Using a fallback error to show an error message. Forgot to put try-catch around an api call?');
        }
    }, 100);
}

async function getConfig(): Promise<Configuration> {
    const config = {
        basePath: REACT_APP_API_BASE_URL,
    } as { basePath: string; apiKey: string };

    let bearerToken: string;
    try {
        bearerToken = await getBearerToken();
    } catch (error) {
        console.log('Failed to get a bearer token. ' + error);
    }
    config.apiKey = bearerToken;
    return config;
}

function convertDateStringsToDates(input: any) {
    // Ignore things that aren't objects.
    if (input === null || typeof input !== 'object') {
        return input;
    }

    _.transform(
        input,
        (sum, value, key) => {
            if (input.hasOwnProperty(key)) {
                if (typeof value === 'string') {
                    const parsedDate = datefns.parseJSON(value);
                    if (datefns.isValid(parsedDate)) {
                        sum[key] = parsedDate;
                    }
                } else if (typeof value === 'object') {
                    // Recurse into object
                    convertDateStringsToDates(value);
                }
            }
            return sum;
        },
        input
    );

    return input;
}

// wrapper for `fetch` to support Date conversions.
const appFetch = async (url, options) => {
    options.headers['x-correlation-id'] = randomUniqueId();

    let response;
    try {
        response = await isomorphicFetch(url, options);
    } catch (e) {
        Logger.error('AJAX call to ' + url + ' has failed. ' + e.toString());
        setupFallbackError('The server is offline or unreachable.');
        throw 'The server is offline or unreachable.';
    }

    if (response.status < 200 || response.status >= 300) {
        Logger.error(`An AJAX call to ${url} has returned error code ${response.status}`);
        const responseJson = await response.json();
        const error =
            responseJson?.userMessage ??
            (response.status < 500
                ? 'The operation has failed. Please check your input and try again.'
                : 'An error occured and might not to be caused by your input.');
        setupFallbackError(error);
        throw error;
    }

    response.json = _.wrap(response.json.bind(response), async (originalJsonFunction) => {
        const jsonValue = await originalJsonFunction();
        return convertDateStringsToDates(jsonValue);
    });

    return response;
};

export async function companyApi(): Promise<api.CompanyApi> {
    return new api.CompanyApi(await getConfig(), undefined, appFetch);
}

export async function countryApi(): Promise<api.CountryApi> {
    return new api.CountryApi(await getConfig(), undefined, appFetch);
}

export async function assessmentDefinitionApi(): Promise<api.AssessmentDefinitionsApi> {
    const asntDefApi = new api.AssessmentDefinitionsApi(await getConfig(), undefined, appFetch);
    // override the broken function with our own, working, implementation
    asntDefApi.importAssessmentDefinition = async function (
        file?: Blob,
        name?: string,
        options?: any
    ): Promise<AssessmentDefinitionsImportAssessmentDefinitionResponse> {
        const endpointUrl = `${REACT_APP_API_BASE_URL}/AssessmentDefinitions/ImportAssessmentDefinition?` + new URLSearchParams({ name });
        const formData = new FormData();
        formData.append('file', file);
        const response = await appFetch(endpointUrl, {
            method: 'POST',
            body: formData,
            headers: new Headers({
                Authorization: await getBearerToken(),
            }),
        });
        switch (response.ok) {
            case true:
                const json = await response.json();
                return json;
            default:
                throw new Error();
        }
    };
    return asntDefApi;
}

export type WellGroup = { id: number; name: string; wells: { id: number; name: string; registrationId: string }[] };
export type WellGroupMap = { [key: string]: WellGroup };
type getAssessmentWellGroups = (assessmentId: number, options?: any) => Promise<WellGroupMap>;

export async function assetApi(): Promise<api.AssetApi & { getAssessmentWellGroups }> {
    const assetApi = <api.AssetApi & { getAssessmentWellGroups }>new api.AssetApi(await getConfig(), undefined, appFetch);

    // override the broken function with our own, working, implementation
    assetApi._import = async function (file?: Blob, assessmentId?: number, bypassWarnings?: boolean, options?: any): Promise<AssetsImportAssetsResponse> {
        const endpointUrl = `${REACT_APP_API_BASE_URL}/Asset/Import?assessmentId=${assessmentId}&bypassWarnings=${bypassWarnings}`;
        const formData = new FormData();
        formData.append('file', file);
        const response = await appFetch(endpointUrl, {
            method: 'POST',
            body: formData,
            headers: new Headers({
                Authorization: await getBearerToken(),
            }),
        });
        switch (response.ok) {
            case true:
                const json = await response.json();
                return json;
            default:
                throw new Error();
        }
    };

    assetApi.getAssessmentWellGroups = async function (assessmentId: number): Promise<WellGroupMap> {
        const { assets } = await this.getAllAssetsForAssessment(assessmentId);
        return _.chain(assets)
            .groupBy((i) => i.wellGroupId)
            .values()
            .map((wells) => ({
                id: wells[0].wellGroupId,
                name: wells[0].wellGroupName,
                wells: _.map(wells, (well) => ({
                    id: well.id,
                    name: well.name,
                    registrationId: well.api10,
                })),
            }))
            .keyBy('id')
            .value();
    };

    return assetApi;
}

export async function assessmentApi(): Promise<api.AssessmentApi> {
    return new api.AssessmentApi(await getConfig(), undefined, appFetch);
}

export async function localFactorApi(): Promise<api.LocalFactorApi> {
    return new api.LocalFactorApi(await getConfig(), undefined, appFetch);
}

export async function eventApi(): Promise<api.EventsApi> {
    return new api.EventsApi(await getConfig(), undefined, appFetch);
}

export async function scoreApi(): Promise<api.ScoreApi> {
    return new api.ScoreApi(await getConfig(), undefined, appFetch);
}

export async function rubricApi(): Promise<api.RubricApi> {
    return new api.RubricApi(await getConfig(), undefined, appFetch);
}

export async function deductionApi(): Promise<api.DeductionApi> {
    return new api.DeductionApi(await getConfig(), undefined, appFetch);
}

export async function certificationApi(): Promise<api.CertificationApi> {
    return new api.CertificationApi(await getConfig(), undefined, appFetch);
}

export type ExtendedQuestionnaireApi = Omit<api.QuestionnaireApi, 'getResponses'> & {
    /**
     * Due to the limitations of our current code generation, enum is generated as bunch of `<any>` but
     * this method needs a string.
     */
    getResponses: (
        body?: Omit<QuestionnairesGetResponsesData, 'questionCids'> & {
            questionCids: (LmrLmrWellKnownQuestions | string)[];
        },
        options?: any
    ) => Promise<QuestionnairesGetResponsesResponse>;
};

export async function questionnaireApi(): Promise<ExtendedQuestionnaireApi> {
    return (await new api.QuestionnaireApi(await getConfig(), undefined, appFetch)) as unknown as ExtendedQuestionnaireApi;
}

export async function unifiedAssetApi(): Promise<api.UnifiedAssetApi> {
    return new api.UnifiedAssetApi(await getConfig(), undefined, appFetch);
}

type ExtendedAnnotationApi = api.AnnotationApi & {
    saveAssetAnnotation: (assessmentId: number, assetId: number, evaluationScopeId: number, annotation: Annotation) => Promise<void>;
    saveAssetQuestionAnnotations: (
        batch: {
            assessmentId: number;
            assetId: number;
            questionnaireQuestionId: number;
            evaluationScopeId: number;
            annotation: Annotation;
        }[]
    ) => Promise<void>;
};

export async function annotationApi(): Promise<ExtendedAnnotationApi> {
    const annotationApi: ExtendedAnnotationApi = new api.AnnotationApi(await getConfig(), undefined, appFetch) as ExtendedAnnotationApi;

    annotationApi.saveAssetAnnotation = async function (assessmentId: number, evaluationScopeId: number, assetId: number, annotation: Annotation) {
        const topic = {
            assessmentId: assessmentId,
            evaluationScopeId: evaluationScopeId,
            assetId: assetId,
        };

        const docRefTask = annotationApi.addEvaluationScopeAssetDocumentReferences([
            {
                topic,
                documentReferences: annotation.documentReference
                    ? [
                          {
                              documentId: annotation.documentReference.documentId,
                              citation: annotation.documentReference.citation,
                          },
                      ]
                    : [],
                replace: true,
            },
        ]);

        const noteTask = annotationApi.addEvaluationScopeAssetNotes([
            {
                topic,
                notes: annotation.note ? [{ text: annotation.note }] : [],
                replace: true,
            },
        ]);

        await Promise.all([docRefTask, noteTask]);
    };

    annotationApi.saveAssetQuestionAnnotations = async function (
        batch: {
            assessmentId: number;
            assetId: number;
            questionnaireQuestionId: number;
            evaluationScopeId: number;
            annotation: Annotation;
        }[]
    ) {
        var addDocRefsBatch = [];
        var addNotesBatch = [];
        for (const { assessmentId, assetId, questionnaireQuestionId, evaluationScopeId, annotation } of batch) {
            const topic = {
                assessmentId: assessmentId,
                evaluationScopeId: evaluationScopeId,
                assetId: assetId,
                questionnaireQuestionId: questionnaireQuestionId,
            };

            addDocRefsBatch.push({
                topic,
                documentReferences: annotation.documentReference
                    ? [
                          {
                              documentId: annotation.documentReference.documentId,
                              citation: annotation.documentReference.citation,
                          },
                      ]
                    : [],
                replace: true,
            });

            addNotesBatch.push({
                topic,
                notes: annotation.note ? [{ text: annotation.note }] : [],
                replace: true,
            });
        }

        await Promise.all([
            annotationApi.addEvaluationScopeAssetQuestionDocumentReferences(addDocRefsBatch),
            annotationApi.addEvaluationScopeAssetQuestionNotes(addNotesBatch),
        ]);
    };
    return annotationApi;
}

const documentCache: { [assessmentId: number]: { [documentName: string]: DocumentsDocument } } = {};

export async function documentApi(): Promise<api.DocumentApi> {
    const documentApi = await new api.DocumentApi(await getConfig(), undefined, appFetch);

    documentApi.createDocuments = _.wrap(
        documentApi.createDocuments,
        async function (originalCreateDocuments, body?: DocumentsCreateDocumentsRequest, options?: any) {
            const response = await originalCreateDocuments.bind(this)(body, options);
            const assessmentDocuments = (documentCache[body.assessmentId] ??= {});
            for (const document of response.documents) {
                assessmentDocuments[document.name] = document;
            }
            return response;
        }
    );

    documentApi.getDocuments = _.wrap(documentApi.getDocuments, async function (originalGetDocuments, assessmentId?: number, options?: any) {
        let assessmentDocuments = documentCache[assessmentId];
        if (assessmentDocuments === undefined) {
            assessmentDocuments = documentCache[assessmentId] = {};
            const documents = await originalGetDocuments.bind(this)(assessmentId, options);
            for (const document of documents) {
                assessmentDocuments[document.name] = document;
            }
        }
        return _.values(assessmentDocuments);
    });

    documentApi.deleteDocuments = _.wrap(
        documentApi.deleteDocuments,
        async function (originalDeleteDocuments, body?: DocumentsDeleteDocumentRequest, options?: any) {
            try {
                const response = await originalDeleteDocuments.bind(this).deleteDocuments(body, options);
                if (documentCache[body.assessmentId]) {
                    documentCache[body.assessmentId] = _.pickBy(documentCache[body.assessmentId], (document) => body.documentIds.includes(document.id));
                }
                return response;
            } catch (error) {
                delete documentCache[body.assessmentId];
                throw error;
            }
        }
    );

    return documentApi;
}
