import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { DeductionsAmortizationDecision, DeductionsDeductionAmortizationHistoryRecord } from '@projectcanary/trustwell-server-client-ts';
import { personNameColumn, utcDateFormatter } from 'Utils/formats';
import _ from 'lodash';

export type Row = DeductionsDeductionAmortizationHistoryRecord & { totalAmortizedPoints: number; pointsAmortized: number };

export const columns: GridColDef<Row>[] = [
    {
        headerName: 'Amortization Decision',
        field: 'decision',
        type: 'string',
        width: 300,
        renderCell: (params) => {
            const historyRecord = params.row;

            if (!historyRecord.decision) {
                return 'undecided';
            } else {
                return (
                    historyRecord.decision +
                    (historyRecord.decision == DeductionsAmortizationDecision.Postpone ? ' ⟶ ' + historyRecord.effectiveDecision : '') +
                    (historyRecord.isTentative ? ' (Tentative)' : '')
                );
            }
        },
        sortable: false,
    },
    {
        headerName: 'Decision Date',
        field: 'decisionDateTime',
        type: 'date',
        width: 300,
        sortable: false,
        valueFormatter: utcDateFormatter,
    },
    {
        headerName: 'Effective Date',
        field: 'decisionEffectiveDate',
        type: 'date',
        width: 200,
        sortable: false,
        valueFormatter: ({ value }) => (value ? utcDateFormatter({ value }) : 'next rating'),
    },
    {
        headerName: 'Decided By',
        field: 'decider',
        ...personNameColumn,
        sortable: false,
    },
    {
        headerName: 'Points Amortized',
        field: 'pointsAmortized',
        type: 'number',
        width: 200,
        sortable: false,
        valueFormatter: ({ value }) => _.round(value, 1),
    },
    {
        headerName: 'Total Points Amortized',
        field: 'totalAmortizedPoints',
        type: 'number',
        width: 200,
        sortable: false,
        valueFormatter: ({ value }) => _.round(value, 1),
    },
    {
        headerName: 'Total Years Amortized',
        field: 'totalAmortizationCount',
        type: 'number',
        width: 200,
        sortable: false,
    },
];
