import {
    AssessmentsAssessment,
    LocalFactorsLocalFactorScore,
    LocalFactorsCreateAssessmentLocalFactorScoreRequest,
    LocalFactorsLocalFactor,
    LocalFactorsWellGroupComplete,
    LocalFactorsAssessmentLocalFactorScore,
    AssessmentsWellGroup,
} from '@projectcanary/trustwell-server-client-ts';

export enum LocalFactorConfigStatus {
    Unconfigured = 1,
    SelectConfig,
    ConfirmConfig,
    Configured,
}
export enum LocalFactorScope {
    override = 'Override',
    wellGroup = 'Well Group',
    assessment = 'Assessment',
    notApplicable = 'Local Factor N/A',
}
export type LocalFactorScore = number | number[] | 'N/A';
export type FormattedScoresObject = { [localFactorId: number]: LocalFactorScore };

export const formatNewScoreObjForPost = (
    value: LocalFactorScore,
    factor: LocalFactorsLocalFactor,
    target: 'reset' | 'checkbox' | 'slider',
    selectedWellGroupId: number,
    assessmentId: number,
    assessmentDefinitionId: number
) => {
    let newScore;
    if (target === 'checkbox') {
        if (value === 'N/A') {
            newScore = 3.0;
        } else {
            newScore = 'N/A';
        }
    } else if (target === 'slider' || target === 'reset') {
        newScore = value;
    }
    const newScoreObj = {
        assessmentId: assessmentId,
        localFactorId: factor.localFactorId,
        score: newScore === 'N/A' ? null : newScore,
        wellGroupId: selectedWellGroupId,
        localFactorVersionId: assessmentDefinitionId,
    };
    return newScoreObj;
};

export const formatScoreUpdatesFromResponse = (
    response: LocalFactorsLocalFactorScore,
    factor: LocalFactorsLocalFactor,
    currentWellGroupScoreArray: LocalFactorsLocalFactorScore[],
    currentWellGroupScoreObj: FormattedScoresObject
) => {
    const currentWellGroupScoresCopy = [...currentWellGroupScoreArray];
    const priorScore = currentWellGroupScoresCopy.find(
        (oldScore) => oldScore.localFactorId === response.localFactorId && oldScore.wellGroupId === response.wellGroupId
    );
    if (priorScore) {
        priorScore.score = response.score;
    } else {
        currentWellGroupScoresCopy.push(response);
    }
    const updatedScoreObject = {
        ...currentWellGroupScoreObj,
        [factor.localFactorId]: response.score,
    };
    return {
        updatedScoreArray: currentWellGroupScoresCopy,
        updatedScoreObj: updatedScoreObject,
    };
};

export const checkComplete = (wellGroupsComplete: LocalFactorsWellGroupComplete[], selectedWellGroup: AssessmentsWellGroup) => {
    // default to true to prevent changes until all data loaded for well group
    const completionObj = wellGroupsComplete.find((wellGroup) => wellGroup.wellGroupId === selectedWellGroup.id) ?? { wellGroupComplete: false };
    return completionObj.wellGroupComplete;
};

export const updateCompletionArray = (response: LocalFactorsWellGroupComplete, wellGroupsComplete: LocalFactorsWellGroupComplete[]) => {
    const updatedWellGroupsComplete = [...wellGroupsComplete];
    const updatedWellGroup = updatedWellGroupsComplete.find((wg) => wg.wellGroupId === response.wellGroupId);
    if (!updatedWellGroup) {
        updatedWellGroupsComplete.push(response);
    } else {
        updatedWellGroup.wellGroupComplete = response.wellGroupComplete;
    }
    return updatedWellGroupsComplete;
};

export const formatTemplateScoreObjects = (assessmentLevelScores: LocalFactorsAssessmentLocalFactorScore[]) => {
    const assessmentAndNotApplicableScoreObj = {};
    const wellGroupScoreObj = {};
    assessmentLevelScores.forEach((factor) => {
        if (factor.assessmentFactor) {
            const factorScore = { [factor.localFactorId]: factor.score };
            Object.assign(assessmentAndNotApplicableScoreObj, factorScore);
        } else if (factor.notApplicableFactor) {
            const factorScore = { [factor.localFactorId]: 'N/A' };
            Object.assign(assessmentAndNotApplicableScoreObj, factorScore);
        } else if (factor.wellGroupFactor) {
            const defaultWellGroupScore = { [factor.localFactorId]: null };
            Object.assign(wellGroupScoreObj, defaultWellGroupScore);
        }
    });

    return {
        assessmentAndNotApplicable: assessmentAndNotApplicableScoreObj,
        wellGroup: wellGroupScoreObj,
    };
};

export const formatAssessmentScoresForPost = (
    assessmentScores: FormattedScoresObject,
    configSelections: object,
    assessment: AssessmentsAssessment
): LocalFactorsCreateAssessmentLocalFactorScoreRequest => {
    const scoreArray = [];
    for (const [factorId, selection] of Object.entries(configSelections)) {
        if (selection === 'Local Factor N/A') {
            scoreArray.push({
                AssessmentId: assessment.id,
                LocalfactorVersionId: assessment.versionId,
                LocalfactorId: parseInt(factorId),
                Score: null,
                NotApplicableFactor: true,
                AssessmentFactor: false,
                WellGroupFactor: false,
            });
        } else if (selection === 'Assessment') {
            scoreArray.push({
                AssessmentId: assessment.id,
                LocalfactorVersionId: assessment.versionId,
                LocalfactorId: parseInt(factorId),
                Score: assessmentScores[factorId],
                NotApplicableFactor: false,
                AssessmentFactor: true,
                WellGroupFactor: false,
            });
        } else if (selection === 'Well Group') {
            scoreArray.push({
                AssessmentId: assessment.id,
                LocalfactorVersionId: assessment.versionId,
                LocalfactorId: parseInt(factorId),
                Score: null,
                NotApplicableFactor: false,
                AssessmentFactor: false,
                WellGroupFactor: true,
            });
        }
    }
    return { payload: scoreArray } as LocalFactorsCreateAssessmentLocalFactorScoreRequest;
};

export const formatOverrideAndWellGroupScores = (
    templateScoreObj: FormattedScoresObject,
    assessmentDefaultScores: FormattedScoresObject,
    currentWellGroupScores: LocalFactorsLocalFactorScore[]
) => {
    const newScoreObj = { ...templateScoreObj };
    const overrideObj = { ...assessmentDefaultScores };
    for (let lfScore of currentWellGroupScores) {
        const factorId = lfScore.localFactorId;
        const score = lfScore.score || 'N/A';
        if (factorId in overrideObj) {
            if (overrideObj[factorId] === lfScore.score) {
                continue;
            } else {
                overrideObj[factorId] = score;
            }
        } else {
            newScoreObj[lfScore.localFactorId] = score;
        }
    }
    return {
        updatedWellGroupScores: newScoreObj,
        updatedOverrideScores: overrideObj,
    };
};
