/**
 * This is well rating status for rendering. It matches the back-end rating status, but adds "ExpiresSoon", because
 * we render it differently.
 */
export enum WellCertStatus {
    Expired,
    ExpiresSoon,
    NeverCertified,
    Active,
    Upcoming,
}
