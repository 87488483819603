import React, { useContext, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';

type KeyboardShortcut = { shortcut: any; callback: (event: any) => void };

export type KeyboardShortcutsContextType = {
    register: (ks: KeyboardShortcut) => void;
    unregister: (shortcut: KeyboardShortcut['shortcut']) => void;
};

const KeyboardShortcutsContext = React.createContext<KeyboardShortcutsContextType>({
    register: () => {},
    unregister: () => {},
});

export const KeyboardShortcutsProvider = ({ children }) => {
    const shortcuts = useRef<KeyboardShortcut[]>([]);

    const context = useMemo(
        () => ({
            register: (ks: KeyboardShortcut) => {
                shortcuts.current.push(ks);
            },
            unregister: (shortcut: KeyboardShortcut['shortcut']) => {
                _.remove(shortcuts.current, (s) => _.isEqual(s.shortcut, shortcut));
            },
        }),
        []
    );

    useEffect(() => {
        const listener = (event) => {
            const callback = _.find(shortcuts.current, (s) => _.matches(s.shortcut)(event))?.callback;
            if (callback) {
                callback(event);
            }
        };
        window.addEventListener('keydown', listener);
        return () => window.removeEventListener('keydown', listener);
    }, []);
    return <KeyboardShortcutsContext.Provider value={context}>{children}</KeyboardShortcutsContext.Provider>;
};

export const useKeyboardShortcut = (shortcut: KeyboardShortcut['shortcut'] | undefined, callback: KeyboardShortcut['callback']) => {
    const keyboardShortcutContext = useContext<KeyboardShortcutsContextType>(KeyboardShortcutsContext);
    useEffect(() => {
        if (shortcut) {
            keyboardShortcutContext.register({ shortcut: shortcut, callback: callback });
            return () => {
                keyboardShortcutContext.unregister(shortcut);
            };
        }
    }, [shortcut, callback, keyboardShortcutContext]);
};
